import { Button } from '@mui/material';
import { AxiosResponse } from 'axios';
import { useSnackbar } from 'notistack';
import { useEffect, useState } from 'react';
import { CreateSenderDialog } from '../../components/SendersListComponent/CreateSenderDialog';
import { ISender } from '../../components/SendersListComponent/interfaces';
import { createSender } from '../../utils/api/POST';
import { getSenders } from '../../utils/api/GET';
import { Container } from '../../components/Container';
import { defineColumns } from './helpers';
import { DataGrid } from '@mui/x-data-grid';

const TemplateList: React.FC = (): JSX.Element => {
  const [show, setShow] = useState(false);
  const [email, setEmail] = useState('');
  const [loading, setLoading] = useState(false);
  const [, setSenderEmail] = useState('');
  const [senders, setSenders] = useState<ISender[]>([]);
  const [, setSenderEdit] = useState<ISender>();

  const { enqueueSnackbar } = useSnackbar();

  const handleGetSenders = (): void => {
    setLoading(true);
    getSenders()
      .then((res: AxiosResponse<any> | undefined) => {
        if (res) {
          const data = res.data;
          setSenders(data);
          setLoading(false);
        }
      })
      .catch(() => {
        enqueueSnackbar(
          'Não foi possível carregar as informações requeridas, por favor recarregue a página.',
          {
            variant: 'error',
            autoHideDuration: 3000,
          }
        );
      });
  };

  const handleCreateSender = () => {
    setLoading(true);
    createSender(email)
      .then(res => {
        if (res?.status === 201) {
          enqueueSnackbar('Remetente cadastrado!', {
            variant: 'success',
            preventDuplicate: true,
            autoHideDuration: 3000,
          });
          setShow(false);
          setLoading(false);
          handleGetSenders();
          setEmail('');
        }
      })
      .catch(() => {
        setLoading(false);
        enqueueSnackbar('Este remetente já existe em nosso banco de dados.', {
          variant: 'error',
          preventDuplicate: true,
          autoHideDuration: 3000,
        });
      });
  };

  useEffect(() => {
    handleGetSenders();
    setSenderEmail('');
  }, []);

  return (
    <Container
      title="Lista de Remetentes"
      topRightContent={
        <Button
          onClick={() => {
            setShow(true);
          }}
        >
          Novo remetente
        </Button>
      }
    >
      <CreateSenderDialog
        email={email}
        show={show}
        loading={loading}
        handleCreateSender={handleCreateSender}
        setShow={setShow}
        setEmail={setEmail}
      />

      <DataGrid
        columns={defineColumns(setSenderEdit, setShow)}
        rows={senders}
      />

      {/* <ContentContainer>
        <TopContainer>
          <FiltersContainer>
            <SenderFilter
              senderEmail={senderEmail}
              handleChange={handleChangeFilter}
              handleGetSenders={handleGetSenders}
              setSenderEmail={setSenderEmail}
            />
          </FiltersContainer>
        </TopContainer>

        <SenderListContainer container>
          <SenderListTitles container>
            <Title>E-mail</Title>
            <Title>ID</Title>
            <Title>Verificado</Title>
            <Title>Ações</Title>
          </SenderListTitles>
          <DividerLine />

          {loading ? (
            <CircularProgressBox>
              <CircularProgress size={24} />
            </CircularProgressBox>
          ) : !senders.length ? (
            <NoSendersContainer>
              <NoSendersText variant="body1">
                Não existe remetentes cadastrados.
              </NoSendersText>
            </NoSendersContainer>
          ) : (
						<>
							Teste
							{senders.map((sender) => (
								<>
									<Sender
										senderID={senderID}
										verifyLoading={verifyLoading}
										handleVerifySender={handleVerifySender}
										sender={sender}
										key={sender.id}
									/>
									<DividerLine />
								</>
							))}
						</>
          )}
        </SenderListContainer>
      </ContentContainer> */}
    </Container>
  );
};

export default TemplateList;
