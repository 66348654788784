import { Form, Formik, FormikConfig, FormikValues } from 'formik';
import { useTranslation } from 'react-i18next';
import { useSnackbar } from 'notistack';
import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { RemoveCampaign } from '../../Auth/actions/campaing.action';
import { ICampaing } from '../../Auth/interfaces/campaing.interface';
import { IReducers } from '../../Auth/store/store';
import { BASE_RCS_URL, BASE_URL, BASE_WHATSAPP_URL, BASE_EMAIL_URL } from '../../utils/api';
import {
  createCampaing,
  createWhatsappCampaing,
  uploadRichCardFile,
} from '../../utils/api/POST/';
import CampaingTabs from '../Tabs';
import campaingValidation from './constants/campaingValidation';
import CAMPAING_INITIAL_VALUES from './constants/initials-values-campaingForm.constant';
import Container from '../../components/Container';

const FormStepper: React.FC<any> = ({
  onSubmit,
}: FormikConfig<FormikValues>): JSX.Element => {
  const { t } = useTranslation();

  const [step, setStep] = useState(0);
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const history = useHistory();
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();

  const { fileStore }: ICampaing = useSelector(
    (state: IReducers) => state.campaing
  );
  const currentValidationSchema = campaingValidation[step];

  const setCampaingReduxValues = (): void => {
    dispatch(RemoveCampaign());
  };

  useEffect(() => {
    dispatch(RemoveCampaign());
  }, []);

  return (
    <Formik
      // validationSchema={currentValidationSchema}
      initialValues={CAMPAING_INITIAL_VALUES}
      onSubmit={async (values, helpers) => {
        await onSubmit(values, helpers);
        let {
          template,
          title,
          sender,
          filename,
          headers,
          schedule_date,
          start_date,
          end_date,
          campaign_type,
          matrix_variables,
          use_file,
          tags,
          start_hour,
          end_hour,
          use_whitelist,
          use_shortener_url,
          use_blacklist_nexus,
          is_one_day,
          cost_center_id,
          id_ai_user_layout,
          rcs_content_type,
          rcs_content_text,
          rcs_content_file_url,
          rcs_content_title,
          rcs_content_description,
          rcs_content_file_type,
          rcs_rich_card_file,
          suggestions,
          business_id,
					template_ids,
					use_cpc,
					queue_id,
					integration_id,
        } = values;
        const wContact = matrix_variables.find((item: any) => {
          return item.w_template_variable === 'w___contato';
        });
        const contact_variable_index = wContact.file_index;

        const wName = matrix_variables.find((item: any) => {
          return item.w_template_variable === 'w___nome';
        });
        const name_variable_index = wName ? wName.file_index : null;

        const wCPF = matrix_variables.find((item: any) => {
          return item.w_template_variable === 'w___cpf';
        });
        const cpf_variable_index = wCPF ? wCPF.file_index : null;

        matrix_variables = matrix_variables.filter((item: any) => {
          return 'template_variable' in item || 'ai_template_variable' in item;
        });

        if (campaign_type === 'SMS') {
          const params = {
            template,
            title,
            contact_variable_index,
            filename,
            headers,
            schedule_date,
            start_date,
            end_date,
            campaign_type,
            matrix_variables,
            use_file,
            tags,
            start_hour,
            end_hour,
            use_whitelist,
            use_shortener_url,
            use_blacklist_nexus,
            is_one_day,
            cost_center_id,
            id_ai_user_layout,
          };

          if (cpf_variable_index !== undefined && cpf_variable_index !== null) {
            params['cpf_variable_index'] = cpf_variable_index;
          }

          if (
            name_variable_index !== undefined &&
            name_variable_index !== null
          ) {
            params['name_variable_index'] = name_variable_index;
          }

          await createCampaing(params, `${BASE_URL}/sms/campaigns/`, fileStore)
            .then(res => {
              if (res?.status === 200 || res?.status === 201) {
                setTimeout(() => {
                  history.push('/campaign-list?type=SMS');
                  setCampaingReduxValues();
                }, 500);
              } else {
                setLoading(false);
                let errorMessage = t(
                  'create-campaign.messages.unexpected-error'
                );
                if ('error' in res.data) {
                  errorMessage = res.data['error'];
                }
                enqueueSnackbar(errorMessage, {
                  variant: 'error',
                  autoHideDuration: 5000,
                });
              }
            })
            .catch(error => {
              setLoading(false);
							console.error(error);
              let errorMessage = t('create-campaign.messages.unexpected-error');
              if (error.response?.data?.error) {
                errorMessage = error.response.data.error;
              }
              enqueueSnackbar(errorMessage, {
                variant: 'error',
                autoHideDuration: 5000,
              });
            });
        } else if (campaign_type === 'WHATSAPP') {
          const params = {
            title,
            contact_variable_index,
            filename,
            matrix_variables,
            cost_center_id,
            id_ai_user_layout,
            is_official: true,
						business_ids: [business_id],
						template_ids,
						use_cpc,
						queue_id
          };

					if (integration_id) {
						params['integration_id'] = integration_id;
					}

          if (typeof cpf_variable_index === 'number') {
            params['cpf_variable_index'] = cpf_variable_index;
          }

          await createWhatsappCampaing(
            params,
            `${BASE_WHATSAPP_URL}/whatsapp/campaigns/`,
            fileStore
          )
            .then(res => {
              if (res?.status === 200) {
                setTimeout(() => {
                  history.push('/campaign-list?type=WHATSAPP');
                  setCampaingReduxValues();
                }, 500);
              } else {
                setLoading(false);
                enqueueSnackbar(
                  t('create-campaign.messages.something-wrong-happened'),
                  {
                    variant: 'error',
                    preventDuplicate: true,
                  }
                );
                setTimeout(() => {
                  enqueueSnackbar(
                    t('create-campaign.messages.verify-inserted-data'),
                    {
                      variant: 'warning',
                      preventDuplicate: true,
                    }
                  );
                }, 1000);
              }
            })
            .catch(error => {
              setLoading(false);
							console.error(error);
              let errorMessage = t('create-campaign.messages.unexpected-error');
              if (error.response?.data?.error) {
                errorMessage = error.response.data.error;
              }
              enqueueSnackbar(errorMessage, {
                variant: 'error',
                autoHideDuration: 5000,
              });
            });
        } else if (campaign_type === 'RCS') {
          const params = {
            title: title,
            filename: filename,
            cost_center_id: cost_center_id,
            contact_variable_index: contact_variable_index ?? undefined,
            name_variable_index: name_variable_index ?? undefined,
            cpf_variable_index: cpf_variable_index ?? undefined,
            content_type: rcs_content_type,
          };

          if (rcs_content_type === 'TEXT') {
            params['content'] = {
              text: rcs_content_text,
              suggestions: suggestions.length > 0 ? suggestions : undefined,
            };
          }

          if (rcs_content_type === 'RICHCARD') {
            params['content'] = {
              title: rcs_content_title,
              description: rcs_content_description ?? undefined,
              suggestions: suggestions.length > 0 ? suggestions : undefined,
            };

            if (rcs_content_file_type === 'file_url') {
              params['content']['fileUrl'] = rcs_content_file_url;
            }

            if (rcs_content_file_type === 'local_file') {
              const fileUrl = await uploadRichCardFile(rcs_rich_card_file.file);
              params['content']['fileUrl'] = fileUrl;
            }
          }

          await createCampaing(
            params,
            `${BASE_RCS_URL}/rcs/campaigns/`,
            fileStore
          )
            .then(res => {
              if (res?.status === 200 || res?.status === 201) {
                setTimeout(() => {
                  history.push('/campaign-list?type=RCS');
                  setCampaingReduxValues();
                }, 500);
              } else {
                setLoading(false);
                let errorMessage = t(
                  'create-campaign.messages.unexpected-error'
                );
                if ('error' in res.data) {
                  errorMessage = res.data['error'];
                }
                enqueueSnackbar(errorMessage, {
                  variant: 'error',
                  autoHideDuration: 5000,
                });
              }
            })
            .catch(error => {
              setLoading(false);
							console.error(error);
              let errorMessage = t('create-campaign.messages.unexpected-error');
              if (error.response?.data?.error) {
                errorMessage = error.response.data.error;
              }
              enqueueSnackbar(errorMessage, {
                variant: 'error',
                autoHideDuration: 5000,
              });
            });
        } else if (campaign_type === 'EMAIL') {
					const params = {
            title: title,
            filename: filename,
            cost_center_id: cost_center_id,
            template: template,
						sender: sender,
          };

          await createCampaing(
            params,
            `${BASE_EMAIL_URL}/email/campaigns/`,
            fileStore
          )
            .then(res => {
              if (res?.status === 200 || res?.status === 201) {
                setTimeout(() => {
                  history.push('/campaign-list?type=EMAIL');
                  setCampaingReduxValues();
                }, 500);
              } else {
                setLoading(false);
                let errorMessage = t(
                  'create-campaign.messages.unexpected-error'
                );
                if ('error' in res.data) {
                  errorMessage = res.data['error'];
                }
                enqueueSnackbar(errorMessage, {
                  variant: 'error',
                  autoHideDuration: 5000,
                });
              }
            })
            .catch(error => {
              setLoading(false);
							console.error(error);
              let errorMessage = t('create-campaign.messages.unexpected-error');
              if (error.response?.data?.error) {
                errorMessage = error.response.data.error;
              }
              enqueueSnackbar(errorMessage, {
                variant: 'error',
                autoHideDuration: 5000,
              });
            });
        }
      }}
    >
      <Container title={t('create-campaign.title')}>
        <Form>
          <CampaingTabs
            open={open}
            loading={loading}
            step={step}
            setLoading={setLoading}
            setOpenSideBar={setOpen}
            setStep={setStep}
          />
        </Form>
      </Container>
    </Formik>
  );
};

export default FormStepper;
