import { AxiosResponse } from 'axios';
import { BASE_EMAIL_URL } from '../../api';
import { request } from '../BASE_REQUEST';

export const updateEmailTemplate = async (
  subject: string,
  body: string,
  id: string
): Promise<AxiosResponse<any> | undefined> => {
  try {
    const response = await request({
      url: `${BASE_EMAIL_URL}/email/templates/${id}/`,
      method: 'PUT',
      data: {
        subject,
        content: body,
      },
    });
    return response;
  } catch (error) {
    Promise.reject(error);
  }
};
