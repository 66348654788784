import { request } from '../BASE_REQUEST';

const getReport = async (url, campaignType) => {
  try {
    const response = await request({
      url: url,
      method: 'GET',
    });
    return response;
  } catch (error) {
    Promise.reject(error);
  }
};

export default getReport;
