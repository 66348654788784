import { Box, styled, Typography } from '@mui/material';

export const StyledFormContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  rowGap: theme.spacing(3),
  width: '70%',
  [theme.breakpoints.down('xl')]: {
    margin: 'auto',
  },
  [theme.breakpoints.up('xl')]: {
    margin: 'auto',
  },
  paddingTop: theme.spacing(3),
}));

export const StyledLoginTitle = styled(Typography)(({ theme }) => ({
  [theme.breakpoints.down('xl')]: {
    fontSize: 22,
  },
}));

export const StyledPasswordOptionsContainer = styled(Box)(() => ({
  display: 'grid',
  gridTemplateColumns: '1fr 1fr',
  width: '100%',
  alignItems: 'center',
}));

export const StyledFirstColumn = styled(Box)(() => ({
  display: 'flex',
  alignItems: 'center',
}));

export const StyledSecondColumn = styled(Box)(() => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-end',
}));

export const StyledTypography = styled(Typography)(
  ({ theme: { breakpoints } }) => ({
    [breakpoints.down('xl')]: {
      fontSize: 12,
      marginTop: 4,
    },
  })
);

export const StyledTypographyLInk = styled(Typography)(
  ({ theme: { breakpoints } }) => ({
    color: '#2525aa',
    borderBottom: `1px solid #2525aa`,
    cursor: 'pointer',
    transition: 'all 0.3s ease-out',

    [breakpoints.down('xl')]: {
      fontSize: 12,
      marginTop: 4,
    },

    '&:hover': {
      color: '#141461',
    },
  })
);

export const StyledLoginButtonContainer = styled(Box)(({ theme }) => ({
  width: '70%',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  margin: 'auto',
  [theme.breakpoints.up('xl')]: {
    paddingTop: theme.spacing(4),
  },
}));
