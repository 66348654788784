import { Grid, IconButton } from '@mui/material';
import IconEdit from '@mui/icons-material/Edit';
import { GridColDef, GridRenderCellParams } from '@mui/x-data-grid';
import { ISender } from '../../components/SendersListComponent/interfaces';

export const defineColumns = (
  setSenderEdit: React.Dispatch<React.SetStateAction<ISender | undefined>>,
  setShow: React.Dispatch<React.SetStateAction<boolean>>
): GridColDef[] => [
  {
    field: 'email',
    headerName: 'E-mail',
    flex: 4,
  },
  {
    field: 'verified',
    headerName: 'Verificado',
    flex: 1,
    align: 'center',
    renderCell: (params: GridRenderCellParams) => {
      return params.value ? 'Sim' : 'Não';
    },
  },
  {
    field: 'actions',
    headerName: 'Ações',
    flex: 1,
    align: 'center',
    headerAlign: 'center',
    renderCell: (cell: GridRenderCellParams) => {
      return (
        <Grid container justifyContent={'space-evenly'} alignItems={'center'}>
          <Grid item xs={6}>
            <IconButton
              onClick={() => {
                setSenderEdit(cell.row);
                setShow(true);
              }}
            >
              <IconEdit />
            </IconButton>
          </Grid>
        </Grid>
      );
    },
  },
];
