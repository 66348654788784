import React from 'react';
import {
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
} from '@mui/material';
import { Formik } from 'formik';
import CustomDialogTitle from './DialogTtitle';
import { DescriptionInput } from '../DescriptionInput';
import { TransactionSummary } from '../TransactionSummary';
import { TransactionTypeSelect } from '../TransactionTypeSelect';
import { transactionSchema } from '../../constants/transactionSchema';
import { initialValues } from './constants/NewTransactionInitialValues';
import { BalanceInput } from '../../../../components/PlansList/BalanceInput';
import { CreateTransactionDialogProps } from './interfaces';
import {
  CancelButton,
  CustomDialog,
  SaveButton,
  NewTemplateText,
  TextButtonStyled,
} from './styles';

export const CreateTransactionDialog: React.FC<
  CreateTransactionDialogProps
> = ({
  show,
  loading,
  userInfo,
  newBalance,
  userPlanInfo,
  showConfirmationModal,
  setShowModal,
  handleCreateTransaction,
  setShowConfirmationModal,
}: CreateTransactionDialogProps): JSX.Element => {
  const handleClose = (resetForm: () => void): void => {
    resetForm();
    setShowModal(false);
  };

  const handleCloseConfirmationModal = (resetForm: () => void): void => {
    resetForm();
    setShowConfirmationModal(false);
  };

  const handleSubmitAndShowModal = (
    type: string,
    description: string,
    value: string
  ): void => {
    if (type && description && value) {
      setShowConfirmationModal(true);
    }
  };

  return (
    <div>
      <Formik
        initialValues={initialValues}
        onSubmit={(values, { resetForm }) => {
          handleCreateTransaction(values.type, Number(values.value), resetForm);
        }}
        validationSchema={transactionSchema}
      >
        {({
          values: { description, type, value },
          handleChange,
          handleSubmit,
          resetForm,
        }) => (
          <CustomDialog
            onClose={() => handleClose(resetForm)}
            aria-labelledby="customized-dialog-title"
            open={show}
          >
            <CustomDialogTitle
              id="customized-dialog-title"
              onClose={() => handleClose(resetForm)}
            >
              <NewTemplateText>Nova transação</NewTemplateText>
            </CustomDialogTitle>
            <>
              <DialogContent dividers>
                <TransactionTypeSelect
                  value={type}
                  handleChange={handleChange('type')}
                />

                <DescriptionInput
                  value={description}
                  handleChange={handleChange('description')}
                />

                <BalanceInput
                  handleChange={handleChange('value')}
                  label="Valor da transação"
                  value={value}
                />
              </DialogContent>
              <DialogActions>
                <CancelButton
                  variant="contained"
                  color="error"
                  autoFocus
                  onClick={() => handleClose(resetForm)}
                >
                  <TextButtonStyled variant="body1">Cancelar</TextButtonStyled>
                </CancelButton>

                <SaveButton
                  variant="contained"
                  color="success"
                  disabled={!type || !description || !value}
                  onClick={() =>
                    handleSubmitAndShowModal(type, description, value)
                  }
                >
                  <TextButtonStyled variant="body1">Confirmar</TextButtonStyled>
                </SaveButton>
              </DialogActions>
              <Dialog
                open={showConfirmationModal}
                onClose={() => handleCloseConfirmationModal(resetForm)}
              >
                <DialogTitle>
                  <Typography variant="h3">
                    Você tem certeza das informações inseridas?
                  </Typography>
                </DialogTitle>
                <DialogContent>
                  <TransactionSummary
                    type={type}
                    value={value}
                    newBalance={newBalance}
                    userName={userInfo.name}
                    originalBalance={userPlanInfo.balance}
                  />
                </DialogContent>
                <DialogActions>
                  <CancelButton
                    variant="contained"
                    color="error"
                    autoFocus
                    onClick={() => handleCloseConfirmationModal(resetForm)}
                  >
                    <TextButtonStyled variant="body1">Voltar</TextButtonStyled>
                  </CancelButton>

                  <SaveButton
                    variant="contained"
                    color="success"
                    onClick={() => handleSubmit()}
                  >
                    {loading ? (
                      <CircularProgress size={24} color="inherit" />
                    ) : (
                      <TextButtonStyled variant="body1">
                        Salvar
                      </TextButtonStyled>
                    )}
                  </SaveButton>
                </DialogActions>
              </Dialog>
            </>
          </CustomDialog>
        )}
      </Formik>
    </div>
  );
};
