import { MenuItem, styled, Theme } from '@mui/material';

export const SelectTypeStyled = ({ breakpoints }: Theme): any => ({
  [`& fieldset`]: {
    borderRadius: 3,
  },

  '& .MuiInputLabel-root': {
    fontSize: 16,
  },

  '& .MuiOutlinedInput-root ': {
    fontSize: 16,
  },

  [breakpoints.down('xl')]: {
    height: 45,

    '& .MuiSelect-select': {
      fontSize: 11,
    },
    '& .MuiInputLabel-root': {
      marginTop: -0.5,
      fontSize: 13,
    },
    '& .MuiOutlinedInput-root ': {
      height: 45,
      fontSize: 13,
    },
  },
});

export const MenuItemStyled = styled(MenuItem)(({ theme }) => ({
  [theme.breakpoints.down('xl')]: {
    fontSize: 12,
  },
}));
