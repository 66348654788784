import {
  DialogActions,
  DialogContent,
  FormControl,
  FormControlLabel,
  Grid,
  Radio,
  RadioGroup,
  TextField,
  Theme,
} from '@mui/material';
import { RadioButtonsContainer } from '../AddContactUpload/styles';
import CustomDialogTitle from '../DialogTitle/CustomDialogTitle';
import {
  CustomDialog,
  AddContactText,
  ButtonsContainer,
  ButtonsWrapper,
  BackButton,
  SaveButton,
  ButtonText,
} from './styles';

const EditTextField = ({ breakpoints }: Theme) => ({
  width: '100%',
  [`& fieldset`]: {
    borderRadius: 2,
  },

  [breakpoints.down('xl')]: {
    '& .MuiOutlinedInput-input': {
      height: 15,
      fontSize: 13,
    },
    '& .MuiInputLabel-root': {
      fontSize: 13,
    },
  },
});

interface ICustomDialogTitle {
  showManually: boolean;
  setShowManually: React.Dispatch<React.SetStateAction<boolean>>;
}

const AddContactManuallyDialog: React.FC<ICustomDialogTitle> = ({
  showManually,
  setShowManually,
}: ICustomDialogTitle): JSX.Element => {
  const handleClose = () => {
    setShowManually(false);
  };

  return (
    <div>
      <CustomDialog
        fullWidth
        maxWidth={'lg'}
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={showManually}
      >
        <CustomDialogTitle id="customized-dialog-title" onClose={handleClose}>
          <AddContactText variant="h2">
            Adicionar contato manualmente
          </AddContactText>
        </CustomDialogTitle>
        <RadioButtonsContainer>
          <FormControl component="fieldset">
            <RadioGroup
              aria-label="gender"
              defaultValue="female"
              name="radio-buttons-group"
            >
              <FormControlLabel
                value="female"
                control={<Radio />}
                label="Adicionar contato"
              />
              <FormControlLabel
                value="male"
                control={<Radio />}
                label="Adicionar contato e incluir em uma lista existente"
              />
              <FormControlLabel
                value="other"
                control={<Radio />}
                label="Adicionar contato em uma nova lista"
              />
            </RadioGroup>
          </FormControl>
        </RadioButtonsContainer>

        <DialogContent dividers>
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <FormControl>
                <TextField
                  name="data-input"
                  label="Nome"
                  type="text"
                  sx={EditTextField}
                />
              </FormControl>
            </Grid>
            <Grid item xs={6}>
              <FormControl>
                <TextField
                  name="data-input"
                  label="Sobrenome"
                  type="text"
                  sx={EditTextField}
                />
              </FormControl>
            </Grid>
          </Grid>

          <Grid container spacing={2}>
            <Grid item xs={6}>
              <FormControl>
                <TextField
                  name="data-input"
                  label="Email"
                  type="email"
                  sx={EditTextField}
                />
              </FormControl>
            </Grid>
            <Grid item xs={6}>
              <FormControl>
                <TextField
                  name="data-input"
                  label="Email alternativo"
                  type="email"
                  sx={EditTextField}
                />
              </FormControl>
            </Grid>
          </Grid>

          <Grid container spacing={2}>
            <Grid item xs={4}>
              <TextField
                name="data-input"
                label="Rua"
                type="text"
                sx={EditTextField}
              />
            </Grid>
            <Grid item xs={2}>
              <TextField
                name="data-input"
                label="Número"
                type="number"
                sx={EditTextField}
              />
            </Grid>
            <Grid item xs={3}>
              <TextField
                name="data-input"
                label="Cidade"
                type="text"
                sx={EditTextField}
              />
            </Grid>
            <Grid item xs={3}>
              <TextField
                name="data-input"
                label="Estado"
                type="text"
                sx={EditTextField}
              />
            </Grid>
          </Grid>

          <Grid container spacing={2}>
            <Grid item xs={2}>
              <FormControl>
                <TextField
                  name="data-input"
                  label="Cep"
                  type="number"
                  sx={EditTextField}
                />
              </FormControl>
            </Grid>
            <Grid item xs={4}>
              <FormControl>
                <TextField
                  name="data-input"
                  label="País"
                  type="text"
                  sx={EditTextField}
                />
              </FormControl>
            </Grid>
          </Grid>
        </DialogContent>

        <DialogActions>
          <ButtonsContainer>
            <ButtonsWrapper>
              <BackButton
                onClick={handleClose}
                variant="contained"
                color="secondary"
              >
                <ButtonText>Voltar</ButtonText>
              </BackButton>
              <SaveButton variant="contained" color="primary">
                <ButtonText>Salvar</ButtonText>
              </SaveButton>
            </ButtonsWrapper>
          </ButtonsContainer>
        </DialogActions>
      </CustomDialog>
    </div>
  );
};

export default AddContactManuallyDialog;
