import React from 'react';
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import { Doughnut } from 'react-chartjs-2';
import { Container } from './styles';

ChartJS.register(ArcElement, Tooltip, Legend);

interface SMSChartProps {
  sms_events: any;
}

export const SMSChart: React.FC<SMSChartProps> = ({
  sms_events,
}: SMSChartProps): JSX.Element => {
  const SMSdata: any = [];
  const SMSLabels: any = [];

  const options = {
    responsive: true,
    plugins: {
      legend: {
        position: 'top' as const,
      },
      title: {
        display: true,
        text: 'SMS',
      },
    },
  };

  for (const obj in sms_events) {
    SMSdata.push(sms_events[obj]);
  }

  for (const obj in sms_events) {
    // eslint-disable-next-line no-prototype-builtins
    if (sms_events.hasOwnProperty(obj)) {
      if (obj.length) {
        let label = '';
        if (obj === 'total') {
          label = `Total`;
        } else if (obj === 'analyzed') {
          label = 'Analisado';
        } else if (obj === 'triggered') {
          label = 'Disparado';
        } else if (obj === 'delivered') {
          label = 'Entregue';
        }
        label = `${label}: ${sms_events[obj].toLocaleString(
          undefined, // leave undefined to use the visitor's browser
          // locale or a string like 'en-US' to override it.
          { minimumFractionDigits: 2 }
        )}`;
        SMSLabels.push(label);
      }
    }
  }

  const data = {
    labels: SMSLabels,
    datasets: [
      {
        label: 'Sumário de SMS',
        data: SMSdata,
        backgroundColor: [
          'rgba(79, 79, 79, 0.7)',
          'rgba(105, 105, 105, 0.7)',
          'rgba(128, 128, 128, 0.7)',
          'rgba(169, 169, 169, 0.7)',
        ],
        borderColor: [
          'rgba(79, 79, 79, 1)',
          'rgba(105, 105, 105, 1)',
          'rgba(128, 128, 128, 1)',
          'rgba(169, 169, 169, 1)',
        ],
        borderWidth: 1,
      },
    ],
  };

  return (
    <Container>
      <Doughnut options={options} data={data} />
    </Container>
  );
};
