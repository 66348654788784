import { Box, styled, Typography } from '@mui/material';
import { Field } from 'formik';

export const StyledBox = styled(Box)(() => ({
  display: 'flex',
  '& .MuiPaper-root': {
    display: 'flex',
  },
}));

export const SenderDefinition = styled(Typography)(() => ({
  color: '#808080',
  fontWeight: 500,
  margin: '0px 10px 0px 5px',
  fontSize: 15,
  display: 'flex',
  justifyContent: 'center',
  flexDirection: 'column',
}));

export const TextTittle = styled(Typography)(({ theme }) => ({
  [theme.breakpoints.down('xl')]: {
    fontSize: 12,
  },
}));

export const StyledField = styled(Field)(() => ({
  width: '10px',
}));
