import React from 'react';
import { BrowserRouter } from 'react-router-dom';
import AuthRoutes from '../../AuthRoutes';

const Auth: React.FC = (): JSX.Element => {
  return (
    <main>
      <BrowserRouter>
        <AuthRoutes />
      </BrowserRouter>
    </main>
  );
};

export default Auth;
