import { Box, FormControl, TextField, Theme } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useFormikContext } from 'formik';
import { ChangeEvent, useEffect, useState } from 'react';
import ArrowForwardOutlinedIcon from '@mui/icons-material/ArrowForwardOutlined';
import SyncAltIcon from '@mui/icons-material/SyncAlt';
import {
  IHeaders,
  IMatrixVariables,
  IAIMatrixVariables,
  WMatrixVariables,
} from './interfaces';
import {
  AssociateButton,
  CloseIcon,
  TemplateVariableText,
  Variable,
  VariableInnerTextContainer,
  VariablesContainer,
  ButtonText,
  HeaderText,
  MenuItemStyled,
  StyledGrid,
  StyledLeft,
  StyledLeftContentTop,
  StyledLeftContentRight,
} from './styles';
import { ICampaing } from '../../../Auth/interfaces/campaing.interface';
import { useDispatch, useSelector } from 'react-redux';
import { IReducers } from '../../../Auth/store/store';
import { addCampaing } from '../../../Auth/actions/campaing.action';

const SelectTypeStyled = ({ breakpoints }: Theme) => ({
  width: 300,
  borderRadius: 0,

  '& .MuiInputLabel-root': {
    fontSize: 18,
  },

  '& .MuiOutlinedInput-root ': {
    fontSize: 18,
  },

  [breakpoints.down('xl')]: {
    width: 200,
    height: 45,

    '& .MuiSelect-select': {
      fontSize: 11,
    },
    '& .MuiInputLabel-root': {
      marginTop: -0.5,
      fontSize: 13,
    },
    '& .MuiOutlinedInput-root ': {
      height: 45,
      fontSize: 13,
    },
  },

  [`& fieldset`]: {
    borderRadius: 0,
  },
});

const VariablesStep: React.FC = (): JSX.Element => {
  const {
    values: { headers, matrix_variables },
    setFieldValue,
  } = useFormikContext<IHeaders>();

  const {
    templateVariables,
    wTemplateVariables,
    matrix_variables_array,
  }: ICampaing = useSelector((state: IReducers) => state.campaing);

  const dispatch = useDispatch();

  const [selectValue, setSelectValue] = useState<string>('');
  const [variableValue, setVariableValue] = useState<string>('');
  const [matrixObject, setMatrixObject] = useState(
    {} as IMatrixVariables | IAIMatrixVariables | WMatrixVariables
  );
  const [matrixArray, setMatrixArray] = useState<
    IMatrixVariables[] | IAIMatrixVariables[] | WMatrixVariables[]
  >(matrix_variables_array ?? []);

  const { t } = useTranslation();

  const setArrayValue = (campaing: ICampaing): void => {
    dispatch(addCampaing(campaing));
  };

  const handleSelectChange = (
    event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ): void => {
    const value = event.target.value;
    setSelectValue(value);
    const newValue = headers.findIndex(x => x === value);
    const data: IMatrixVariables | IAIMatrixVariables | WMatrixVariables = {
      file_index: newValue,
    };
    setMatrixObject(prevState => {
      return {
        ...prevState,
        ...data,
      };
    });
  };

  const handleVariablesChange = (
    event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ): void => {
    const value = event.target.value;
    setVariableValue(value);
    let data: IMatrixVariables | IAIMatrixVariables | WMatrixVariables;
    if (value.includes('ia___')) {
      data = {
        ai_template_variable: value,
      };
    } else if (value.includes('w___')) {
      data = {
        w_template_variable: value,
      };
    } else {
      data = {
        template_variable: value,
      };
    }

    setMatrixObject(prevState => {
      return {
        ...prevState,
        ...data,
      };
    });
  };

  const handlePushToMatrixArray = () => {
    if (Object.keys(matrixObject).length === 2) {
      console.log(matrixObject);
      matrixArray.push(matrixObject);
      setFieldValue('matrix_variables', matrixArray);
      setArrayValue({ matrix_variables_array: matrixArray });
      setMatrixObject({});
      setSelectValue('');
      setVariableValue('');
    }
  };

  const handleDeleteFromMatrixArray = (index: number) => {
    const newList = matrixArray.filter(
      variable => matrixArray.indexOf(variable) !== index
    );

    setMatrixArray(newList);
    setFieldValue('matrix_variables', newList);
    setArrayValue({ matrix_variables_array: newList });
  };

  // const handleAlertMessage = (
  //   message: string,
  //   variant: VariantType | undefined = 'error',
  //   duration = 2000
  // ): void => {
  //   enqueueSnackbar(message, {
  //     preventDuplicate: true,
  //     variant: variant,
  //     autoHideDuration: duration
  //   });
  // };

  const disableInputSelect = () => {
    if (
      templateVariables?.length === matrix_variables.length ||
      !templateVariables?.length
    ) {
      return true;
    }
  };

  useEffect(() => {
    if (matrix_variables_array && !matrix_variables_array.length) {
      setMatrixArray([]);
    }
  }, [matrix_variables_array]);

  return (
    <StyledGrid>
      <StyledLeft>
        <StyledLeftContentTop>
          <Box>
            <FormControl>
              <TextField
                select
                sx={SelectTypeStyled}
                value={selectValue}
                onChange={e => handleSelectChange(e)}
                label={t('create-campaign.tabs.settings.headers')}
                disabled={disableInputSelect()}
              >
                {headers.map((header, index) => (
                  <MenuItemStyled
                    disabled={
                      matrix_variables.find(obj => {
                        obj.file_index === index;
                      }) !== undefined
                        ? true
                        : false
                    }
                    key={index}
                    value={header}
                  >
                    {header}
                  </MenuItemStyled>
                ))}
              </TextField>
            </FormControl>
          </Box>
          <SyncAltIcon color="primary" />
          <Box>
            <FormControl>
              <TextField
                select
                sx={SelectTypeStyled}
                value={variableValue}
                onChange={e => handleVariablesChange(e)}
                label={t('create-campaign.tabs.settings.variable-names')}
                disabled={disableInputSelect()}
              >
                {templateVariables?.map((variable, index) => (
                  <MenuItemStyled
                    disabled={
                      matrix_variables.find(obj =>
                        'template_variable' in obj
                          ? obj.template_variable ===
                            variable.replace(/[{}]/g, '')
                          : 'w_template_variable' in obj
                          ? obj.w_template_variable ===
                            variable.replace(/[{}]/g, '')
                          : obj.ai_template_variable ===
                            variable.replace(/[{}]/g, '')
                      ) || !selectValue
                        ? true
                        : false
                    }
                    key={index}
                    value={variable.replace(/[{}]/g, '')}
                  >
                    {variable.replace(/[{}]/g, '')}
                  </MenuItemStyled>
                ))}
              </TextField>
            </FormControl>
          </Box>
        </StyledLeftContentTop>
        <AssociateButton
          variant="contained"
          color="secondary"
          onClick={handlePushToMatrixArray}
        >
          <ButtonText variant="body1">
            {t('create-campaign.tabs.settings.buttons.associate')}
          </ButtonText>
        </AssociateButton>
      </StyledLeft>
      <StyledLeftContentRight>
        <VariablesContainer>
          {matrix_variables_array?.map((object, index) => (
            <Variable key={index}>
              <VariableInnerTextContainer>
                <TemplateVariableText variant="body2">
                  {'template_variable' in object
                    ? object?.template_variable?.replace(/[{}]/g, '')
                    : 'w_template_variable' in object
                    ? object?.w_template_variable?.replace(/[{}]/g, '')
                    : object?.ai_template_variable?.replace(/[{}]/g, '')}
                </TemplateVariableText>
                <ArrowForwardOutlinedIcon color="primary" />
                <HeaderText variant="body2">
                  {headers[object.file_index]}
                </HeaderText>
                <CloseIcon
                  onClick={() => handleDeleteFromMatrixArray(index)}
                  color="secondary"
                />
              </VariableInnerTextContainer>
            </Variable>
          ))}
        </VariablesContainer>
      </StyledLeftContentRight>
    </StyledGrid>
  );
};

export default VariablesStep;
