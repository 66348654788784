import { useFormikContext } from 'formik';
import { useTranslation } from 'react-i18next';
import { FirstStepProps } from '../../../utils/interfaces';
import { FormControl, TextField, Theme } from '@mui/material';
import { CampaignTileProps } from './interfaces';

const customTextFieldStyles = ({ breakpoints }: Theme) => ({
  width: '100%',
  '& .MuiOutlinedInput-root ': {
    height: 50,
    fontSize: 18,
  },
  '& .MuiInputLabel-root': {
    fontSize: 18,
  },

  [breakpoints.down('xl')]: {
    '& .MuiInputLabel-root': {
      marginTop: -0.65,
      fontSize: 13,
    },
    '& .MuiOutlinedInput-root ': {
      height: 45,
      fontSize: 13,
    },
  },

  [`& fieldset`]: {
    borderRadius: 2,
  },
});

const CampaingTitleInput: React.FC<CampaignTileProps> = ({
  handleChange,
}: CampaignTileProps): JSX.Element => {
  const {
    values: { title },
  } = useFormikContext<FirstStepProps>();
  const { t } = useTranslation();

  return (
    <FormControl>
      <TextField
        label={t('create-campaign.tabs.campaign.fields.campaign-title')}
        variant="outlined"
        type="text"
        value={title}
        onChange={handleChange}
        sx={customTextFieldStyles}
        inputProps={{ maxLength: 256 }}
      />
    </FormControl>
  );
};

export default CampaingTitleInput;
