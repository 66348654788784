import { useSnackbar } from 'notistack';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import { ITemplate } from '../../Auth/interfaces/template.interface';
import { IReducers } from '../../Auth/store/store';
import { updateEmailTemplate } from '../../utils/api/PUT';
import { Container } from './styles';
import { TemplateBody } from './TemplateBody';
import TopBarEdit from './TopBar/TopBarEdit';

const EditTemplate: React.FC = (): JSX.Element => {
  const { body, id, name, subject }: ITemplate = useSelector(
		(state: IReducers) => state.template
  );

  const [isMobile, setIsMobile] = useState(false);
	const [subjectString, setSubject] = useState(subject || '');
  const [loading, setLoading] = useState(false);
	const [srcDoc, setSrcDoc] = useState(body || '');
  const { enqueueSnackbar } = useSnackbar();

  const handleUpdateEmailTemplate = () => {
    setLoading(true);
    updateEmailTemplate(subjectString, body ?? '', id ?? '')
      .then(response => {
        if (response && response.status === 200) {
          enqueueSnackbar('Template atualizado!', {
            variant: 'success',
            autoHideDuration: 2000,
          });
          setLoading(false);
        }
      })
      .catch(err => {
        setLoading(false);
        alert(err);
      });
  };

  return (
    <Container>
      <TopBarEdit
        name={name ?? ''}
        subject={subjectString}
        setSubject={setSubject}
        loading={loading}
        setLoading={setLoading}
        handleUpdateEmailTemplate={handleUpdateEmailTemplate}
        isMobile={isMobile}
        setIsMobile={setIsMobile}
      />

      <TemplateBody
        srcDoc={srcDoc}
        setSrcDoc={setSrcDoc}
        isMobile={isMobile}
        subjectString={subjectString}
        setSubject={setSubject}
      />
    </Container>
  );
};

export default EditTemplate;
