import { useTranslation } from 'react-i18next';
import { StyleSideImageContainer } from './styles';
import SideImageLogo from '../../../assets/images/computerImage.svg';

const SideImage: React.FC = (): JSX.Element => {
  const { t } = useTranslation();

  return (
    <StyleSideImageContainer>
      <img src={SideImageLogo} alt={t('login.side-image-alt')} />
    </StyleSideImageContainer>
  );
};

export default SideImage;
