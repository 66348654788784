import { ADD_AUTH, REMOVE_AUTH } from '../constants/auth.constant';
import { IAuth } from '../interfaces/auth.interface';

export type AuthAction = { type: string; payload: IAuth };

export const addAuth = (auth: IAuth): AuthAction => ({
  type: ADD_AUTH,
  payload: auth,
});

export const removeAuth = (auth: IAuth): AuthAction => ({
  type: REMOVE_AUTH,
  payload: auth,
});
