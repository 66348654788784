import { Box, Grid, styled } from '@mui/material';

export const GraphicContainer = styled(Grid)(() => ({
  width: '100%',
  padding: '15px',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
}));

export const SmsChartContainer = styled(Grid)(({ theme: { breakpoints } }) => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',

  [breakpoints.down('xl')]: {
    padding: '10px 40px',
  },
}));

export const NoInfoContainer = styled(Box)(() => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  width: '100%',
  height: '100%',
}));
