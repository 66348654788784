import { Box, Divider, styled, Typography, Paper } from '@mui/material';

export const DividerBox = styled(Box)(({ theme }) => ({
  width: '100%',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  marginTop: theme.spacing(8),
}));

export const DividerText = styled(Typography)(({ theme }) => ({
  [theme.breakpoints.down('xl')]: {
    fontSize: 14,
  },
  [theme.breakpoints.up('xl')]: {
    fontSize: 20,
  },
}));

export const DividerLine = styled(Divider)(({ theme }) => ({
  height: 1,
  backgroundColor: '#ccc',

  [theme.breakpoints.down('xl')]: {
    width: '87%',
  },
  [theme.breakpoints.up('xl')]: {
    width: '85%',
  },
  [theme.breakpoints.up(1800)]: {
    width: '88%',
  },
}));

export const StyledBoxAccordion = styled(Box)(() => ({
  marginTop: '44px',

  alignItems: 'center',
  width: '100%',
}));

export const TemplatesContainer = styled(Box)(({ theme }) => ({
  width: '100%',
  marginBottom: 10,
  padding: '10px',
  display: 'flex',
  flexDirection: 'column',

  [theme.breakpoints.down('xl')]: {
    '& .MuiSvgIcon-root': {
      fontSize: 20,
    },
  },
}));

export const StyledPaper = styled(Paper)(({ theme }) => ({
  marginTop: theme.spacing(2),

  '& .MuiCollapse-root': {
    width: '100%',
  },
}));

export const CircularProgressBox = styled(Box)(({ theme }) => ({
  alignItems: 'center',
  justifyContent: 'center',
  [theme.breakpoints.down('xl')]: {
    height: 250,
  },
  [theme.breakpoints.up('xl')]: {
    height: 420,
  },
  display: 'flex',
}));

export const LinkContainer = styled(Box)(() => ({
  display: 'flex',
  justifyContent: 'flex-end',
}));

export const StyledTypographyLink = styled(Typography)(({ theme }) => ({
  color: theme.palette.secondary.main,
  textDecoration: 'underline',
  cursor: 'pointer',
}));
