import { request } from '../BASE_REQUEST';
import { BASE_CHAT_URL } from '../../api';

const getQueues = async params => {
    try {
        const response = await request({
            url: `${BASE_CHAT_URL}/queue`,
            method: 'GET',
            params: params
        });
        return response;
    } catch (error) {
        Promise.reject(error);
    }
};

export default getQueues;
