import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useSnackbar } from 'notistack';
import Grid from '@mui/material/Grid';
import { CircularProgress, Tooltip, IconButton } from '@mui/material';
import InfoIcon from '@mui/icons-material/Info';
import Container from '../../components/Container';
import { DataGrid, GridColDef } from '@mui/x-data-grid';
import { NoWrapSpan } from './styles';
import { useHistory } from 'react-router-dom';

import getAiLayouts from '../../utils/api/GET/getAiLayouts';

export default function AILayouts() {
  const { t } = useTranslation();
  const [layouts, setLayouts] = useState([]);
  const [loading, setLoading] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const history = useHistory();

  useEffect(async () => {
    try {
      setLoading(true);

      const result = await getAiLayouts();
      if (result.status != 200) {
        enqueueSnackbar(t('ai-layouts.messages.get-layouts-error'), {
          variant: 'error',
          autoHideDuration: 5000,
        });
      } else {
        setLayouts(result.data);
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);

      enqueueSnackbar(t('ai-layouts.messages.get-layouts-error'), {
        variant: 'error',
        autoHideDuration: 5000,
      });
    }
  }, []);

  const layoutColumns: GridColDef[] = [
    {
      field: 'ai_name',
      headerName: t('ai-layouts.table.header.ai'),
      editable: false,
      flex: 0.7,
      renderCell: params => (
        <div style={{ display: 'flex', flexDirection: 'column' }}>
          <Tooltip title={params.row.ai_name}>
            <NoWrapSpan>{params.row.ai_name}</NoWrapSpan>
          </Tooltip>
        </div>
      ),
    },
    {
      field: 'title',
      headerName: t('ai-layouts.table.header.title'),
      editable: false,
      flex: 0.7,
      renderCell: params => (
        <div style={{ display: 'flex', flexDirection: 'column' }}>
          <Tooltip title={params.row.title}>
            <NoWrapSpan>{params.row.title}</NoWrapSpan>
          </Tooltip>
        </div>
      ),
    },
    {
      field: 'actions',
      headerName: t('ai-layouts.table.header.actions'),
      sortable: false,
      editable: false,
      flex: 0.1,
      align: 'center',
      renderCell: params => (
        <IconButton
          onClick={() => {
            history.push(`ai-layouts-details/${params.row.id}`);
          }}
        >
          <Tooltip title={t('ai-layouts.table.tooltip.details')}>
            <InfoIcon></InfoIcon>
          </Tooltip>
        </IconButton>
      ),
    },
  ];

  return (
    <Container title={t('ai-layouts.title')}>
      {loading ? (
        <Grid
          container
          alignItems="center"
          justifyContent="center"
          style={{ minHeight: 'calc(100vh - 200px)' }} // Adjust this height to center properly
        >
          <CircularProgress size={20} />
        </Grid>
      ) : (
        <DataGrid
          rows={layouts}
          columns={layoutColumns}
          sx={{
            '& .MuiDataGrid-columnHeader': {
              width: 'auto !important',
              minWidth: '0',
            },
          }}
          hideFooter={true}
        />
      )}
    </Container>
  );
}
