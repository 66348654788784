import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  DialogActions,
  DialogTitle,
  Button,
  Typography,
  Box,
  CircularProgress,
  FormControlLabel,
  Checkbox,
  FormGroup,
} from '@mui/material';
import { convertXslxToCsv, getFileType } from '../../utils/FileHelper';
import {
  CustomDialog,
  InputFileBox,
  StyledIcon,
  StyledTypographyErr,
  SubmitButton,
} from './styles';

const MAX_FILE_SIZE = 2 * 1024 * 1024; // 2mb

export default function NewRequestDialog({
  isSaving,
  isOpen,
  onSave,
  onOpenChange,
}) {
  const { t } = useTranslation('translation', { keyPrefix: 'data-requests' });
  const [file, setFile] = useState(null);
  const [errors, setErrors] = useState({});
  const [isReadingFile, setIsReadingFile] = useState(false);

  useEffect(() => {
    if (isOpen) {
      clearState();
    }
  }, [isOpen]);

  function clearState() {
    setFile(null);
    setErrors({});
  }

  function handleClose() {
    onOpenChange(false);
  }

  function handleSave() {
    if (Object.keys(errors).length > 0) {
      return;
    }
    const newErrors = {};
    if (!file)
      newErrors.file = t('new-request-dialog.validations.file-not-selected');
    if (Object.keys(newErrors).length > 0) {
      setErrors(newErrors);
      return;
    }
    setErrors({});
    onSave(file);
  }

  async function handleSelectFile(event) {
    clearState();
    setIsReadingFile(true);

    try {
      let file;

      file = event.currentTarget.files[0];
      if (!file) return;

      const fileType = getFileType(file.name);
      const acceptedFileTypes = ['xlsx', 'csv'];

      if (!acceptedFileTypes.includes(fileType)) {
        setErrors({
          file: t('new-request-dialog.validations.file-not-supported'),
        });
        return;
      }

      const reader = new FileReader();
      reader.readAsBinaryString(file);

      let fileContent = await new Promise((resolve, reject) => {
        reader.onload = e => {
          if (e?.target?.result) {
            resolve(e.target.result);
          } else {
            reject();
          }
        };
      });

      if (fileType === 'xlsx') {
        fileContent = convertXslxToCsv(fileContent);
        const newFileName = file.name.replace(/\.xlsx$/, '.csv');
        file = new File([fileContent], newFileName, {
          type: 'text/csv',
        });
      }

      const fileRows = fileContent.split('\n');
      const firstRow = fileRows[0].trim();

      if (!firstRow) {
        setErrors({ file: t('new-request-dialog.validations.file-empty') });
        return;
      }

      const firstRowContainSeparators = firstRow.match(/[,;]/g);
      if (firstRowContainSeparators) {
        setErrors({
          file: t('new-request-dialog.validations.file-too-many-columns'),
        });
        return;
      }

      const firstRowOnlyLetters = firstRow.replace(/\D/g, '').length === 0;
      if (firstRowOnlyLetters) {
        setErrors({
          file: t('new-request-dialog.validations.file-has-headers'),
        });
        return;
      }

      const phoneNumberRegex = /^(\d{2}|\d{0})(0?\d{2})((\d{4,5})(\d{4}))$/;

      const isValidPhoneNumber = phoneNumberRegex.test(
        firstRow.replace(/^\+/g, '').replace(/^0+/, '')
      );

      if (!isValidPhoneNumber) {
        setErrors({
          file: t('new-request-dialog.validations.phone-number-invalid'),
        });
        return;
      }

      if (file.size > MAX_FILE_SIZE) {
        setErrors({ file: t('new-request-dialog.validations.file-max-size') });
        return;
      }

      setFile({ name: file.name, content: fileContent });
    } catch (err) {
      console.error(err);
      setErrors({ file: t('new-request-dialog.messages.file-read-error') });
    } finally {
      setIsReadingFile(false);
    }
  }

  return (
    <CustomDialog
      onClose={handleClose}
      aria-labelledby="new-request-dialog"
      open={isOpen ?? false}
      TextField
      sx={{ borderRadius: 0 }}
      fullWidth
      maxWidth="sm"
      style={{ padding: '15px' }}
    >
      <DialogTitle fullWidth="true">
        {t('new-request-dialog.title')}
      </DialogTitle>

      <Box padding="8px 20px 0px 20px">
        <InputFileBox>
          <label
            htmlFor="file"
            style={{
              background: 'transparent',
              height: 50,
              width: '100%',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
              paddingLeft: 10,
              cursor: 'pointer',
            }}
          >
            <Typography noWrap variant="body1">
              {file?.name ?? t('new-request-dialog.fields.file')}
            </Typography>

            {isReadingFile ? <CircularProgress size={24} /> : <StyledIcon />}
          </label>
          <input
            accept=".xlsx,.csv"
            id="file"
            name="file"
            type="file"
            onChange={handleSelectFile}
            style={{ display: 'none' }}
          />
          {errors.file && (
            <StyledTypographyErr>{errors.file}</StyledTypographyErr>
          )}
        </InputFileBox>

        <Typography variant="body1" sx={{ marginTop: '8px', fontSize: '14px' }}>
          {t('new-request-dialog.fields.file-helper-text')}
        </Typography>

        <FormGroup sx={{ marginTop: '16px' }}>
          <Typography variant="body1" sx={{ fontWeight: 600 }}>
            {t('new-request-dialog.fields.verifications')}
          </Typography>

          <FormControlLabel
            control={
              <Checkbox
                sx={{ pt: '4px', pb: '4px' }}
                checked={true}
                onChange={() => {}}
                disabled={true}
              />
            }
            label={t('new-request-dialog.fields.verifications-whatsapp')}
          />

          <FormControlLabel
            control={
              <Checkbox
                sx={{ pt: '4px', pb: '4px' }}
                checked={false}
                onChange={() => {}}
                disabled={true}
              />
            }
            label={t('new-request-dialog.fields.verifications-hot-number')}
          />

          <FormControlLabel
            control={
              <Checkbox
                sx={{ pt: '4px', pb: '4px' }}
                checked={false}
                onChange={() => {}}
                disabled={true}
              />
            }
            label={t('new-request-dialog.fields.verifications-receive-sms')}
          />
        </FormGroup>
      </Box>

      <DialogActions>
        <Button variant="contained" color="secondary" onClick={handleClose}>
          {t('new-request-dialog.buttons.cancel')}
        </Button>
        <SubmitButton
          variant="contained"
          onClick={handleSave}
          disabled={isSaving || isReadingFile}
        >
          {isSaving ? (
            <CircularProgress size={24} />
          ) : (
            t('new-request-dialog.buttons.send')
          )}
        </SubmitButton>
      </DialogActions>
    </CustomDialog>
  );
}
