import { Button, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import { ArrowBack } from '@mui/icons-material';

export const StyledBackButton = styled(Button)(({ theme }) => ({
  width: 250,
  height: 50,
  borderRadius: 0,
  textTransform: 'none',

  [theme.breakpoints.down('xl')]: {
    width: 200,
    height: 40,
  },
}));

export const StyledArrowBack = styled(ArrowBack)(({ theme }) => ({
  position: 'absolute',
  left: 10,

  [theme.breakpoints.down('xl')]: { fontSize: 18 },
}));

export const TypographyButtonsFowardBack = styled(Typography)(({ theme }) => ({
  [theme.breakpoints.down('xl')]: { fontSize: 12 },
}));
