import {
  CircularProgress,
  DialogActions,
  DialogContent,
  FormControl,
  TextField,
  Theme,
} from '@mui/material';
import { validateEmail } from '../../../utils/ValidatorsHelper';
import CustomDialogTitle from './DialogTtitle';
import { ICustomCreateSenderDialog } from './interfaces';
import {
  CancelButton,
  CustomDialog,
  SaveButton,
  CircularProgressBox,
  NewSenderText,
  TextButtonStyled,
} from './styles';

const EditTextField = ({ breakpoints }: Theme) => ({
  width: '100%',
  [`& fieldset`]: {
    borderRadius: 2,
  },

  [breakpoints.down('xl')]: {
    '& .MuiOutlinedInput-input': {
      height: 15,
      fontSize: 13,
    },
    '& .MuiInputLabel-root': {
      fontSize: 13,
    },
  },
});

const CreateSenderDialog: React.FC<ICustomCreateSenderDialog> = ({
  setEmail,
  email,
  handleCreateSender,
  setShow,
  show,
  loading,
}: ICustomCreateSenderDialog): JSX.Element => {
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.preventDefault();
    setEmail(e.target.value);
  };

  const handleClose = () => {
    setShow(false);
    setEmail('');
  };

  return (
    <div>
      <CustomDialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={show}
      >
        <CustomDialogTitle id="customized-dialog-title" onClose={handleClose}>
          <NewSenderText variant="h2">Novo remetente</NewSenderText>
        </CustomDialogTitle>
        {loading ? (
          <CircularProgressBox>
            <CircularProgress color="secondary" size={24} />
          </CircularProgressBox>
        ) : (
          <DialogContent dividers>
            <FormControl>
              <TextField
                name="email-input"
                label="Email"
                type="email"
                value={email}
                onChange={handleChange}
                error={email.length > 1 && !validateEmail(email)}
                helperText={
                  email.length > 1 && !validateEmail(email)
                    ? 'Insira um e-mail válido'
                    : ''
                }
                FormHelperTextProps={{
                  style: {
                    color: '#EC5757',
                    fontFamily: "'Montserrat', 'sans-serif'",
                  },
                }}
                sx={EditTextField}
              />
            </FormControl>
          </DialogContent>
        )}
        <DialogActions>
          <CancelButton
            variant="contained"
            color="error"
            autoFocus
            onClick={handleClose}
          >
            <TextButtonStyled variant="body1">Cancelar</TextButtonStyled>
          </CancelButton>

          <SaveButton
            variant="contained"
            color="success"
            onClick={handleCreateSender}
          >
            <TextButtonStyled variant="body1">Salvar</TextButtonStyled>
          </SaveButton>
        </DialogActions>
      </CustomDialog>
    </div>
  );
};

export default CreateSenderDialog;
