import { styled as Styled, Grid, TextField } from '@mui/material';
// import SettingsIcon from '@mui/icons-material/Settings';
import styled from 'styled-components';

export const BoxGridsTemplate = Styled(Grid)(() => ({
  position: 'fixed',
  overflow: 'hidden',
  justifyContent: 'center',
  alignItems: 'center',
  height: '100%',
}));

// export const SideBarIcon = Styled(Grid)(() => ({
//   width: 50,
//   display: 'flex',
//   justifyContent: 'center'
// }));

// export const StyledSettingsIcon = Styled(SettingsIcon)(({ theme }) => ({
//   marginTop: 30,
//   color: theme.palette.primary.main,
//   cursor: 'pointer',
//   transition: 'all 0.3s ease',

//   '&:hover': {
//     color: theme.palette.primary.dark
//   }
// }));

export const FirsGridTemplate = Styled(Grid)(() => ({
  '& .CodeMirror': {
    height: '100vh',
    zIndex: 0,
  },
}));

export const SecondGridTemplate = Styled(Grid)(() => ({
  height: '100%',
  paddingBottom: 62,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
}));

// export const BoxInputStyled = Styled(Box)(() => ({
//   width: '80%',
//   height: 20,
//   justifyContent: 'center'
// }));

export const TextFieldStyled = Styled(TextField)(() => ({
  width: '50%',
  display: 'flex',
  margin: 'auto',
  color: '#fff',

  '& input:valid + fieldset': {
    borderColor: '#fff',
  },

  '&:hover': {
    borderColor: '#fff',
  },
}));

// export const BoxImage = Styled(Box)(() => ({
//   width: '10%'
// }));

// export const BoxEditCode = Styled(Box)(() => ({
//   width: '100%'
// }));

export const IframeStyled = styled.iframe<{
  isMobile: boolean;
}>`
  display: flex;
  width: ${({ isMobile }) => (isMobile ? '350px' : '100%')};
  justify-content: center;
  align-items: center;
  align-self: ${({ isMobile }) => (isMobile ? 'center' : '')};
  justify-self: ${({ isMobile }) => (isMobile ? 'center' : '')};
  margin: 'auto';
`;
