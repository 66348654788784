import { AxiosResponse } from 'axios';
import { BASE_URL } from '../../api';
import { request } from '../BASE_REQUEST';

const getSMSTemplates = async (): Promise<AxiosResponse<any> | undefined> => {
  try {
    const response = await request({
      url: `${BASE_URL}/sms/templates/`,
      method: 'GET',
    });
    return response;
  } catch (error) {
    Promise.reject(error);
  }
};

export default getSMSTemplates;
