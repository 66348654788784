import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { ReactComponent as CampaingOpenImage } from '../../assets/images/campaingOpen.svg';
import { RemoveCampaign } from '../../Auth/actions/campaing.action';
import { addNav } from '../../Auth/actions/side-nav.action';
import { ISideNav } from '../../Auth/interfaces/side-nav.interface';
import {
  StyledContainer,
  StyledInnerContainer,
  StyledSubtitle,
  StyledTitle,
  TypographyLink,
} from './styles';

const CampaingOpen: React.FC = (): JSX.Element => {
  const history = useHistory();
  const dispatch = useDispatch();

  const setSideNav = (sideNav: ISideNav): void => {
    dispatch(addNav(sideNav));
  };

  const setCampaingReduxValues = (): void => {
    dispatch(RemoveCampaign());
  };

  const handleReturnToHome = useCallback(() => {
    setSideNav({ showSide: true });
    setCampaingReduxValues();

    history.push('/');
  }, []);

  return (
    <StyledContainer>
      <StyledInnerContainer>
        <StyledTitle variant="h1">
          Sua campanha foi aberta com sucesso!
        </StyledTitle>
        <StyledSubtitle variant="body1">
          Em breve você conseguirá acompanhar as estatísticas.
        </StyledSubtitle>
        <CampaingOpenImage />
        <TypographyLink
          variant="body1"
          sx={{ cursor: 'pointer' }}
          color="secondary"
          onClick={handleReturnToHome}
        >
          Voltar para a página inicial
        </TypographyLink>
      </StyledInnerContainer>
    </StyledContainer>
  );
};

export default CampaingOpen;
