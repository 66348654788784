import { useState, useEffect } from 'react';
import { useSnackbar } from 'notistack';
import { format, startOfDay, endOfDay } from 'date-fns';
import {
  CircularProgress,
  Button,
  Tooltip,
  IconButton,
  TextField,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import DownloadIcon from '@mui/icons-material/Download';
import { DataGrid } from '@mui/x-data-grid';
import getDataRequests from '../../utils/api/GET/getDataRequests';
import getDataRequestUrl from '../../utils/api/GET/getDataRequestUrl';
import { uploadDataRequestFile } from '../../utils/api/POST/uploadDataRequestFile';

import Container from '../../components/Container';
import NewRequestDialog from './NewRequestDialog';
import { LoadingContainer, NoWrapSpan } from './styles';
import DesktopDatePicker from '@mui/lab/DesktopDatePicker';

const defaultFilters = {
  processed: '',
  createdAtStart: new Date(),
  createdAtEnd: new Date(),
};

export default function DataRequestList() {
  const [isLoading, setIsLoading] = useState(false);
  const [requests, setRequests] = useState([]);
  const [currentPage, setCurrentPage] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const [filters, setFilters] = useState(defaultFilters);
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [isSendingRequest, setIsSendingRequest] = useState(false);

  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation();

  useEffect(() => {
    if (!isNaN(filters.createdAtEnd) && !isNaN(filters.createdAtStart)) {
      handleLoadRequests();
    }
  }, [currentPage, pageSize, filters]);

  async function handleLoadRequests() {
    try {
      setIsLoading(true);

      const query = {
        processed: filters?.processed || null,
        createdAtStart:
          filters?.createdAtStart && startOfDay(filters?.createdAtStart),
        createdAtEnd: filters?.createdAtEnd && endOfDay(filters?.createdAtEnd),
      };

      const result = await getDataRequests(currentPage + 1, pageSize, query);

      if (result.status === 200) {
        const { data, count } = result.data;

        const mappedData = data.map(request => {
          const requestName = request.fileKey?.match(/\/([^/]+).csv$/)[1];
          const processedStr = request.processed
            ? t('data-requests.values.yes')
            : t('data-requests.values.no');
          const formattedData = format(
            new Date(request.createdAt),
            'dd/MM/yyyy HH:mm:ss'
          );

          return {
            ...request,
            requestName,
            processedStr,
            formattedData,
          };
        });

        setRequests({
          data: mappedData,
          count,
        });
      } else {
        throw new Error(`Failed to load requests. Status: ${result.status}`);
      }
    } catch (error) {
      console.error(error);
      enqueueSnackbar(t('data-requests.messages.get-requests-error'), {
        variant: 'error',
        autoHideDuration: 5000,
      });
    } finally {
      setIsLoading(false);
    }
  }

  async function handleDownloadFile(requestId) {
    try {
      const result = await getDataRequestUrl(requestId);

      if (result.status === 200) {
        const downloadLink = document.createElement('a');
        downloadLink.href = result.data.downloadUrl;
        downloadLink.style.display = 'none';
        document.body.appendChild(downloadLink);
        downloadLink.click();
        document.body.removeChild(downloadLink);
      } else {
        throw new Error(
          `Failed to get download link. Status: ${result.status}`
        );
      }
    } catch (error) {
      console.error(error);
      enqueueSnackbar(t('data-requests.messages.get-download-link-error'), {
        variant: 'error',
        autoHideDuration: 5000,
      });
    }
  }

  async function handleSendRequest(file) {
    try {
      setIsSendingRequest(true);

      const result = await uploadDataRequestFile(file);

      if (result.status === 200) {
        enqueueSnackbar(t('data-requests.messages.send-request-success'), {
          variant: 'success',
          autoHideDuration: 5000,
        });
        setIsDialogOpen(false);
        setFilters({ ...defaultFilters });
      } else {
        throw new Error(`Failed to send request: ${result}`);
      }
    } catch (error) {
      console.error(error);
      enqueueSnackbar(t('data-requests.messages.send-request-error'), {
        variant: 'error',
        autoHideDuration: 5000,
      });
    } finally {
      setIsSendingRequest(false);
    }
  }

  function handleChange(event) {
    setFilters(prevState => ({
      ...prevState,
      [event.target.name]: event.target.value,
    }));
  }

  function handleDateChange(name, value) {
    setFilters(prevState => ({
      ...prevState,
      [name]: value,
    }));
  }

  function handleClearFilters() {
    setFilters(defaultFilters);
  }

  return (
    <Container
      title={t('data-requests.title')}
      topRightContent={
        <Button onClick={() => setIsDialogOpen(true)}>
          {t('data-requests.buttons.new-request')}
        </Button>
      }
    >
      <div style={{ width: '100%' }}>
        <div
          style={{
            marginTop: '12px',
            alignItems: 'center',
            display: 'flex',
            justifyContent: 'center',
            marginBottom: '10px',
          }}
        >
          <FormControl style={{ width: '200px' }} size="small" fullWidth>
            <InputLabel id="request-data-filter-status-label">
              {t('data-requests.filters.status')}
            </InputLabel>
            <Select
              id="request-data-filter-status"
              name="processed"
              labelId="request-data-filter-status-label"
              label="Status"
              value={filters.processed}
              onChange={handleChange}
            >
              <MenuItem value="true">
                {t('data-requests.filters.status-processed')}
              </MenuItem>
              <MenuItem value="false">
                {t('data-requests.filters.status-pending')}
              </MenuItem>
            </Select>
          </FormControl>
          <DesktopDatePicker
            sx={{ marginLeft: '15px' }}
            label={t('data-requests.filters.start-date')}
            inputFormat="dd/MM/yyyy"
            value={filters.createdAtStart}
            onChange={value => handleDateChange('createdAtStart', value)}
            renderInput={params => (
              <TextField
                size="small"
                sx={{ width: '200px', marginLeft: '15px' }}
                {...params}
              />
            )}
          />
          <DesktopDatePicker
            sx={{ marginLeft: '15px' }}
            size="small"
            label={t('data-requests.filters.end-date')}
            inputFormat="dd/MM/yyyy"
            value={filters.createdAtEnd}
            onChange={value => handleDateChange('createdAtEnd', value)}
            renderInput={params => (
              <TextField
                size="small"
                sx={{ width: '200px', marginLeft: '15px' }}
                {...params}
              />
            )}
          />
          <Button
            variant="contained"
            size="small"
            style={{ maxHeight: '39px', marginLeft: '15px', padding: '8px' }}
            onClick={handleClearFilters}
          >
            {t('data-requests.buttons.clear-filters')}
          </Button>
        </div>
      </div>

      {isLoading ? (
        <LoadingContainer>
          <CircularProgress size={24} />
        </LoadingContainer>
      ) : (
        <DataGrid
          rows={requests.data}
          columns={[
            {
              field: 'id',
              headerName: t('data-requests.table.header.id'),
              editable: false,
              flex: 0.7,
              renderCell: params => (
                <div>
                  <Tooltip title={params.row.id}>
                    <NoWrapSpan>{params.row.id}</NoWrapSpan>
                  </Tooltip>
                </div>
              ),
            },
            {
              field: 'request_name',
              headerName: t('data-requests.table.header.request'),
              editable: false,
              flex: 0.7,
              renderCell: params => (
                <div>
                  <Tooltip title={params.row.requestName}>
                    <NoWrapSpan>{params.row.requestName}</NoWrapSpan>
                  </Tooltip>
                </div>
              ),
            },
            {
              field: 'statistic_total',
              headerName: t('data-requests.table.header.total'),
              editable: false,
              flex: 0.7,
              renderCell: params => (
                <div>
                  <Tooltip title={params.row.phoneNumbersCount}>
                    <NoWrapSpan>
                      {params.row.phoneNumbersCount ??
                        t('data-requests.values.pending')}
                    </NoWrapSpan>
                  </Tooltip>
                </div>
              ),
            },
            {
              field: 'processed',
              headerName: t('data-requests.table.header.processed'),
              editable: false,
              flex: 0.7,
              renderCell: params => (
                <div>
                  <Tooltip title={params.row.processedStr}>
                    <NoWrapSpan>{params.row.processedStr}</NoWrapSpan>
                  </Tooltip>
                </div>
              ),
            },
            {
              field: 'date',
              headerName: t('data-requests.table.header.date'),
              editable: false,
              flex: 0.7,
              renderCell: params => (
                <div>
                  <Tooltip title={params.row.formattedData}>
                    <NoWrapSpan>{params.row.formattedData}</NoWrapSpan>
                  </Tooltip>
                </div>
              ),
            },
            {
              field: 'actions',
              headerName: t('data-requests.table.header.actions'),
              sortable: false,
              editable: false,
              flex: 0.3,
              align: 'center',
              renderCell: params => {
                return (
                  <IconButton
                    onClick={() => handleDownloadFile(params.row.id)}
                    disabled={!params.row.processed}
                    style={{ opacity: params.row.processed ? '1' : '0.3' }}
                  >
                    <Tooltip title={t('data-requests.table.tooltip.download')}>
                      <DownloadIcon />
                    </Tooltip>
                  </IconButton>
                );
              },
            },
          ]}
          sx={{
            '& .MuiDataGrid-columnHeader': {
              width: 'auto !important',
              minWidth: '0',
            },
            marginTop: '20px',
          }}
          rowHeight={45}
          pageSize={pageSize}
          rowsPerPageOptions={[10, 25, 50, 100]}
          onPageSizeChange={setPageSize}
          rowCount={requests.count}
          pagination
          paginationMode="server"
          page={currentPage}
          onPageChange={setCurrentPage}
        />
      )}

      <NewRequestDialog
        isSaving={isSendingRequest}
        isOpen={isDialogOpen}
        onSave={handleSendRequest}
        onOpenChange={setIsDialogOpen}
      />
    </Container>
  );
}
