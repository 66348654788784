import { Button, styled, Typography } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';

export const SCreateNewTransactionButton = styled(Button)(({ theme }) => ({
  width: 300,
  height: 55,
  display: 'flex',
  justifyContent: 'space-evenly',
  alignItems: 'center',
  borderRadius: 0,

  [theme.breakpoints.down('xl')]: {
    width: 220,
    height: 45,
    marginTop: -30,
  },
}));

export const ButtonIcon = styled(AddIcon)(({ theme }) => ({
  color: '#fff',

  [theme.breakpoints.down('xl')]: {
    fontSize: 20,
  },
}));

export const ButtonTitle = styled(Typography)(({ theme }) => ({
  color: '#fff',
  textTransform: 'initial',

  [theme.breakpoints.down('xl')]: {
    fontSize: 12,
  },
}));
