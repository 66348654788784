import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { addNav } from '../../Auth/actions/side-nav.action';
import { ISideNav } from '../../Auth/interfaces/side-nav.interface';
import FormStepper from '../../components/Stepper';
import CAMPAING_INITIAL_VALUES from '../../components/Stepper/constants/initials-values-campaingForm.constant';

const Campaign: React.FC = (): JSX.Element => {
  const dispatch = useDispatch();

  const setSideNav = (sideNav: ISideNav): void => {
    dispatch(addNav(sideNav));
  };

  useEffect(() => {
    setSideNav({ showSide: false });
  }, []);

  return (
    <>
      <FormStepper
        initialValues={CAMPAING_INITIAL_VALUES}
        onSubmit={() => {}}
      />
    </>
  );
};

export default Campaign;
