import { styled, Theme } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import { Box, Button, Typography } from '@mui/material';

export const CustomDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    display: 'flex',
    flexDirection: 'column',
    width: 350,
    height: 310,
    rowGap: theme.spacing(3),
    overflow: 'hidden',

    [theme.breakpoints.down('xl')]: {
      width: 350,
      height: 310,
    },
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1),
  },
}));

export const NewTemplateText = styled(Typography)(({ theme }) => ({
  fontSize: 18,
  [theme.breakpoints.down('xl')]: {
    fontSize: 16,
  },
}));

export const TextButtonStyled = styled(Typography)(({ theme }) => ({
  [theme.breakpoints.down('xl')]: {
    fontSize: 12,
  },
}));

export const CancelButton = styled(Button)(({ theme }) => ({
  borderRadius: 0,
  textTransform: 'initial',
  width: 100,
  height: 32,

  [theme.breakpoints.down('xl')]: {
    width: 80,
    height: 28,
  },
}));

export const SaveButton = styled(Button)(({ theme }) => ({
  borderRadius: 0,
  textTransform: 'initial',
  width: 100,
  height: 32,
  color: '#FFF',

  [theme.breakpoints.down('xl')]: {
    width: 80,
    height: 28,
  },
}));

export const CircularProgressBox = styled(Box)(() => ({
  alignItems: 'center',
  justifyContent: 'center',
  width: 350,
  height: 280,
  display: 'flex',
}));

export const EditTextFieldSms = ({ breakpoints }: Theme): any => ({
  width: '100%',
  [`& fieldset`]: {
    borderRadius: 2,
  },

  [breakpoints.down('xl')]: {
    '& .MuiOutlinedInput-input': {
      height: 15,
      fontSize: 12,
    },
    '& .MuiInputLabel-root': {
      fontSize: 13,
    },
  },
});
