import {
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  Theme,
  Button,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useSnackbar } from 'notistack';
import React, { useState, useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';
import { ITemplate } from '../../../Auth/interfaces/template.interface';
import { IReducers } from '../../../Auth/store/store';
import { sendEditedSmsText } from '../../../utils/api/PUT';
import { IEditSMSTemplateModal } from './interfaces';
import {
  ButtonText,
  SaveButton,
  TextFieldContainer,
  TextChangeSms,
} from './styles';

const EditTextFieldSms = ({ breakpoints }: Theme) => ({
  width: '100%',
  [`& fieldset`]: {
    borderRadius: 2,
  },

  [breakpoints.down('xl')]: {
    '& .MuiOutlinedInput-input': {
      height: 15,
      fontSize: 12,
    },
    '& .MuiInputLabel-root': {
      fontSize: 13,
    },
  },
});

const EditSMSTemplateModal: React.FC<IEditSMSTemplateModal> = ({
  open,
  setOpen,
  deleteLoading,
  setDeleteLoading,
  content,
  title,
  setScreenLoad,
  type,
  handleSaveTemplate
}: IEditSMSTemplateModal): JSX.Element => {
	const { t } = useTranslation('translation', { keyPrefix: 'templates.dialogs.edit-template' });
  const [mounted, setMounted] = useState(false);
  const [SMSText, setSMSText] = useState(content);
  const [SMSTemplateTitle, setSMSTemplateTitle] = useState(title);

  const isMountedRef = useRef(null);

  useEffect(() => {
    isMountedRef.current = true;
    setMounted(true);

    return () => {
      isMountedRef.current = false;
    };
  }, []);

  useEffect(() => {
    if (mounted) {
      setSMSText(SMSText);
      setSMSTemplateTitle(SMSTemplateTitle);
    }
  }, [mounted]);

  const { enqueueSnackbar } = useSnackbar();

  const { id }: ITemplate = useSelector((state: IReducers) => state.template);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSMSText(event.target.value);
  };

  const handleClose = () => {
    setOpen(false);
    setScreenLoad(false);
  };

  const handleTitleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSMSTemplateTitle(event.target.value);
  };

  const handleSendEditedSMSMessage = () => {
    setDeleteLoading(true);
    sendEditedSmsText(id ?? '', type ,SMSText, SMSTemplateTitle)
      .then(response => {
        if (response && response.status === 200) {
          enqueueSnackbar(t('messages.edit-success'), {
            variant: 'success',
            autoHideDuration: 2000,
          });
          setDeleteLoading(false);
          setOpen(false);
          setSMSText('');
          setSMSTemplateTitle(''); // Limpe o título após a conclusão
          setScreenLoad(false);
          handleSaveTemplate();
        }
      })
      .catch(() => {
        enqueueSnackbar(
          t('messages.edit-error'),
          {
            variant: 'error',
            autoHideDuration: 2000,
          }
        );
        setDeleteLoading(false);
        setOpen(false);
      });
  };

  return (
    <Dialog open={open}>
      <DialogTitle>
        <TextChangeSms variant="body1">
          {type === 'SMS'
            ? t('sms-title')
            : t('whatsapp-title')}
        </TextChangeSms>
      </DialogTitle>
      <DialogContent>
          <TextField
            sx={EditTextFieldSms}
            autoFocus
            value={SMSText}
            onChange={handleChange}
            margin="dense"
            id="content"
            label={t('fields.message')}
            type="text"
            fullWidth
            variant="outlined"
            multiline
            minRows={8}
            maxRows={10}
          />
          <TextFieldContainer>
          <TextField
            sx={EditTextFieldSms}
            value={SMSTemplateTitle}
            onChange={handleTitleChange}
            margin="dense"
            id="title"
            label={t('fields.title')}
            type="text"
            fullWidth
            variant="outlined"
          />
        </TextFieldContainer>
      </DialogContent>
      <DialogActions>
        <Button variant="contained" onClick={handleClose} color="secondary">
          <ButtonText variant="body2">
						{t('buttons.cancel')}
					</ButtonText>
        </Button>
        <SaveButton
          variant="contained"
          onClick={handleSendEditedSMSMessage}
          disabled={!SMSText}
        >
          {deleteLoading ? (
            <CircularProgress size={20} color="inherit" />
          ) : (
            <ButtonText variant="body2">
							{t('buttons.confirm')}
						</ButtonText>
          )}
        </SaveButton>
      </DialogActions>
    </Dialog>
  );
          }

export default EditSMSTemplateModal;
