import { request } from '../BASE_REQUEST';
import { BASE_CHAT_URL } from '../../api';

const getIntegrations = async params => {
    try {
        const response = await request({
            url: `${BASE_CHAT_URL}/queueIntegration`,
            method: 'GET',
            params: {
							limit: "all",
							...params,
						}
        });
        return response;
    } catch (error) {
        Promise.reject(error);
    }
};

export default getIntegrations;
