import { Editor } from '../Editor';
import {
  FirsGridTemplate,
  SecondGridTemplate,
  BoxGridsTemplate,
  IframeStyled,
  // TextFieldStyled,
  // SideBarIcon,
  // StyledSettingsIcon
} from './styles';

import { ITemplateBody } from './interfaces';
import { ITemplate } from '../../../Auth/interfaces/template.interface';
import { IReducers } from '../../../Auth/store/store';
import { useDispatch, useSelector } from 'react-redux';
import { addTemplate } from '../../../Auth/actions/template.action';
// import ConfigSidebar from './ConfigSidebar/ConfigSidebar';

const TemplateBody: React.FC<ITemplateBody> = ({
  isMobile,
  srcDoc,
  setSrcDoc,
}: ITemplateBody): JSX.Element => {
  const template: ITemplate = useSelector((state: IReducers) => state.template);
  // const [open, setOpen] = useState(false);
  const dispatch = useDispatch();

  // const updateInputValue = (e: React.ChangeEvent<HTMLInputElement>) => {
  //   e.preventDefault();
  //   setSubject(e.target.value);
  // };

  const setTemplateBody = (template: ITemplate): void => {
    dispatch(addTemplate(template));
  };

  const handleChange = (event: string) => {
    setSrcDoc(event);
    template.body = event;
    setTemplateBody(template);
  };

  return (
    <>
      <BoxGridsTemplate container>
        {/* <SideBarIcon item>
          <StyledSettingsIcon onClick={() => setOpen(true)} />
        </SideBarIcon> */}
        {/* <ConfigSidebar open={open} setOpen={setOpen} /> */}
        <FirsGridTemplate item lg={5} xl={5}>
          <Editor language="htmlmixed" value={srcDoc} onChange={handleChange} />
        </FirsGridTemplate>
        <SecondGridTemplate item lg={7} xl={7}>
          {/* <TextFieldStyled
            label="Assunto"
            value={subjectString}
            onChange={updateInputValue}
          /> */}
          <IframeStyled
            isMobile={isMobile}
            srcDoc={srcDoc}
            title="output"
            sandbox="allow-scripts"
            frameBorder="0"
            width="100%"
            height="100%"
          />
        </SecondGridTemplate>
      </BoxGridsTemplate>
    </>
  );
};

export default TemplateBody;
