import { Box, Button, ThemeProvider } from '@mui/material';
import { SnackbarProvider } from 'notistack';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { addNav } from './Auth/actions/side-nav.action';
import { IAuth } from './Auth/interfaces/auth.interface';
import { ISideNav } from './Auth/interfaces/side-nav.interface';
import { IReducers } from './Auth/store/store';
import Auth from './pages/Auth/Auth';
import LayoutNew from './pages/LayoutNew';
import lightTheme from './styles/themes/light';
import { useTranslation } from 'react-i18next';
import { ptBR, enUS } from '@mui/x-data-grid';

const App: React.FC = (): JSX.Element => {
  const dispatch = useDispatch();
  const { access }: IAuth = useSelector((state: IReducers) => state.auth);
  const { i18n, t } = useTranslation();

  const setShowSideNav = (sideNav: ISideNav) => {
    dispatch(addNav(sideNav));
  };

  useEffect(() => {
    setShowSideNav({ showSide: true });
  }, []);

  const lightThemeWithLocale = {
    ...lightTheme,
    components: {
      ...lightTheme.components,
      MuiDataGrid: {
        ...lightTheme.components.MuiDataGrid,
        defaultProps: {
          ...lightTheme.components.MuiDataGrid.defaultProps,
          localeText: {
            ...lightTheme.components.MuiDataGrid.defaultProps.localeText,
            ...(i18n.language.startsWith('en')
              ? enUS.components.MuiDataGrid.defaultProps.localeText
              : ptBR.components.MuiDataGrid.defaultProps.localeText),
            columnMenuUnsort: t('components.MuiDataGrid.columnMenuUnsort'),
            columnMenuSortAsc: t('components.MuiDataGrid.columnMenuSortAsc'),
            columnMenuSortDesc: t('components.MuiDataGrid.columnMenuSortDesc'),
            columnMenuFilter: t('components.MuiDataGrid.columnMenuFilter'),
            columnMenuHideColumn: t(
              'components.MuiDataGrid.columnMenuHideColumn'
            ),
            columnMenuShowColumns: t(
              'components.MuiDataGrid.columnMenuShowColumns'
            ),
          },
        },
      },
    },
  };

  return (
    <SnackbarProvider maxSnack={4} preventDuplicate>
      <ThemeProvider theme={lightThemeWithLocale}>
        {access ? <LayoutNew /> : <Auth />}
      </ThemeProvider>
    </SnackbarProvider>
  );
};

export default App;
