import { ADD_DASHBOARD, REMOVE_DASHBOARD } from '../constants/dashboard.action';
import { IDashboard } from '../interfaces/dashboard.interface';

export type DashboardAction = {
  type: string;
  payload: IDashboard | undefined;
};

export const addDashboard = (dashboard: IDashboard): DashboardAction => ({
  type: ADD_DASHBOARD,
  payload: dashboard,
});

export const RemoveDashBoard = (): DashboardAction => ({
  type: REMOVE_DASHBOARD,
  payload: undefined,
});
