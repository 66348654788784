import { Box, Grid, styled } from '@mui/material';

export const Container = styled(Box)(() => ({
  width: '100%',
  height: '100%',
  flexGrow: 1,
  overflow: 'hidden',
}));

export const StyledGridContainer = styled(Grid)(() => ({
  height: '100%',
}));

export const StyledGridItem = styled(Grid)(() => ({}));

export const StyledLogoBox = styled(Box)(({ theme }) => ({
  margin: 'auto auto 0px auto',
  display: 'flex',
  width: '70%',
  justifyContent: 'flex-start',
  [theme.breakpoints.down('xl')]: {
    height: 200,
    width: 300,
    marginLeft: 100,
  },
  [theme.breakpoints.up('xl')]: {
    height: 300,
  },
}));

export const StyleSideImageContainer = styled(Box)(({ theme }) => ({
  backgroundColor: '#2525aa',
  width: '100%',
  height: '100vh',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  '& img': {
    width: 640,
    margin: 'auto 40px auto 100px',
    [theme.breakpoints.up('xl')]: {
      width: 750,
    },
  },
}));
