import { ContainerStyled } from '../Tabs/styles';
import {
  ContainerTitleTextStyled,
  ContainerTitleStyled,
  ContainerTitleMiddleBoxStyled,
  ContainerTitleRigthBoxStyled,
} from './styles';
import { IContainer } from './types';

export const Container: React.FC<IContainer> = ({
  title,
  topMiddleContent,
  topRightContent,
  children,
}: IContainer): JSX.Element => {
  return (
    <ContainerStyled>
      <ContainerTitleStyled>
        <ContainerTitleTextStyled>{title}</ContainerTitleTextStyled>
        <ContainerTitleMiddleBoxStyled>
          {topMiddleContent}
        </ContainerTitleMiddleBoxStyled>
        <ContainerTitleRigthBoxStyled>
          {topRightContent}
        </ContainerTitleRigthBoxStyled>
      </ContainerTitleStyled>
      {children}
    </ContainerStyled>
  );
};
