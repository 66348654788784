import { request } from '../BASE_REQUEST';

import { BASE_URL } from '../../api';
const getCampaignDetails = async campaignId => {
  try {
    const response = await request({
      url: `${BASE_URL}/sms/campaigns/${campaignId}/`,
      method: 'GET',
    });
    return response;
  } catch (error) {
    Promise.reject(error);
  }
};

export default getCampaignDetails;
