import React from 'react';
import { Box } from '@mui/material';
import { TabPanelProps } from '../../utils/interfaces';

const TabPanel: React.FC<TabPanelProps> = (
  props: TabPanelProps
): JSX.Element => {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`scrollable-prevent-tabpanel-${index}`}
      aria-labelledby={`scrollable-prevent-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Box>{children}</Box>
        </Box>
      )}
    </div>
  );
};

export default TabPanel;
