import { Box, styled } from '@mui/material';

export const Container = styled(Box)(({ theme }) => ({
  width: '100%',
  height: 850,

  [theme.breakpoints.down('xl')]: {
    height: 550,
  },
}));
