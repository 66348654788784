import { Box, Divider, styled, Typography } from '@mui/material';

export const Container = styled(Box)(({ theme: { breakpoints } }) => ({
  paddingTop: 37,
  paddingBottom: 42,

  [breakpoints.down('xl')]: {
    paddingBottom: 30,
    paddingTop: 43,
  },
}));

export const PageTilte = styled(Typography)(({ theme: { breakpoints } }) => ({
  marginLeft: 30,
  [breakpoints.up('xl')]: {
    fontSize: 28,
  },
  [breakpoints.down('xl')]: {
    fontSize: 20,
    marginLeft: 15,
  },
}));

export const CustomDivider = styled(Divider)(({ theme: { breakpoints } }) => ({
  width: '90%',
  marginLeft: -30,

  [breakpoints.down('xl')]: {
    marginLeft: -80,
  },
}));
