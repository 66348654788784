import * as React from 'react';
import {
  DataGrid,
  GridColDef,
  GridRenderCellParams,
  ptBR,
} from '@mui/x-data-grid';
import { TransactionsListProps } from '../../interface';
import { Box, Theme, Tooltip, Typography } from '@mui/material';
import { CustomTypographyText } from './styles';

export const TransactionList: React.FC<TransactionsListProps> = ({
  transactions,
}: TransactionsListProps): JSX.Element => {
  let onlyText: string;
  let onlyNumber: string;

  const dataGridStyles = ({ breakpoints }: Theme) => ({
    backgroundColor: '#F8F8F8',
    height: '812px',
    overflow: 'hidden',
    width: '100%',
    borderRadius: 0,

    [breakpoints.down('xl')]: {
      height: '528px',
    },
  });

  const columns: GridColDef[] = [
    {
      field: 'created_at',
      headerName: 'Data da transação',
      width: 200,
      renderCell: (params: GridRenderCellParams) => (
        <CustomTypographyText>{params.value}</CustomTypographyText>
      ),
    },
    {
      field: 'id',
      headerName: 'ID',
      width: 80,
      renderCell: (params: GridRenderCellParams) => (
        <CustomTypographyText>{params.value}</CustomTypographyText>
      ),
    },
    {
      field: 'transaction_source',
      headerName: 'Fonte',
      width: 140,
      renderCell: (params: GridRenderCellParams) => (
        <CustomTypographyText>{params.value}</CustomTypographyText>
      ),
    },
    {
      field: 'transaction_type',
      headerName: 'Tipo',
      width: 135,
      renderCell: (params: GridRenderCellParams) => (
        <CustomTypographyText type="type">{params.value}</CustomTypographyText>
      ),
    },
    {
      field: 'value',
      headerName: 'Valor',
      width: 160,
      renderCell: (params: GridRenderCellParams) => (
        <CustomTypographyText type="value">
          {params.value.toLocaleString('pt-br', {
            style: 'currency',
            currency: 'BRL',
          })}
        </CustomTypographyText>
      ),
    },
    {
      field: 'details',
      headerName: 'Detalhes',
      editable: false,
      width: window.innerWidth >= 1536 ? 400 : 120,
      renderCell: (params: GridRenderCellParams) => {
        const previousValueText: string[] = params.value
          .split(' - ')
          .splice(3, 2);

        const valueStrings = previousValueText[0];

        if (valueStrings) {
          const onlyNumberString = valueStrings.split(':')[1].replace(' ', '');
          onlyText = valueStrings.split(':')[0];
          onlyNumber = Number(onlyNumberString).toLocaleString('pt-br', {
            style: 'currency',
            currency: 'BRL',
          });
        }

        return (
          <>
            {window.innerWidth >= 1536 ? (
              <CustomTypographyText sx={{ fontSize: '12px' }}>
                {params.value.split('-').splice(0, 1).join('') +
                  ', ' +
                  onlyText +
                  ': ' +
                  onlyNumber}
              </CustomTypographyText>
            ) : (
              <Tooltip
                title={
                  <Box
                    sx={{
                      display: 'flex',
                      flexDirection: 'column',
                      rowGap: 2,
                    }}
                  >
                    <Typography variant="body2" sx={{ fontSize: '12px' }}>
                      {params.value.split('-').splice(0, 1).join('')}
                    </Typography>
                    <Typography variant="body2" sx={{ fontSize: '12px' }}>
                      {onlyText + ': ' + onlyNumber}
                    </Typography>
                  </Box>
                }
              >
                <Box
                  sx={{
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    whiteSpace: 'nowrap',
                  }}
                >
                  <CustomTypographyText sx={{ fontSize: '12px' }}>
                    {params.value.split('-').splice(0, 1).join('') +
                      ', ' +
                      onlyText +
                      ': ' +
                      onlyNumber}
                  </CustomTypographyText>
                </Box>
              </Tooltip>
            )}
          </>
        );
      },
    },
  ];

  const rows = transactions.map(transaction => {
    return {
      created_at: transaction.created_at,
      id: transaction.id,
      transaction_source: transaction.transaction_source,
      transaction_type: transaction.transaction_type,
      value: transaction.value,
      details: transaction.details,
    };
  });

  return (
    <div style={{ height: 400, width: '100%' }}>
      <DataGrid
        rows={rows}
        columns={columns}
        localeText={ptBR.components.MuiDataGrid.defaultProps.localeText}
        sx={dataGridStyles}
      />
    </div>
  );
};
