import { Grid, styled, Typography } from '@mui/material';

export const StyledBoxContainer = styled(Grid)<{
  daily_schedule: string[];
}>(({ theme }) => ({
  width: '100%',
  height: '50px',
  // marginTop: theme.spacing(2),
  border: `1px solid #dcdcdc`,
  borderRadius: 6,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
	marginLeft: 0,
}));

export const AccordionText = styled(Typography)(({ theme }) => ({
  [theme.breakpoints.down('xl')]: {
    fontSize: 13,
  },
}));
