import { FormControl, TextField, Theme } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useFormikContext } from 'formik';
import React, { ChangeEvent } from 'react';
import { MenuItemStyled } from './styles';
import { useDispatch } from 'react-redux';
import { addCampaing } from '../../../Auth/actions/campaing.action';
import { ICampaing } from '../../../Auth/interfaces/campaing.interface';
import { FirstStepProps } from '../../../utils/interfaces';
import { campaing_types } from '../../Stepper/constants/campaing_types.constant';
import { useSelector } from 'react-redux';

const SelectTypeStyled = ({ breakpoints }: Theme) => ({
  width: '100%',
  borderRadius: 2,

  '& .MuiInputLabel-root': {
    fontSize: 18,
  },

  '& .MuiOutlinedInput-root ': {
    fontSize: 18,
  },

  [breakpoints.down('xl')]: {
    width: '100%',
    height: 45,

    '& .MuiSelect-select': {
      fontSize: 11,
    },
    '& .MuiInputLabel-root': {
      marginTop: -0.5,
      fontSize: 13,
    },
    '& .MuiOutlinedInput-root ': {
      height: 45,
      fontSize: 13,
    },
  },

  [`& fieldset`]: {
    borderRadius: 2,
  },
});

const CampaingTypeSelect: React.FC = (): JSX.Element => {
  const { t } = useTranslation();
  const { values, setFieldValue, touched, errors } =
    useFormikContext<FirstStepProps>();
  const dispatch = useDispatch();
  const setCampaignType = (campaing: ICampaing): void => {
    dispatch(addCampaing(campaing));
  };

  const handleChange = (
    event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const campaing = campaing_types.find(
      campaing => campaing.value === event.target.value
    );

    setFieldValue('campaign_type', campaing.campaign_type);
    setFieldValue('campaign_type_ai', campaing.hasAI);
    setFieldValue('campaign_type_field', campaing.value);
    setFieldValue('matrix_variables', []);
    setFieldValue('template', '');
    setFieldValue('filename', null);
    setFieldValue('contact_variable', '');
    setFieldValue('contact_variable_index', '');
    setCampaignType({ campaign_type: campaing.campaign_type });
  };

  const { hasWhatsapp, hasAI, hasRCS, hasEmail } = useSelector(state => state.auth);

  const availableCampaigns = campaing_types.filter(campaign => {
    if (campaign.campaign_type === 'WHATSAPP' && !hasWhatsapp) return false;
    if (campaign.hasAI && !hasAI) return false;
		if (campaign.campaign_type === 'RCS' && !hasRCS) return false;
		if (campaign.campaign_type === 'EMAIL' && !hasEmail) return false;
    return true;
  });

  return (
    <>
      <FormControl>
        <TextField
          select
          label={t('create-campaign.tabs.campaign.fields.campaign-type')}
          value={values.campaign_type_field}
          onChange={e => handleChange(e)}
          error={
            touched.campaign_type_field && Boolean(errors.campaign_type_field)
          }
          sx={SelectTypeStyled}
        >
          {availableCampaigns.map(campaign => (
            <MenuItemStyled key={campaign.id} value={campaign.value}>
              {campaign.display}
            </MenuItemStyled>
          ))}
        </TextField>
      </FormControl>
    </>
  );
};

export default CampaingTypeSelect;
