import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Button, CircularProgress, Grid, IconButton } from '@mui/material';
import IconEdit from '@mui/icons-material/Edit';
import { useTranslation } from 'react-i18next';
import { getMainUsers } from '../../utils/api/GET/getUsers';
import { DataGrid } from '@mui/x-data-grid';
import Container from '../../components/Container';
import CreateMainUserDialog from '../../components/UserList/CreateMainUserDialog';
import { useSnackbar } from 'notistack';
import { createMainUser } from '../../utils/api/POST/createMainUser';
import { editMainUser } from '../../utils/api/PUT/editMainUser';
import { LoadingContainer } from './styles';

const defaultFormData = {
	name: '',
	email: '',
	password: '',
	is_main: false,
};

export default function MainUsers() {
	const { t } = useTranslation();
  const [show, setShow] = useState(false);
  const [users, setUsers] = useState([]);
  const [loading, setLoading] = useState(false);
  const [userEdit, setUserEdit] = useState(defaultFormData);
	const { is_master } = useSelector((state) => state.auth);

  const { enqueueSnackbar } = useSnackbar();

  const handleGetMainUsers = () => {
    setLoading(true);
    getMainUsers(null).then(res => {
      if (res) {
        setUsers(res.data);
        setLoading(false);
      }
    });
  };

  const handleSave = () => {
    setLoading(true);

		const userData = userEdit;

		if (!userData.password || !is_master) {
			delete userData.password;
		}

		console.log(userData);

    if (userData.id) {
      editMainUser(userData)
        .then(() => {
          setShow(false);
          setLoading(false);
          handleGetMainUsers();
          // setUserEdit({ is_staff: true })
        })
        .catch(() => {
          setLoading(false);
        });
    } else {
      createMainUser(userData)
        .then(res => {
          if (res?.status === 200) {
            enqueueSnackbar(t('users.messages.create-user-success'), {
              variant: 'success',
              preventDuplicate: true,
              autoHideDuration: 3000,
            });
            setShow(false);
            setLoading(false);
            handleGetMainUsers();
            // setUserEdit({ is_staff: true })
          }
        })
        .catch(() => {
          setLoading(false);
          enqueueSnackbar(t('users.messages.create-user-already-exists'), {
            variant: 'error',
            preventDuplicate: true,
            autoHideDuration: 3000,
          });
        });
    }
  };

  useEffect(() => {
    handleGetMainUsers();
  }, []);

  const columns = [
    {
      field: 'name',
      headerName: t('users.table.header.name'),
      flex: 0.5,
    },
    {
      field: 'email',
      headerName: t('users.table.header.email'),
      flex: 0.5,
    },
    {
      field: 'permission',
      headerName: t('users.table.header.permission'),
      flex: 0.5,
      renderCell: cell => {
        return cell.row.groups.includes('Main')
					? t('users.permissions.admin-permission')
					: t('users.permissions.operator-permission')
      },
    },
    {
      field: 'actions',
      headerName: t('users.table.header.actions'),
      flex: 0.2,
      align: 'center',
      headerAlign: 'center',
      renderCell: cell => {
        return (
          <Grid container justifyContent={'space-evenly'} alignItems={'center'}>
            <Grid item xs={6}>
              <IconButton
                onClick={() => {
                  const is_main = cell.row.groups.includes('Main')
                    ? true
                    : false;
                  setUserEdit({ ...cell.row, is_main: is_main });
                  setShow(true);
                }}
              >
                <IconEdit />
              </IconButton>
            </Grid>
          </Grid>
        );
      },
    },
  ];

  return (
    <Container
      title={t('users.title')}
      topRightContent={
        <Button
          onClick={() => {
						setUserEdit(defaultFormData);
            setShow(true);
          }}
        >
          {t('users.buttons.new-user')}
        </Button>
      }
    >
      {loading ? (
        <LoadingContainer>
          <CircularProgress size={24} />
        </LoadingContainer>
      ) : (
        <>
          <CreateMainUserDialog
            show={show}
            loading={loading}
            user={userEdit}
            setUser={setUserEdit}
            setShow={setShow}
            handleSave={handleSave}
          />
          <Grid
            container
            item
            xs={12}
            spacing={0}
            sx={{ marginTop: '20px', height: '80vh' }}
          >
            <DataGrid
              rowHeight={35}
              columns={columns}
              rows={users}
              hideFooter={true}
            />
          </Grid>
        </>
      )}
    </Container>
  );
}
