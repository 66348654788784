import { Box, styled, Typography } from '@mui/material';

export const AvatarContainer = styled(Box)(({ theme: { breakpoints } }) => ({
  width: '100%',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',

  [breakpoints.up('xl')]: {
    paddingTop: 50,
  },
}));

export const Container = styled(Box)(({ theme: { breakpoints } }) => ({
  display: 'flex',
  flexDirection: 'column',
  width: '100%',
  paddingTop: 14,

  [breakpoints.up('xl')]: {
    paddingTop: 20,
  },
}));

export const UserInfoInnerContainer = styled(Box)(
  ({ theme: { breakpoints } }) => ({
    display: 'flex',
    width: '100%',
    alignItems: 'center',
    padding: '17px 0px 17px 17px',

    [breakpoints.down('xl')]: {
      padding: 12,
    },
  })
);

export const UserInfoTitle = styled(Typography)(
  ({ theme: { palette, breakpoints } }) => ({
    fontSize: 13,
    color: palette.text.primary,
    paddingLeft: 5,

    [breakpoints.up('xl')]: {
      fontSize: 18,
    },
  })
);

export const UserInfoText = styled(Typography)(
  ({ theme: { palette, breakpoints } }) => ({
    fontSize: 13,
    color: palette.primary.dark,
    paddingLeft: 5,

    [breakpoints.up('xl')]: {
      fontSize: 16,
    },
  })
);
