import { AxiosResponse } from 'axios';
import { BASE_URL } from '../../api';
import { request } from '../BASE_REQUEST';

export const createSender = async (
  email: string
): Promise<AxiosResponse<any> | undefined> => {
  try {
    const response = await request({
      url: `${BASE_URL}/api/senders/`,
      method: 'POST',
      data: { email },
    });

    return response;
  } catch (error) {
    return Promise.reject(error);
  }
};
