import { AxiosResponse } from 'axios';
import { BASE_URL, BASE_WHATSAPP_URL, BASE_EMAIL_URL } from '../../api';
import { request } from '../BASE_REQUEST';

export const getSpecificEmailTemplate = async (
  id: string,
  type: string
): Promise<AxiosResponse<any> | undefined> => {
  try {
    if (type === 'WHATSAPP') {
      const response = await request({
        url: `${BASE_WHATSAPP_URL}/whatsapp/templates/${id}/`,
        method: 'GET',
      });
      return response;
    } else if (type === "SMS") {
      const response = await request({
        url: `${BASE_URL}/sms/templates/${id}/`,
        method: 'GET',
      });
      return response;
    } else if (type === "EMAIL") {
			const response = await request({
				url: `${BASE_EMAIL_URL}/email/templates/${id}/`,
				method: 'GET',
			});
			return response;
		} else {
			return Promise.reject(`Invalid type: ${type}`);
		}
  } catch (error) {
    return Promise.reject(error);
  }
};
