import { AxiosResponse } from 'axios';
import { BASE_URL, BASE_WHATSAPP_URL } from '../../api';
import { request } from '../BASE_REQUEST';

export const sendEditedSmsText = async (
  id: string,
  type: string,
  content: string,
  title:string,
): Promise<AxiosResponse<any>> => {
  try {
    let url = `${BASE_URL}/sms/templates/${id}/`;
    if (type === 'WHATSAPP') {
      url = `${BASE_WHATSAPP_URL}/whatsapp/templates/${id}/`;
    }
    
    const data = {}; 

    if (title !== null) {
      data.title = title;
    }

    if (content !== null) {
      data.content = content; 
    }

    const response = await request({
      url: url,
      method: 'PUT',
      data: data,
    });
    return response;
  } catch (error) {
    return Promise.reject(error);
  }
};
