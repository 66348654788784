import { Grid, styled } from '@mui/material';

export const GraphicAndCardsContainer = styled(Grid)(
  ({ theme: { breakpoints } }) => ({
    width: '93.4%',
    height: 500,
    borderRadius: 8,
    overflow: 'auto',
    justifyContent: 'center',

    [breakpoints.up('xl')]: {
      marginTop: 0,
    },
  })
);
