import { request } from '../BASE_REQUEST';
import { BASE_URL } from '../../api';

export const createCostCenter = async data => {
  try {
    const response = await request({
      url: `${BASE_URL}/sms/cost-center/`,
      method: 'POST',
      data: { ...data },
    });

    return response;
  } catch (error) {
    throw error;
  }
};
