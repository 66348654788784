import { BASE_URL } from '../../api';
import { request } from '../BASE_REQUEST';

export const updateCampaignAPI = async ()=> {
  try {
    const response = await request({
      url: `${BASE_URL}/sms/update-campaigns/`,
      method: 'POST',
      data: {},
    });

    return response;
  } catch (error) {
    return Promise.reject(error);
  }
};
