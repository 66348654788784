import * as yup from 'yup';

const validationLoginSchema = yup.object({
  email: yup
    .string()
    .email('login.validation.email.invalid')
    .required('login.validation.email.required'),
  password: yup
    .string()
    .min(8, 'login.validation.password.min-length')
    .required('login.validation.password.required'),
});

export default validationLoginSchema;
