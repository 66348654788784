import { SideNavAction } from '../actions/side-nav.action';
import { ADD_NAV, REMOVE_NAV } from '../constants/side-nav.constant';
import { ISideNav } from '../interfaces/side-nav.interface';

const initialState: ISideNav = {
  showSide: true,
  buttonIndex: 0,
};

const sideNavReducer = (
  state: ISideNav = initialState,
  action: SideNavAction
): ISideNav => {
  switch (action.type) {
    case ADD_NAV: {
      const { showSide, buttonIndex } = action.payload;

      return {
        ...state,
        showSide,
        buttonIndex,
      };
    }

    case REMOVE_NAV: {
      return initialState;
    }

    default:
      return state;
  }
};

export default sideNavReducer;
