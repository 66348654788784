import { AxiosResponse } from 'axios';
import { BASE_URL } from '../../api';
import { request } from '../BASE_REQUEST';

export const getSenders = async (): Promise<AxiosResponse<any> | undefined> => {
  try {
    const response = await request({
      url: `${BASE_URL}/api/senders/`,
      method: 'GET',
    });

    return response;
  } catch (error) {
    Promise.reject(error);
  }
};

export const getNextSenders = async (
  nextURL: string
): Promise<AxiosResponse<any> | undefined> => {
  try {
    const response = await request({
      url: nextURL,
      method: 'GET',
    });

    return response;
  } catch (error) {
    Promise.reject(error);
  }
};

export const getPreviousSenders = async (
  previousURL: string
): Promise<AxiosResponse<any> | undefined> => {
  try {
    const response = await request({
      url: previousURL,
      method: 'GET',
    });

    return response;
  } catch (error) {
    Promise.reject(error);
  }
};
