import { request } from '../BASE_REQUEST';
import { BASE_DATA_URL } from '../../api';

const getDataRequestUrl = async requestId => {
  try {
    const response = await request({
      url: `${BASE_DATA_URL}/api/whatsapp/requests/${requestId}/download`,
      method: 'GET',
    });
    return response;
  } catch (error) {
    Promise.reject(error);
  }
};

export default getDataRequestUrl;
