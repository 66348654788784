import {
  DialogActions,
  DialogTitle,
  Button,
  TextField,
  FormControl,
} from '@mui/material';
import { useTranslation } from 'react-i18next';

import { CustomDialog } from './styles';

export default function CreateCostCenterDialog({
  loading,
  show,
  setShow,
  handleSave,
  costCenter,
  handleCostCenterChange,
  errors,
  editMode,
  disableEditMode,
}) {
	const { t } = useTranslation();

  const handleClose = () => {
    setShow(false);
    disableEditMode();
  };

  return (
    <CustomDialog
      onClose={handleClose}
      aria-labelledby="create-route-dialog"
      open={show ?? false}
      TextField
      sx={{ borderRadius: 0 }}
      fullWidth
      maxWidth="xs"
      style={{ padding: '15px' }}
    >
      <DialogTitle fullWidth="true">
				{editMode
					? t('cost-centers.cost-center-dialog.title-edit')
					: t('cost-centers.cost-center-dialog.title-create')}
			</DialogTitle>

      <FormControl margin="dense">
        <TextField
          label={t('cost-centers.cost-center-dialog.fields.name')}
          value={costCenter}
          error={Boolean(errors.tax)}
          helperText={errors.tax}
          onChange={handleCostCenterChange}
        />
      </FormControl>
      <DialogActions>
        <Button
          variant="contained"
          color="secondary"
          autoFocus
          onClick={handleClose}
        >
          {t('cost-centers.cost-center-dialog.buttons.cancel')}
        </Button>
        <Button variant="contained" onClick={handleSave} disabled={loading}>
          {editMode
						? t('cost-centers.cost-center-dialog.buttons.update')
						: t('cost-centers.cost-center-dialog.buttons.save')}
        </Button>
      </DialogActions>
    </CustomDialog>
  );
}
