import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { useTranslation } from 'react-i18next';
import { IPopMenu } from './interfaces';
import { useHistory } from 'react-router-dom';
import { IAuth } from '../../../Auth/interfaces/auth.interface';
import { useDispatch, useSelector } from 'react-redux';
import { IReducers } from '../../../Auth/store/store';
import { TextStyled } from './styles';
import { ISideNav } from '../../../Auth/interfaces/side-nav.interface';
import { addNav } from '../../../Auth/actions/side-nav.action';

const PopMenu: React.FC<IPopMenu> = ({
  open,
  anchorEl,
  setAnchorEl,
}: IPopMenu): JSX.Element => {
  const { t } = useTranslation();
  const history = useHistory();
  const dispatch = useDispatch();
  const { is_staff, is_main }: IAuth = useSelector(
    (state: IReducers) => state.auth
  );

  const setIndexOfSideNavButton = (sideNav: ISideNav) => {
    dispatch(addNav(sideNav));
  };

  const handleNavigation = (route: string): void => {
    setAnchorEl(null);
    history.push(route);
    setIndexOfSideNavButton({ buttonIndex: 2, showSide: true });
  };

  return (
    <div>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={() => setAnchorEl(null)}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
      >
        {/* {is_staff && (
					<MenuItem onClick={() => handleNavigation('/plans-control')}>
						<TextStyled> Controle de planos </TextStyled>
					</MenuItem>
				)} */}
        <MenuItem onClick={() => handleNavigation('/templates-list')}>
          <TextStyled>{t('layout.submenu.settings.template-list')}</TextStyled>
        </MenuItem>
        {/* <MenuItem onClick={() => handleNavigation('/containment-list')}>
          <TextStyled> Lista de contenção </TextStyled>
        </MenuItem> */}
        {/* <MenuItem onClick={() => handleNavigation('/senders-list')}>
          <TextStyled> Lista de remetentes </TextStyled>
        </MenuItem> */}
        {/* {is_staff && (
          <MenuItem onClick={() => handleNavigation('/users')}>
            <TextStyled> Usuários Nexbox </TextStyled>
          </MenuItem>
        )} */}
        {is_main && (
          <MenuItem onClick={() => handleNavigation('/main-users')}>
            <TextStyled>{t('layout.submenu.settings.users')}</TextStyled>
          </MenuItem>
        )}
        {is_main && (
          <MenuItem onClick={() => handleNavigation('/cost-center')}>
            <TextStyled>{t('layout.submenu.settings.cost-center')}</TextStyled>
          </MenuItem>
        )}
        {/* {is_staff && (
          <MenuItem onClick={() => handleNavigation('/route-list')}>
            <TextStyled> Rotas </TextStyled>
          </MenuItem>
        )} */}
        {is_staff && (
          <MenuItem onClick={() => handleNavigation('/admin')}>
            <TextStyled>{t('layout.submenu.settings.admin')}</TextStyled>
          </MenuItem>
        )}
      </Menu>
    </div>
  );
};

export default PopMenu;
