import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import { Box, Button, Typography } from '@mui/material';

export const CustomDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    display: 'flex',
    flexDirection: 'column',
    width: '560px',
    rowGap: theme.spacing(3),
    overflow: 'hidden',
    // [theme.breakpoints.down('xl')]: {
    //   width: 640,
    //   height: 345
    // }
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1),
  },
}));

export const NewUserText = styled(Typography)(({ theme }) => ({
  fontSize: 18,
  [theme.breakpoints.down('xl')]: {
    fontSize: 16,
  },
}));

export const TextAdm = styled(Typography)(({ theme }) => ({
  fontSize: 16,
  [theme.breakpoints.down('xl')]: {
    fontSize: 14,
  },
}));

export const TextButtons = styled(Typography)(({ theme }) => ({
  fontSize: 14,
  [theme.breakpoints.down('xl')]: {
    fontSize: 12,
  },
}));

export const CancelButton = styled(Button)(({ theme }) => ({
  borderRadius: 0,
  textTransform: 'initial',
  width: 100,
  height: 32,

  [theme.breakpoints.down('xl')]: {
    width: 80,
    height: 28,
  },
}));

export const SaveButton = styled(Button)(({ theme }) => ({
  borderRadius: 0,
  textTransform: 'initial',
  width: 100,
  height: 32,
  color: '#FFF',

  [theme.breakpoints.down('xl')]: {
    width: 80,
    height: 28,
  },
}));

export const CircularProgressBox = styled(Box)(({ theme }) => ({
  alignItems: 'center',
  justifyContent: 'center',
  width: 350,
  height: 280,
  display: 'flex',

  [theme.breakpoints.down('xl')]: {
    width: 300,
    height: 230,
  },
}));
