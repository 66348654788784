import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Grid, Typography } from '@mui/material';
import { StyledBoxContainer } from './styles';
import { useDispatch, useSelector } from 'react-redux';
import { addCampaing } from '../../../../Auth/actions/campaing.action';
import { ICampaing } from '../../../../Auth/interfaces/campaing.interface';
import { useFormikContext } from 'formik';
import { PlanningTableContextProps, PlanningTableProps } from './interfaces';
import { IReducers } from '../../../../Auth/store/store';
import DatePicker, { DateObject } from 'react-multi-date-picker';
import Icon from 'react-multi-date-picker/components/icon';

const PlanningTable: React.FC<PlanningTableProps> = ({
  minDate,
  maxDate,
  value,
  setValue,
  disabled,
}: // setDays
PlanningTableProps): JSX.Element => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { setFieldValue } = useFormikContext<PlanningTableContextProps>();
  const [dateStr, setDateStr] = useState<string>();

  const { daily_schedule }: ICampaing = useSelector(
    (state: IReducers) => state.campaing
  );

  const setCampaingDailyDates = (campaing: ICampaing): void => {
    dispatch(addCampaing(campaing));
  };

  let definitiveArray: string[] = [];

  const handleLog = (date: DateObject) => {
    const dateStr = date.format('YYYY/MM/DD');
    const userDateStr = date.format('DD/MM/YYYY');
    definitiveArray = [dateStr];
    setValue(dateStr);
    setDateStr(userDateStr);
    setCampaingDailyDates({
      daily_schedule: definitiveArray,
      schedule_date: definitiveArray[0],
      weekly_schedule: [],
      monthly_schedule: '',
      custom_schedule_date: '',
      custom_schedule_finish_date: '',
    });
    setFieldValue('daily_schedule', definitiveArray);
    setFieldValue('schedule_date', definitiveArray[0]);
    setFieldValue('weekly_schedule', []);
    setFieldValue('is_one_day', true);
    // setDays([]);
  };

  useEffect(() => {
    if (disabled) {
      setValue(null);
      setDateStr(null);
      setCampaingDailyDates({
        daily_schedule: [],
        schedule_date: '',
        weekly_schedule: [],
        monthly_schedule: '',
        custom_schedule_date: '',
        custom_schedule_finish_date: '',
      });
      setFieldValue('daily_schedule', []);
      setFieldValue('schedule_date', '');
      setFieldValue('weekly_schedule', []);
      setFieldValue('is_one_day', false);
    }
  }, [disabled]);

  const weekDays = [
    t('create-campaign.tabs.schedule.date-picker.sun'),
    t('create-campaign.tabs.schedule.date-picker.mon'),
    t('create-campaign.tabs.schedule.date-picker.tue'),
    t('create-campaign.tabs.schedule.date-picker.wed'),
    t('create-campaign.tabs.schedule.date-picker.thu'),
    t('create-campaign.tabs.schedule.date-picker.fri'),
    t('create-campaign.tabs.schedule.date-picker.sat'),
  ];
  const months = [
    t('create-campaign.tabs.schedule.date-picker.jan'),
    t('create-campaign.tabs.schedule.date-picker.feb'),
    t('create-campaign.tabs.schedule.date-picker.mar'),
    t('create-campaign.tabs.schedule.date-picker.apr'),
    t('create-campaign.tabs.schedule.date-picker.may'),
    t('create-campaign.tabs.schedule.date-picker.jun'),
    t('create-campaign.tabs.schedule.date-picker.jul'),
    t('create-campaign.tabs.schedule.date-picker.aug'),
    t('create-campaign.tabs.schedule.date-picker.sep'),
    t('create-campaign.tabs.schedule.date-picker.oct'),
    t('create-campaign.tabs.schedule.date-picker.nov'),
    t('create-campaign.tabs.schedule.date-picker.dec'),
  ];

  return (
    <StyledBoxContainer
      container
      daily_schedule={daily_schedule ?? []}
      columnSpacing={2}
    >
      <Grid item>
        <Typography color={disabled ? '#bcbcbc' : '#000'}>
          {dateStr || t('create-campaign.tabs.schedule.date')}
        </Typography>
      </Grid>
      <Grid item>
        <DatePicker
          minDate={new Date()}
          weekDays={weekDays}
          months={months}
          render={<Icon color={disabled ? '#bcbcbc' : '#000'} />}
          value={value || {}}
          onChange={handleLog}
          disabled={disabled}
        />
      </Grid>
    </StyledBoxContainer>
  );
};

export default PlanningTable;
