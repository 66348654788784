import { Visibility, VisibilityOff } from '@mui/icons-material';
import {
  FormControl,
  IconButton,
  InputAdornment,
  TextField,
  Theme,
} from '@mui/material';
import { useFormikContext } from 'formik';
import { useTranslation } from 'react-i18next';
import React, { useState } from 'react';
import { PasswordProps } from '../../../utils/interfaces';
import { PasswordInputProps } from './interfaces';

const formHelperStyle = {
  fontSize: 13,
  fontFamily: "'Montserrat', 'sans-serif'",
};

const customTextFieldStyles = ({ breakpoints }: Theme) => ({
  width: '100%',
  [breakpoints.down('xl')]: {
    '& .MuiOutlinedInput-root ': {
      height: 45,
      fontSize: 12,
    },
  },
  [`& fieldset`]: {
    borderRadius: 4,
  },
});

const PasswordInput: React.FC<PasswordInputProps> = ({
  label,
}: PasswordInputProps): JSX.Element => {
  const [showPassword, setShowPassword] = useState(false);
  const {
    values: { password },
    handleChange,
    touched,
    errors,
  } = useFormikContext<PasswordProps>();
  const { t } = useTranslation();

  return (
    <FormControl variant="outlined">
      <TextField
        variant="outlined"
        id="standard-outlined-password"
        type={showPassword ? 'text' : 'password'}
        label={label || t('login.password')}
        value={password}
        onChange={handleChange('password')}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <IconButton
                aria-label={t('login.show-password')}
                edge="end"
                onClick={() => setShowPassword(!showPassword)}
              >
                {showPassword ? <Visibility /> : <VisibilityOff />}
              </IconButton>
            </InputAdornment>
          ),
        }}
        error={touched.password && Boolean(errors.password)}
        helperText={touched.password && t(errors.password)}
        FormHelperTextProps={{
          style: formHelperStyle,
        }}
        sx={customTextFieldStyles}
      />
    </FormControl>
  );
};

export default PasswordInput;
