import { request } from '../BASE_REQUEST';
import { BASE_WHATSAPP_URL } from '../../api';

const getWhatsappMessages = async params => {
  try {
    const response = await request({
      url: `${BASE_WHATSAPP_URL}/whatsapp/messages/`,
      method: 'GET',
      params: params,
    });
    return response;
  } catch (error) {
    Promise.reject(error);
  }
};

export default getWhatsappMessages;
