import { FileCsvParsed } from './interfaces/FileTypes';
import * as XLSX from 'xlsx';

export const parseCsv = (csv: string): FileCsvParsed => {
  const result: FileCsvParsed = {
    header: [''],
    data: [],
  };

  csv = csv.replaceAll('\r', '');

  const [header, ...content] = csv.split('\n');

  result.header = header.split(/[;]/);

  const maxCols = result.header.length;

  let count = 0;
  for (const item of content) {
    const newItem = item.split(/[;]/);
    result.data.push(newItem.slice(0, maxCols));
    count += 1;
    if (count > 3) {
      break;
    }
  }

  // content.forEach((item: any) => {
  //   const newItem = item.split(/[;]/);
  //   result.data.push(newItem.slice(0, maxCols));
  // });
  // result.data.push(content.slice(0, 2))

  return result;
};

export const convertXslxToCsv = (file: string | ArrayBuffer): string => {
  const bstr = file;
  const wb = XLSX.read(bstr, { type: 'binary' });
  const wsname = wb.SheetNames[0];
  const ws = wb.Sheets[wsname];
  return XLSX.utils.sheet_to_csv(ws);
};

export const getFileType = (fileName: string): string => {
  const type = fileName.split('.').pop();
  return type ? type : '';
};

export const checkFileTypeAccepted = (
  type: string,
  typeAccepteds: string[]
): boolean => {
  return typeAccepteds.includes(type);
};
