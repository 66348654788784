import { Radio } from '@mui/material';
import { useFormikContext } from 'formik';
import React from 'react';
import { CollapseTitleProps, TemplateInfoIDProps } from './interfaces';
import { SenderDefinition, StyledBox, TextTittle, StyledField } from './styles';

const CollapseTitle: React.FC<CollapseTitleProps> = ({
  templateInfo,
  handleOnChange,
  fileNameInput,
}: CollapseTitleProps): JSX.Element => {
  const {
    values: { template },
  } = useFormikContext<TemplateInfoIDProps>();

  return (
    <StyledBox>
      <StyledField
        component={Radio}
        value={template}
        onClick={(e: React.MouseEvent<SVGSVGElement, MouseEvent>) =>
          e.stopPropagation()
        }
        checked={template === templateInfo.id}
        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
          e.preventDefault();
          handleOnChange(templateInfo.id, templateInfo.title);
        }}
        disabled={fileNameInput ? false : true}
        e
      />
      <SenderDefinition variant="body1">
        <TextTittle>{templateInfo.title}</TextTittle>
      </SenderDefinition>
    </StyledBox>
  );
};

export default CollapseTitle;
