import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import { Box, Button, Typography } from '@mui/material';

export const CustomDialog = styled(Dialog)(() => ({
  '& .MuiPaper-root': {
    padding: '10px',
    // maxWidth: '700px'
  },
  '& .MuiDialogActions-root': {
    padding: '20px',
  },
}));

export const NewTemplateText = styled(Typography)(({ theme }) => ({
  fontSize: 18,
  [theme.breakpoints.down('xl')]: {
    fontSize: 16,
  },
}));

export const TextButtonStyled = styled(Typography)(({ theme }) => ({
  [theme.breakpoints.down('xl')]: {
    fontSize: 12,
  },
}));

export const CancelButton = styled(Button)(({ theme }) => ({
  borderRadius: 0,
  textTransform: 'initial',
  width: 100,
  height: 32,

  [theme.breakpoints.down('xl')]: {
    width: 80,
    height: 28,
  },
}));

export const SaveButton = styled(Button)(({ theme }) => ({
  borderRadius: 0,
  textTransform: 'initial',
  width: 100,
  height: 32,
  color: '#FFF',

  [theme.breakpoints.down('xl')]: {
    width: 80,
    height: 28,
  },
}));

export const CircularProgressBox = styled(Box)(() => ({
  alignItems: 'center',
  justifyContent: 'center',
  width: '100%',
  height: 280,
  display: 'flex',
}));
