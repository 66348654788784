import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { CircularProgressBox, TemplatesContainer } from './styles';
import { CollapseContent } from './CollapseContent';
import { useDispatch, useSelector } from 'react-redux';
import { IReducers } from '../../../Auth/store/store';
import { getWhatsappTemplates } from '../../../utils/api/GET';
import { getEmailTemplates } from '../../../utils/api/GET';
import { AxiosResponse } from 'axios';
import { TemplateContentProps } from './CollapseContent/interfaces';
import { useFormikContext } from 'formik';
import { ICampaing } from '../../../Auth/interfaces/campaing.interface';
import { addCampaing } from '../../../Auth/actions/campaing.action';
import { NoTemplates } from './NoTemplates';
import { CircularProgress, Grid } from '@mui/material';
import { getSMSTemplates } from '../../../utils/api/GET';
import { useSnackbar } from 'notistack';

interface ThirdStepProps {
  stepWasReady: (step: number) => void;
  fileNameInput;
}

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const ThirdStep: React.FC<ThirdStepProps> = props => {
  const {
    campaign_type,
    templateVariables,
    wTemplateVariables,
    template,
    isAI,
  }: ICampaing = useSelector((state: IReducers) => state.campaing);
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const { setFieldValue } = useFormikContext();
  const [loading, setLoading] = useState(false);
  const [templates, setTemplates] = useState<TemplateContentProps[]>([]);

  const { t } = useTranslation();

  const handleGetTemplates = () => {
    setLoading(true);
    if (campaign_type === 'WHATSAPP') {
      getWhatsappTemplates().then(
        (res: AxiosResponse<any> | undefined): void => {
          if (res) {
            const { data } = res;

            setTemplates(data.results);
            setLoading(false);
          }
        }
      );
    } else if (campaign_type === 'SMS') {
      getSMSTemplates().then((res: AxiosResponse<any> | undefined) => {
        if (res) {
          const { data } = res;
          setTemplates(data.results);
          setLoading(false);
        }
      });
    } else if (campaign_type === 'EMAIL') {
			getEmailTemplates().then((res: AxiosResponse<any> | undefined) => {
				if (res) {
					const { data } = res;
					setTemplates(data.results);
					setLoading(false);
				}
			});
		}
  };

  const setTemplateID = (campaing: ICampaing): void => {
    dispatch(addCampaing(campaing));
  };

  const setTemplateVariables = (campaing: ICampaing): void => {
    dispatch(addCampaing(campaing));
  };

  const handleChange = async (templateID: string, templateName: string) => {
    props.stepWasReady(2);

		if (campaign_type === 'EMAIL') {
			setTemplateID({
				template: templateID,
				templateName: templateName,
			});
			setFieldValue('template', templateID);
		} else {
			const template = templates.find(obj => obj.id === templateID);
			const pattern = /{{(.*?)}}/g;
			const containsCurlyBrackets = template.content.match(pattern);
			let aiVairables = [];
			let wVairables = [];
			if (templateVariables && templateVariables.length) {
				aiVairables = templateVariables
					.filter(variable => {
						if (!variable.includes('ia___')) {
							return false;
						}
						return true;
					})
					.map(variable => {
						return variable;
					});

				wVairables = templateVariables
					.filter(variable => {
						if (!variable.includes('w___')) {
							return false;
						}
						return true;
					})
					.map(variable => {
						return variable;
					});
			}

			if (containsCurlyBrackets) {
				const filteredVariables = [
					...new Set(containsCurlyBrackets.filter(String)),
				];

				setTemplateVariables({
					templateVariables: [
						...wVairables,
						...aiVairables,
						...filteredVariables,
					],
				});
			} else {
				setTemplateVariables({
					templateVariables: [...wVairables, ...aiVairables],
				});
			}

			setTemplateID({
				template: templateID,
				templateName: templateName,
			});
			setFieldValue('template', templateID);
		}
  };

  useEffect(() => {
    handleGetTemplates();
  }, []);

  useEffect(() => {
    if (template) {
      if (!templateVariables?.length) {
        enqueueSnackbar(
          t('create-campaign.validations.template-has-no-variables'),
          {
            variant: 'info',
            autoHideDuration: 3000,
          }
        );
      }
    }
  }, []);

  return (
    <Grid container>
      <Grid container item xs={12}>
        {loading ? (
          <CircularProgressBox>
            <CircularProgress size={24} />
          </CircularProgressBox>
        ) : templates.length ? (
          <>
            <TemplatesContainer>
              {/* <StyledPaper> */}
              <CollapseContent
                fileNameInput={props.fileNameInput}
                loading={loading}
                setLoading={setLoading}
                handleChange={handleChange}
                templatesResults={templates}
              />
              {/* </StyledPaper> */}
            </TemplatesContainer>
          </>
        ) : (
          <NoTemplates />
        )}
      </Grid>
    </Grid>
  );
};

export default ThirdStep;
