import { Box, Grid, styled, Typography } from '@mui/material';

export const Container = styled(Box)(() => ({
  height: '100vh',
  marginLeft: -20,
}));

export const InnerContainer = styled(Grid)(({ theme: { palette } }) => ({
  width: '100%',
  height: '100%',
  justifyContent: 'center',
  backgroundColor: palette.background.default,
}));

export const FirstColumn = styled(Grid)(({ theme: { palette } }) => ({
  backgroundColor: palette.background.default,
  paddingLeft: 39,
}));

export const ButtonContainer = styled(Box)(({ theme: { breakpoints } }) => ({
  position: 'absolute',
  right: 30,
  top: 30,

  [breakpoints.down('xl')]: {
    top: 55,
  },
}));

export const ListContainer = styled(Box)(
  ({ theme: { breakpoints, palette } }) => ({
    height: 564,
    overflowY: 'hidden',
    marginLeft: -30,
    backgroundColor: palette.background.default,

    [breakpoints.up('xl')]: {
      height: 810,
    },

    [breakpoints.down('xl')]: {
      marginLeft: -38,
    },
  })
);

export const LoadingContainer = styled(Box)(({ theme: { breakpoints } }) => ({
  width: '100%',
  height: 600,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',

  [breakpoints.down('xl')]: {
    height: 450,
  },
}));

export const ImageContainer = styled(Box)(() => ({
  width: '100%',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  paddingTop: 50,
}));

export const SecondColumn = styled(Grid)(({ theme: { palette } }) => ({
  borderLeft: '1px solid #ccc',
  alignItems: 'center',
  justifyContent: 'center',
  width: '100%',
  backgroundColor: palette.background.default,
}));

export const NoUserInfoMessageContainer = styled(Box)(() => ({
  width: '100%',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  paddingTop: 30,
}));

export const NoUserInfoMessage = styled(Typography)(
  ({ theme: { palette } }) => ({
    color: palette.primary.main,
    fontSize: 14,
  })
);

export const NoTransactionText = styled(Box)(() => ({
  width: '100%',
  height: 600,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
}));
