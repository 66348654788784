import { FormControl, TextField } from '@mui/material';
import { NumberFormatCustom } from '../NumberFormatCustom';
import { EditTextField } from '../TaxInput/styles';
import { BalanceInputProps } from './interface';

export const BalanceInput: React.FC<BalanceInputProps> = ({
  value,
  label,
  handleChange,
}: BalanceInputProps): JSX.Element => (
  <FormControl>
    <TextField
      required
      name="balance-input"
      label={label}
      type="text"
      value={value}
      onChange={handleChange}
      sx={EditTextField}
      InputProps={{
        inputComponent: NumberFormatCustom as any,
      }}
    />
  </FormControl>
);
