import { AuthAction } from '../actions/auth.action';
import { ADD_AUTH, REMOVE_AUTH } from '../constants/auth.constant';
import { IAuth } from '../interfaces/auth.interface';

const initialState: IAuth = {
  access: '',
  name: '',
  user_id: 0,
  is_staff: false,
  sms_bi_url: '',
};

const authReducer = (
  state: IAuth = initialState,
  action: AuthAction
): IAuth => {
  switch (action.type) {
    case ADD_AUTH: {
      return {
        ...state,
        ...action.payload,
      };
    }

    case REMOVE_AUTH: {
      return initialState;
    }

    default:
      return state;
  }
};

export default authReducer;
