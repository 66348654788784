import { Box, styled } from '@mui/material';
import { DataGrid as MuiDataGrid } from '@mui/x-data-grid';

export const LoadingContainer = styled(Box)(() => ({
  width: '100%',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  marginTop: 30,
}));

export const StyledSpan = styled('span')(() => ({
  width: '100%',
  height: '100%',
  justifyContent: 'center',
  display: 'flex',
  alignItems: 'center',
}));

export const NoWrapSpan = styled('span')(() => ({
  width: '100%',
  height: 'auto',
  display: 'inline-block',
  whiteSpace: 'nowrap',
  overflow: 'hidden !important',
  textOverflow: 'ellipsis',
}));
