import {
  Box,
  Typography,
  Grid,
  styled,
  Accordion,
  AccordionDetails,
} from '@mui/material';

export const StyledSecondGrid = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'column',
}));

export const StyledTitleBox = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  marginBottom: theme.spacing(2),
  rowGap: 12,
}));

export const StyledTemplateID = styled(Typography)(() => ({
  color: '#808080',
  fontSize: 12,
  marginLeft: 10,
}));

export const BoxTemplateID = styled(Box)(() => ({
  display: 'flex',
  alignItems: 'center',
}));

export const StyledTemplateContainer = styled(Grid)(() => ({
  display: 'inline-block',

  border: '1px solid #ccc',
  borderRadius: 8,
  width: '100%',
  height: 182,
  backgroundColor: '#F8F8FBA6',
}));

export const StyledTitleContainer = styled(Box)(() => ({
  width: '100%',
  display: 'flex',
}));

export const StyledTemplateNameTitle = styled(Typography)(({ theme }) => ({
  fontWeight: 700,
  color: theme.palette.secondary.main,
  fontSize: 12,
  marginTop: '10px',
  marginLeft: '10px',
}));

export const StyledTemplateNameTitleBox = styled(Typography)(() => ({
  fontWeight: 400,
  fontSize: 12,
  wordBreak: 'break-all',
  marginTop: '10px',
  marginLeft: '10px',
}));

export const StyledLastEditTitleBox = styled(Typography)(() => ({
  fontWeight: 400,
  fontSize: 12,
}));

export const BoxTemplateName = styled(Box)(() => ({
  display: 'flex',
  width: '100%',
  alignItems: 'center',
}));

export const TemplateNameStyled = styled(Typography)(() => ({
  color: '#808080',
  fontSize: 12,
  marginLeft: 10,
}));

export const BoxTemplateDate = styled(Box)(() => ({
  display: 'flex',
  width: '100%',
  alignItems: 'center',
}));

export const TemplateDateStyled = styled(Typography)(() => ({
  color: '#808080',
  fontSize: 12,
  marginLeft: 10,
}));

export const TextTemplateStyled = styled(Typography)(({ theme }) => ({
  color: theme.palette.secondary.main,
  fontSize: 12,
}));

export const StyledThirdGridItem = styled(Grid)(() => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
}));

export const StyledLastEditTitle = styled(Typography)(({ theme }) => ({
  fontWeight: 700,
  color: theme.palette.secondary.main,
  fontSize: 14,
}));

export const StyledLastEditBox = styled(Box)(() => ({
  border: '1px solid #c2c2c2',
  borderRadius: 8,
  height: 60,
  width: '90%',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
}));

export const StyledFirstGridItem = styled(Grid)(() => ({
  display: 'flex',
  flexDirection: 'column',
  width: '100%',
  alignItems: 'center',
  justifyContent: 'center',
}));

export const StyledTemplateNameBox = styled(Box)(() => ({
  border: '1px solid #c2c2c2',
  borderRadius: 8,
  height: 60,
  width: '80%',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
}));

export const StyledAccordion = styled(Accordion)<{ checked: boolean }>(
  ({ theme, checked }) => ({
    border: checked ? `3px solid ${theme.palette.primary.main}` : '',
  })
);

export const AccordionContainerStyled = styled(Box)(() => ({
  justifyContent: 'space-between',

  '& .MuiPaper-root': {
    background: 'transparent',
  },
}));

export const AccordionStyles = styled(AccordionDetails)(() => ({
  display: 'flex',
  width: '100%',

  '& .MuiBox-root': {
    width: '100%',
  },
}));

export const StyledBox = styled(Box)(() => ({
  display: 'flex',
}));
