import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import App from './App';
import './index.css';
import reportWebVitals from './reportWebVitals';
import {
  axiosInterceptors,
  axiosResponseInterceptors,
} from './utils/api/BASE_REQUEST';
import { storeConfig, persistedStore } from './Auth/store/store';
import { PersistGate } from 'redux-persist/lib/integration/react';
import { CircularProgress } from '@mui/material';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import './i18n';

axiosInterceptors(storeConfig);
axiosResponseInterceptors();

ReactDOM.render(
  <React.StrictMode>
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <Provider store={storeConfig}>
        <PersistGate loading={<CircularProgress />} persistor={persistedStore}>
          <BrowserRouter>
            <App />
          </BrowserRouter>
        </PersistGate>
      </Provider>
    </LocalizationProvider>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
