import { request } from '../BASE_REQUEST';
import { BASE_SHURIKEN_URL } from '../../api';

const getCompanies = async params => {
    try {
        const response = await request({
            url: `${BASE_SHURIKEN_URL}/api/businesses/`,
            method: 'GET',
            params: params,
        });
        return response;
    } catch (error) {
        Promise.reject(error);
    }
};

export default getCompanies;
