import { AxiosResponse } from 'axios';
import { IUser } from '../../../components/UserList/interfaces';
import { BASE_URL } from '../../api';
import { request } from '../BASE_REQUEST';

export const editMainUser = async (
  user: IUser
): Promise<AxiosResponse<any> | undefined> => {
  try {
    const response = await request({
      url: `${BASE_URL}/account/main-user/${user.id}/`,
      method: 'PUT',
      data: {
        ...user,
      },
    });
    return response;
  } catch (error) {
    Promise.reject(error);
  }
};
