import { Box, styled } from '@mui/system';

export const StyleSideImageContainer = styled(Box)(({ theme }) => ({
  backgroundColor: '#2E3192',
  width: '100%',
  height: '100vh',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  '& img': {
    width: 500,
    margin: 'auto 40px auto 100px',
    [theme.breakpoints.up('xl')]: {
      width: 750,
    },
  },
}));
