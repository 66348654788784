import { Box, Grow, Tooltip } from '@mui/material';
import ArrowForwardRoundedIcon from '@mui/icons-material/ArrowForwardRounded';
import { ICard } from './interface';
import {
  CardInnerWrapper,
  CardItem,
  CardItemTitle,
  CardWrapper,
  ContainerWrapper,
  FirstContainer,
  GoToIconButton,
  SummaryNumber,
  SummaryText,
  ToolTipText,
  TopBarContainer,
} from './styles';
import React from 'react';
import { useHistory } from 'react-router';
import { ISideNav } from '../../../Auth/interfaces/side-nav.interface';
import { useDispatch } from 'react-redux';
import { addNav } from '../../../Auth/actions/side-nav.action';

export const Card: React.FC<ICard> = ({
  cardTitle,
  toolTipMessage,
  firstSummaryNumber,
  firstSummaryText,
  icon,
  route,
  showArrow = true,
}: ICard): JSX.Element => {
  const history = useHistory();
  const dispatch = useDispatch();

  const setIndexOfSideNavButton = (sideNav: ISideNav) => {
    dispatch(addNav(sideNav));
  };

  const handleGoToRoute = (routeName: string): void => {
    if (routeName === '/campaign-list') {
      setIndexOfSideNavButton({ buttonIndex: 1, showSide: true });
    } else if (routeName === '/templates-list') {
      setIndexOfSideNavButton({ buttonIndex: 2, showSide: true });
    }
    history.push(routeName);
  };

  return (
    <CardWrapper item md={3} xl={3}>
      <CardItem>
        <CardInnerWrapper>
          <TopBarContainer>
            <Box style={{ display: 'flex' }}>
              <CardItemTitle>{cardTitle}</CardItemTitle>
              {icon}
            </Box>
            {showArrow ? (
              <GoToIconButton
                disableRipple
                disableFocusRipple
                disableTouchRipple
                onClick={() => handleGoToRoute(route)}
              >
                <Tooltip
                  TransitionComponent={Grow}
                  leaveDelay={100}
                  placement="top"
                  title={<ToolTipText>{toolTipMessage}</ToolTipText>}
                >
                  <ArrowForwardRoundedIcon
                    color={'secondary'}
                    fontSize={'small'}
                  />
                </Tooltip>
              </GoToIconButton>
            ) : null}
          </TopBarContainer>
          <ContainerWrapper container>
            <FirstContainer item md={12} xl={12}>
              {firstSummaryNumber === '0' ? null : (
                <SummaryNumber>{firstSummaryNumber}</SummaryNumber>
              )}
              <SummaryText>{firstSummaryText}</SummaryText>
            </FirstContainer>
          </ContainerWrapper>
        </CardInnerWrapper>
      </CardItem>
    </CardWrapper>
  );
};
