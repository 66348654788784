import { createTheme, PaletteOptions } from '@mui/material/styles';
import colors from './colors';
import type {} from '@mui/x-data-grid/themeAugmentation';
import { ptBR } from '@mui/x-data-grid';

const palette: PaletteOptions = {
  mode: 'light',
  primary: {
    '50': '#F0F7FF',
    '100': '#C2E0FF',
    '200': '#99CCF3',
    '300': '#66B2FF',
    '400': '#3399FF',
    '500': '#007FFF',
    '600': '#0072E5',
    '700': '#0059B2',
    '800': '#004C99',
    '900': '#003A75',
    main: colors.grayScale7,
    light: colors.grayScale7,
    dark: colors.grayScale7,
    contrastText: colors.white,
  },
  divider: 'rgba(194, 224, 255, 0.08)',
  background: {
    default: colors.white,
    paper: colors.white,
  },
  common: {
    black: '#1D1D1D',
    white: '#fff',
  },
  text: {
    primary: colors.black,
    secondary: colors.grayScale6,
    disabled: colors.grayScale3,
  },
  grey: {
    '50': colors.white,
    '100': colors.grayScale1,
    '200': colors.grayScale2,
    '300': colors.grayScale3,
    '400': '#999999',
    '500': colors.grayScale4,
    '600': colors.grayScale5,
    '700': colors.grayScale6,
    '800': colors.grayScale7,
    '900': colors.black,
    A100: '#EEEEEE',
    A200: '#AAAAAA',
    A400: '#777777',
    A700: '#222222',
  },
  error: {
    '50': '#FFF0F1',
    '100': '#FFDBDE',
    '200': '#FFBDC2',
    '300': '#FF99A2',
    '400': '#FF7A86',
    '500': '#FF505F',
    '600': '#EB0014',
    '700': '#C70011',
    '800': '#94000D',
    '900': '#570007',
    main: colors.alert,
    light: '#FF99A2',
    dark: '#C70011',
    contrastText: '#fff',
  },
  success: {
    '50': '#E9FBF0',
    '100': '#C6F6D9',
    '200': '#9AEFBC',
    '300': '#6AE79C',
    '400': '#3EE07F',
    '500': '#21CC66',
    '600': '#1DB45A',
    '700': '#1AA251',
    '800': '#178D46',
    '900': '#0F5C2E',
    main: colors.sucess,
    light: '#6AE79C',
    dark: '#1AA251',
    contrastText: 'rgba(0, 0, 0, 0.87)',
  },
  warning: {
    '50': '#FFF9EB',
    '100': '#FFF3C1',
    '200': '#FFECA1',
    '300': '#FFDC48',
    '400': '#F4C000',
    '500': '#DEA500',
    '600': '#D18E00',
    '700': '#AB6800',
    '800': '#8C5800',
    '900': '#5A3600',
    main: '#DEA500',
    light: '#FFDC48',
    dark: '#AB6800',
    contrastText: 'rgba(0, 0, 0, 0.87)',
  },
  secondary: {
    main: colors.grayScale5,
    light: colors.grayScale7,
    dark: colors.grayScale7,
    contrastText: colors.white,
  },
  info: {
    main: colors.grayScale7,
    light: '#4fc3f7',
    dark: '#0288d1',
    contrastText: 'rgba(0, 0, 0, 0.87)',
  },
  contrastThreshold: 3,
  tonalOffset: 0.2,
  action: {
    active: '#fff',
    hover: 'rgba(255, 255, 255, 0.08)',
    hoverOpacity: 0.08,
    selected: 'rgba(255, 255, 255, 0.16)',
    selectedOpacity: 0.16,
    disabled: 'rgba(255, 255, 255, 0.3)',
    disabledBackground: 'rgba(255, 255, 255, 0.12)',
    disabledOpacity: 0.38,
    focus: 'rgba(255, 255, 255, 0.12)',
    focusOpacity: 0.12,
    activatedOpacity: 0.24,
  },
};

// Create a theme instance.
const theme = createTheme({
  breakpoints: {
    keys: ['xs', 'sm', 'md', 'lg', 'xl'],
    values: {
      xs: 0,
      sm: 600,
      md: 900,
      lg: 1280,
      xl: 1536,
    },
    unit: 'px',
  },
  palette,
  typography: {
    fontFamily: 'Montserrat',
    h1: {
      fontFamily: 'Montserrat',
      fontSize: 40,
      fontWeight: 800,
      lineHeight: 1.1142857142857143,
    },
    h2: {
      fontFamily: 'Montserrat',
      fontSize: 32,
      fontWeight: 800,
      lineHeight: 1.2222222222222223,
    },
    h3: {
      fontFamily: 'Montserrat',
      fontSize: 24,
      lineHeight: 1.2222222222222223,
      letterSpacing: 0.2,
      fontWeight: 'bold',
    },
    h4: {
      fontFamily: 'Montserrat',
      fontSize: 20,
      lineHeight: 1.5,
      letterSpacing: 0.2,
      fontWeight: 500,
    },
    h5: {
      fontFamily: 'Montserrat',
      fontSize: 19,
      lineHeight: 1.5,
      letterSpacing: 0.1,
      fontWeight: 'bold',
    },
    h6: {
      fontSize: 18,
      lineHeight: 1.5,
      fontWeight: 500,
    },
    subtitle1: { fontSize: 17, fontWeight: 'bold' },
    subtitle2: { fontSize: 16, fontWeight: 'bold' },
    body1: { fontSize: 16, fontWeight: 'normal' },
    body2: { fontSize: 14, fontWeight: 600 },
    caption: { fontSize: 14, fontWeight: 'normal' },
    button: { fontSize: 14, textTransform: 'unset' },
    overline: {},
  },
  components: {
    MuiSvgIcon: {
      defaultProps: {
        htmlColor: colors.grayScale7,
      },
    },
    MuiPaper: {
      styleOverrides: {
        root: {
          outline: 'none',
        },
      },
    },
    MuiSelect: {
      styleOverrides: {
        select: {
          '&:focus': {
            backgroundColor: colors.white,
            borderColor: '#6677B9 !important',
            boxShadow: 'none',
          },
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          width: '100%',
        },
      },
    },
    MuiInputBase: {
      styleOverrides: {
        input: {
          backgroundColor: 'white',
          '&::-ms-reveal': {
            display: 'none !important',
            visibility: 'hidden',
          },
          '&::-ms-clear': {
            display: 'none !important',
            visibility: 'hidden',
          },
        },
        root: {
          '& fieldset': {
            border: '1px solid #dcdcdc !important',
          },
          '&:hover fieldset': {
            borderColor: '#6677B9 !important',
          },
          '&.Mui-focused fieldset': {
            borderColor: '#6677B9 !important',
          },
          '&.Mui-error fieldset': {
            borderColor: '#e21a4c !important',
          },
        },
      },
    },
    MuiInputLabel: {
      styleOverrides: {
        root: {
          padding: 0,
          margin: 0,
          '&.MuiFormLabel-root': {
            fontSize: 14,
            fontWeight: 600,
            fontFamily: 'Montserrat',
            padding: 0,
            margin: 0,
          },
        },
      },
    },
    MuiDataGrid: {
      defaultProps: {
        autoHeight: true,
        hideFooter: false,
        // pageSize: 20,
        // density: "compact",
        disableColumnFilter: true,
        disableColumnSelector: true,
        localeText: {
          ...ptBR.components.MuiDataGrid.defaultProps.localeText,
          columnMenuUnsort: 'Ordem Original',
          columnMenuSortAsc: 'Ordem Crescente',
          columnMenuSortDesc: 'Ordem Decrescente',
          columnMenuFilter: 'Filtrar',
          columnMenuHideColumn: 'Ocultar',
          columnMenuShowColumns: 'Exibir Colunas',
        },
      },
      styleOverrides: {
        root: {
          border: 0,
          fontFamily: ['Montserrat', 'sans-serif'].join(','),
          WebkitFontSmoothing: 'auto',
          letterSpacing: 'normal',
          '& .MuiDataGrid-root, .rendering-zone': {
            maxHeight: 'none !important',
          },
          '& .MuiDataGrid-root .MuiDataGrid-window': {
            position: 'relative !important',
          },
          '& .MuiDataGrid-root .MuiDataGrid-viewport': {
            maxHeight: 'none !important',
          },
          '& .MuiDataGrid-root': {
            height: 'auto !important',
          },
          '& .MuiDataGrid-iconSeparator': {
            display: 'block',
            color: colors.white,
          },
          // '& .MuiDataGrid-filterIcon': {
          // 	filter: 'brightness(0) invert(1)',
          // 	backgroundColor: 'transparent'
          // },
          '& .MuiDataGrid-menuIcon': {
            filter: 'brightness(0) invert(1)',
            backgroundColor: 'transparent',
          },
          '& .MuiDataGrid-sortIcon': {
            filter: 'brightness(0) invert(1)',
            backgroundColor: 'transparent',
          },
          '& .MuiDataGrid-footerContainer': {
            display: 'flex',
            justifyContent: 'center',
            backgroundColor: 'transparent',
          },
          '& .MuiPagination-ul': {
            display: 'flex',
            justifyContent: 'center',
            width: `calc(100vw - 760px)`,
            '.MuiPaginationItem-firstLast': {
              border: `1px solid ${colors.grayScale2}`,
              borderRadius: '8px',
              padding: '17px 19px',
            },
            '.MuiPaginationItem-previousNext': {
              border: `1px solid ${colors.grayScale2}`,
              borderRadius: '8px',
              padding: '17px 19px',
            },
            li: {
              backgroundColor: 'transparent',
              color: colors.grayScale7,
              '.Mui-selected': {
                backgroundColor: 'transparent',
                color: colors.grayScale7,
                fontWeight: 'bold',
                borderRadius: 0,
              },
            },
          },
          '& .MuiDataGrid-columnHeaders': {
            backgroundColor: colors.grayScale4,
          },
          '& .MuiDataGrid-columnHeader': {
            display: 'flex',
            alignItems: 'center',
            cursor: 'pointer',
            outline: 'none',
            color: 'white',
            border: 0,
            '&:hover': {
              border: 0,
            },
            '&:focus': {
              outline: 'none',
              border: 0,
            },
            '&:focus-within': {
              outline: 'none',
              border: 0,
            },
          },
          '& .MuiDataGrid-columnHeaderDraggableContainer': {
            height: '40px',
            padding: '0 7px 0 7px',
            border: `1px solid ${colors.grayScale4}`,
            borderRadius: '8px',
          },
          '& .MuiDataGrid-columnHeaderCheckbox .MuiDataGrid-columnHeaderDraggableContainer':
            {
              border: '0 !important',
            },
          '& .dg-noHeader .MuiDataGrid-columnHeaderDraggableContainer': {
            border: '0 !important',
            outline: 'none',
          },
          '& .MuiDataGrid-cell': {
            color: colors.grayScale7,
            borderBottom: '1px solid #888888',
            backgroundColor: colors.white,
            fontSize: 12,
            fontWeight: 'normal',
            '&:focus': {
              outline: 'none !important',
            },
          },
          '& .MuiDataGrid-cell:hover': {
            backgroundColor: '#888888',
            color: 'white',
          },
        },
      },
    },
    MuiCssBaseline: {
      styleOverrides: {
        root: {
          fontFamily: 'Montserrat',
        },
      },
    },
    MuiTypography: {
      styleOverrides: {
        root: {
          fontFamily: 'Montserrat',
        },
      },
    },
    MuiButtonBase: {
      defaultProps: {
        disableTouchRipple: true,
      },
    },
    MuiButton: {
      defaultProps: {
        variant: 'contained',
        disableElevation: true,
        sx: {
          fontFamily: 'Montserrat',
          fontSize: '14px',
          fontWeight: 'normal',
        },
      },
      styleOverrides: {
        root: {
          borderRadius: 0,
        },
        sizeLarge: {
          padding: '0.875rem 1rem',
          lineHeight: 1.3125,
          letterSpacing: 0,
        },
        sizeSmall: {
          padding: '4px 0px',
          '@media (min-width:900px)': {
            padding: '4px 8px',
          },
        },
      },
      variants: [
        {
          props: {
            // variant: 'code'
          },
          style: {
            color: '#B2BAC2',
            border: '1px solid',
            borderColor: '#265D97',
            backgroundColor: '#132F4C',
            fontFamily: 'Montserrat',
            fontWeight: 'normal',
            fontSize: '0.8125rem',
            lineHeight: 1.5,
            letterSpacing: 0,
            WebkitFontSmoothing: 'subpixel-antialiased',
            '&:hover, &.Mui-focusVisible': {
              borderColor: 'orange',
              backgroundColor: '#173A5E',
              '& .MuiButton-endIcon': {
                color: colors.white,
              },
            },
            '& .MuiButton-startIcon': {
              color: '#B2BAC2',
            },
            '& .MuiButton-endIcon': {
              display: 'inline-block',
              position: 'absolute',
              right: 0,
              marginRight: 10,
              color: '#B2BAC2',
            },
          },
        },
        {
          props: {
            // variant: 'link'
          },
          style: {
            fontSize: '0.875rem',
            fontWeight: 700,
            color: colors.white,
            mb: 1,
            '& svg': {
              ml: -0.5,
            },
          },
        },
        {
          props: { variant: 'contained', color: 'primary' },
          style: {
            backgroundColor: colors.grayScale7,
            color: colors.white,
            borderColor: colors.grayScale7,
          },
        },
        {
          props: { variant: 'contained', color: 'secondary' },
          style: {
            backgroundColor: colors.grayScale6,
            color: colors.white,
            borderColor: colors.grayScale6,
          },
        },
        {
          props: { variant: 'contained', color: 'error' },
          style: {
            backgroundColor: colors.alert,
            color: colors.white,
            borderColor: colors.alert,
          },
        },
        {
          props: { variant: 'contained', color: 'info' },
          style: {
            backgroundColor: colors.grayScale2,
            color: colors.grayScale6,
            borderColor: colors.grayScale6,
          },
        },
        {
          props: { variant: 'outlined', color: 'info' },
          style: {
            backgroundColor: colors.grayScale3,
            color: colors.white,
            borderColor: colors.grayScale3,
          },
        },
        {
          props: { variant: 'outlined', color: 'primary' },
          style: {
            backgroundColor: colors.white,
            color: colors.grayScale7,
            borderColor: colors.grayScale2,
          },
        },
        {
          props: { variant: 'outlined', color: 'secondary' },
          style: {
            backgroundColor: colors.white,
            color: colors.grayScale6,
            borderColor: colors.grayScale2,
            '&:hover': {
              borderColor: colors.grayScale2,
            },
          },
        },
        {
          props: { variant: 'outlined', color: 'error' },
          style: {
            backgroundColor: colors.white,
            color: colors.alert,
            borderColor: colors.alert,
          },
        },
      ],
    },
    MuiTab: {
      styleOverrides: {
        root: {
          fontFamily: 'Montserrat',
          fontSize: 16,
          fontWeight: 200,
          color: colors.white,
          minHeight: 60,
          backgroundColor: colors.grayScale7,
          marginLeft: 25,
          marginRight: 18,
          opacity: 0.9,
          '&.Mui-selected': {
            color: colors.grayScale7,
            backgroundColor: colors.white,
            fontFamily: 'Montserrat',
            fontSize: 16,
            fontWeight: 600,
          },
        },
      },
    },
    MuiTabs: {
      styleOverrides: {
        indicator: {
          backgroundColor: colors.grayScale7,
          margin: '0 17px',
          maxWidth: 'calc(100vh-34px)',
          fontFamily: 'Montserrat',
          fontSize: 16,
          fontWeight: 600,
        },
      },
    },
    MuiPaginationItem: {
      styleOverrides: {
        root: {
          minHeight: 40,
          minWidth: 40,
          margin: '0 10px 0 10px',
        },
      },
    },
    MuiCheckbox: {
      styleOverrides: {
        root: {
          marginRight: '5px',
          '&:hover': {
            backgroundColor: 'transparent',
          },
          '&.Mui-checked': {
            color: colors.grayScale7,
          },
        },
      },
    },
    MuiFormControl: {
      defaultProps: {
        fullWidth: true,
      },
      styleOverrides: {
        root: {
          width: '100%¨',
        },
      },
    },
    MuiFormControlLabel: {
      styleOverrides: {
        root: {
          width: '100%¨',
          '& .MuiFormControlLabel-label': {
            fontFamily: 'Montserrat',
            fontSize: 16,
            fontWeight: 'normal',
          },
        },
      },
    },
  },
});

export default theme;
