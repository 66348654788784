import { Button, TextField } from '@mui/material';
import DesktopDatePicker from '@mui/lab/DesktopDatePicker';
import { useTranslation } from 'react-i18next';

export default function MiddleContainer({
  handleChange,
  handleSearch,
  startDate,
  handstartDateChange,
  endDate,
  handendDateChange,
  clearFilters,
  phone,
}) {
	const { t } = useTranslation();

  return (
    <div style={{ width: '100%' }}>
      <div
        style={{
          marginTop: '12px',
          alignItems: 'center',
          display: 'flex',
          justifyContent: 'center',
          marginBottom: '10px',
        }}
      >
        <TextField
          label={t('phones.filter.phone')}
          value={phone}
          onChange={handleChange}
          name="phone"
          size="small"
        />
        <DesktopDatePicker
          sx={{ marginLeft: '15px' }}
          label={t('phones.filter.start-date')}
          inputFormat="dd/MM/yyyy"
          value={startDate}
          onChange={handstartDateChange}
          renderInput={params => (
            <TextField
              size="small"
              sx={{ width: '200px', marginLeft: '15px' }}
              {...params}
            />
          )}
        />
        <DesktopDatePicker
          sx={{ marginLeft: '15px' }}
          size="small"
          label={t('phones.filter.end-date')}
          inputFormat="dd/MM/yyyy"
          value={endDate}
          onChange={handendDateChange}
          renderInput={params => (
            <TextField
              size="small"
              sx={{ width: '200px', marginLeft: '15px' }}
              {...params}
            />
          )}
        />
        <Button
          variant="contained"
          size="small"
          style={{ maxHeight: '39px', marginLeft: '15px', padding: '8px' }}
          onClick={handleSearch}
        >
          {t('phones.filter.search')}
        </Button>
        <Button
          variant="contained"
          size="small"
          style={{ maxHeight: '39px', marginLeft: '15px', padding: '8px' }}
          onClick={clearFilters}
        >
          {t('phones.filter.clear')}
        </Button>
      </div>
    </div>
  );
}
