import { useState } from 'react';

import { Tooltip } from '@mui/material';
import { useTranslation } from 'react-i18next';

import Container from '../../components/Container';
import MiddleContainer from '../../components/Phones/MiddleContainer';

import { useSnackbar } from 'notistack';

import { LoadingContainer, NoWrapSpan } from './styles';
import { CircularProgress } from '@mui/material';

import { DataGrid, GridColDef } from '@mui/x-data-grid';

import { BASE_URL } from '../../utils/api';

import getPhones from '../../utils/api/GET/getPhones';
import getWhatsappMessages from '../../utils/api/GET/getWhatsappMessages';

export default function Phones() {
  const [fields, setFields] = useState({
    phone: null,
    start_date: null,
    end_date: null,
  });
  const [loading, setLoading] = useState(false);
  // const [currentPage, setCurrentPage] = useState(0);
  // const [pageCount, setPageCount] = useState(0);
  // const [nextPage, setNextPage] = useState(0);
  // const [previousPage, setPreviousPage] = useState(0);
  const [phones, setPhones] = useState([]);
  const { enqueueSnackbar } = useSnackbar();
	const { t } = useTranslation();

  const handleChange = event => {
    setFields({ ...fields, [event.target.name]: event.target.value });
  };

  const handstartDateChange = date => {
    setFields({ ...fields, start_date: date });
  };

  const handendDateChange = date => {
    setFields({ ...fields, end_date: date });
  };

  const clearFilters = () => {
    setFields({ phone: '', start_date: null, end_date: null });
  };

  const handleSearch = async (page = null) => {
    if (!fields.phone) {
      enqueueSnackbar(t('phones.messages.phone-required'), {
        variant: 'error',
        autoHideDuration: 5000,
      });
    } else {
      try {
        setLoading(true);
        let url = `${BASE_URL}/sms/number/`;
        let params = {};
        if (typeof page === 'string') {
          url = page;
        } else {
          if (fields.start_date !== null) {
            params['start_date'] =
              fields.start_date.toLocaleDateString('pt-BR');
          }

          if (fields.end_date !== null) {
            params['end_date'] = fields.end_date.toLocaleDateString('pt-BR');
          }

          params['number'] = fields.phone;
        }

        const responses = await Promise.all([
          getPhones(url, params),
          getWhatsappMessages(params),
        ]);

        const smsData = await responses[0];
        const whatsappData = await responses[1];

        if (smsData.status != 200 || whatsappData.status != 200) {
          setLoading(false);
          enqueueSnackbar(t('phones.messages.phone-search-error'), {
            variant: 'error',
            autoHideDuration: 5000,
          });
        } else {
          // setPageCount(result.data.count);
          // setNextPage(result.data.next)
          // setPreviousPage(result.data.previous)

          smsData.data.forEach(function (data) {
            data.type = 'SMS';
          });
          setPhones([...smsData.data, ...whatsappData.data]);
          setLoading(false);
        }
      } catch (error) {
        setLoading(false);
      }
    }
  };
  // const handlePageChange = (newPage: number) => {
  //   // We have the cursor, we can allow the page transition.
  //   setCurrentPage(newPage)
  //   if (newPage - currentPage > 0) {
  //     handleSearch(nextPage);
  //   } else {
  //     handleSearch(previousPage);
  //   }
  // };

  const columns: GridColDef[] = [
    {
      field: 'created_at',
      headerName: t('phones.table.header.created-at'),
      editable: false,
      flex: 0.3,
      renderCell: params => (
        <Tooltip title={params.row.created_at}>
          <NoWrapSpan>{params.row.created_at}</NoWrapSpan>
        </Tooltip>
      ),
    },
    {
      field: 's_from',
      headerName: t('phones.table.header.from'),
      editable: false,
      flex: 0.2,
      renderCell: params => (
        <Tooltip title={params.row.s_from}>
          <NoWrapSpan>{params.row.s_from}</NoWrapSpan>
        </Tooltip>
      ),
    },
    {
      field: 's_to',
      headerName: t('phones.table.header.to'),
      editable: false,
      flex: 0.2,
      renderCell: params => (
        <Tooltip title={params.row.s_to}>
          <NoWrapSpan>{params.row.s_to}</NoWrapSpan>
        </Tooltip>
      ),
    },
    {
      field: 'campaign_title',
      headerName: t('phones.table.header.campaign-title'),
      editable: false,
      flex: 0.4,
      renderCell: params => (
        <Tooltip title={params.row.campaign_title}>
          <NoWrapSpan>{params.row.campaign_title}</NoWrapSpan>
        </Tooltip>
      ),
    },
    {
      field: 'status',
      headerName: t('phones.table.header.status'),
      editable: false,
      flex: 0.35,
      renderCell: params => (
        <Tooltip title={params.row.status}>
          <NoWrapSpan>{params.row.status}</NoWrapSpan>
        </Tooltip>
      ),
    },
    {
      field: 'message',
      headerName: t('phones.table.header.message'),
      editable: false,
      flex: 1,
      renderCell: params => (
        <Tooltip title={params.row.message}>
          <NoWrapSpan>{params.row.message}</NoWrapSpan>
        </Tooltip>
      ),
    },
    {
      field: 'type',
      headerName: t('phones.table.header.type'),
      editable: false,
      flex: 0.2,
      renderCell: params => {
        const type = params.row.type === 'SMS' ? params.row.type : 'WHATSAPP';
        return (
          <Tooltip
            title={type}
          >
            <NoWrapSpan>
              {type}
            </NoWrapSpan>
          </Tooltip>
        );
      },
    },
  ];

  return (
    <Container
      title={t('phones.title')}
      topMiddleContent={
        <MiddleContainer
          handleChange={handleChange}
          handleSearch={handleSearch}
          startDate={fields.start_date}
          handstartDateChange={handstartDateChange}
          endDate={fields.end_date}
          handendDateChange={handendDateChange}
          clearFilters={clearFilters}
          phone={fields.phone}
        />
      }
    >
      {loading ? (
        <LoadingContainer>
          <CircularProgress size={24} />
        </LoadingContainer>
      ) : (
        <DataGrid
          rowHeight={35}
          rows={phones}
          columns={columns}
          // hideFooterPagination
          pageSize={100}
          rowsPerPageOptions={[100]}
          // rowCount={pageCount}
          pagination
          // paginationMode="server"
          // page={currentPage}
          // onPageChange={handlePageChange}
        />
      )}
    </Container>
  );
}
