import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { IconButton, Menu, MenuItem } from '@mui/material';

import { ReactComponent as BrFlagImg } from '../../assets/images/brazil-flag-icon.svg';
import { ReactComponent as USFlagImg } from '../../assets/images/united-states-flag-icon.svg';

import { StyledLanguageButtonsContainer } from './styles';
import { ContentCut } from '@mui/icons-material';

export default function LanguageButtons() {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const { i18n, t } = useTranslation();

  function handleClick(event: React.MouseEvent<HTMLButtonElement>) {
    setAnchorEl(event.currentTarget);
  }

  function handleClose() {
    setAnchorEl(null);
  }

  function handleChangeLanguage(language: 'pt' | 'en') {
    i18n.changeLanguage(language);
    handleClose();
  }

  const open = Boolean(anchorEl);
  const CurrentLanguageFlag = i18n.language.startsWith('en')
    ? USFlagImg
    : BrFlagImg;

  return (
    <StyledLanguageButtonsContainer>
      <IconButton
        id="current-language-flag-button"
        aria-controls={open ? 'basic-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        sx={{ padding: 0 }}
        onClick={handleClick}
      >
        <CurrentLanguageFlag />
      </IconButton>

      <Menu
        id="select-language-menu-button"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{ 'aria-labelledby': 'current-language-flag-button' }}
        sx={{ marginTop: '0.5rem' }}
      >
        <MenuItem onClick={() => handleChangeLanguage('pt')}>
          <BrFlagImg width="22px" style={{ marginRight: '8px' }} />
          {t('layout.languages.pt')}
        </MenuItem>
        <MenuItem onClick={() => handleChangeLanguage('en')}>
          <USFlagImg width="22px" style={{ marginRight: '8px' }} />
          {t('layout.languages.en')}
        </MenuItem>
      </Menu>
    </StyledLanguageButtonsContainer>
  );
}
