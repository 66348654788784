import { request } from '../BASE_REQUEST';
import { BASE_AI_URL } from '../../api';

const getAiLayouts = async () => {
    try {
        const response = await request({
            url: `${BASE_AI_URL}/ai/ai-user-layouts/`,
            method: 'GET',
        });
        return response;
    } catch (error) {
        Promise.reject(error);
    }
};

export default getAiLayouts;
