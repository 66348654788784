import { AxiosResponse } from 'axios';
import { request } from '../BASE_REQUEST';

export const getTemplates = async (
  type?: string,
  url?: string
): Promise<AxiosResponse<any> | undefined> => {
  try {
    return await request({
      url: url,
      method: 'GET',
    });
  } catch (error) {
    Promise.reject(error);
  }
};

export const getNextTemplates = async (
  nextURL: string
): Promise<AxiosResponse<any>> => {
  try {
    return await request({ url: nextURL, method: 'GET' });
  } catch (error) {
    return Promise.reject(error);
  }
};

export const getPreviousTemplates = async (
  previousURL: string
): Promise<AxiosResponse<any>> => {
  try {
    return await request({ url: previousURL, method: 'GET' });
  } catch (error) {
    return Promise.reject(error);
  }
};
