import { AppBar, Box, IconButton, styled, Typography } from '@mui/material';

export const StyledBox = styled(Box)(({ theme }) => ({
  width: '100%',
  height: 80,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  marginBottom: 10,

  [theme.breakpoints.down('xl')]: {
    height: 45,
    '& .MuiSvgIcon-root': {
      fontSize: 22,
    },
  },
}));

export const FrameContainer = styled(Box)(() => ({
  display: 'flex',
  height: '100%',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
}));

export const AppBarStyled = styled(AppBar)(({ theme }) => ({
  position: 'relative',

  [theme.breakpoints.down('xl')]: {
    height: 50,
    '& .MuiTypography-root': {
      fontSize: '13px',
    },
    '& .MuiSvgIcon-root': {
      fontSize: '20px',
    },
  },
}));

export const IconButtonStyled = styled(IconButton)(({ theme }) => ({
  [theme.breakpoints.down('xl')]: {
    marginTop: -10,
    fontSize: 12,
  },
}));

export const TextName = styled(Typography)(({ theme }) => ({
  [theme.breakpoints.down('xl')]: {
    marginTop: -10,
  },
}));
