import { Button, styled, Typography, MenuItem } from '@mui/material';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';

export const AddListButton = styled(Button)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-evenly',
  alignItems: 'center',

  [theme.breakpoints.down('xl')]: {
    width: 220,
    height: 45,
  },
}));

export const ButtonIcon = styled(KeyboardArrowDownIcon)(({ theme }) => ({
  color: '#fff',

  [theme.breakpoints.down('xl')]: {
    fontSize: 20,
  },
}));

export const ButtonTitle = styled(Typography)(({ theme }) => ({
  color: '#fff',
  textTransform: 'initial',

  [theme.breakpoints.down('xl')]: {
    fontSize: 12,
  },
}));

export const CustomMenuItem = styled(MenuItem)(({ theme }) => ({
  display: 'flex',
  width: '300px',
  [theme.breakpoints.down('xl')]: {
    width: '220px',

    '& .MuiSvgIcon-root': {
      fontSize: 18,
    },
    '& .MuiTypography-root': {
      fontSize: 13,
    },
  },
}));

export const TextButton = styled(Typography)(({ theme }) => ({
  marginLeft: 20,

  [theme.breakpoints.down('xl')]: {
    marginLeft: 10,
  },
}));
