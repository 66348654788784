import { Box, styled, Typography } from '@mui/material';
import { Link } from 'react-router-dom';

export const StyledContainer = styled(Box)(() => ({
  overflow: 'hidden',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  height: '100vh',
}));

export const StyledInnerContainer = styled(Box)(({ theme }) => ({
  width: '60%',
  display: 'flex',
  flexDirection: 'column',
  rowGap: theme.spacing(5),
  alignItems: 'center',
  justifyContent: 'center',
  margin: 'auto',

  [theme.breakpoints.down('xl')]: {
    width: '60%',
    height: '480px',
  },
  [theme.breakpoints.up(1400)]: {
    width: '60%',
    height: '700px',
  },
}));

export const StyledTitle = styled(Typography)(({ theme }) => ({
  fontSize: 32,

  [theme.breakpoints.down('xl')]: {
    fontSize: 25,
  },
}));

export const StyledSubtitle = styled(Typography)(({ theme }) => ({
  fontSize: 28,

  [theme.breakpoints.down('xl')]: {
    fontSize: 20,
  },
}));

export const StyledLinkText = styled(Link)(({ theme }) => ({
  fontSize: 20,
  color: theme.palette.secondary.main,
  textDecoration: 'underline',
  cursor: 'pointer',
}));

export const TypographyLink = styled(Typography)(({ theme }) => ({
  [theme.breakpoints.down('xl')]: {
    fontSize: 14,
  },
}));
