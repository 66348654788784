import { useState } from 'react';
import {
  CircularProgress,
  DialogActions,
  DialogContent,
  FormControl,
  Grid,
  Switch,
  TextField,
} from '@mui/material';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { IAuth } from '../../../Auth/interfaces/auth.interface';
import { IReducers } from '../../../Auth/store/store';
import { validateEmail } from '../../../utils/ValidatorsHelper';
import CustomDialogTitle from './DialogTitle';
import { IDialogProps } from './interfaces';
import {
  CancelButton,
  CustomDialog,
  SaveButton,
  CircularProgressBox,
  TextAdm,
} from './styles';

const CreateMainUserDialog: React.FC<IDialogProps> = ({
  handleSave,
  loading,
  show,
  setShow,
  user,
  setUser,
}: IDialogProps): JSX.Element => {
	const { t } = useTranslation();
	const { is_master, user_id }: IAuth = useSelector((state: IReducers) => state.auth);

	const [confirmPassword, setConfirmPassword] = useState<string>('');
	const [confirmPasswordError, setConfirmPasswordError] = useState<boolean>(false);

  const handleEmailChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.preventDefault();
    setUser({ ...user, email: e.target.value });
  };

  const isEditing = (): boolean => (user?.id && user.id > 0 ? true : false);

  const handlePasswordChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.preventDefault();
		setConfirmPasswordError(false);
    setUser({ ...user, password: e.target.value });
  };

  const handleConfirmPasswordChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.preventDefault();
		setConfirmPasswordError(false);
    setConfirmPassword(e.target.value);
  };

  const handleNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.preventDefault();
    setUser({ ...user, name: e.target.value });
  };

  const handleIs_MainChange = () => {
    setUser({ ...user, is_main: !user.is_main });
  };

  const handleClose = () => {
    setShow(false);
  };

	const handleSubmit = (event) => {
		event.preventDefault();

		const passwordFilled = user.password || confirmPassword;
		const passwordMatch = user.password === confirmPassword;

		if (passwordFilled && !passwordMatch) {
			setConfirmPasswordError(true);
			return
		}

		handleSave();
	}

  return (
    <CustomDialog
      onClose={handleClose}
      aria-labelledby="customized-dialog-title"
      open={show ?? false}
      sx={{ borderRadius: 0 }}
    >
			<form onSubmit={handleSubmit}>
				<CustomDialogTitle id="customized-dialog-title" onClose={handleClose}>
					<h3>{isEditing() ? t('users.user-dialog.title-edit') : t('users.user-dialog.title-create')}</h3>
				</CustomDialogTitle>
				{loading ? (
					<CircularProgressBox>
						<CircularProgress color="secondary" size={24} />
					</CircularProgressBox>
				) : (
					<DialogContent dividers>
						<Grid container spacing={2}>
							<Grid item xs={8}>
								<FormControl>
									<TextField
										name="name-input"
										label={t('users.user-dialog.fields.name')}
										type="text"
										fullWidth
										value={user?.name}
										onChange={handleNameChange}
										required
									/>
								</FormControl>
							</Grid>
							<Grid
								item
								xs={4}
								display="flex"
								alignItems="flex-end"
								alignContent="flex-end"
							>
								<FormControl fullWidth={false}>
									<TextAdm variant="body1">
										{t('users.user-dialog.fields.admin')}
									</TextAdm>
									<Switch 
										checked={user.is_main} 
										onChange={handleIs_MainChange}
										disabled={user_id === user.id}
									/>
								</FormControl>
							</Grid>
							<Grid item xs={12}>
								<FormControl>
									<TextField
										name="email-input"
										label={t('users.user-dialog.fields.email')}
										type="email"
										autoComplete="new-password"
										value={user?.email}
										required
										disabled={isEditing()}
										onChange={handleEmailChange}
										error={user?.email && !validateEmail(user?.email ?? '')}
										helperText={
											user?.email && !validateEmail(user?.email ?? '')
												? t('users.user-dialog.validations.email')
												: ''
										}
										FormHelperTextProps={{
											style: {
												color: '#EC5757',
												fontFamily: "'Montserrat', 'sans-serif'",
											},
										}}
									/>
								</FormControl>
							</Grid>
							{(is_master || !isEditing()) && (
								<>
									<Grid item xs={8}>
										<FormControl>
											<TextField
												name="password-input"
												label={t('users.user-dialog.fields.password')}
												type="password"
												autoComplete="new-password"
												required={!isEditing()}
												value={user?.password}
												inputProps={{ minLength: 8 }}
												onChange={handlePasswordChange}
												error={confirmPasswordError}
												helperText={
													confirmPasswordError
														? t('users.user-dialog.validations.confirm-password')
														: ''
												}
												FormHelperTextProps={{
													style: {
														color: '#EC5757',
														fontFamily: "'Montserrat', 'sans-serif'",
													},
												}}
											/>
										</FormControl>
									</Grid>

									<Grid item xs={8}>
										<FormControl>
											<TextField
												name="confirm-password-input"
												label={t('users.user-dialog.fields.confirm-password')}
												type="password"
												autoComplete="new-password"
												required={!isEditing()}
												inputProps={{ minLength: 8 }}
												value={confirmPassword}
												onChange={handleConfirmPasswordChange}
												error={confirmPasswordError}
												helperText={
													confirmPasswordError
														? t('users.user-dialog.validations.confirm-password')
														: ''
												}
												FormHelperTextProps={{
													style: {
														color: '#EC5757',
														fontFamily: "'Montserrat', 'sans-serif'",
													},
												}}
											/>
										</FormControl>
									</Grid>
								</>
							)}
						</Grid>
					</DialogContent>
				)}
				<DialogActions>
					<CancelButton
						variant="contained"
						color="secondary"
						autoFocus
						onClick={handleClose}
					>
						{t('users.user-dialog.buttons.cancel')}
					</CancelButton>

					<SaveButton type="submit" variant="contained" disabled={loading}>
						{t('users.user-dialog.buttons.save')}
					</SaveButton>
				</DialogActions>
			</form>
    </CustomDialog>
  );
};

export default CreateMainUserDialog;
