import { useTranslation } from 'react-i18next';
import { CalendarTodayRounded } from '@mui/icons-material';
import { DialogActions, DialogContent } from '@mui/material';
import { useFormikContext } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import { ICampaing } from '../../../Auth/interfaces/campaing.interface';
import { IReducers } from '../../../Auth/store/store';
import { ModalProps } from './interfaces';
import ModalTitle from './ModalTitle/ModalTtitle';
import { CircularProgress, Chip } from '@mui/material';
import {
  BackEditButton,
  SendCampaingButton,
  StyledBoxForProgressIndicator,
  StyledCampaingDeadlines,
  StyledDialog,
  StyledIconAndTextBox,
  StyledIconAndTextContainer,
  StyledTextContent,
  StyledTypographyTitle,
  TypographyTitle,
  ButtonText,
} from './style';
import moment from 'moment';
import { addCampaing } from '../../../Auth/actions/campaing.action';
import { useSnackbar } from 'notistack';

const ConfirmationModal: React.FC<ModalProps> = ({
  open,
  handleClose,
  loading,
  setLoading,
}: ModalProps): JSX.Element => {
  const { values, handleSubmit, setFieldValue } = useFormikContext();
  const {
    title,
    start_date,
    end_date,
    senderEMAIL,
    filename,
    contact_variable,
    campaign_type,
    schedule_date,
    daily_schedule,
    sms_quota,
    email_quota,
    use_file,
    is_one_day,
    tags,
  }: ICampaing = useSelector((state: IReducers) => state.campaing);
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation();
  const setCampaingDates = (campaing: ICampaing): void => {
    dispatch(addCampaing(campaing));
  };

  const handleFormat = async () => {
    if (values.campaign_type === 'SMS') {
      const formatedDates = daily_schedule?.map(dateString => {
        return moment(dateString).format('DD/MM/YYYY');
      });
      setCampaingDates({ schedule_date: formatedDates[0] });
      setFieldValue('daily_schedule', formatedDates);
      setFieldValue('schedule_date', formatedDates[0]);
    }
  };

  const handleFormatAndSubmit = () => {
    // if (values.campaign_type === 'WHATSAPP' || (values.campaign_type === 'SMS' && (daily_schedule?.length || values.is_one_day))) {
    setLoading(true);
    handleFormat().then(() => {
      handleSubmit();
      handleSendingMessage();
    });
    // }
  };

  const handleSendingMessage = () => {
    enqueueSnackbar(t('create-campaign.messages.sending-campaign'), {
      variant: 'info',
      autoHideDuration: 5000,
    });
  };

  return (
    <div>
      <StyledDialog onClose={handleClose} open={open}>
        {loading ? (
          <StyledBoxForProgressIndicator>
            <CircularProgress />
          </StyledBoxForProgressIndicator>
        ) : (
          <>
            <ModalTitle onClose={handleClose}>
              <StyledTypographyTitle>
                {t('create-campaign.confirmation-modal.review-campaign')}
              </StyledTypographyTitle>
            </ModalTitle>
            <DialogContent dividers>
              <StyledCampaingDeadlines>
                <StyledIconAndTextContainer>
                  <TypographyTitle variant="subtitle1" role="div" gutterBottom>
                    {t('create-campaign.confirmation-modal.campaign-name')}
                  </TypographyTitle>
                  <StyledIconAndTextBox>{title}</StyledIconAndTextBox>
                </StyledIconAndTextContainer>
                <StyledIconAndTextContainer>
                  <TypographyTitle variant="subtitle1" role="div" gutterBottom>
                    {t('create-campaign.confirmation-modal.campaign-type')}
                  </TypographyTitle>
                  <StyledIconAndTextBox>
                    <StyledTextContent variant="body2">
                      {campaign_type || 'SMS'}
                    </StyledTextContent>
                  </StyledIconAndTextBox>
                </StyledIconAndTextContainer>
              </StyledCampaingDeadlines>
              <StyledCampaingDeadlines>
                <StyledIconAndTextContainer>
                  <TypographyTitle variant="subtitle1" role="div" gutterBottom>
                    {t('create-campaign.confirmation-modal.contact-variable')}
                  </TypographyTitle>
                  <StyledIconAndTextBox>
                    {values.contact_variable}
                  </StyledIconAndTextBox>
                </StyledIconAndTextContainer>
                <StyledIconAndTextContainer>
                  <TypographyTitle variant="subtitle1" role="div" gutterBottom>
                    {t('create-campaign.confirmation-modal.selected-file')}
                  </TypographyTitle>
                  <StyledIconAndTextBox>
                    <StyledTextContent variant="body2">
                      {filename}
                    </StyledTextContent>
                  </StyledIconAndTextBox>
                </StyledIconAndTextContainer>
              </StyledCampaingDeadlines>

              {campaign_type === 'EMAIL' ? (
                <StyledCampaingDeadlines>
                  <StyledIconAndTextContainer>
                    <TypographyTitle
                      variant="subtitle1"
                      role="div"
                      gutterBottom
                    >
                      {t(
                        'create-campaign.confirmation-modal.sender-definitions'
                      )}
                      <StyledIconAndTextBox>
                        <StyledTextContent variant="body2">
                          {senderEMAIL}
                        </StyledTextContent>
                      </StyledIconAndTextBox>
                    </TypographyTitle>
                  </StyledIconAndTextContainer>
                </StyledCampaingDeadlines>
              ) : null}

              <StyledCampaingDeadlines>
                {/* <StyledIconAndTextContainer>
                  {campaign_type === 'EMAIL' ? (
                    <TypographyTitle variant="subtitle1" role="div" gutterBottom>
                      Saldo de e-mail
                      <StyledIconAndTextBox>
                        <StyledTextContent variant="body2">
                          {email_quota}
                        </StyledTextContent>
                      </StyledIconAndTextBox>
                    </TypographyTitle>
                  ) : (
                    <TypographyTitle variant="subtitle1" role="div" gutterBottom>
                      Saldo de SMS
                      <StyledIconAndTextBox>
                        <StyledTextContent variant="body2">
                          {sms_quota}
                        </StyledTextContent>
                      </StyledIconAndTextBox>
                    </TypographyTitle>
                  )}
                </StyledIconAndTextContainer> */}
                {values.campaign_type === 'SMS' &&
                  start_date &&
                  end_date &&
                  schedule_date && (
                    <StyledIconAndTextContainer>
                      <TypographyTitle
                        variant="subtitle1"
                        role="div"
                        gutterBottom
                      >
                        {t('create-campaign.confirmation-modal.trigger-days')}
                        <StyledIconAndTextBox>
                          <StyledTextContent variant="body2">
                            {schedule_date}
                          </StyledTextContent>
                        </StyledIconAndTextBox>
                      </TypographyTitle>
                    </StyledIconAndTextContainer>
                  )}
              </StyledCampaingDeadlines>
              {values.campaign_type === 'SMS' &&
                start_date &&
                end_date &&
                schedule_date && (
                  <StyledCampaingDeadlines>
                    <StyledIconAndTextContainer>
                      <TypographyTitle
                        variant="subtitle1"
                        role="div"
                        gutterBottom
                      >
                        {t('create-campaign.confirmation-modal.schedule-start')}
                      </TypographyTitle>
                      <StyledIconAndTextBox>
                        <CalendarTodayRounded color="secondary" />
                        <StyledTextContent variant="body2">
                          {new Date(start_date ?? '').toLocaleDateString(
                            'pt-BR'
                          )}
                        </StyledTextContent>
                      </StyledIconAndTextBox>
                    </StyledIconAndTextContainer>
                    <StyledIconAndTextContainer>
                      <TypographyTitle
                        variant="subtitle1"
                        role="div"
                        gutterBottom
                      >
                        {t('create-campaign.confirmation-modal.schedule-end')}
                      </TypographyTitle>
                      <StyledIconAndTextBox>
                        <CalendarTodayRounded color="secondary" />
                        <StyledTextContent variant="body2">
                          {new Date(end_date ?? '').toLocaleDateString('pt-BR')}
                        </StyledTextContent>
                      </StyledIconAndTextBox>
                    </StyledIconAndTextContainer>
                  </StyledCampaingDeadlines>
                )}
              {values.campaign_type === 'SMS' && (
                <StyledCampaingDeadlines>
                  {/* <StyledIconAndTextContainer>
                    <TypographyTitle
                      variant="subtitle1"
                      role="div"
                      gutterBottom
                    >
                      Arquivo Usado para disparo
                    </TypographyTitle>
                    <StyledIconAndTextBox>
                      <CalendarTodayRounded color="secondary" />
                      <StyledTextContent variant="body2">
                        {use_file ? 'Sim' : 'Não'}
                      </StyledTextContent>
                    </StyledIconAndTextBox>
                  </StyledIconAndTextContainer> */}
                  <StyledIconAndTextContainer>
                    <TypographyTitle
                      variant="subtitle1"
                      role="div"
                      gutterBottom
                    >
                      {t('create-campaign.confirmation-modal.tags')}
                    </TypographyTitle>
                    <StyledIconAndTextBox>
                      <StyledTextContent variant="body2">
                        {tags &&
                          tags.map((value, index) => {
                            return (
                              <Chip
                                key={`cm_chip_${index}`}
                                style={{ margin: '5px' }}
                                variant="outlined"
                                label={value}
                              />
                            );
                          })}
                      </StyledTextContent>
                    </StyledIconAndTextBox>
                  </StyledIconAndTextContainer>
                </StyledCampaingDeadlines>
              )}
            </DialogContent>
            <DialogActions>
              <BackEditButton
                variant="outlined"
                onClick={handleClose}
                color="secondary"
              >
                <ButtonText variant="body2">
                  {t(
                    'create-campaign.confirmation-modal.buttons.back-and-edit'
                  )}
                </ButtonText>
              </BackEditButton>

              <SendCampaingButton
                variant="contained"
                onClick={handleFormatAndSubmit}
              >
                <ButtonText variant="subtitle1">
                  {t(
                    'create-campaign.confirmation-modal.buttons.send-campaign'
                  )}
                </ButtonText>
              </SendCampaingButton>
            </DialogActions>
          </>
        )}
      </StyledDialog>
    </div>
  );
};

export default ConfirmationModal;
