import { DashboardAction } from '../actions/dashboard.action';
import { ADD_DASHBOARD, REMOVE_DASHBOARD } from '../constants/dashboard.action';
import { IDashboard } from '../interfaces/dashboard.interface';

const initialState: IDashboard = {
  count_campaign: 0,
  count_contact_list: 0,
  count_list: 0,
  count_template: 0,
};

const dashboardReducer = (
  state: IDashboard = initialState,
  action: DashboardAction
): IDashboard => {
  switch (action.type) {
    case ADD_DASHBOARD: {
      return {
        ...state,
        ...action.payload,
      };
    }

    case REMOVE_DASHBOARD: {
      return initialState;
    }

    default: {
      return state;
    }
  }
};

export default dashboardReducer;
