import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import FormControl from '@mui/material/FormControl';

export const CustomDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiPaper-root': {
    padding: '20px',
  },
  '& .MuiDialogActions-root': {
    padding: '20px',
  },
}));

export const CustomFormControl = styled(FormControl)(({ theme }) => ({
  margin: '15px',
}));
