import { request } from '../BASE_REQUEST';

const getPhones = async (url, params) => {
  try {
    const response = await request({
      url: url,
      method: 'GET',
      params: params,
    });
    return response;
  } catch (error) {
    Promise.reject(error);
  }
};

export default getPhones;
