import { AxiosResponse } from 'axios';
import { request } from '../BASE_REQUEST';
import { createWhatsappCampaignProps } from '../interfaces';

export const createWhatsappCampaing = async (
  fields: createWhatsappCampaignProps,
  url: string,
  file: File
): Promise<AxiosResponse<any> | undefined> => {
  if (!fields.id_ai_user_layout) {
    delete fields.id_ai_user_layout;
  }
  const response = await request({ url, data: fields, method: 'POST' });
  const UPLOAD_URL = response.data.upload_url;
  if (UPLOAD_URL) {
    return await createImage(file, UPLOAD_URL);
  } else {
    return response;
  }
};

export const createImage = async (
  file: File,
  url: string
): Promise<AxiosResponse<any>> => {
  const response = await request({
    method: 'PUT',
    url,
    data: file,
    headers: {
      'Content-Type': 'text/csv',
    },
  });

  return response;
};
