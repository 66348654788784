import { ArrowForward } from '@mui/icons-material';
import { Button, Typography } from '@mui/material';
import { styled } from '@mui/system';

export const StyledFowardButton = styled(Button)(({ theme }) => ({
  width: 250,
  height: 50,
  borderRadius: 0,
  display: 'flex',
  textTransform: 'none',

  [theme.breakpoints.down('xl')]: {
    width: 200,
    height: 40,
    borderRadius: 0,
  },
}));

export const StyledArrowFoward = styled(ArrowForward)(({ theme }) => ({
  position: 'absolute',
  right: 10,

  [theme.breakpoints.down('xl')]: { fontSize: 18 },
}));

export const TypographyButtonsFowardBack = styled(Typography)(({ theme }) => ({
  [theme.breakpoints.down('xl')]: {
    fontSize: 12,
  },
}));

export const StyledFowardButtonFourthSteep = styled(Button)(({ theme }) => ({
  width: 250,
  height: 50,
  borderRadius: 0,
  display: 'flex',
  textTransform: 'none',

  [theme.breakpoints.down('xl')]: {
    width: 200,
    height: 40,
    borderRadius: 0,
    marginTop: -25,
  },
}));
