import {
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Button,
  TextField,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
// import ReportHeader from '../ReportHeader';
import ReportTagsInput from '../../ReportTagsInput/ReportTagsInput';
import DesktopDatePicker from '@mui/lab/DesktopDatePicker';

// const weekDays = ['Dom', 'Seg', 'Ter', 'Qua', 'Qui', 'Sex', 'Sáb'];
// const months = [
//   'Jan',
//   'Fev',
//   'Mar',
//   'Abr',
//   'Mai',
//   'Jun',
//   'Jul',
//   'Aug',
//   'Set',
//   'Out',
//   'Nov',
//   'Dez'
// ];

export default function MiddleContainer({
  handleChange,
  //  handleSearch,
  status,
  costCenters,
  handleCostCenterChange,
  startDate,
  handstartDateChange,
  endDate,
  handendDateChange,
  clearFilters,
  fields,
  campaignType,
  handleCampaignTypeChange,
  hasSms,
  hasWhatsapp,
	hasEmail,
	hasRCS,
}) {
  const { t } = useTranslation();

	const campaignTypeSelectDisabled = [hasSms, hasWhatsapp, hasEmail, hasRCS].filter(Boolean).length === 1;

  return (
    <div style={{ width: '100%' }}>
      {/* <div>
              <ReportHeader
                cost={cost}
                analyzed={analyzed}
                invalid={invalid}
                triggered={triggered}
                delivered={delivered}
                not_delivered={not_delivered}
                total={total}
                blacklist={blacklist}
              />
            </div> */}
      <div
        style={{
          marginTop: '12px',
          alignItems: 'center',
          display: 'flex',
          justifyContent: 'center',
          marginBottom: '10px',
        }}
      >
        <Select
          label={t('campaigns.filter.campaign-type')}
          onChange={e => handleCampaignTypeChange(e)}
          value={campaignType}
          sx={{
            height: '30px',
            width: '120px',
            marginRight: '15px',
          }}
          disabled={campaignTypeSelectDisabled}
        >
          {hasWhatsapp && (
            <MenuItem value="WHATSAPP" selected={campaignType === 'WHATSAPP'}>
              WHATSAPP
            </MenuItem>
          )}
          {hasSms && (
            <MenuItem value="SMS" selected={campaignType === 'SMS'}>
              SMS
            </MenuItem>
          )}
          {hasRCS && (
            <MenuItem value="RCS" selected={campaignType === 'RCS'}>
              RCS
            </MenuItem>
          )}
					{hasEmail && (
						<MenuItem value="EMAIL" selected={campaignType === 'EMAIL'}>
							EMAIL
						</MenuItem>
					)}
        </Select>
        <FormControl
          style={{ width: '250px', paddingRight: '15px' }}
          size="small"
          fullWidth
        >
          <InputLabel id="demo-simple-select-label">
            {t('campaigns.filter.cost-center')}
          </InputLabel>
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            renderValue={value =>
              fields['cost_center_id']
                ? costCenters.find(obj => obj.id === value)['name']
                : null
            }
            label={t('campaigns.filter.cost-center')}
            onChange={handleCostCenterChange}
          >
            {costCenters.map(item => (
              <MenuItem key={`${item.id}_${item.name}`} value={item.id}>
                {item.name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>

        <FormControl style={{ width: '250px' }} size="small" fullWidth>
          <InputLabel id="status-label">
            {t('campaigns.filter.status')}
          </InputLabel>
          <Select
            labelId="status-label"
            id="status"
            name="status"
            value={status}
            label="Status"
            onChange={handleChange}
            inputProps={{
              style: { justifyContent: 'center' },
            }}
          >
            <MenuItem value={'ACTIVE'}>{t('campaigns.status.active')}</MenuItem>
            <MenuItem value={'PAUSED'}>{t('campaigns.status.paused')}</MenuItem>
            <MenuItem value={'FINISHED'}>
              {t('campaigns.status.finished')}
            </MenuItem>
            <MenuItem value={'RUNNING'}>
              {t('campaigns.status.running')}
            </MenuItem>
            <MenuItem value={'CANCELLED'}>
              {t('campaigns.status.cancelled')}
            </MenuItem>
            <MenuItem value={'ERROR'}>{t('campaigns.status.error')}</MenuItem>
            <MenuItem value={'SCHEDULED'}>
              {t('campaigns.status.scheduled')}
            </MenuItem>
          </Select>
        </FormControl>
        <DesktopDatePicker
          sx={{ marginLeft: '15px' }}
          label={t('campaigns.filter.start-date')}
          inputFormat="dd/MM/yyyy"
          value={startDate}
          onChange={handstartDateChange}
          renderInput={params => (
            <TextField
              size="small"
              sx={{ width: '200px', marginLeft: '15px' }}
              {...params}
            />
          )}
        />
        <DesktopDatePicker
          sx={{ marginLeft: '15px' }}
          size="small"
          label={t('campaigns.filter.end-date')}
          inputFormat="dd/MM/yyyy"
          value={endDate}
          onChange={handendDateChange}
          renderInput={params => (
            <TextField
              size="small"
              sx={{ width: '200px', marginLeft: '15px' }}
              {...params}
            />
          )}
        />
        {/* <Button variant="contained" size="small" style={{ maxHeight: '39px', marginLeft: '15px', padding: '8px'}} onClick={handleSearch}>
                Pesquisar
              </Button> */}
        <Button
          variant="contained"
          size="small"
          style={{ maxHeight: '39px', marginLeft: '15px', padding: '8px' }}
          onClick={clearFilters}
        >
          {t('campaigns.filter.clear')}
        </Button>
      </div>
    </div>
  );
}
