import { ADD_NAV, REMOVE_NAV } from '../constants/side-nav.constant';
import { ISideNav } from '../interfaces/side-nav.interface';

export type SideNavAction = { type: string; payload: ISideNav };

export const addNav = (sideNav: ISideNav): SideNavAction => ({
  type: ADD_NAV,
  payload: sideNav,
});

export const RemoveCampaign = (sideNav: ISideNav): SideNavAction => ({
  type: REMOVE_NAV,
  payload: sideNav,
});
