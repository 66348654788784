import { styled } from '@mui/material';

export const NoWrapSpan = styled('span')(() => ({
    width: '100%',
    height: 'auto',
    display: 'inline-block',
    whiteSpace: 'nowrap',
    overflow: 'hidden !important',
    textOverflow: 'ellipsis',
}));
