type ColorsType = {
  white: string;
  black: string;
  colorDefault: string;
  grayScale1: string;
  grayScale2: string;
  grayScale3: string;
  grayScale4: string;
  grayScale5: string;
  grayScale6: string;
  grayScale7: string;
  grayScale8: string;
  estoque: string;
  financeiro: string;
  contador: string;
  vendas: string;
  crm: string;
  relatorios: string;
  alert: string;
  sucess: string;
};

const colors: ColorsType = {
  white: '#ffffff',
  black: '#000000',
  colorDefault: '#414141',
  grayScale1: '#f1f1f1',
  grayScale2: '#dcdcdc',
  grayScale3: '#bcbcbc',
  grayScale4: '#888888',
  grayScale5: '#636363',
  grayScale6: '#414141',
  grayScale7: '#1a1a1a',
  grayScale8: '#F8F8F9',
  estoque: '#eeca08',
  financeiro: '#6552e1',

  contador: '#7db928',
  vendas: '#22d0b3',
  crm: '#8a0fb2',
  relatorios: '#6677b9',
  alert: '#e21a4c',
  sucess: '#7db928',
};
export default colors;
