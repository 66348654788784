import DashboardIcon from '@mui/icons-material/Dashboard';
import CampaignIcon from '@mui/icons-material/Campaign';
import SettingsIcon from '@mui/icons-material/Settings';
import LogoutIcon from '@mui/icons-material/Logout';

export const menuItens = [
  {
		id: "dashboard",
    label: 'layout.menu.dashboard',
    icon: <DashboardIcon />,
    url: '/home',
  },
  {
		id: "campaign",
    label: 'layout.menu.campaign',
    icon: <CampaignIcon />,
    url: '/campaign-list',
  },
  {
		id: "settings",
    label: 'layout.menu.settings',
    icon: <SettingsIcon />,
    url: '/settings',
  },
];

export const menuSair = {
	id: "logout",
  label: 'layout.menu.logout',
  icon: <LogoutIcon />,
  url: '/',
};
