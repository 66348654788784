import { AxiosResponse } from 'axios';
import { BASE_URL } from '../../api';
import { request } from '../BASE_REQUEST';

export const deleteTemplate = async (
  id: string,
  type: string
): Promise<AxiosResponse<any> | undefined> => {
  try {
    if (type === 'EMAIL') {
      const response = await request({
        url: `${BASE_URL}/api/email-template/${id}/`,
        method: 'DELETE',
      });
      return response;
    } else {
      const response = await request({
        url: `${BASE_URL}/api/sms-template/${id}/`,
        method: 'DELETE',
      });
      return response;
    }
  } catch (error) {
    return Promise.reject(error);
  }
};
