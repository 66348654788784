import { Box, styled } from '@mui/material';

export const GraphicBox = styled(Box)(({ theme }) => ({
  height: 415,
  width: '50%',
  position: 'absolute',
  bottom: 140,

  [theme.breakpoints.down('xl')]: {
    height: 315,
    width: '45%',
    bottom: 45,
  },
}));
