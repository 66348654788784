import { Theme } from '@mui/material';

export const EditTextField = ({ breakpoints }: Theme): any => ({
  width: '100%',
  [`& fieldset`]: {
    borderRadius: 2,
  },

  [breakpoints.down('xl')]: {
    '& .MuiOutlinedInput-input': {
      height: 15,
      fontSize: 12,
    },
    '& .MuiInputLabel-root': {
      fontSize: 13,
    },
  },
});
