import Toolbar from '@mui/material/Toolbar';
import {
  KeyboardBackspaceIcon,
  DesktopWindowsIcon,
  PhoneIphoneIcon,
} from './Icons';
import { CircularProgress, IconButton, Theme } from '@mui/material';
import { ChangeEvent, useEffect, useState } from 'react';
import { ITopBarEdit } from './interfaces';
import { useHistory } from 'react-router';
import { ISideNav } from '../../../Auth/interfaces/side-nav.interface';
import { addNav } from '../../../Auth/actions/side-nav.action';
import { useDispatch } from 'react-redux';
import DoneOutlinedIcon from '@mui/icons-material/DoneOutlined';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import { TextFieldStyled } from '../TemplateBody/styles';
import {
  BoxFirstButtons,
  AppBarStyled,
  ButtonCodeStyled,
  BoxSecondButtons,
  BoxPositionButtons,
  ButtonSave,
  BoxButtonCode,
  ButonCodeText,
  IconButtonStyled,
  SaveText,
  IconArrowStyled,
  TemplateText,
} from './styles';

const StyledTextField = ({ breakpoints }: Theme) => ({
  [breakpoints.down('xl')]: {
    marginTop: -2,
    '& .MuiOutlinedInput-root': {
      height: 30,
      fontSize: 13,
    },
    '& .MuiSvgIcon-root': {
      fontSize: 20,
    },
  },
});

const icons = [
  {
    icon: <DesktopWindowsIcon />,
    label: 'desktop',
  },
  {
    icon: <PhoneIphoneIcon />,
    label: 'mobile',
  },
];

const TopBarEdit: React.FC<ITopBarEdit> = ({
  setIsMobile,
  handleUpdateEmailTemplate,
  loading,
  subject,
  setSubject,
  name,
}: ITopBarEdit): JSX.Element => {
  const [edit, setEdit] = useState(false);
  const [isIconSelected, setIsIconSelected] = useState(0);
  const history = useHistory();
  const dispatch = useDispatch();

  const setShowSideNav = (sideNav: ISideNav): void => {
    dispatch(addNav(sideNav));
  };

  const handleClick = (index: number) => {
    setIsIconSelected(index);
  };

  const handleMobileDisplay = (label: string) => {
    if (label === 'mobile') {
      setIsMobile(true);
    } else {
      setIsMobile(false);
    }
  };

  const handleGoBack = () => {
    setShowSideNav({ showSide: true });
    history.push('/templates-list?type=EMAIL');
  };

  const handleStartEditing = () => {
    setEdit(true);
  };

  const handleConfirmEditing = () => {
    setEdit(false);
  };

  const handleChange = (
    event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setSubject(event.target.value);
  };

	useEffect(() => {
    function handleKeyDown(event: KeyboardEvent) {
      if (event.ctrlKey && event.key === 's') {
        event.preventDefault();
        handleUpdateEmailTemplate();
      }
    }

    window.addEventListener('keydown', handleKeyDown);
    return () => window.removeEventListener('keydown', handleKeyDown);
  }, [handleUpdateEmailTemplate]);

  return (
    <AppBarStyled position="sticky">
      <Toolbar>
        <BoxPositionButtons>
          <BoxFirstButtons>
            <IconArrowStyled
              size="large"
              color="inherit"
              sx={{ color: '#C2C2C2', position: 'relative', left: 0 }}
              onClick={handleGoBack}
            >
              <KeyboardBackspaceIcon />
            </IconArrowStyled>

            <BoxButtonCode>
              <ButtonCodeStyled>
                <ButonCodeText>Code</ButonCodeText>
              </ButtonCodeStyled>
            </BoxButtonCode>
            <ButtonSave onClick={handleUpdateEmailTemplate}>
              {loading ? (
                <CircularProgress
                  color="inherit"
                  sx={{ marginTop: 0.8 }}
                  size={20}
                />
              ) : (
                <SaveText variant="body2">Salvar</SaveText>
              )}
            </ButtonSave>
            {icons.map((item, index) => (
              <IconButtonStyled
                key={index}
                isButtonSelected={isIconSelected === index}
                onClick={() => {
                  handleClick(index);
                  handleMobileDisplay(item.label);
                }}
              >
                {item.icon}
              </IconButtonStyled>
            ))}
          </BoxFirstButtons>

          <BoxSecondButtons>
            <TemplateText variant="body1" color="lightGray">
              Template: {name}
            </TemplateText>

            <TextFieldStyled
              sx={StyledTextField}
              focused={edit}
              value={subject}
              onChange={e => {
                handleChange(e);
              }}
              color="secondary"
              InputProps={{
                endAdornment: edit ? (
                  <IconButton onClick={handleConfirmEditing}>
                    <DoneOutlinedIcon color="secondary" />
                  </IconButton>
                ) : (
                  <IconButton onClick={handleStartEditing}>
                    <EditOutlinedIcon sx={{ color: '#c0c0c0' }} />
                  </IconButton>
                ),
              }}
              inputRef={input => input && input.focus()}
              disabled={!edit}
            />
          </BoxSecondButtons>
        </BoxPositionButtons>
      </Toolbar>
    </AppBarStyled>
  );
};

export default TopBarEdit;
