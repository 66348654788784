import { styled, Box, MenuItem, Typography } from '@mui/material';

export const BoxTitle = styled(Box)(() => ({
  paddingLeft: 20,
}));

export const ContentContainer = styled(Box)(() => ({}));

export const TopContainer = styled(Box)(({ theme }) => ({
  width: '100%',
  marginTop: 100,
  alignItems: 'center',
  position: 'relative',

  [theme.breakpoints.down('xl')]: {
    marginTop: 70,
  },
}));

export const ContainmentListContainer = styled(Box)(() => ({
  width: '100%',
  marginTop: 50,
}));

export const CustomMenuItem = styled(MenuItem)(({ theme }) => ({
  display: 'flex',
  width: '300px',
  [theme.breakpoints.down('xl')]: {
    width: '240px',

    '& .MuiSvgIcon-root': {
      fontSize: 18,
    },
    '& .MuiTypography-root': {
      fontSize: 13,
    },
  },
}));

export const TextButton = styled(Typography)(({ theme }) => ({
  marginLeft: 20,

  [theme.breakpoints.down('xl')]: {
    marginLeft: 10,
  },
}));
