import { useTranslation } from 'react-i18next';
import Chip from '@mui/material/Chip';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import { useState } from 'react';

export default function TagsInput({ handleTagChanges, defaultTags }) {
  const [tags, setTags] = useState(defaultTags);
  const [text, setText] = useState('');

  const { t } = useTranslation();

  const handleDelete = tag => {
    const newTags = tags.filter(item => item !== tag);
    handleTagChanges(newTags);
    setTags(newTags);
  };

  const onKeyPress = e => {
    if (e.key === 'Enter' && !tags.includes(text)) {
      const newTags = [...tags, text];
      handleTagChanges(newTags);
      setTags(newTags);
      setText('');
    }
  };

  const handleTextChange = event => [setText(event.target.value)];

  return (
    <div style={{ marginLeft: '20px', marginTop: '20px', width: '400px' }}>
      <Typography align="center" variant="subtitle1">
        {t('create-campaign.tabs.campaign.fields.tags')}
      </Typography>
      <TextField
        style={{ width: '400px' }}
        value={text}
        onChange={handleTextChange}
        onKeyPress={onKeyPress}
        variant="standard"
      />
      <div
        style={{
          marginBottom: '20px',
          marginTop: '10px',
          width: '400px',
          padding: '1px',
        }}
      >
        {tags.map(value => {
          return (
            <Chip
              style={{ margin: '3px' }}
              key={`chip_${value}`}
              label={value}
              onDelete={() => handleDelete(value)}
              variant="outlined"
            />
          );
        })}
      </div>
    </div>
  );
}
