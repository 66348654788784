import React from 'react';
import { Container, Grid, Button, Typography, IconButton } from '@mui/material';
import { Autorenew } from '@mui/icons-material';
import { BASE_URL, BASE_WHATSAPP_URL, BASE_AI_URL } from '../../utils/api';
import { updateCampaignAPI } from '../../utils/api/POST/updateCampaign';

function AdminRouter() {
  const openUrlInNewTab = url => {
    window.open(url, '_blank');
  };

  const handleRefreshClick = async () => {
    try {
      await updateCampaignAPI();
      console.log('Campanha atualizada com sucesso!');
    } catch (error) {
      console.error('Erro ao atualizar campanha:', error);
    }
  };

  return (
    <Container maxWidth="md" sx={{ marginTop: '20px', textAlign: 'center' }}>
      <Typography variant="h2" component="h1">
        NEXUS ADMIN
      </Typography>

      <Grid
        container
        spacing={2}
        sx={{ justifyContent: 'center', marginTop: '20px' }}
      >
        {/* Botões NEXUS SMS, NEXUS WHATSAPP, NEXUS AI */}
        <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'center' }}>
          <Button
            variant="contained"
            size="large"
            sx={{ maxHeight: '51px', padding: '12px', fontSize: '14px' }}
            onClick={() => openUrlInNewTab(`${BASE_URL}/admin`)}
            aria-label="NEXUS SMS"
          >
            NEXUS SMS
          </Button>
          <Button
            variant="contained"
            size="large"
            sx={{
              maxHeight: '51px',
              padding: '12px',
              fontSize: '14px',
              marginLeft: '10px',
            }}
            onClick={() => openUrlInNewTab(`${BASE_WHATSAPP_URL}/admin`)}
            aria-label="NEXUS WHATSAPP"
          >
            NEXUS WHATSAPP
          </Button>
          <Button
            variant="contained"
            size="large"
            sx={{
              maxHeight: '51px',
              padding: '12px',
              fontSize: '14px',
              marginLeft: '10px',
            }}
            onClick={() => openUrlInNewTab(`${BASE_AI_URL}/admin`)}
            aria-label="NEXUS AI"
          >
            NEXUS AI
          </Button>
        </Grid>
        <Grid item xs={12} sx={{ textAlign: 'center', marginTop: '6px' }}>
          <IconButton
            color="primary"
            onClick={handleRefreshClick}
            aria-label="Atualizar campanhas SMS"
            sx={{ marginLeft: '10px' }}
          >
            <Autorenew style={{ fontSize: '42px' }} />
          </IconButton>
        </Grid>
        <Grid item xs={12} sx={{ textAlign: 'center', marginTop: '1px' }}>
          <Typography variant="subtitle2">
            Atualizar estatísticas das campanhas de SMS.
          </Typography>
        </Grid>
      </Grid>
    </Container>
  );
}

export default AdminRouter;
