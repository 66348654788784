import { request } from '../BASE_REQUEST';
import { BASE_RCS_URL, BASE_URL, BASE_WHATSAPP_URL, BASE_EMAIL_URL } from '../../api';

export const triggerCampaign = async (campaignId, campaignType) => {
  try {
    let url = `${BASE_URL}/sms/campaigns/${campaignId}/trigger/`;
    if (campaignType === 'WHATSAPP') {
      url = `${BASE_WHATSAPP_URL}/whatsapp/campaigns/${campaignId}/trigger/`;
    }
		if (campaignType === 'RCS') {
			url = `${BASE_RCS_URL}/rcs/campaigns/${campaignId}/trigger/`;
		} else if (campaignType === 'EMAIL') {
			url = `${BASE_EMAIL_URL}/email/campaigns/${campaignId}/trigger/`;
		}
    const response = await request({
      url: url,
      method: 'POST',
    });

    return response;
  } catch (error) {
    throw error;
  }
};
