import React from 'react';
import { GraphicAndCardsContainer } from './styles';

interface IGraphicAndCardsContainer {
  children: React.ReactNode;
}

export const GraphicAndCardsContainerComponent: React.FC<
  IGraphicAndCardsContainer
> = ({ children }: IGraphicAndCardsContainer): JSX.Element => (
  <GraphicAndCardsContainer container spacing={0}>
    {children}
  </GraphicAndCardsContainer>
);
