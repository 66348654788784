import { AxiosResponse } from 'axios';
import { BASE_URL } from '../../api';
import { request } from '../BASE_REQUEST';

export const getUsers = async (
  url: string | null
): Promise<AxiosResponse<any> | undefined> => {
  try {
    const response = await request({
      url: url ?? `${BASE_URL}/account/users/`,
      method: 'GET',
    });

    return response;
  } catch (error) {
    return Promise.reject(error);
  }
};

export const getMainUsers = async (
  url: string | null
): Promise<AxiosResponse<any> | undefined> => {
  try {
    const response = await request({
      url: url ?? `${BASE_URL}/account/main-users/`,
      method: 'GET',
    });

    return response;
  } catch (error) {
    return Promise.reject(error);
  }
};

export const getNextUsers = async (
  nextUrl: string
): Promise<AxiosResponse<any> | undefined> => {
  try {
    const response = await request({
      url: nextUrl,
      method: 'GET',
    });

    return response;
  } catch (error) {
    return Promise.reject(error);
  }
};

export const getPreviousUsers = async (
  previousUrl: string
): Promise<AxiosResponse<any> | undefined> => {
  try {
    const response = await request({
      url: previousUrl,
      method: 'GET',
    });

    return response;
  } catch (error) {
    return Promise.reject(error);
  }
};

export const getUsersAccount = async (): Promise<
  AxiosResponse<any> | undefined
> => {
  try {
    const response = await request({
      url: `${BASE_URL}/account/users/?has_account=false`,
      method: 'GET',
    });
    return response;
  } catch (error) {
    return Promise.reject(error);
  }
};
