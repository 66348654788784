import { Box, styled } from '@mui/material';

export const StyledLanguageButtonsContainer = styled(Box)(() => ({
  display: 'flex',
  alignItems: 'center',
  gap: '8px',

  '& > *': {
    width: '32px',
    height: '22px',
    cursor: 'pointer',
    transition: 'opacity 0.1s ease-in-out',
    '&:hover': {
      opacity: 0.8,
    },
  },
}));
