// import { useSelector } from 'react-redux';
// import { ICampaing } from '../../../../Auth/interfaces/campaing.interface';
// import { IReducers } from '../../../../Auth/store/store';
import { getTemplatesProps } from '../../../../utils/api/interfaces';
import { StyledBox } from './styles';
import { CollapseTitle } from './CollapseTitle';
import { TemplateContentProps } from './interfaces';

const CollapseContent: React.FC<getTemplatesProps> = ({
  templatesResults,
  handleChange,
  fileNameInput
}: getTemplatesProps): JSX.Element => {
  // const { campaign_type }: ICampaing = useSelector(
  //   (state: IReducers) => state.campaing
  // );

  return (
    <>
      {templatesResults.length &&
        templatesResults.map((obj: TemplateContentProps) => (
          <StyledBox key={obj.id}>
            {/* <Accordion> */}
            <CollapseTitle
              key={obj.id}
              templateInfo={obj}
              handleOnChange={handleChange}
              fileNameInput={fileNameInput}
            />
            {/* <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <Box>
                  <CollapseTitle
                    key={obj.id}
                    templateInfo={obj}
                    handleOnChange={handleChange}
                  />
                </Box>
              </AccordionSummary>
              {loading ? (
                <CircularProgressBox>
                  <CircularProgress size={24} />
                </CircularProgressBox>
              ) : (
                <AccordionDetails>
                  <Box>
                    <>
                      {campaign_type === 'SMS' ? (
                        <SmsTemplateDetails template={obj} />
                      ) : (
                        <EmailTemplateDetails template={obj} />
                      )}
                    </>
                  </Box>
                </AccordionDetails>
              )}
            </Accordion> */}
          </StyledBox>
        ))}
    </>
  );
};

export default CollapseContent;
