import React, { useEffect, useState } from 'react';
import FormatListBulletedRoundedIcon from '@mui/icons-material/FormatListBulletedRounded';
import ChromeReaderModeRoundedIcon from '@mui/icons-material/ChromeReaderModeRounded';
import CampaignRoundedIcon from '@mui/icons-material/CampaignRounded';
import {
  Card,
  CardContainer,
  ContactsCard,
  // Graphic,
  GraphicAndCardsContainer,
  SMSChart,
} from '../../components/Dashboard';
import ReactHtmlParser from 'react-html-parser';

import {
  // GraphicContainer,
  SmsChartContainer,
  // NoInfoContainer
} from './styles';
import { getGeneralStatistics } from '../../utils/api/GET';
import { ICountResponse } from './interfaces';
import { IDashboard } from '../../Auth/interfaces/dashboard.interface';
import { useDispatch, useSelector } from 'react-redux';
import { IReducers } from '../../Auth/store/store';
import { LinearProgress, Typography } from '@mui/material';
import { addDashboard } from '../../Auth/actions/dashboard.action';
import { IAuth } from '../../Auth/interfaces/auth.interface';
import Container from '../../components/Container';

export const Dashboard: React.FC = (): JSX.Element => {
  const dispatch = useDispatch();
  const {
    count_campaign,
    count_contact_list,
    count_list,
    count_template,
  }: IDashboard = useSelector((state: IReducers) => state.dashboard);

  const countInfos = {
    count_campaign,
    count_contact_list,
    count_list,
    count_template,
  };

  const [infos, setInfos] = useState<ICountResponse>(countInfos);
  const [SMSEvents, setSMSEvents] = useState({});
  // const [emailEvents, setEmailEvents] = useState<any>({});
  const [graphicLoading, setGraphicLoading] = useState(true);

  const setDashboardInfo = (dashboard: IDashboard) => {
    dispatch(addDashboard(dashboard));
  };

  const handleGetStatistics = () => {
    getGeneralStatistics().then(res => {
      if (res) {
        console.log(res);
        const {
          count_campaign,
          count_contact_list,
          count_list,
          count_template,
          sms_events,
        } = res.data;

        setInfos({
          count_campaign,
          count_contact_list,
          count_list,
          count_template,
        });

        setSMSEvents(sms_events);
        // setEmailEvents(email_events);

        setDashboardInfo({
          count_template,
          count_list,
          count_contact_list,
          count_campaign,
        });
      }
      setGraphicLoading(false);
    });
  };

  const campaingCardText =
    infos.count_campaign < 1
      ? 'Nenhuma campanha criada'
      : infos.count_campaign > 1
      ? 'Campanhas criadas'
      : 'Campanha criada';

  const templateCardText =
    infos.count_template < 1
      ? 'Nenhum template criado'
      : infos.count_template > 1
      ? 'Templates criados'
      : 'Template criado';

  const listCardText =
    infos.count_list < 1
      ? 'Nenhuma lista registrada'
      : infos.count_list > 1
      ? 'Listas registradas'
      : 'Lista registrada';

  const contactsCardText =
    infos.count_contact_list < 1
      ? 'Nenhum contato registrado'
      : infos.count_list > 1
      ? 'Contatos registrados'
      : 'Contato registrado';

  useEffect(() => {
    handleGetStatistics();
  }, []);

  const { sms_bi_url }: IAuth = useSelector((state: IReducers) => state.auth);

  return (
    <>
      {sms_bi_url ? (
        ReactHtmlParser(sms_bi_url)
      ) : (
        <Container
          title="Dashboard"
          // topRightContent={name !== '' ? "Olá, {name}!" : "Olá!"}
        >
          <CardContainer sx={{ marginTop: '20px' }}>
            <Card
              route="/campaign-list"
              icon={<CampaignRoundedIcon color={'secondary'} />}
              cardTitle="Campanhas"
              toolTipMessage="Visualizar detalhes das campanhas"
              firstSummaryNumber={`${count_campaign}`}
              firstSummaryText={campaingCardText}
            />

            <Card
              route="/templates-list"
              icon={<ChromeReaderModeRoundedIcon color={'secondary'} />}
              cardTitle="Templates"
              toolTipMessage="Visualizar detalhes dos templates"
              firstSummaryNumber={`${count_template}`}
              firstSummaryText={templateCardText}
            />

            <Card
              showArrow={false}
              route=""
              icon={<FormatListBulletedRoundedIcon color={'secondary'} />}
              cardTitle="Listas"
              toolTipMessage="Visualizar listas"
              firstSummaryNumber={`${count_list}`}
              firstSummaryText={listCardText}
            />

            <ContactsCard
              content={`${count_contact_list}`}
              title={contactsCardText}
            />
          </CardContainer>

          <GraphicAndCardsContainer>
            {/* <GraphicContainer item md={7} xl={7}>
          {Object.keys(emailEvents).length ? (
            <Graphic email_events={emailEvents} />
          ) : graphicLoading ? (
            <LinearProgress />
          ) : (
            <NoInfoContainer>
              <Typography variant="body1">
                Não há informações no momento.
              </Typography>
            </NoInfoContainer>
          )}
        </GraphicContainer> */}

            <SmsChartContainer item md={4} xl={4}>
              {Object.keys(SMSEvents).length ? (
                <SMSChart sms_events={SMSEvents} />
              ) : graphicLoading ? (
                <LinearProgress />
              ) : (
                <Typography variant="body1">
                  Não há informações no momento.
                </Typography>
              )}
            </SmsChartContainer>
          </GraphicAndCardsContainer>
        </Container>
      )}
    </>
  );
};
