import { CircularProgress } from '@mui/material';
import { useFormikContext } from 'formik';
import { useTranslation } from 'react-i18next';
import { StyledButton, StyledTypography } from './styles';

const ContinueLoginButton: React.FC = (): JSX.Element => {
  const { t } = useTranslation();
  const { isSubmitting } = useFormikContext();

  return (
    <StyledButton type="submit" variant="contained" disabled={isSubmitting}>
      {isSubmitting ? (
        <CircularProgress color="secondary" size={24} />
      ) : (
        <StyledTypography variant="body1">
          {t('login.continue-button')}
        </StyledTypography>
      )}
    </StyledButton>
  );
};

export default ContinueLoginButton;
