import { Box, styled } from '@mui/material';

export const Container = styled(Box)(({ theme: { breakpoints } }) => ({
  width: '65%',
  marginLeft: 100,
  position: 'relative',
  bottom: 20,

  [breakpoints.down('xl')]: {
    marginTop: -70,
    width: '65%',
    marginLeft: 90,
    marginBottom: 10,
    paddingBottom: 60,
  },
}));
