import { Box, Grid, styled } from '@mui/material';
import TabPanel from '../TabPanel';

export const ContainerStyled = styled(Box)(() => ({
  width: '100%',
}));

export const StyledTabPanel = styled(TabPanel)(() => ({
  padding: 0,
  width: '100%',
  '& .MuiBox-root': {
    padding: '0px',
  },
}));

export const StyledTabsContainer = styled(Box)(() => ({
  width: '100%',
}));

export const StyledTabMenu = styled(Box)(({ theme }) => ({
  borderBottom: '1px solid #ccc',
  '& .MuiTabs-flexContainer': {
    justifyContent: 'space-between',

    '& .MuiButtonBase-root': {
      position: 'relative',
      right: 14,
    },

    [theme.breakpoints.down('xl')]: {
      '& #scrollable-prevent-tab-3': {
        marginLeft: -30,
      },
    },
  },
}));

export const StyledButtonsContainer = styled(Grid)(() => ({
  height: 40,
  display: 'flex',
  justifyContent: 'space-between',
  position: 'fixed',
  zIndex: 999,
  bottom: '15px',
  padding: '0 15px',
}));
