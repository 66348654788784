import { FormikValues } from 'formik';

const CAMPAING_INITIAL_VALUES: FormikValues = {
  template: '',
  title: '',
  campaign_type: 'SMS',
  sender: 0,
  senderEMAIL: '',
  contact_variable_index: '',
  filename: '',
  headers: [],
  start_date: null,
  end_date: null,
  schedule_date: '',
  daily_schedule: [],
  weekly_schedule: [],
  monthly_schedule: '',
  custom_schedule_date: '',
  custom_schedule_finish_date: '',
  email_quota: 0,
  matrix_variables: [],
  use_file: true,
  tags: [],
  use_whitelist: false,
  use_shortener_url: false,
  use_blacklist_nexus: false,
  is_one_day: false,
  cost_center_id: null,
  id_ai_user_layout: null,
  is_schedule_enabled: false,
  rcs_content_type: 'TEXT',
  rcs_content_text: '',
  rcs_content_file_url: '',
  rcs_content_file_type: 'file_url',
  rcs_content_title: '',
  rcs_content_description: '',
  suggestions: [],
	template_ids: [],
	business_id: null,
	use_cpc: false,
};

export default CAMPAING_INITIAL_VALUES;
