import { styled, Dialog, Typography, Box, Button } from '@mui/material';

export const CustomDialog = styled(Dialog)(() => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
}));

export const AddContactText = styled(Typography)(({ theme }) => ({
  fontSize: 18,
  [theme.breakpoints.down('xl')]: {
    fontSize: 16,
  },
}));

export const ButtonsContainer = styled(Box)(() => ({
  width: '100%',
  display: 'flex',
  justifyContent: 'flex-end',
  padding: 10,
}));

export const ButtonsWrapper = styled(Box)(() => ({
  display: 'flex',
  justifyContent: 'space-between',
  flexDirection: 'row',
  columnGap: 10,
}));

export const BackButton = styled(Button)(() => ({
  width: 130,
  height: 35,
  borderRadius: 0,
}));

export const SaveButton = styled(Button)(() => ({
  width: 130,
  height: 35,
  borderRadius: 0,
}));

export const ButtonText = styled(Typography)(() => ({
  color: '#FFF',
  textTransform: 'initial',
  fontSize: 13,
}));
