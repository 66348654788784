import { Box, Divider, styled, Typography } from '@mui/material';

export const StyledForm = styled(Box)(({ theme }) => ({
  width: '100%',
  height: 200,
  display: 'flex',
  flexDirection: 'column',
  rowGap: theme.spacing(2),
  justifyContent: 'center',
  margin: '20px 0px 20px 0px',

  [theme.breakpoints.down('xl')]: {
    height: 150,
  },
}));

export const DividerBox = styled(Box)(() => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  width: '100%',
}));

export const DividerText = styled(Typography)(({ theme }) => ({
  [theme.breakpoints.up('xl')]: {
    fontSize: 20,
  },

  [theme.breakpoints.down('xl')]: {
    fontSize: 14,
  },
}));

export const DividerLine = styled(Divider)(({ theme }) => ({
  height: 1,
  backgroundColor: '#ccc',
  [theme.breakpoints.down('xl')]: {
    width: '40%',
  },
  [theme.breakpoints.up('xl')]: {
    width: '40%',
  },
  [theme.breakpoints.up(1800)]: {
    width: '40%',
  },
}));

export const CircularProgressBox = styled(Box)(({ theme }) => ({
  alignItems: 'center',
  justifyContent: 'center',
  [theme.breakpoints.down('xl')]: {
    height: 160,
  },
  [theme.breakpoints.up('xl')]: {
    height: 220,
  },
  display: 'flex',
}));
