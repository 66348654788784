import React from 'react';
import { useTranslation } from 'react-i18next';
import { ContinueLoginButton } from '../../Buttons/ContinueLoginButton';
import { EmailInput, PasswordInput } from '../../Inputs';
import {
  StyledFormContainer,
  StyledLoginButtonContainer,
  StyledLoginTitle,
} from './styles';

const LoginForm: React.FC = (): JSX.Element => {
  const { t } = useTranslation();

  return (
    <StyledFormContainer>
      <StyledLoginTitle variant="h1">{t('login.title')}</StyledLoginTitle>
      <EmailInput />
      <PasswordInput />

      {/* <StyledPasswordOptionsContainer>
        <StyledFirstColumn>
          <CheckBox />
          <StyledTypography>Lembrar-me</StyledTypography>
        </StyledFirstColumn>
        <StyledSecondColumn>
          <StyledTypographyLInk>Esqueceu a senha?</StyledTypographyLInk>
        </StyledSecondColumn>
      </StyledPasswordOptionsContainer> */}

      <StyledLoginButtonContainer>
        <ContinueLoginButton />
      </StyledLoginButtonContainer>
    </StyledFormContainer>
  );
};

export default LoginForm;
