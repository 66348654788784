import { styled, Typography } from '@mui/material';

export const CustomTypographyTitle = styled(Typography)(
  ({ theme: { palette } }) => ({
    fontSize: 13,
    color: palette.primary.main,
    fontWeight: 'bold',
  })
);

export const CustomTypographyText = styled(Typography)<{ type?: string }>(
  ({ theme: { palette }, type }) => ({
    fontSize: 11,
    color:
      type === 'value'
        ? palette.success.dark
        : type === 'type'
        ? palette.secondary.dark
        : palette.grey[800],
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    textAlign: 'center',
  })
);

export const DetailsTitleText = styled(Typography)(
  ({ theme: { palette } }) => ({
    fontSize: 15,
    color: palette.primary.main,
    fontWeight: 'bold',
  })
);
