import { FormControl, TextField, Theme } from '@mui/material';
import { useFormikContext } from 'formik';
import { useTranslation } from 'react-i18next';
import React from 'react';
import { EmailProps } from '../../../utils/interfaces';

const formHelperStyle = {
  fontSize: 13,
  fontFamily: "'Montserrat', 'sans-serif'",
};

const customTextFieldStyles = ({ breakpoints }: Theme) => ({
  width: '100%',
  [breakpoints.down('xl')]: {
    '& .MuiOutlinedInput-root ': {
      height: 45,
      fontSize: 12,
    },
  },
  [`& fieldset`]: {
    borderRadius: 4,
  },
});

const EmailInput: React.FC = (): JSX.Element => {
  const {
    values: { email },
    handleChange,
    touched,
    errors,
  } = useFormikContext<EmailProps>();

  const { t } = useTranslation();

  return (
    <FormControl variant="outlined">
      <TextField
        variant="outlined"
        label={t('login.email')}
        id="standard-outlined-email"
        type="text"
        value={email}
        onChange={handleChange('email')}
        error={touched.email && Boolean(errors.email)}
        helperText={touched.email && t(errors.email)}
        FormHelperTextProps={{
          style: formHelperStyle,
        }}
        sx={customTextFieldStyles}
      />
    </FormControl>
  );
};

export default EmailInput;
