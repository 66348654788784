import React from 'react';
import { useTranslation } from 'react-i18next';
import { BackButtonProps } from '../../../utils/interfaces';
import {
  StyledArrowBack,
  StyledBackButton,
  TypographyButtonsFowardBack,
} from './styles';

const BackButton: React.FC<BackButtonProps> = ({
  handleClick,
  isLastStep,
}: BackButtonProps): JSX.Element => {
  const { t } = useTranslation();

  return (
    <StyledBackButton
      variant="outlined"
      color="secondary"
      onClick={handleClick}
      disabled={isLastStep === 0}
    >
      <StyledArrowBack />

      <TypographyButtonsFowardBack variant="body1">
        {t('create-campaign.buttons.back')}
      </TypographyButtonsFowardBack>
    </StyledBackButton>
  );
};

export default BackButton;
