import { FormControl, TextField } from '@mui/material';
import { DescriptionInputProps } from './interface';
import { EditTextField } from './styles';

export const DescriptionInput: React.FC<DescriptionInputProps> = ({
  value,
  handleChange,
}: DescriptionInputProps): JSX.Element => (
  <FormControl>
    <TextField
      name="moviment-description-input"
      label="Descrição do movimento"
      type="text"
      value={value}
      required
      multiline
      minRows={5}
      maxRows={9}
      onChange={handleChange}
      sx={EditTextField}
    />
  </FormControl>
);
