import React from 'react';
import {
  CardInnerWrapper,
  CardItem,
  CardItemTitle,
  CardWrapper,
  ContainerWrapper,
  FirstContainer,
  SummaryNumber,
  SummaryText,
  TopBarContainer,
} from '../Card/styles';
import { Box } from '@mui/material';
import ContactMailRoundedIcon from '@mui/icons-material/ContactMailRounded';
import { ICardContacts } from '../interfaces';

export const DashBoardContactsCard: React.FC<ICardContacts> = ({
  content,
  title,
}: ICardContacts): JSX.Element => (
  <CardWrapper item md={3} xl={3}>
    <CardItem>
      <CardInnerWrapper>
        <TopBarContainer>
          <Box style={{ display: 'flex' }}>
            <CardItemTitle>Contatos</CardItemTitle>
            <ContactMailRoundedIcon color={'secondary'} />
          </Box>
        </TopBarContainer>
        <ContainerWrapper container>
          <FirstContainer item md={12} xl={12} style={{ padding: 20 }}>
            {content === '0' ? null : <SummaryNumber>{content}</SummaryNumber>}
            <SummaryText>{title}</SummaryText>
          </FirstContainer>
        </ContainerWrapper>
      </CardInnerWrapper>
    </CardItem>
  </CardWrapper>
);
