import { ADD_CAMPAING, REMOVE_CAMPAING } from '../constants/campaing.constant';
import { ICampaing } from '../interfaces/campaing.interface';

export type Campaingaction = { type: string; payload?: ICampaing };

export const addCampaing = (campaing: ICampaing): Campaingaction => ({
  type: ADD_CAMPAING,
  payload: campaing,
});

export const RemoveCampaign = (): Campaingaction => ({
  type: REMOVE_CAMPAING,
  payload: undefined,
});
