import 'codemirror/lib/codemirror.css';
import 'codemirror/theme/material.css';
import 'codemirror/mode/htmlmixed/htmlmixed';

import 'codemirror/addon/lint/lint.css';
import 'codemirror/addon/lint/lint';
import 'codemirror/addon/lint/javascript-lint';
import 'codemirror/addon/lint/html-lint';
import 'codemirror/addon/lint/css-lint';

import 'codemirror/addon/hint/show-hint.css';
import 'codemirror/addon/hint/show-hint';
import 'codemirror/addon/hint/javascript-hint';
import 'codemirror/addon/hint/html-hint';
import 'codemirror/addon/hint/css-hint';

import { Controlled as ControlledEditor } from 'react-codemirror2';

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const Editor: React.FC<any> = (props: any): JSX.Element => {
  const { language, value, onChange } = props;

  const handleChange = (editor: any, data: any, value: any) => {
    onChange(value);
  };

  return (
    <ControlledEditor
      onBeforeChange={handleChange}
      value={value}
      className="code-mirror-wrapper"
      options={{
        lineWrapping: true,
        mode: language,
        theme: 'material',
        lineNumbers: true,
				lint: true,
				extraKeys: {
					'Ctrl-Space': 'autocomplete',
				},
      }}
    />
  );
};

export default Editor;
