import { AxiosResponse } from 'axios';
import { BASE_URL } from '../../api';
import { request } from '../../api/BASE_REQUEST';

export const makeLogin = async (
  email: string,
  password: string
): Promise<AxiosResponse<any>> => {
  try {
    const response = await request({
      url: `${BASE_URL}/account/login/`,
      data: { email, password },
      method: 'POST',
    });
    return response;
  } catch (error) {
    return Promise.reject(error);
  }
};
