import { request } from '../BASE_REQUEST';

import { BASE_URL } from '../../api';
export const getCostCenter = async () => {
  try {
    const response = await request({
      url: `${BASE_URL}/sms/cost-center/`,
      method: 'GET',
    });
    return response;
  } catch (error) {
    Promise.reject(error);
  }
};
