import { styled, Box, Typography, Button, TextField } from '@mui/material';

import CloudUploadOutlinedIcon from '@mui/icons-material/CloudUploadOutlined';

export const StyledGridContainer = styled(Box)(() => ({
  width: '100%',
  display: 'grid',
  gridTemplateColumns: '2fr 1fr',
  alignItems: 'center',
}));

export const InputFileBox = styled(Box)(({ theme }) => ({
  width: '100%',
  marginTop: 20,
  cursor: 'pointer',
  display: 'inline-block',
  height: 50,
  border: '1px solid #ccc',
  borderRadius: 5,
  overflow: 'hidden',

  '& .MuiFormControl-root': {
    width: '100%',
    visibility: 'hidden',
  },

  [theme.breakpoints.down('xl')]: {
    '& .MuiTypography-root': {
      fontSize: 13,
    },
  },
}));

export const StyledIcon = styled(CloudUploadOutlinedIcon)(() => ({
  color: 'black',
  fontSize: 20,
  display: 'flex',
  marginRight: 20,
}));

export const StyledTypografhyErr = styled(Typography)(() => ({
  color: 'red',
}));

export const ButtonInputFile = styled(Box)(() => ({
  width: '100%',
  display: 'flex',
  justifyContent: 'flex-end',
  marginTop: '20px',
}));

export const StyledButtonFile = styled(Button)(({ theme }) => ({
  fontFamily: 'Montserrat',
  borderRadius: 0,
  textTransform: 'initial',
  width: 190,
  height: 45,

  [theme.breakpoints.up('xl')]: {
    marginLeft: 20,
  },

  [theme.breakpoints.down('xl')]: {
    width: 150,
    height: 35,
  },
}));

export const TextButtonUpload = styled(Typography)(({ theme }) => ({
  [theme.breakpoints.down('xl')]: {
    fontSize: 12,
  },
}));

export const StyledInputFile = styled(TextField)(() => ({
  width: '100%',
}));
