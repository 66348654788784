import { request } from '../BASE_REQUEST';
import { BASE_EMAIL_URL } from '../../api';

const getEmailSenders = async params => {
    try {
        const response = await request({
            url: `${BASE_EMAIL_URL}/email/senders/`,
            method: 'GET',
            params: params,
        });
        return response;
    } catch (error) {
        Promise.reject(error);
    }
};

export default getEmailSenders;
