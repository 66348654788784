import { CustomButtonProps } from './interface';
import { ButtonIcon, ButtonTitle, SCreateNewTransactionButton } from './styles';

export const CreateNewTransactionButton: React.FC<CustomButtonProps> = ({
  ...rest
}: CustomButtonProps): JSX.Element => {
  return (
    <SCreateNewTransactionButton variant="contained" {...rest}>
      <ButtonIcon />
      <ButtonTitle>Nova transação</ButtonTitle>
    </SCreateNewTransactionButton>
  );
};
