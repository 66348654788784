import {
  DialogActions,
  DialogContent,
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
} from '@mui/material';
import CustomDialogTitle from '../DialogTitle/CustomDialogTitle';
import { InputFile } from '../InputFile';
import {
  CustomDialog,
  AddContactText,
  RadioButtonsContainer,
  AlignDialogContent,
  CsvText,
  ButtonsContainer,
  ButtonsWrapper,
  BackButton,
  ButtonText,
  SaveButton,
} from './styles';

interface IContactUpload {
  showUpload: boolean;
  setShowUpload: React.Dispatch<React.SetStateAction<boolean>>;
}

const AddContactUpload: React.FC<IContactUpload> = ({
  showUpload,
  setShowUpload,
}: IContactUpload): JSX.Element => {
  const handleClose = () => {
    setShowUpload(false);
  };
  return (
    <CustomDialog
      fullWidth
      maxWidth={'lg'}
      onClose={handleClose}
      aria-labelledby="customized-dialog-title"
      open={showUpload}
    >
      <CustomDialogTitle id="customized-dialog-title" onClose={handleClose}>
        <AddContactText variant="h2">Upload CSV</AddContactText>
      </CustomDialogTitle>
      <RadioButtonsContainer>
        <FormControl component="fieldset">
          <RadioGroup
            aria-label="gender"
            defaultValue="female"
            name="radio-buttons-group"
          >
            <FormControlLabel
              value="female"
              control={<Radio />}
              label="Adicionar contato"
            />
            <FormControlLabel
              value="male"
              control={<Radio />}
              label="Adicionar contato e incluir em uma lista existente"
            />
            <FormControlLabel
              value="other"
              control={<Radio />}
              label="Adicionar contato em uma nova lista"
            />
          </RadioGroup>
        </FormControl>
      </RadioButtonsContainer>

      <DialogContent dividers>
        <AlignDialogContent>
          <CsvText>Insira um arquivo CSV válido.</CsvText>
          <InputFile />
        </AlignDialogContent>
      </DialogContent>
      <DialogActions>
        <ButtonsContainer>
          <ButtonsWrapper>
            <BackButton
              onClick={handleClose}
              variant="contained"
              color="secondary"
            >
              <ButtonText>Voltar</ButtonText>
            </BackButton>
            <SaveButton variant="contained" color="primary">
              <ButtonText>Salvar</ButtonText>
            </SaveButton>
          </ButtonsWrapper>
        </ButtonsContainer>
      </DialogActions>
    </CustomDialog>
  );
};

export default AddContactUpload;
