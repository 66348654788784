import React, { ChangeEvent, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { CampaingTitleInput } from '../../Inputs';
import { CampaingTypeSelect } from '../../Inputs';
import {
  CircularProgressBox,
  DividerBox,
  DividerLine,
  DividerText,
} from './styles';
import { useSnackbar } from 'notistack';
import { SenderList } from '../../SenderList';
import { getSenders } from '../../../utils/api/GET';
import { getCostCenter } from '../../../utils/api/GET';
import { Sender } from '../../../utils/interfaces';
import { AxiosResponse } from 'axios';
import { NoRemetentsDisplay } from '../../NoRemetentsDisplay';
import { useFormikContext } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import { ICampaing } from '../../../Auth/interfaces/campaing.interface';
import { addCampaing } from '../../../Auth/actions/campaing.action';
import { Box, CircularProgress, Grid } from '@mui/material';
import { IReducers } from '../../../Auth/store/store';
import { IFirstStep } from './interfaces';
import TagsInput from '../../../components/TagsInput/TagsInput';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';

const FirstStep: React.FC<IFirstStep> = ({
  open,
  setOpen,
}: IFirstStep): JSX.Element => {
  const [sendersArray, setSendersArray] = useState<Sender[]>([]);
  const [loading, setLoading] = useState(false);
  const [costCenters, setCostCenter] = useState([]);
  const [selectedCostCenter, setSelectedCostCenter] = useState(0);
  const { values, setFieldValue } = useFormikContext();
  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const setCampaingValues = (campaing: ICampaing): void => {
    dispatch(addCampaing(campaing));
  };

  const { campaign_type }: ICampaing = useSelector(
    (state: IReducers) => state.campaing
  );

  const setCampaignTitle = (campaing: ICampaing): void => {
    dispatch(addCampaing(campaing));
  };

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    setFieldValue('title', e.target.value);
    setCampaignTitle({ title: e.target.value });
  };

  const handleTagChanges = tags => {
    setFieldValue('tags', tags);
    setCampaingValues({
      tags: tags,
    });
  };

  const handleGetCenter = async () => {
    try {
      const result = await getCostCenter();
      if (result.status != 200) {
        enqueueSnackbar(t('create-campaign.messages.get-cost-center-error'), {
          variant: 'error',
          autoHideDuration: 5000,
        });
      }
      setCostCenter(result.data);
    } catch (error) {
      enqueueSnackbar(t('create-campaign.messages.get-cost-center-error'), {
        variant: 'error',
        autoHideDuration: 5000,
      });
    }
  };

  useEffect(() => {
    handleGetCenter();
    if (campaign_type === 'EMAIL') {
      setLoading(true);
      getSenders().then((res: AxiosResponse<any> | undefined): void => {
        if (res) {
          const { data } = res;
          setSendersArray(data);
          setLoading(false);
        }
      });
    }
  }, [campaign_type]);

  const handleCostCenterChange = event => {
    setSelectedCostCenter(event.target.value);
    setFieldValue('cost_center_id', event.target.value);
  };

  return (
    <>
      <Box component="div" height="130px" m={0}>
        <Grid
          container
          border="1px dashed grey"
          style={{ marginTop: '16px', paddingTop: '16px' }}
        >
          <Grid item md={6} xs={12} padding={1}>
            <CampaingTitleInput handleChange={handleChange} />
          </Grid>
          <Grid item md={6} xs={12} padding={1}>
            <CampaingTypeSelect />
          </Grid>
          <Grid item md={6} xs={12} padding={1}>
            <FormControl fullWidth sx={{ marginTop: '25px' }}>
              <InputLabel id="demo-simple-select-label">
                {t('create-campaign.tabs.campaign.fields.cost-center')}
              </InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={values['cost_center_id']}
                label={t('create-campaign.tabs.campaign.fields.cost-center')}
                onChange={handleCostCenterChange}
              >
                {costCenters.map(item => (
                  <MenuItem key={item.id} value={item.id}>
                    {item.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          {values.campaign_type === 'SMS' && (
            <Grid item md={6} xs={12} padding={1}>
              <TagsInput
                defaultTags={values.tags}
                handleTagChanges={handleTagChanges}
              />
            </Grid>
          )}
        </Grid>
      </Box>

      {/* <Grid container item xs={12}>
        {campaign_type ? (
          campaign_type === 'SMS' ? null : (
            <DividerBox>
              <DividerLine />
              <DividerText variant="h2" gutterBottom>
                Configurações de remetentes
              </DividerText>
              <DividerLine />
             </DividerBox>
          )
        ) : null}
      </Grid> */}
      {/*
      <Grid container item xs={12}>
        {campaign_type ? (
          campaign_type === 'EMAIL' ? (
            sendersArray.length ? (
              <SenderList
                open={open}
                setOpen={setOpen}
                senderObj={sendersArray}
              />
            ) : loading ? (
              <CircularProgressBox>
                <CircularProgress size={24} />
              </CircularProgressBox>
            ) : (
              <NoRemetentsDisplay />
            )
          ) : null
        ) : null}
      </Grid> */}
    </>
  );
};
export default FirstStep;
