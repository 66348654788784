import { AxiosResponse } from 'axios';
import { BASE_URL, BASE_WHATSAPP_URL, BASE_EMAIL_URL } from '../../api';
import { request } from '../BASE_REQUEST';

export const createTemplate = async (
  title: string,
  type: string,
  content: string,
	subject: string,
): Promise<AxiosResponse<any> | undefined> => {
  try {
    if (type === 'WHATSAPP') {
      const response = await request({
        url: `${BASE_WHATSAPP_URL}/whatsapp/templates/`,
        method: 'POST',
        data: {
          title,
          content,
        },
      });
      return response;
    } else if (type === 'SMS') {
      const response = await request({
        url: `${BASE_URL}/sms/templates/`,
        method: 'POST',
        data: {
          title,
          content,
        },
      });
      return response;
    } else if (type === 'EMAIL') {
			const response = await request({
				url: `${BASE_EMAIL_URL}/email/templates/`,
				method: 'POST',
				data: {
					title,
					subject,
					content,
				},
			});
			return response;
		} else {
			return Promise.reject(`Invalid type: ${type}`);
		}
  } catch (error) {
    return Promise.reject(error);
  }
};
