import React from 'react';
import NumberFormat from 'react-number-format';
import { CustomProps } from './interface';

export const NumberFormatCustom = React.forwardRef<
  NumberFormat<any>,
  CustomProps
>(function NumberFormatCustom(props: CustomProps, ref) {
  const { onChange, ...other } = props;

  return (
    <NumberFormat
      {...other}
      getInputRef={ref}
      onValueChange={values => {
        onChange({
          target: {
            name: props.name,
            value: values.value,
          },
        });
      }}
      thousandSeparator="."
      decimalSeparator=","
      isNumericString
      fixedDecimalScale
      decimalScale={2}
      prefix="R$"
    />
  );
});
