import { GridProps } from '@mui/material';
import { CardContainer } from './styles';
import React from 'react';

interface ICardContainer extends GridProps {
  children: React.ReactNode;
}

export const DashboardCardContainer: React.FC<ICardContainer> = ({
  children,
  ...rest
}: ICardContainer): JSX.Element => (
  <CardContainer container {...rest}>
    {children}
  </CardContainer>
);
