import { useTranslation } from 'react-i18next';
import { ReactComponent as AddTemplateSVG } from '../../../../assets/images/addTemplate.svg';
import { StyledContainer, StyledTypography } from './styles';

const NoTemplates: React.FC = (): JSX.Element => {
  const { t } = useTranslation();

  return (
    <StyledContainer>
      <AddTemplateSVG />
      <StyledTypography variant="body1">
        {t('create-campaign.tabs.settings.no-templates')}
      </StyledTypography>
    </StyledContainer>
  );
};

export default NoTemplates;
