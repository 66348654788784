import React from 'react';

import ArticleIcon from '@mui/icons-material/Article';
import GroupIcon from '@mui/icons-material/Group';
import { Menu } from '@mui/material';

import {
  ButtonIcon,
  ButtonTitle,
  AddListButton,
  CustomMenuItem,
  TextButton,
} from './styles';
interface IAddButton {
  setShowUpload: React.Dispatch<React.SetStateAction<boolean>>;
  setShowManually: React.Dispatch<React.SetStateAction<boolean>>;
}

const AddNewListButton: React.FC<IAddButton> = ({
  setShowUpload,
  setShowManually,
}: IAddButton): JSX.Element => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  const openMenu = Boolean(anchorEl);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <AddListButton variant="contained" onClick={handleClick}>
        <ButtonTitle>Adicionar contatos</ButtonTitle>
        <ButtonIcon />
      </AddListButton>

      <Menu
        elevation={0}
        id="actions-menu"
        anchorEl={anchorEl}
        open={openMenu}
        onClose={() => handleCloseMenu()}
      >
        <CustomMenuItem onClick={() => setShowUpload(true)}>
          <ArticleIcon />
          <TextButton>Upload CSV</TextButton>
        </CustomMenuItem>
        <CustomMenuItem onClick={() => setShowManually(true)}>
          <GroupIcon />
          <TextButton>Adicionar manualmente</TextButton>
        </CustomMenuItem>
      </Menu>
    </>
  );
};

export default AddNewListButton;
