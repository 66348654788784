import {
  Backdrop,
  Button,
  CircularProgress,
  Typography,
  TextField,
  MenuItem,
  Theme,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { AxiosResponse } from 'axios';
import { useSnackbar } from 'notistack';
import { useDispatch } from 'react-redux';
import { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import useQuery from '../../hooks/useQuery';
import { getTemplates } from '../../utils/api/GET';
import { addNav } from '../../Auth/actions/side-nav.action';
import { createTemplate } from '../../utils/api/POST';
import { deleteTemplate } from '../../utils/api/DELETE';
import { addTemplate } from '../../Auth/actions/template.action';
import { Templates } from '../../components/TemplateList/Templates';
import { ISideNav } from '../../Auth/interfaces/side-nav.interface';
import { ITemplate } from '../../Auth/interfaces/template.interface';
import { CircularProgressBox } from './styles';
import { getSpecificEmailTemplate } from '../../utils/api/GET';
import { CreateTemplateDialog } from '../../components/TemplateList/CreateTemplateDialog';
import { TemplateContentProps } from '../../components/Stepper/ThirdStep/CollapseContent/interfaces';

import {
  ContentContainer,
  NoTemplatesContainer,
  TemplateListContainer,
} from './styles';
import { Container } from '../../components/Container';

import { BASE_URL, BASE_WHATSAPP_URL, BASE_EMAIL_URL } from '../../utils/api';
import { useSelector } from 'react-redux';

const SelectStyles = ({ breakpoints }: Theme) => ({
  width: 130,
  borderRadius: 0,
  marginLeft: 2,
  marginBottom: 1.5,
  height: 20,

  [breakpoints.down('xl')]: {
    '& .MuiInputLabel-root': {
      fontSize: 13,
      marginTop: 0.1,
    },

    '& .MuiOutlinedInput-root': {
      height: 40,
    },
    '& .MuiSelect-select': {
      fontSize: 11,
    },
  },

  [`& fieldset`]: {
    borderRadius: 2,
  },
});

const TemplateList: React.FC = (): JSX.Element => {
  const history = useHistory();
	const query = useQuery();
	const { t } = useTranslation();
  const [title, setTitle] = useState('');
  const [show, setShow] = useState(false);
  const [type, setType] = useState(() => query.get('type') || "SMS");
  const [content, setContent] = useState('');
  const [subject, setSubject] = useState('');
  const [loading, setLoading] = useState(false);
  const [SMSContent, setSMSContent] = useState('');
  const [SMSTitle, setSMSTitle] = useState('');
  const [showModal, setShowModal] = useState(false);
  const [screenLoad, setScreenLoad] = useState(false);
  const [showContent, setShowContent] = useState(false);
  const [deleteLoading, setDeleteLoading] = useState(false);
  const [showEditSMSModal, setShowEditSMSModal] = useState(false);
  const [errors, setErrors] = useState({
    title: '',
    content: '',
		subject: '',
  });
  const [templates, setTemplates] = useState<TemplateContentProps[]>([]);
  const [searchValue, setSearchValue] = useState('');

  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();

  const setShowSideNav = (sideNav: ISideNav): void => {
    dispatch(addNav(sideNav));
  };
  const setTemplateInfos = (template: ITemplate): void => {
    dispatch(addTemplate(template));
  };

  const [currentPage, setCurrentPage] = useState(0);
  const [pageCount, setPageCount] = useState(0);
  const [nextPage, setNextPage] = useState(0);
  const [previousPage, setPreviousPage] = useState(0);
  const { hasWhatsapp, hasEmail } = useSelector(state => state.auth);

  const handleGetTemplates = (targetUrl ?: string): void => {
    let url = '';
    if (type === 'SMS') {
      url = `${BASE_URL}/sms/templates/`;
    } else if (type === 'WHATSAPP') {
      url = `${BASE_WHATSAPP_URL}/whatsapp/templates/`;
    } else if (type === 'EMAIL') {
      url = `${BASE_EMAIL_URL}/email/templates/`;
		}

    if (targetUrl) {
      url = targetUrl;
      if (searchValue) {
        url = `${url}&title=${searchValue}`;
      }
    } else {
      url = `${url}?title=${searchValue}`;
    }

    setLoading(true);
    getTemplates(type, url)
      .then((res: AxiosResponse<any> | undefined) => {
        if (res) {
          const data = res.data.results;
          setPageCount(res.data.count);
          setNextPage(res.data.next);
          setPreviousPage(res.data.previous);
          setTemplates(data);
          setLoading(false);
        }
      })
      .catch(() => {
        enqueueSnackbar(
          t('templates.messages.get-templates-error'),
          { variant: 'warning', autoHideDuration: 2000 }
        );
      });
  };

  const handleCreateTemplate = () => {
    let errorsObj = { title: '', content: '', subject: '' };

    errorsObj = { ...errorsObj, title: !title ? t('templates.messages.required-field') : '' };

		if (type === "SMS" || type === "WHATSAPP") {
			errorsObj = { ...errorsObj, content: !content ? t('templates.messages.required-field') : '' };
		}

		if (type === "EMAIL") {
			errorsObj = { ...errorsObj, subject: !subject ? t('templates.messages.required-field') : '' };
		}

    // if(!errorsObj.content) {
    //   const pattern = /{{(.*?)}}/g;
    //   const matches =  content.match(pattern);

    //   matches.forEach((element) => {
    //     if(element.replaceAll(' ', '') == "{{}}") {
    //       errorsObj = {...errorsObj, content: 'Variáveis não podem ser nulas.'}
    //       return
    //     }
    //   });

    // }

    if (errorsObj.title || errorsObj.content || errorsObj.subject) {
      setErrors(errorsObj);
    } else {
      setLoading(true);
      createTemplate(title, type, content, subject)
        .then(res => {
          if (res?.status === 201 || res?.status === 200) {
						enqueueSnackbar(t('templates.messages.template-create-success'), {
							variant: 'success',
							preventDuplicate: true,
							autoHideDuration: 3000,
						});

						if (type === "EMAIL") {
							setTemplateInfos({
								body: res.data.content,
								subject: res.data.subject,
								id: res.data.id,
								name: res.data.title,
							});
							history.push('/edit-template');
						} else {
							setShow(false);
							setLoading(false);
							setTitle('');
							setContent('');
							setSubject('');
							handleGetTemplates();
						}
          }
        })
        .catch((error) => {
          setLoading(false);
          enqueueSnackbar(
            t('templates.messages.template-create-error'),
            {
              variant: 'error',
              preventDuplicate: true,
              autoHideDuration: 3000,
            }
          );
					if (error.response) {
						errorsObj = { ...errorsObj, title: error.response.data.title ? error.response.data.title[0] : '' };
						errorsObj = { ...errorsObj, content: error.response.data.content ? error.response.data.content[0] : '' };
						errorsObj = { ...errorsObj, subject: error.response.data.subject ? error.response.data.subject[0] : '' };
						setErrors(errorsObj);
					}
        });
    }
  };

  const handleShowContent = (templateID: string, name: string) => {
    setScreenLoad(true);
    getSpecificEmailTemplate(templateID ?? '', type)
      .then(response => {
        if (response && response.status === 200 && type === 'SMS') {
          setScreenLoad(false);
          setTemplateInfos({
            id: templateID,
            name,
          });
          setSMSContent(response?.data.content);
          setSMSTitle(response?.data.title);
          setShowContent(true);
        } else if (response && response.status === 200 && type === 'EMAIL') {
          setScreenLoad(false);
          setTemplateInfos({
            id: templateID,
            name,
            body: response.data.content,
            subject: response.data.subject,
          });
          setShowContent(true);
        }
      })
      .catch(() => {
        setScreenLoad(false);
        enqueueSnackbar(
          t('templates.messages.get-template-error'),
          { variant: 'warning', autoHideDuration: 2000 }
        );
      });
  };

  const handleEditTemplate = (id: string, name: string) => {
    setScreenLoad(true);
    getSpecificEmailTemplate(id, type)
      .then(response => {
        // if (response && response.status === 200 && type === 'WHATSAPP') {
        //   setShowSideNav({ showSide: false });
        //   setTemplateInfos({
        //     body: response.data.content,
        //     subject: response.data.subject,
        //     id,
        //     name
        //   });
        //   history.push('/edit-template');
        //   setScreenLoad(false);
        // } else if (response && response.status === 200 && type === 'SMS') {
				if (type === "EMAIL") {
					setTemplateInfos({
						body: response.data.content,
						subject: response.data.subject,
						id: id,
						name,
					});
					history.push('/edit-template');
					setScreenLoad(false);
				} else {
					setTemplateInfos({
						content: response.data.content,
						id: id,
						name,
					});
					setSMSContent(response?.data.content);
					setSMSTitle(response?.data.title);
					setShowEditSMSModal(true);
					// }
				}
      })
      .catch(() => {
        enqueueSnackbar(
          t('templates.messages.get-template-error'),
          { variant: 'warning', autoHideDuration: 2000 }
        );
        setScreenLoad(false);
      });
  };

  const handleDeleteTemplate = (id: string) => {
    setDeleteLoading(true);
    setTemplateInfos({ id: id });
    deleteTemplate(id, type)
      .then(response => {
        if (response && response.status === 200) {
          enqueueSnackbar(t('templates.messages.delete-template-success'), {
            variant: 'success',
            autoHideDuration: 2000,
          });
          setDeleteLoading(false);
          setShowModal(false);
          handleGetTemplates();
        }
      })
      .catch(() => {
        enqueueSnackbar(
          t('templates.messages.delete-template-error'),
          {
            variant: 'error',
            autoHideDuration: 2000,
          }
        );

        setShowModal(false);
        setDeleteLoading(false);
      });
  };

  const handleShowDeleteModalAndSetID = (id: string) => {
    setTemplateInfos({ id: id });
    id && setShowModal(true);
  };

  const handlePageChange = newPage => {
    // We have the cursor, we can allow the page transition.
    setCurrentPage(newPage);
    if (newPage - currentPage > 0) {
      handleGetTemplates(nextPage);
    } else {
      handleGetTemplates(previousPage);
    }
  };

  const handleSaveTemplate = () => {
    handleGetTemplates();
  };

  const handleSearch = event => {
    if (event.key === 'Enter') {
      handleGetTemplates('');
    }
  };

  const handleSearchChange = event => {
    setSearchValue(event.target.value);
  };

  const handleTypeChange = event => {
    setType(event.target.value);
  };

  useEffect(() => {
    handleGetTemplates('');
    setTemplateInfos({ type: type });
  }, [type]);

  return (
    <Container
      title={t('templates.title')}
      topMiddleContent={
        <div style={{ display: 'flex' }}>
          <TextField
            label={t('templates.filter.template')}
            value={searchValue}
            onChange={handleSearchChange}
            size="small"
            onKeyPress={handleSearch}
            variant="outlined"
          />
          <TextField
            select
            label={t('templates.filter.type')}
            value={type}
            onChange={e => handleTypeChange(e)}
            defaultValue="SMS"
            sx={SelectStyles}
            disabled={hasEmail ? false : true}
          >
            {/* <MenuItem value="WHATSAPP">WHATSAPP</MenuItem> */}
            <MenuItem value="SMS">SMS</MenuItem>
            <MenuItem value="EMAIL">EMAIL</MenuItem>
          </TextField>
        </div>
      }
      topRightContent={
        <Button
          onClick={() => {
            setShow(true);
          }}
        >
          {t('templates.new-template')}
        </Button>
      }
    >
      <CreateTemplateDialog
        type={type}
        content={content}
        title={title}
        show={show}
        loading={loading}
        setContent={setContent}
        setTitle={setTitle}
        handleCreateTemplate={handleCreateTemplate}
        setShow={setShow}
				subject={subject}
				setSubject={setSubject}
        errors={errors}
      />

      {screenLoad && (
        <Backdrop open={screenLoad} sx={{ zIndex: 1, flex: 1 }}>
          <CircularProgressBox>
            <CircularProgress color="secondary" size={24} />
          </CircularProgressBox>
        </Backdrop>
      )}

      <ContentContainer>
        <TemplateListContainer container>
          {loading ? (
            <CircularProgressBox>
              <CircularProgress size={24} />
            </CircularProgressBox>
          ) : !templates.length ? (
            <NoTemplatesContainer>
              <Typography variant="body1">
                {t('templates.no-templates')}
              </Typography>
            </NoTemplatesContainer>
          ) : (
            <Templates
              templates={templates}
              SMSContent={SMSContent}
              SMSTitle={SMSTitle}
              deleteLoading={deleteLoading}
              showEditSMSModal={showEditSMSModal}
              showModal={showModal}
              type={type}
              content={SMSContent}
              showContent={showContent}
              setShowEditSMSModal={setShowEditSMSModal}
              setShowModal={setShowModal}
              setDeleteLoading={setDeleteLoading}
              setScreenLoad={setScreenLoad}
              setShowContent={setShowContent}
              handleShowContent={handleShowContent}
              handleShowDeleteModalAndSetID={handleShowDeleteModalAndSetID}
              handleEditTemplate={handleEditTemplate}
              handleDeleteTemplate={handleDeleteTemplate}
              pageCount={pageCount}
              currentPage={currentPage}
              handlePageChange={handlePageChange}
              handleSaveTemplate={handleSaveTemplate}
            />
          )}
        </TemplateListContainer>
      </ContentContainer>
    </Container>
  );
};

export default TemplateList;
