import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import { Typography, Tooltip } from '@mui/material';
import Box from '@mui/material/Box';
import TableContainer from '@mui/material/TableContainer';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import DeleteIcon from '@mui/icons-material/Delete';
import Fab from '@mui/material/Fab';
import ConfirmDialog from '../../components/ConfirmDialog/ConfirmDialog';

import { useParams } from 'react-router-dom';

import { useSnackbar } from 'notistack';

import { DataGrid, GridColDef } from '@mui/x-data-grid';

import getCampaignDetails from '../../utils/api/GET/getCampaignDetails';
import getJobs from '../../utils/api/GET/getJobs';
import getMO from '../../utils/api/GET/getMO';

import {
  LoadingContainer,
  NoWrapSpan,
  ContainerTitleTextStyled,
  CustomTableCell,
  TableCellMarginBottom,
} from './styles';
import { CircularProgress } from '@mui/material';
import { useHistory } from 'react-router-dom';

import { cancelCampaign } from '../../utils/api/PATCH/cancelCampaign.js';

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

const TABLE_SIZE = 500;

export default function CampaignDetails() {
  let { id } = useParams();
  const [value, setValue] = useState(0);
  const [loading, setLoading] = useState(false);
  const [jobsFetched, setJobsFetched] = useState(false);
  const [answerFetched, setAnswerFetched] = useState(false);
  const [fields, setFields] = useState({
    total: 0,
    available: 0,
    availablePercent: 0,
    invalid: 0,
    invalidPercent: 0,
    smsSample: '',
    status: '',
  });
  const [jobs, setJobs] = useState([]);
  const [answer, setAnswer] = useState([]);
  const history = useHistory();
  const [openConfirmDialog, setOpenConfirmDialog] = useState(false);

  const { t } = useTranslation('translation', {
    keyPrefix: 'sms-campaign-details',
  });

  const columnsJobs: GridColDef[] = [
    {
      field: 'date',
      headerName: 'Data de Disparo',
      editable: false,
      flex: 0.3,
      renderCell: params => (
        <Tooltip title="Data de Disparo">
          <NoWrapSpan>{params.row.date} </NoWrapSpan>
        </Tooltip>
      ),
    },
    {
      field: 'hour',
      headerName: 'Hora',
      editable: false,
      flex: 0.3,
      renderCell: params => (
        <Tooltip title="Hora">
          {params.row.type === 'scheduled' ? (
            <NoWrapSpan>
              {params.row.start_hour} - {params.row.end_hour}
            </NoWrapSpan>
          ) : (
            <NoWrapSpan>{params.row.start_hour}</NoWrapSpan>
          )}
        </Tooltip>
      ),
    },
    {
      field: 'type',
      headerName: 'tipo',
      editable: false,
      flex: 0.3,
      renderCell: params => {
        const status = params.row.type;
        if (status === 'scheduled') {
          return 'Agenda';
        } else if (status === 'api') {
          return 'Disparo';
        } else {
          return 'ERRO INESPERADO';
        }
      },
    },
    {
      field: 'total',
      headerName: 'Total de linhas do arquivo',
      editable: false,
      flex: 0.3,
      renderCell: params => (
        <Tooltip title="Total de linhas do arquivo">
          <NoWrapSpan>{params.row.total}</NoWrapSpan>
        </Tooltip>
      ),
    },
    {
      field: 'status',
      headerName: 'Status',
      editable: false,
      flex: 0.3,
      renderCell: params => {
        const status = params.row.status;
        if (status === 'PENDING') {
          return 'Aguardando Disparo';
        } else if (status === 'SCHEDULE') {
          return 'Agendado';
        } else if (status === 'RUNNING') {
          return 'Processando Disparo';
        } else if (status === 'DONE') {
          return 'Finalizado';
        } else if (status === 'ERROR') {
          return 'Erro';
        } else {
          return 'ERRO INESPERADO';
        }
      },
    },
  ];

  const columnsAnswer: GridColDef[] = [
    {
      field: 'created_at',
      headerName: t('tabs.responses.table.header.created-at'),
      editable: false,
      flex: 0.3,
      renderCell: params => (
        <Tooltip title="Data de Disparo">
          <NoWrapSpan>{params.row.created_at}</NoWrapSpan>
        </Tooltip>
      ),
    },
    {
      field: 's_from',
      headerName: t('tabs.responses.table.header.from'),
      editable: false,
      flex: 0.3,
      renderCell: params => (
        <Tooltip title="Telefone">
          <NoWrapSpan>{params.row.s_from}</NoWrapSpan>
        </Tooltip>
      ),
    },
    {
      field: 'message',
      headerName: t('tabs.responses.table.header.message'),
      editable: false,
      flex: 0.3,
      renderCell: params => (
        <Tooltip title={params.row.message}>
          <NoWrapSpan>{params.row.message}</NoWrapSpan>
        </Tooltip>
      ),
    },
  ];

  const handleChange = async (event, newValue) => {
    if (newValue == 1 && !answerFetched) {
      setAnswerFetched(true);
      setLoading(true);
      try {
        const jobs = await getMO(id);
        console.log(jobs);
        setLoading(false);
        if (jobs.status !== 200) {
          enqueueSnackbar(t('messages.get-campaign-details-error'), {
            variant: 'error',
            autoHideDuration: 5000,
          });
          return;
        }

        const data = jobs.data;
        setAnswer(data);
      } catch (err) {
        console.log(err);
        enqueueSnackbar(t('messages.get-campaign-jobs.error'), {
          variant: 'error',
          autoHideDuration: 5000,
        });
        setLoading(false);
      }
    }
    setValue(newValue);
  };
  const { enqueueSnackbar } = useSnackbar();

  const handleCancelCampaign = async () => {
    setLoading(true);
    try {
      const response = await cancelCampaign(id);
      if (response.status != 200) {
        enqueueSnackbar(t('messages.cancel-campaign-error'), {
          variant: 'error',
          autoHideDuration: 1500,
        });
        return;
      }
      setLoading(false);
      history.push('/campaign-list');
    } catch (err) {
      setLoading(false);
      enqueueSnackbar(t('messages.cancel-campaign-error'), {
        variant: 'error',
        autoHideDuration: 1500,
      });
    }
  };

  const handleCancelDialog = () => {
    setOpenConfirmDialog(false);
  };

  const handleOkDialog = () => {
    setOpenConfirmDialog(false);
    handleCancelCampaign();
  };

  const handleCloseDialog = () => {
    setOpenConfirmDialog(false);
  };

  useEffect(async () => {
    setLoading(true);
    try {
      const campaignDetails = await getCampaignDetails(id);
      console.log(campaignDetails);
      setLoading(false);
      if (campaignDetails.status !== 200) {
        enqueueSnackbar(t('messages.get-campaign-details-error'), {
          variant: 'error',
          autoHideDuration: 5000,
        });
        return;
      }

      const data = campaignDetails.data;

      const cTotal = data.total_trigger;
      const cInvalid = data.count_sms_invalid;
      const cAvailable = cTotal - cInvalid;
      const cAvailablePercentage =
        cTotal !== 0 ? ((cAvailable / cTotal) * 100).toFixed(2) : 0;
      const cInvalidPercentag =
        cTotal !== 0 ? ((cInvalid / cTotal) * 100).toFixed(2) : 0;
      const whitelistCount = cAvailable - data.count_not_whitelist;
      const blacklistCount = data.count_blacklist_nexus;
      const whitelistCountPercentage =
        cAvailable !== 0 ? ((whitelistCount / cAvailable) * 100).toFixed(2) : 0;
      const blacklistCountPercentage =
        cTotal !== 0 ? ((blacklistCount / cTotal) * 100).toFixed(2) : 0;
      console.log(data);

      setFields({
        total: data.count_row,
        available: cAvailable,
        availablePercent: `${cAvailablePercentage}%`,
        invalid: cInvalid,
        invalidPercent: `${cInvalidPercentag}%`,
        smsSample: data.sms_sample,
        useWhitelist: data.use_whitelist,
        useBlacklistNexus: data.use_blacklist_nexus,
        status: data.status,
        whitelistCount: data.count_sms_triggered,
        whitelistCountPercentage: `${whitelistCountPercentage}%`,
        blacklistCount: blacklistCount,
        blacklistCountPercentage: `${blacklistCountPercentage}%`,
      });
    } catch (err) {
      console.log(err);
      enqueueSnackbar(t('messages.get-campaign-details-error'), {
        variant: 'error',
        autoHideDuration: 5000,
      });
      setLoading(false);
    }
  }, []);

  return (
    <>
      <ConfirmDialog
        open={openConfirmDialog}
        title={t('cancel-dialog.title')}
        message={t('cancel-dialog.message')}
        handleCancel={handleCancelDialog}
        handleOk={handleOkDialog}
        handleClose={handleCloseDialog}
      />
      {fields.status && fields.status !== 'RUNNING' && (
        <Fab
          onClick={() => setOpenConfirmDialog(true)}
          sx={{
            position: 'fixed',
            bottom: '50px',
            right: '80px',
            zIndex: '99999',
            backgroundColor: 'rgba(201, 0, 0)',
            '&:hover': {
              backgroundColor: 'rgba(201, 0, 0)',
            },
          }}
          aria-label="add"
        >
          <DeleteIcon style={{ color: 'white' }} />
        </Fab>
      )}
      <Box sx={{ width: '100%' }}>
        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
          <Tabs
            variant="fullWidth"
            value={value}
            onChange={handleChange}
            aria-label="basic tabs example"
          >
            <Tab label={t('tabs.general.title')} {...a11yProps(0)} />
            <Tab label={t('tabs.responses.title')} {...a11yProps(2)} />
          </Tabs>
        </Box>
        <TabPanel value={value} index={0}>
          {loading ? (
            <LoadingContainer>
              <CircularProgress size={24} />
            </LoadingContainer>
          ) : (
            <div style={{ display: 'flex', justifyContent: 'space-around' }}>
              <TableContainer sx={{ maxWidth: TABLE_SIZE }} align="left">
                <Table
                  sx={{ width: TABLE_SIZE, border: 1 }}
                  aria-label="simple table2"
                >
                  <TableBody>
                    <TableRow>
                      <TableCell
                        sx={{ backgroundColor: 'black', color: 'white' }}
                        align="center"
                      >
                        {t('tabs.general.grids.general.title')}
                      </TableCell>
                    </TableRow>
                  </TableBody>
                </Table>

                <Table
                  sx={{ width: TABLE_SIZE, border: 1, marginTop: '-1px' }}
                  aria-label="simple table"
                >
                  <TableBody>
                    <TableRow sx={{ borderLeft: '1px solid !important' }}>
                      <CustomTableCell align="right">
                        {t('tabs.general.grids.general.line-quantity')}
                      </CustomTableCell>
                      <CustomTableCell align="center">
                        {fields.total}
                      </CustomTableCell>
                      <CustomTableCell align="left">100%</CustomTableCell>
                    </TableRow>
                    <TableRow>
                      <CustomTableCell align="right">
                        {t('tabs.general.grids.general.available-to-send')}
                      </CustomTableCell>
                      <CustomTableCell align="center">
                        {fields.available}
                      </CustomTableCell>
                      <CustomTableCell align="left">
                        {fields.availablePercent}
                      </CustomTableCell>
                    </TableRow>
                    <TableRow>
                      <CustomTableCell align="right">
                        {t('tabs.general.grids.general.invalid')}
                      </CustomTableCell>
                      <CustomTableCell align="center">
                        {fields.invalid}
                      </CustomTableCell>
                      <CustomTableCell align="left">
                        {fields.invalidPercent}
                      </CustomTableCell>
                    </TableRow>
                    {/* <TableRow>
                      <CustomTableCell align="right">
                        {t('tabs.general.grids.general.blacklist')}
                      </CustomTableCell>
                      <CustomTableCell align="center">
                        {fields.blacklistCount}
                      </CustomTableCell>
                      <CustomTableCell align="left">
                        {fields.blacklistCountPercentage}
                      </CustomTableCell>
                    </TableRow> */}
                    <TableRow>
                      <CustomTableCell align="right">
                        {t('tabs.general.grids.general.whitelist')}
                      </CustomTableCell>
                      <CustomTableCell align="center">
                        {fields.whitelistCount}
                      </CustomTableCell>
                      <CustomTableCell align="left">
                        {fields.whitelistCountPercentage}
                      </CustomTableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </TableContainer>
              <TableContainer
                sx={{ maxWidth: TABLE_SIZE, marginRight: '50px' }}
                align="right"
              >
                <Table
                  sx={{ width: TABLE_SIZE, border: 1 }}
                  aria-label="simple table2"
                >
                  <TableBody>
                    <TableRow>
                      <TableCell
                        sx={{ backgroundColor: 'black', color: 'white' }}
                        align="center"
                      >
                        {t('tabs.general.grids.settings.title')}
                      </TableCell>
                    </TableRow>
                  </TableBody>
                </Table>

                <Table
                  sx={{ width: TABLE_SIZE, border: 1, marginTop: '-1px' }}
                  aria-label="simple table"
                >
                  <TableBody>
                    <TableRow>
                      <CustomTableCell sx={{ width: '60%' }} align="right">
                        {t('tabs.general.grids.settings.whitelist-used')}
                      </CustomTableCell>
                      <CustomTableCell sx={{ width: '40%' }} align="left">
                        {fields.useWhitelist === 'True'
                          ? t('tabs.general.grids.settings.yes')
                          : t('tabs.general.grids.settings.no')}
                      </CustomTableCell>
                    </TableRow>
                    <TableRow>
                      <CustomTableCell align="right">
                        {t('tabs.general.grids.settings.blacklist-nexus-used')}
                      </CustomTableCell>
                      <CustomTableCell align="left">
                        {fields.useBlacklistNexus === 'True'
                          ? t('tabs.general.grids.settings.yes')
                          : t('tabs.general.grids.settings.no')}
                      </CustomTableCell>
                    </TableRow>
                  </TableBody>
                </Table>

                <Table
                  sx={{ width: TABLE_SIZE, border: 1, marginTop: '-1px' }}
                  aria-label="simple table"
                >
                  <TableBody></TableBody>
                </Table>
                <Table
                  sx={{ width: TABLE_SIZE, border: 1 }}
                  aria-label="simple table2"
                >
                  <TableBody sx={{ marginTop: '-1px' }}>
                    <TableRow>
                      <TableCell
                        sx={{ backgroundColor: 'black', color: 'white' }}
                        align="center"
                      >
                        {t('tabs.general.grids.sms-sample.title')}
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCellMarginBottom align="center">
                        {fields.smsSample}
                      </TableCellMarginBottom>
                    </TableRow>
                  </TableBody>
                </Table>
              </TableContainer>
            </div>
          )}
        </TabPanel>
        <TabPanel value={value} index={1}>
          <DataGrid
            rowHeight={35}
            rows={answer}
            pagination={false}
            columns={columnsAnswer}
          />
        </TabPanel>
      </Box>
    </>
  );
}
