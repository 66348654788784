import { AxiosResponse } from 'axios';
import { BASE_EMAIL_URL } from '../../api';
import { request } from '../BASE_REQUEST';

const getEmailTemplates = async (params?: any): Promise<
  AxiosResponse<any> | undefined
> => {
  try {
    const response = await request({
      url: `${BASE_EMAIL_URL}/email/templates/`,
      method: 'GET',
			params,
    });
    return response;
  } catch (error) {
    Promise.reject(error);
  }
};

export default getEmailTemplates;
