import { Button, Typography } from '@mui/material';
import { styled } from '@mui/system';

export const StyledButton = styled(Button)(({ theme: { breakpoints } }) => ({
  width: '50%',
  height: 50,
  borderRadius: 0,

  [breakpoints.down('xl')]: {
    height: 40,
    marginTop: -10,
  },
}));

export const StyledTypography = styled(Typography)(
  ({ theme: { breakpoints } }) => ({
    textTransform: 'none',
    [breakpoints.down('xl')]: {
      fontSize: 12,
    },
  })
);
