import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useSnackbar } from 'notistack';
import {
  CircularProgress,
  TextField,
  Button,
  Tooltip,
  IconButton,
  FormControl,
  Select,
  MenuItem,
  InputLabel,
} from '@mui/material';
import DownloadIcon from '@mui/icons-material/Download';
import { DataGrid } from '@mui/x-data-grid';
import getAiTabs from '../../utils/api/GET/getAiTabs';
import getAiTabUrl from '../../utils/api/GET/getAiTabUrl';
import Container from '../../components/Container';
import DesktopDatePicker from '@mui/lab/DesktopDatePicker';
import Grid from '@mui/material/Grid';

import { NoWrapSpan } from './styles';
import { format } from 'date-fns';

export default function AITabs() {
  const [loading, setLoading] = useState(false);
  const [tabs, setTabs] = useState([]);
  const [filters, setFilters] = useState({
    customerPhoneFilter: '',
    startDate: null,
    endDate: null,
    campaignFilter: '',
    tokenFilter: '',
  });

  const { t } = useTranslation();

  useEffect(() => {
    handleLoadTabs();
  }, []);

  const { enqueueSnackbar } = useSnackbar();

  async function handleLoadTabs(pageUrl, newPage, filters) {
    try {
      setLoading(true);

      const result = await getAiTabs(pageUrl, {
        customer_phone: filters?.customerPhoneFilter || null,
        start_date: filters?.startDate
          ? format(filters?.startDate, 'dd/MM/yyyy')
          : null,
        end_date: filters?.endDate
          ? format(filters?.endDate, 'dd/MM/yyyy')
          : null,
        campaign_name: filters?.campaignFilter || null,
        token: filters?.tokenFilter || null,
      });

      if (result.status === 200) {
        const { results, count, previous, next } = result.data;

        const data = results.map((item, index) => {
          const id = item.filekey;

          const nameStr =
            item.campaign_name || `attendance-${index}-${item.created_at}`;

          const resultValues = {
            1: 'ai-tabs.results.finished-successfully',
            2: 'ai-tabs.results.finished-invalid-token',
            3: 'ai-tabs.results.attendance-timeout',
            4: 'ai-tabs.results.error',
            default: 'ai-tabs.results.undefined',
          };

          const tokenValues = {
            1: 'ai-tabs.tokens.with-agreement',
            2: 'ai-tabs.tokens.without-agreement',
          };

          const resultTranslateKey =
            resultValues[item.result] || resultValues['default'];
          const tokenTranslateKey = tokenValues[item.token];

          return {
            ...item,
            id,
            nameStr,
            resultTranslateKey,
            tokenTranslateKey,
          };
        });

        setTabs({
          data: data,
          count,
          previousPage: previous,
          nextPage: next,
          currentPage: newPage || 0,
        });
      } else {
        throw new Error(`Failed to load tabs. Status: ${result.status}`);
      }
    } catch (error) {
      console.log(error);
      enqueueSnackbar(t('ai-tabs.messages.get-tabs.error'), {
        variant: 'error',
        autoHideDuration: 5000,
      });
    } finally {
      setLoading(false);
    }
  }

  const handlePageChange = newPage => {
    if (newPage - tabs.currentPage > 0) {
      handleLoadTabs(tabs.nextPage, newPage);
    } else {
      handleLoadTabs(tabs.previousPage, newPage);
    }
  };

  const handleDownloadFile = async (bucket, fileKey) => {
    try {
      const result = await getAiTabUrl(bucket, fileKey);

      if (result.status === 200) {
        const downloadLink = document.createElement('a');
        downloadLink.href = result.data.download_url;
        downloadLink.style.display = 'none';
        document.body.appendChild(downloadLink);
        downloadLink.click();
        document.body.removeChild(downloadLink);
      } else {
        throw new Error(
          `Failed to get download link. Status: ${result.status}`
        );
      }
    } catch (error) {
      enqueueSnackbar(t('ai-tabs.messages.get-download-link-error'), {
        variant: 'error',
        autoHideDuration: 5000,
      });
    }
  };

  const handleStartDateChange = date => {
    setFilters(prev => ({ ...prev, startDate: date }));
  };

  const handleEndDateChange = date => {
    setFilters(prev => ({ ...prev, endDate: date }));
  };

  function handleChange(name, value) {
    setFilters(prev => ({
      ...prev,
      [name]: value,
    }));
  }

  const clearFilters = () => {
    if (
      filters.customerPhoneFilter ||
      filters.startDate ||
      filters.endDate ||
      filters.tokenFilter ||
      filters.campaignFilter
    ) {
      setFilters({
        customerPhoneFilter: '',
        startDate: null,
        endDate: null,
        tokenFilter: '',
        campaignFilter: '',
      });
      handleLoadTabs();
    }
  };

  const handleSearch = async () => {
    if (
      !filters.customerPhoneFilter &&
      !filters.startDate &&
      !filters.endDate &&
      !filters.tokenFilter &&
      !filters.campaignFilter
    ) {
      enqueueSnackbar(t('ai-tabs.messages.choose-at-least-one-filter'), {
        variant: 'error',
        autoHideDuration: 5000,
      });
    } else {
      await handleLoadTabs(null, 0, filters);
    }
  };

  return (
    <Container title={t('ai-tabs.title')}>
      {/* Add filter UI elements */}
      <div>
        <Grid container justifyContent="center" spacing={2} mb="10px">
          <Grid item>
            <TextField
              label={t('ai-tabs.filters.phone')}
              value={filters.customerPhoneFilter}
              onChange={e =>
                handleChange('customerPhoneFilter', e.target.value)
              }
              name="phone"
              size="small"
            />
          </Grid>
          <Grid item>
            <DesktopDatePicker
              label={t('ai-tabs.filters.start-date')}
              inputFormat="dd/MM/yyyy"
              value={filters.startDate}
              onChange={handleStartDateChange}
              renderInput={params => (
                <TextField
                  size="small"
                  sx={{ width: '200px', marginLeft: '15px' }}
                  {...params}
                />
              )}
            />
          </Grid>
          <Grid item>
            <DesktopDatePicker
              size="small"
              label={t('ai-tabs.filters.end-date')}
              inputFormat="dd/MM/yyyy"
              value={filters.endDate}
              onChange={handleEndDateChange}
              renderInput={params => (
                <TextField
                  size="small"
                  sx={{ width: '200px', marginLeft: '15px' }}
                  {...params}
                />
              )}
            />
          </Grid>
          <Grid item>
            <TextField
              type="text"
              label={t('ai-tabs.filters.campaign')}
              value={filters.campaignFilter}
              onChange={e =>
                setFilters(prev => ({
                  ...prev,
                  campaignFilter: e.target.value,
                }))
              }
              name="campaign"
              size="small"
            />
          </Grid>
          <Grid item>
            <FormControl style={{ width: '250px' }} size="small" fullWidth>
              <InputLabel id="token-label">
                {t('ai-tabs.filters.token')}
              </InputLabel>
              <Select
                labelId="token-label"
                id="token"
                name="token"
                value={filters.tokenFilter || ''}
                label="Token"
                onChange={e => handleChange('tokenFilter', e.target.value)}
                inputProps={{
                  style: { justifyContent: 'center' },
                }}
              >
                <MenuItem value={'ATENDIMENTO_FINALIZADO_COM_ACORDO'}>
                  {t('ai-tabs.filters.token-finished-with-agreement')}
                </MenuItem>
                <MenuItem value={'ATENDIMENTO_FINALIZADO_SEM_ACORDO'}>
                  {t('ai-tabs.filters.token-finished-without-agreement')}
                </MenuItem>
              </Select>
            </FormControl>
          </Grid>
          <Grid item>
            <Button
              variant="contained"
              size="small"
              sx={{ maxHeight: '39px', marginLeft: '15px', padding: '8px' }}
              onClick={handleSearch}
            >
              {t('ai-tabs.buttons.search')}
            </Button>
          </Grid>
          <Grid item>
            <Button
              variant="contained"
              size="small"
              sx={{ maxHeight: '39px', marginLeft: '15px', padding: '8px' }}
              onClick={clearFilters}
            >
              {t('ai-tabs.buttons.clear-filters')}
            </Button>
          </Grid>
        </Grid>
      </div>

      {loading ? (
        <Grid
          container
          alignItems="center"
          justifyContent="center"
          style={{ minHeight: 'calc(100vh - 200px)' }} // Adjust this height to center properly
        >
          <CircularProgress size={60} /> {/* You can adjust the size here */}
        </Grid>
      ) : (
        <DataGrid
          rows={tabs.data}
          columns={[
            {
              field: 'campaign_name',
              headerName: t('ai-tabs.table.header.campaign'),
              editable: false,
              flex: 0.7,
              renderCell: params => (
                <div>
                  <Tooltip title={params.row.nameStr}>
                    <NoWrapSpan>{params.row.nameStr}</NoWrapSpan>
                  </Tooltip>
                </div>
              ),
            },
            {
              field: 'customer_phone',
              headerName: t('ai-tabs.table.header.customer-phone'),
              editable: false,
              flex: 0.7,
              renderCell: params => (
                <div>
                  <Tooltip title={params.row.customer_phone}>
                    <NoWrapSpan>{params.row.customer_phone}</NoWrapSpan>
                  </Tooltip>
                </div>
              ),
            },
            {
              field: 'date',
              headerName: t('ai-tabs.table.header.date'),
              editable: false,
              flex: 0.7,
              renderCell: params => (
                <div>
                  <Tooltip title={params.row.created_at}>
                    <NoWrapSpan>{params.row.created_at}</NoWrapSpan>
                  </Tooltip>
                </div>
              ),
            },
            {
              field: 'result',
              headerName: t('ai-tabs.table.header.result'),
              editable: false,
              flex: 0.7,
              renderCell: params => {
                let result = t(params.row.resultTranslateKey);
                if (params.row.tokenTranslateKey) {
                  result += ` (${t(params.row.tokenTranslateKey)})`;
                }

                return (
                  <div>
                    <Tooltip title={result}>
                      <NoWrapSpan>{result}</NoWrapSpan>
                    </Tooltip>
                  </div>
                );
              },
            },
            {
              field: 'actions',
              headerName: t('ai-tabs.table.header.actions'),
              sortable: false,
              editable: false,
              flex: 0.3,
              align: 'center',
              renderCell: params => {
                return (
                  <IconButton
                    onClick={() =>
                      handleDownloadFile(params.row.bucket, params.row.filekey)
                    }
                  >
                    <Tooltip title={t('ai-tabs.table.tooltip.download')}>
                      <DownloadIcon />
                    </Tooltip>
                  </IconButton>
                );
              },
            },
          ]}
          sx={{
            '& .MuiDataGrid-columnHeader': {
              width: 'auto !important',
              minWidth: '0',
            },
          }}
          rowHeight={45}
          pageSize={20}
          rowsPerPageOptions={[20]}
          rowCount={tabs.count}
          pagination
          paginationMode="server"
          page={tabs.currentPage}
          onPageChange={handlePageChange}
        />
      )}
    </Container>
  );
}
