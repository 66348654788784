import { CampaignDetailsAction } from '../actions/campaign-details.action';
import { ADD_CAMPAIGN_DETAILS } from '../constants/campaign-details.constant';
import { ICampaignDetails } from '../interfaces/campaign-details.interface';

const initialState: ICampaignDetails = {
  id: 0,
  title: '',
  start_date: '',
  end_date: '',
  created_at: '',
  contact_variable: '',
  status: '',
  filename: '',
  contact_list_count: 0,
  sender: 0,
  template_title: '',
  schedule_campaigns: [],
  sheduleChartArray: [],
  campaing_type: '',
};

const campaignDetailsReducer = (
  state: ICampaignDetails = initialState,
  action: CampaignDetailsAction
): ICampaignDetails => {
  switch (action.type) {
    case ADD_CAMPAIGN_DETAILS: {
      const {
        id,
        title,
        start_date,
        end_date,
        created_at,
        contact_variable,
        status,
        filename,
        contact_list_count,
        sender,
        template_title,
        schedule_campaigns,
        sheduleChartArray,
        campaing_type,
      } = action.payload;

      return {
        ...state,
        id,
        title,
        start_date,
        end_date,
        created_at,
        contact_variable,
        status,
        filename,
        contact_list_count,
        sender,
        template_title,
        schedule_campaigns,
        sheduleChartArray,
        campaing_type,
      };
    }

    default:
      return state;
  }
};

export default campaignDetailsReducer;
