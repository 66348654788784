import { applyMiddleware, combineReducers, createStore } from 'redux';
import thunk from 'redux-thunk';
import authReducer from '../reducers/authReducer';
import campaingReducer from '../reducers/campaingReducer';
import sideNavReducer from '../reducers/sideNavReducer';
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import templateReducer from '../reducers/templateReducer';
import dashboardReducer from '../reducers/dashboardReducer';
import campaignDetailsReducer from '../reducers/campaignDetails';
export interface IReducers {
  sideNav: any;
  auth: any;
  campaing: any;
  template: any;
  dashboard: any;
  campaignDetails: any;
}

const persistedConfig = {
  key: 'root',
  storage,
  whitelist: ['auth', 'dashboard', 'sideNav', 'campaignDetails'],
  blacklist: ['campaing'],
};

const reducers = combineReducers({
  sideNav: sideNavReducer,
  auth: authReducer,
  campaing: campaingReducer,
  template: templateReducer,
  dashboard: dashboardReducer,
  campaignDetails: campaignDetailsReducer,
});

const persistedReducer = persistReducer(persistedConfig, reducers);

const storeConfig: any = createStore(persistedReducer, applyMiddleware(thunk));

const persistedStore = persistStore(storeConfig);

export { storeConfig, persistedStore };
