import { styled, Box, Typography, Divider } from '@mui/material';
import { IMatrixVariables, IAIMatrixVariables, WMatrixVariables } from '../Stepper/VariablesStep/interfaces';

export const BoxStepper = styled(Box)<{
  value?: string | string[] | IMatrixVariables[] | IAIMatrixVariables[] | WMatrixVariables[] | undefined;
  step?: boolean;
}>(({ value, step }) => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  width: '100%',
  height: 45,
  cursor: value?.length ? 'pointer' : 'not-allowed',
  backgroundColor: step ? '#CCCCCC' : 'black',
  color: 'white',
}));

export const StepperName = styled(Typography)<{ step: boolean }>(
  ({ step, theme }) => ({
    fontSize: 13,
    fontWeight: 'bold',
    marginLeft: 8,
    color: step ? 'black' : 'white',

    [theme.breakpoints.down('xl')]: {
      fontSize: 11,
      fontWeight: 'normal',
    },
  })
);

export const BoxNumberSteep = styled(Box)<{ step: boolean }>(({ step }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  width: 26,
  height: 26,
  borderRadius: '50%',
  backgroundColor: step ? 'black' : 'white',
  color: step ? 'white' : 'black',
}));

export const NumberStyled = styled(Box)(() => ({
  fontSize: 12,
  fontWeight: 'bold',
  marginTop: 2,
}));

export const DividerStyled = styled(Divider)<{ step: boolean }>(({ step }) => ({
  width: '100%',
  height: '4px',
  backgroundColor: step ? 'black' : '',
}));
