import { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { CircularProgress, Typography } from '@mui/material';
import { useSnackbar } from 'notistack';
import { IUser } from '../../components/UserList/interfaces';
import { TopContainer } from '../../components/TopContainer';
import { UserInfosContainer } from './components/UserInfosContainer';
import DetailsSVG from '../../assets/images/undraw_detailed_analysis_re_tk6j.svg';
import { CreateNewTransactionButton } from '../../components/PlansList/NewTransactionButton';
import { getTransactions } from '../../utils/api/GET';
import { TransactionList } from './components/TransactionList';
import { CreateTransactionDialog } from './components/CreateTransactionDialog';
import { createTransaction } from '../../utils/api/POST';
import { ISideNav } from '../../Auth/interfaces/side-nav.interface';
import { addNav } from '../../Auth/actions/side-nav.action';
import {
  PlanProps,
  TransactionsObjectProps,
  TransactionsProps,
} from './interface';
import {
  ButtonContainer,
  Container,
  FirstColumn,
  ImageContainer,
  InnerContainer,
  ListContainer,
  LoadingContainer,
  NoUserInfoMessage,
  NoUserInfoMessageContainer,
  SecondColumn,
  NoTransactionText,
} from './styles';

export const Transactions: React.FC = (): JSX.Element => {
  const [user, setUser] = useState({} as IUser);
  const [plan, setPlan] = useState({} as PlanProps);
  const [showModal, setShowModal] = useState(false);
  const [newBalance, setNewBalance] = useState(0);
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const [transactionsDetails, setTransactionsDetails] = useState<
    TransactionsObjectProps[]
  >([]);
  const [loading, setLoading] = useState(true);

  const history = useHistory<TransactionsProps>();
  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useDispatch();

  const setUserFromParam = async () => {
    const { user, plan } = history.location.state;

    setUser(user);
    setPlan(plan);
  };

  const handleGetTransactions = (): void => {
    getTransactions(user.id ?? 0).then(response => {
      const data = response?.data;

      setTransactionsDetails(data);
    });
  };

  const handleOpenModal = (): void => {
    setShowModal(true);
  };

  const handleCreateTransaction = (
    transaction_type: string,
    value: number,
    resetForm: () => void
  ) => {
    setLoading(true);
    createTransaction(user.id ?? 0, transaction_type, value)
      .then(response => {
        if (response) {
          enqueueSnackbar('Transação foi registrada com sucesso!', {
            autoHideDuration: 2000,
            variant: 'success',
          });
          const verifyValue = newBalance
            ? transaction_type === 'CREDIT'
              ? newBalance + value
              : newBalance - value
            : transaction_type === 'CREDIT'
            ? Number(plan.balance) + value
            : Number(plan.balance) - value;
          setNewBalance(verifyValue);
          setLoading(false);
          setShowModal(false);
          setShowConfirmationModal(false);
          resetForm();
          handleGetTransactions();
        }
      })
      .catch(() => {
        enqueueSnackbar(
          'Houve um erro ao tentar registrar sua transação. Tente novamente.',
          {
            autoHideDuration: 3000,
            variant: 'error',
          }
        );
        setLoading(false);
      });
  };

  const setIndexOfSideNavButton = (sideNav: ISideNav) => {
    dispatch(addNav(sideNav));
  };

  useEffect(() => {
    setUserFromParam();

    if (user.id) {
      handleGetTransactions();
    }
  }, [user]);

  useEffect(() => {
    setTimeout(() => {
      setLoading(false);
    }, 2000);
  }, []);

  //useEffect utilizado para que toda vez que o usuário clique na seta de "voltar" do navegador
  //a seleção do botão vá para o local correto.
  useEffect(() => {
    setIndexOfSideNavButton({ showSide: true, buttonIndex: 2 });
  }, []);

  return (
    <Container>
      <CreateTransactionDialog
        userInfo={user}
        show={showModal}
        loading={loading}
        userPlanInfo={plan}
        newBalance={newBalance}
        showConfirmationModal={showConfirmationModal}
        setShowModal={setShowModal}
        handleCreateTransaction={handleCreateTransaction}
        setShowConfirmationModal={setShowConfirmationModal}
      />
      <TopContainer
        title={user.name ? `Transações de ${user.name}` : 'Transações'}
      />
      <ButtonContainer>
        <CreateNewTransactionButton onClick={handleOpenModal} />
      </ButtonContainer>

      <InnerContainer container>
        <FirstColumn item md={8} xl={8} lg={8}>
          {loading ? (
            <LoadingContainer>
              <CircularProgress size={24} />
            </LoadingContainer>
          ) : transactionsDetails.length ? (
            <ListContainer>
              <TransactionList transactions={transactionsDetails} />
            </ListContainer>
          ) : (
            <NoTransactionText>
              <Typography>Não há transações no momento.</Typography>
            </NoTransactionText>
          )}
        </FirstColumn>
        <SecondColumn item md={4} xl={4} lg={4}>
          {user.id && plan.id ? (
            <UserInfosContainer
              user={user}
              plan={plan}
              newBalance={newBalance}
            />
          ) : (
            <>
              <NoUserInfoMessageContainer>
                <NoUserInfoMessage variant="body1">
                  As informações do usuário aparecerão aqui.
                </NoUserInfoMessage>
              </NoUserInfoMessageContainer>

              <ImageContainer>
                <img
                  src={DetailsSVG}
                  alt="Ilustração que exemplifica as informações de usuário"
                  width={300}
                  height={300}
                />
              </ImageContainer>
            </>
          )}
        </SecondColumn>
      </InnerContainer>
    </Container>
  );
};
