export const validateEmail = (email: string): boolean => {
  const re =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  const result =
    !!email && email.length > 0 && re.test(String(email).toLowerCase());
  return result;
};

export const validateNumber = (number: number): boolean => {
  const re = /(\(?\d{2}\)?\s)?(\d{4,5}(-)?\d{4})/g;
  return re.test(String(number));
};
