import { FormHelperText, MenuItem, styled } from '@mui/material';

export const StyledHelperText = styled(FormHelperText)(() => ({
  color: '#ec5454',
  fontSize: 13,
  fontFamily: "'Montserrat', 'sans-serif'",
  marginLeft: -2,
  marginTop: 10,
}));

export const MenuItemStyled = styled(MenuItem)(({ theme }) => ({
  [theme.breakpoints.down('xl')]: { fontSize: 12 },
}));
