export default function NumberMask({ number, currency, label }) {
  if (number) {
    return (
      <>
        {' '}
        {label}{' '}
        {currency
          ? number.toLocaleString('pt-br', {
              style: 'currency',
              currency: 'BRL',
            })
          : number.toLocaleString('pt-br', { minimumFractionDigits: 0 })}
      </>
    );
  } else {
    <></>;
  }
  return (
    <>
      {' '}
      {label}{' '}
      {currency
        ? '0'.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })
        : '0'.toLocaleString('pt-br', { minimumFractionDigits: 0 })}
    </>
  );
}
