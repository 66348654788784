import { Dialog, styled, Typography, Box, Button } from '@mui/material';

export const StyledDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiPaper-root': {
    borderRadius: 15,
    width: 630,
    padding: theme.spacing(1),

    [theme.breakpoints.down('xl')]: {
      width: 430,
      padding: theme.spacing(0),
    },

    '& .MuiDialogContent-root': {
      display: 'flex',
      flexDirection: 'column',
      rowGap: 25,
      overflow: 'hidden',
    },

    '& .MuiDialogActions-root': {
      display: 'flex',
      justifyContent: 'space-evenly',
      padding: theme.spacing(2),
    },
  },
}));

export const StyledTypographyTitle = styled(Typography)(({ theme }) => ({
  fontSize: 24,
  marginBottom: theme.spacing(4),
  fontWeight: 'bold',

  [theme.breakpoints.down('xl')]: {
    fontSize: 18,
  },
}));

export const StyledTypographySubtitle = styled(Typography)(({ theme }) => ({
  color: theme.palette.primary.dark,
  fontWeight: 600,

  [theme.breakpoints.down('xl')]: {
    fontSize: 14,
  },
}));

export const StyledCampaingTitleBox = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  marginTop: theme.spacing(2),
}));

export const StyledCampaingDeadlines = styled(Box)(() => ({
  display: 'grid',
  gridTemplateColumns: '1fr 1fr',
  width: '85%',
}));

export const StyledIconAndTextContainer = styled(Box)(() => ({
  width: '100%',
  justifyContent: 'flex-start',
}));

export const StyledIconAndTextBox = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  columnGap: theme.spacing(2),
  marginTop: theme.spacing(1),

  [theme.breakpoints.down('xl')]: {
    marginTop: theme.spacing(0),
    fontSize: 13,

    '& .MuiSvgIcon-root': {
      fontSize: 20,
    },
  },
}));

export const StyledTextContent = styled(Typography)(({ theme }) => ({
  marginTop: theme.spacing(0.8),

  [theme.breakpoints.down('xl')]: {
    fontSize: 13,
  },
}));

export const BackEditButton = styled(Button)(({ theme }) => ({
  textTransform: 'none',
  borderRadius: 15,
  alignItems: 'center',
  justifyContent: 'center',
  width: 217,
  padding: 0,
  height: 33,

  [theme.breakpoints.down('xl')]: {
    width: 170,
    height: 28,
  },
}));

export const SendCampaingButton = styled(Button)(({ theme }) => ({
  textTransform: 'none',
  borderRadius: 15,
  alignItems: 'center',
  justifyContent: 'center',
  width: 217,
  height: 33,

  [theme.breakpoints.down('xl')]: {
    width: 170,
    height: 28,
  },
}));

export const StyledBoxForProgressIndicator = styled(Box)(() => ({
  width: '100%',
  height: 300,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
}));

export const TypographyTitle = styled(Typography)(({ theme }) => ({
  [theme.breakpoints.down('xl')]: {
    fontSize: 13,
  },
}));

export const ButtonText = styled(Typography)(({ theme }) => ({
  [theme.breakpoints.down('xl')]: {
    fontSize: 11,
  },
}));
