import { Grid } from '@mui/material';
import { IMatrixVariables, IAIMatrixVariables, WMatrixVariables } from '../Stepper/VariablesStep/interfaces';
import {
  BoxStepper,
  BoxNumberSteep,
  NumberStyled,
  StepperName,
  DividerStyled,
} from './styles';

interface Props {
  step: number;
  handleChange: (event: React.ChangeEvent<any>, newValue: number) => void;
  field: string | string[] | IMatrixVariables[] | IAIMatrixVariables[] | WMatrixVariables[] | undefined;
  fixedNumber: number;
  showNumber: number;
  title: string;
}

const StepHeader: React.FC<Props> = ({
  step,
  handleChange,
  field,
  fixedNumber,
  showNumber,
  title,
}: Props): JSX.Element => {
  return (
    <Grid container>
      <BoxStepper
        onClick={e => {
          if (field?.length) {
            handleChange(e, fixedNumber);
          }
        }}
        value={field}
        step={step === fixedNumber}
      >
        <BoxNumberSteep step={step === fixedNumber}>
          <NumberStyled>{showNumber}</NumberStyled>
        </BoxNumberSteep>
        <StepperName step={step === fixedNumber}>{title}</StepperName>
      </BoxStepper>
      <DividerStyled step={step === fixedNumber} />
    </Grid>
  );
};

export default StepHeader;
