import {
  Box,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { CircularProgressBox } from '../../Stepper/FirstStep/styles';
import { IShowSMSContent } from './interfaces';
import {
  ButtonText,
  CloseButton,
  Content,
  ContentContainer,
  TextMessage,
} from './styles';

const ShowSmsContent: React.FC<IShowSMSContent> = ({
  content,
  open,
  setOpen,
  setScreenLoad,
}: IShowSMSContent): JSX.Element => {
	const { t } = useTranslation();

  const handleClose = () => {
    setOpen(false);
    setScreenLoad(false);
  };

  return (
    <Box>
      <Dialog open={open} BackdropProps={{ sx: { opacity: 0.5 } }}>
        <DialogTitle>
          <TextMessage variant="body1">
            {t('templates.dialogs.show-sms-content.title')}
          </TextMessage>
        </DialogTitle>
        <DialogContent>
          {content ? (
            <ContentContainer>
              <Content>{content}</Content>
            </ContentContainer>
          ) : (
            <CircularProgressBox>
              <CircularProgress color="secondary" size={24} />
            </CircularProgressBox>
          )}
        </DialogContent>
        <DialogActions>
          <CloseButton
            variant="contained"
            color="secondary"
            onClick={handleClose}
          >
            <ButtonText variant="body2">
							{t('templates.dialogs.show-sms-content.buttons.close')}
						</ButtonText>
          </CloseButton>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default ShowSmsContent;
