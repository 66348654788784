import {
  Box,
  Card,
  CardContent,
  Grid,
  IconButton,
  styled,
  Typography,
} from '@mui/material';

export const CardWrapper = styled(Grid)(({ theme }) => ({
  width: '100%',

  [theme.breakpoints.down('xl')]: {
    marginTop: -20,
  },
}));

export const CardItem = styled(Card)(({ theme: { palette }, theme }) => ({
  width: '80%',
  height: 200,
  backgroundColor: palette.background.default,
  boxShadow: '5px 0px 12px 0px rgba(0,0,0,0.57)',
  transition: 'all 0.4s ease',

  '&:hover': {
    transform: 'scale(1.03)',
  },

  [theme.breakpoints.down('xl')]: {
    width: '70%',
    height: 150,
    borderRadius: 8,

    '& .MuiSvgIcon-root': {
      fontSize: 20,
    },
  },
}));

export const TopBarContainer = styled(Box)(() => ({
  width: '100%',
  display: 'flex',
  justifyContent: 'space-between',
}));

export const CardItemTitle = styled(Typography)(
  ({ theme: { breakpoints } }) => ({
    fontSize: 20,
    paddingRight: 10,

    [breakpoints.down('xl')]: {
      fontSize: 14,

      '& .MuiSvgIcon-root': {
        fontSize: 20,
      },
    },
  })
);

export const GoToIconButton = styled(IconButton)(
  ({ theme: { breakpoints } }) => ({
    width: 25,
    height: 25,
    padding: 5,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    transition: 'all 0.2s ease',

    '&:hover': {
      transform: 'scale(1.2)',
      backgroundColor: 'transparent',
    },

    [breakpoints.down('xl')]: {
      width: 20,
      height: 20,

      '& .MuiSvgIcon-root': {
        fontSize: 20,
      },
    },
  })
);

export const ToolTipText = styled(Typography)(({ theme: { breakpoints } }) => ({
  fontsize: 13,

  [breakpoints.down('xl')]: {
    fontsize: 9,
  },
}));

export const CardInnerWrapper = styled(CardContent)(() => ({
  display: 'flex',
  flexDirection: 'column',
  padding: 20,
}));

export const ContainerWrapper = styled(Grid)(() => ({
  width: '100%',
  marginTop: 30,
}));

export const FirstContainer = styled(Grid)(
  ({ theme: { breakpoints, palette } }) => ({
    height: 100,
    width: 130,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: 0,
    backgroundColor: palette.primary.main,

    [breakpoints.down('xl')]: {
      height: 60,
    },
  })
);

export const SummaryNumber = styled(Typography)(
  ({ theme: { breakpoints } }) => ({
    fontSize: 24,
    fontWeight: 'bold',
    color: '#FFF',

    [breakpoints.down('xl')]: {
      fontSize: 16,
    },
  })
);

export const SummaryText = styled(Typography)(({ theme: { breakpoints } }) => ({
  fontSize: 16,
  color: '#FFF',

  [breakpoints.down('xl')]: {
    fontSize: 10,
  },
}));

export const SecondContainer = styled(Grid)(
  ({ theme: { breakpoints, palette } }) => ({
    height: 100,
    width: 130,
    display: 'flex',
    flexDirection: 'column',
    padding: 10,
    borderRadius: 0,
    backgroundColor: palette.primary.main,

    [breakpoints.down('xl')]: {
      height: 90,
    },
  })
);
