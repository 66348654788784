import { Box, Button, styled, Typography } from '@mui/material';

export const ContentContainer = styled(Box)(() => ({
  width: '100%',
  height: 250,
  border: '1px solid #ccc',
  borderRadius: 0,
  margin: 'auto',
}));

export const Content = styled(Typography)(({ theme }) => ({
  fontSize: 14,
  margin: '20px 20px',

  [theme.breakpoints.down('xl')]: {
    fontSize: 12,
  },
}));

export const CloseButton = styled(Button)(({ theme }) => ({
  borderRadius: 0,
  width: 100,
  height: 32,
  color: '#FFF',

  [theme.breakpoints.down('xl')]: {
    width: 80,
    height: 28,
  },
}));

export const ButtonText = styled(Typography)(({ theme }) => ({
  textTransform: 'initial',
  color: '#FFF',

  [theme.breakpoints.down('xl')]: {
    fontSize: 12,
  },
}));

export const TextMessage = styled(Typography)(({ theme }) => ({
  [theme.breakpoints.down('xl')]: {
    fontSize: 14,
  },
}));
