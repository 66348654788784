import axios, { AxiosRequestConfig, AxiosResponse } from 'axios';
import { Store } from 'redux';
import { tokenWhiteListEnvironment } from '../constants/enviroment.constant';

export const request = async ({
  url,
  data,
  method,
  headers,
  params,
}: AxiosRequestConfig): Promise<AxiosResponse<any>> => {
  const response = await axios({
    method,
    url,
    data,
    headers,
    params: params,
  });

  return response;
};

export const axiosInterceptors = (store: Store): number =>
  axios.interceptors.request.use(
    request => {
      const state = store.getState();
      if (!request.url.match(/s3\.(.*)amazonaws.com/g)) {
        const access = state.auth.access;
        createHeaders(request, access);
      }
      return request;
    },
    error => Promise.reject(error)
  );

export const axiosResponseInterceptors = (): number =>
  axios.interceptors.response.use(
    response => response,
    error => {
      const status = error.response?.status;
      const loginErrorDetail = error.response?.data?.detail;

      if (
        status === 401 &&
        loginErrorDetail !== 'Login or Password incorrect.'
      ) {
        localStorage.clear();

        window.location.href = '/';
      } else {
        return Promise.reject(error);
      }
    }
  );

const checkUrlInWHitelist = (url?: string): boolean => {
  if (url) {
    return new RegExp(tokenWhiteListEnvironment.join('|')).test(url);
  }

  return false;
};

export const createHeaders = (
  { headers, url }: AxiosRequestConfig,
  access: string
): AxiosRequestConfig => {
  if (access && !checkUrlInWHitelist(url)) {
    headers['Authorization'] = `Bearer ${access}`;
  }

  return headers;
};
