import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import DatePicker from './DatePicker/DatePicker';
import { PlanningTable } from './DailyPlanningTable';
import { useFormikContext } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import { ICampaing } from '../../../Auth/interfaces/campaing.interface';
import { addCampaing } from '../../../Auth/actions/campaing.action';
import { IReducers } from '../../../Auth/store/store';
import { TriggerDatesContext } from './WeeklyPlanning/interfaces';
import { Box, Grid, Typography, TextField } from '@mui/material';
import { TimePicker } from '@mui/x-date-pickers/TimePicker';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import Checkbox from '@mui/material/Checkbox';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';

const FourthStep: React.FC = (): JSX.Element => {
	const { start_date, end_date }: ICampaing = useSelector(
		(state: IReducers) => state.campaing
	);
	const {
		values: { schedule_date },
		setFieldValue,
	} = useFormikContext<TriggerDatesContext>();
	const today = new Date();
	const tomorrow = new Date();
	tomorrow.setDate(tomorrow.getDate() + 1);
	// const [days, setDays] = useState<number[]>(weekly_schedule ?? []);
	// const [arrayOfDays, setArrayOfDays] = useState<string[]>([]);
	const [value, setValue] = useState<string>(null);
	const dispatch = useDispatch();
	const [startHour, setStartHour] = useState<Date | null>(null);
	const [endHour, setEndHour] = useState<Date | null>(null);
	const [isScheduleEnabled, setIsScheduleEnabled] = useState<boolean>(false);

  const { t } = useTranslation();

	const setCampaingValues = (campaing: ICampaing): void => {
		dispatch(addCampaing(campaing));
	};

	const handleStartHour = (value: Date | null) => {
		setFieldValue(
			'start_hour',
			value.toLocaleTimeString('pt-BR', { hour: '2-digit', minute: '2-digit' })
		);
		setStartHour(value);
	};

	const handleEndHour = (value: Date | null) => {
		setFieldValue(
			'end_hour',
			value.toLocaleTimeString('pt-BR', { hour: '2-digit', minute: '2-digit' })
		);
		setEndHour(value);
	};

	useEffect(() => {
		setCampaingValues({ schedule_date: schedule_date });
		const s_hour = new Date();
		const e_hour = new Date();
		setStartHour(s_hour);
		setEndHour(e_hour);
		setFieldValue(
			'start_hour',
			s_hour.toLocaleTimeString('pt-BR', { hour: '2-digit', minute: '2-digit' })
		);
		setFieldValue(
			'end_hour',
			e_hour.toLocaleTimeString('pt-BR', { hour: '2-digit', minute: '2-digit' })
		);
	}, []);

	function handleScheduleEnabledChange(event, checked: boolean) {
		setIsScheduleEnabled(checked);
		setFieldValue('is_schedule_enabled', checked);
	}

	return (
		<Grid container mt={2}>
			<Grid item md={12} xs={12} padding={1} border="1px dashed grey">
				<Box
					style={{ display: 'flex', flexDirection: 'row' }}
					component="div"
					height="200px"
					m={2}
				>
					<div id="aloha" style={{ display: 'flex', marginLeft: '17px', flexDirection: 'column' }}>
						<div style={{ width: '100%' }}>
							<strong>
                3.2) {t('create-campaign.tabs.schedule.schedule')}
              </strong>

							<FormGroup sx={{ mt: '12px', mb: '8px' }}>
								<FormControlLabel
									control={
										<Checkbox
											checked={isScheduleEnabled}
											onChange={handleScheduleEnabledChange}
										/>
									}
									label={t('create-campaign.tabs.schedule.enable-schedule')}
								/>
							</FormGroup>

							<PlanningTable
								tomorrow={tomorrow}
								today={today}
								value={value}
								setValue={setValue}
								disabled={!isScheduleEnabled}
							// setDays={setDays}
							/>
						</div>

						<div
							style={{
								display: 'flex',
								flexDirection: 'row',
								marginTop: '24px',
								gap: '12px'
							}}
						>
							<LocalizationProvider dateAdapter={AdapterDateFns}>
								<TimePicker
									label={t('create-campaign.tabs.schedule.start-hour')}
									disableOpenPicker
									ampmInClock={true}
									ampm={false}
									open={false}
									value={startHour}
									onChange={handleStartHour}
									renderInput={params => <TextField {...params} />}
									disabled={!isScheduleEnabled}
								/>
								<TimePicker
									label={t('create-campaign.tabs.schedule.end-hour')}
									disableOpenPicker
									ampmInClock={true}
									ampm={false}
									open={false}
									value={endHour}
									onChange={handleEndHour}
									renderInput={params => <TextField {...params} />}
									disabled={!isScheduleEnabled}
								/>
							</LocalizationProvider>
						</div>
					</div>
					{/* <div>
            <WeeklyPlanning
              setValues={setValues}
              arrayOfDays={arrayOfDays}
            value.toLocaleTimeString("pt-BR", { hour: '2-digit', minute: '2-digit' }));
    setStartHour(value);Days={setDays}
              initialDate={start_date ?? new Date()}
              endDate={endingvalue ?? new Date()}
            />
          </div> */}
				</Box>
				<Typography variant="subtitle2" style={{ margin: '30px', marginTop: '40px' }}>
					{isScheduleEnabled ? t('create-campaign.tabs.schedule.schedule-enabled') : t('create-campaign.tabs.schedule.schedule-disabled')}
				</Typography>
			</Grid>
		</Grid>
	);
};

export default FourthStep;
