import { Container } from './Container';
import {
  ContainerTitleStyled,
  ContainerTitleTextStyled,
  ContainerTitleRigthBoxStyled,
  ContainerTitleUsernameStyled,
} from './styles';
import { IContainer } from './types';

export {
  Container,
  ContainerTitleStyled,
  ContainerTitleTextStyled,
  ContainerTitleRigthBoxStyled,
  ContainerTitleUsernameStyled,
};

export type { IContainer };

export default Container;
