import { request } from '../BASE_REQUEST';
import { BASE_AI_URL } from '../../api';

const getAiTabs = async (pageURL, filters) => {
  try {
    const response = await request({
      url: pageURL || `${BASE_AI_URL}/ai/ai-attendance-history/`,
      method: 'GET',
      params: filters,
    });
    console.log(response)
    return response;
  } catch (error) {
    Promise.reject(error);
  }
};

export default getAiTabs;
