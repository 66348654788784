import { request } from '../BASE_REQUEST';
import { BASE_URL } from '../../api';

export const updateCostCenter = async (costCenterId, data) => {
  try {
    const response = await request({
      url: `${BASE_URL}/sms/cost-center/${costCenterId}/`,
      method: 'PUT',
      data: { ...data },
    });

    return response;
  } catch (error) {
    throw error;
  }
};
