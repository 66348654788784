import { Campaingaction } from '../actions/campaing.action';
import { ADD_CAMPAING, REMOVE_CAMPAING } from '../constants/campaing.constant';
import { ICampaing } from '../interfaces/campaing.interface';

const initialState: ICampaing = {
	template: '',
	title: '',
	campaign_type: '',
	senderID: 0,
	senderEMAIL: '',
	contact_variable: '',
	filename: '',
	headers: [''],
	start_date: null,
	end_date: null,
	schedule_date: '',
	daily_schedule: [],
	weekly_schedule: [],
	monthly_schedule: '',
	custom_schedule_date: '',
	custom_schedule_finish_date: '',
	email_quota: 1,
	sms_quota: 1,
	templateName: '',
	templateVariables: [],
	wTemplateVariables: ["{{w___nome}}", "{{w___cpf}}", "{{w___contato}}"],
	matrix_variables_array: [],
	useFile: false,
	tags: [],
};

const campaingReducer = (
	state: ICampaing = initialState,
	action: Campaingaction
): ICampaing => {
	switch (action.type) {
		case ADD_CAMPAING: {
			return {
				...state,
				...action.payload,
			};
		}

		case REMOVE_CAMPAING: {
			return {
				campaign_type: undefined,
				contact_variable: undefined,
				daily_schedule: undefined,
				email_quota: 1,
				sms_quota: 1,
				templateName: undefined,
				templateVariables: undefined,
				matrix_variables_array: undefined,
				end_date: null,
				fileStore: undefined,
				filename: undefined,
				headers: undefined,
				senderEMAIL: undefined,
				senderID: undefined,
				start_date: null,
				template: undefined,
				title: undefined,
				schedule_date: undefined,
				weekly_schedule: undefined,
				useFile: undefined,
				tags: undefined,
			};
		}

		default: {
			return state;
		}
	}
};

export default campaingReducer;
