import { Box, Button, styled, Typography } from '@mui/material';

export const TextFieldContainer = styled(Box)(({ theme }) => ({
  width: 536,
  height: 250,
  margin: 'auto',

  [theme.breakpoints.down('xl')]: {
    fontSize: 14,
    width: 400,
    height: 220,
  },
}));

export const SaveButton = styled(Button)(({ theme }) => ({
  borderRadius: 0,
  textTransform: 'initial',
  width: 100,
  height: 32,
  color: '#FFF',

  [theme.breakpoints.down('xl')]: {
    width: 80,
    height: 28,
  },
}));

export const ButtonText = styled(Typography)(({ theme }) => ({
  textTransform: 'initial',

  [theme.breakpoints.down('xl')]: {
    fontSize: 12,
  },
}));

export const TextChangeSms = styled(Typography)(({ theme }) => ({
  fontSize: 16,

  [theme.breakpoints.down('xl')]: {
    fontSize: 14,
  },
}));