import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { getCostCenter } from '../../utils/api/GET';
import { updateCostCenter } from '../../utils/api/PUT/updateCostCenter';
import { createCostCenter } from '../../utils/api/POST/createCostCenter';

import { useSnackbar } from 'notistack';

import CreateCostCenterDialog from '../../components/CostCenter/CreateCostCenterDialog';

import Container from '../../components/Container';

import IconEdit from '@mui/icons-material/Edit';

import { DataGrid, GridColDef } from '@mui/x-data-grid';

import { LoadingContainer } from './styles';

import { Button, CircularProgress, Grid, IconButton } from '@mui/material';

export default function CostCenter() {
	const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const [showDialog, setShowDialog] = useState(false);
  const [costCenters, setCostCenters] = useState([]);
  const { enqueueSnackbar } = useSnackbar();
  const [errors, setErrors] = useState({
    user: '',
    protocol: '',
    partner: '',
    tax: '',
  });
  const [editMode, setEditMode] = useState(false);
  const [costCenter, setCostCenter] = useState('');
  const [costCenterId, setCostCenterId] = useState('');

  const openDialog = () => {
    setShowDialog(true);
  };

  const handleSave = async () => {
    let errorsObj = {};

    errorsObj = {
      ...errorsObj,
      costCenter: !costCenter ? t('cost-centers.validations.required-field') : '',
    };
    if (!errorsObj.costCenter) {
      try {
        setShowDialog(false);
        setLoading(true);
        let data = {
          name: costCenter,
        };

        let result = '';

        if (!editMode) {
          result = await createCostCenter(data);
        } else {
          result = await updateCostCenter(costCenterId, data);
        }

        if (result.status != 200 && result.status != 201) {
					const message = editMode
						?	t('cost-centers.messages.update-cost-center-error')
						:	t('cost-centers.messages.create-cost-center-error');

          enqueueSnackbar(message, {
            variant: 'error',
            autoHideDuration: 5000,
          });
        } else {
          handleGetCostCenters();

					const message = editMode
						? t('cost-centers.messages.update-cost-center-success')
						: t('cost-centers.messages.create-cost-center-success');

          enqueueSnackbar(message, {
            variant: 'success',
            autoHideDuration: 5000,
          });
        }

        setLoading(false);
      } catch (error) {
        if (editMode) {
          disableEditMode();
        }
        setLoading(false);
        let errorMessage = editMode
					? t('cost-centers.messages.update-cost-center-error')
					: t('cost-centers.messages.create-cost-center-error');

        if ('response' in error && typeof error.response.data !== 'string') {
          errorMessage = error.response.data['error'];
        }

				enqueueSnackbar(errorMessage, {
					variant: 'error',
					autoHideDuration: 5000,
				});

        setLoading(false);
        setShowDialog(false);
      }
    }
  };

  const handleGetCostCenters = async () => {
    try {
      const result = await getCostCenter();
      if (result.status != 200) {
        enqueueSnackbar(t('cost-centers.messages.get-cost-centers-error'), {
          variant: 'error',
          autoHideDuration: 5000,
        });
      }
      setCostCenters(result.data);
    } catch (error) {
      enqueueSnackbar(t('cost-centers.messages.get-cost-centers-error'), {
        variant: 'error',
        autoHideDuration: 5000,
      });
    }
  };

  const handleCostCenterChange = event => {
    setCostCenter(event.target.value);
  };

  const disableEditMode = () => {
    setEditMode(false);
  };

  useEffect(() => {
    handleGetCostCenters();
  }, []);

  const setCostCenterEdit = row => {
    setCostCenter(row.name);
    setCostCenterId(row.id);
    setEditMode(true);
    setShowDialog(true);
  };

  const columns: GridColDef[] = [
    {
      field: 'id',
      headerName: t('cost-centers.table.header.id'),
      width: 100,
      flex: 0.25,
      editable: false,
    },
    {
      field: 'name',
      headerName: t('cost-centers.table.header.name'),
      width: 140,
      flex: 0.43,
      editable: false,
    },
    {
      field: 'actions',
      headerName: t('cost-centers.table.header.actions'),
      flex: 0.085,
      align: 'center',
      headerAlign: 'center',
      renderCell: cell => {
        return (
          <Grid container justifyContent={'space-evenly'} alignItems={'center'}>
            <Grid item xs={6}>
              <IconButton
                onClick={() => {
                  setCostCenterEdit(cell.row);
                }}
              >
                <IconEdit />
              </IconButton>
            </Grid>
          </Grid>
        );
      },
    },
  ];

  return (
    <Container
      title={t('cost-centers.title')}
      topRightContent={
        <Button onClick={openDialog}>
					{t('cost-centers.buttons.new-cost-center')}
				</Button>
      }
    >
      {loading ? (
        <LoadingContainer>
          <CircularProgress size={24} />
        </LoadingContainer>
      ) : (
        <DataGrid
          sx={{ marginTop: '20px' }}
          rows={costCenters}
          columns={columns}
          hideFooter={true}
          disableSelectionOnClick
        />
      )}

      <CreateCostCenterDialog
        loading={loading}
        show={showDialog}
        setShow={setShowDialog}
        handleSave={handleSave}
        costCenter={costCenter}
        handleCostCenterChange={handleCostCenterChange}
        errors={errors}
        s
        editMode={editMode}
        disableEditMode={disableEditMode}
      ></CreateCostCenterDialog>
    </Container>
  );
}
