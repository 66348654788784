import {
  Button,
  CircularProgress,
  FormControl,
  Grid,
  InputAdornment,
  Typography,
  FormGroup,
  Checkbox,
  FormControlLabel,
  RadioGroup,
  Radio,
  TextField,
	Chip,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import React, { ChangeEvent, useEffect, useMemo, useState } from 'react';
import Papa from "papaparse";
import { useDispatch, useSelector } from 'react-redux';
import { ICampaing } from '../../../Auth/interfaces/campaing.interface';
import { IReducers } from '../../../Auth/store/store';
import { addCampaing } from '../../../Auth/actions/campaing.action';
import {
  CAMPAING_FILE_TYPE_ACCEPTEDS,
  CAMPAING_FILE_TYPE_DEFAULT,
} from '../../../shared/constants/campaing.constants';
import {
  checkFileTypeAccepted,
  convertXslxToCsv,
  getFileType,
  parseCsv,
} from '../../../utils/FileHelper';
import { FileCsvParsed } from '../../../utils/interfaces/FileTypes';
import { validateEmail, validateNumber } from '../../../utils/ValidatorsHelper';
import './File.scss';
import VariablesList from './RadioGroup/VariablesList';
import NameVariablesList from './RadioGroup/NameVariablesList';
import {
  InputFileBox,
  StyledIcon,
  StyledInputFile,
  StyledTypografhyErr,
  TextButtonUpload,
  StyledGrid,
  BottomStyledGrid,
  MenuItemStyled,
  SelectTypeStyled,
  ButtonText,
  AddSuggestionButton,
  SuggestionContainer,
  SuggestionsContainer,
  SuggestionItem,
  CloseIcon,
} from './styles';
import { Field, useFormikContext } from 'formik';
import { useSnackbar } from 'notistack';
import { ThirdStep } from '../ThirdStep';
import { VariablesStep } from '../VariablesStep';
import Box from '@mui/system/Box';
import getAiLayouts from '../../../utils/api/GET/getAiLayouts';
import getCompanies from '../../../utils/api/GET/getCompanies';
import getQueues from '../../../utils/api/GET/getQueues';
import getIntegrations from '../../../utils/api/GET/getIntegrations';
import getWabas from '../../../utils/api/GET/getWabas';
import getWhatsappTemplates from '../../../utils/api/GET/getWhatsappTemplates';
import getWhatsappPhones from '../../../utils/api/GET/getWhatsappPhones';
import getEmailSenders from '../../../utils/api/GET/getEmailSenders';
import { CheckBox } from '@mui/icons-material';
import jschardet from 'jschardet';

const MAX_FILE_SIZE = 400 * 1024 * 1024; //	400MB
const WHATSAPP_REQUIRED_FIELDS = ["telefone", "cpf"];
const WHATSAPP_EXAMPLE_FIELDS = ["11999999999", "12345678900"];
const EMAIL_REQUIRED_FIELDS = ["email"];
const EMAIL_EXAMPLE_FIELDS = ["email@example.com"];

const requiredFieldsMap = {
	"EMAIL": EMAIL_REQUIRED_FIELDS,
	"WHATSAPP": WHATSAPP_REQUIRED_FIELDS,
}

const requiredFieldsExampleMap = {
	"EMAIL": EMAIL_EXAMPLE_FIELDS,
	"WHATSAPP": WHATSAPP_EXAMPLE_FIELDS,
}

const customVariablesMap = {
	"EMAIL": 15,
}

const ALLOWED_RICH_CARD_EXTENSIONS = [
  'mp3',
  'wma',
  'aac',
  'ogg',
  'wav',
  'ac3',
  'avi',
  'mpeg',
  'mov',
  'rmvb',
  'mkv',
  'bmp',
  'gif',
  'png',
  'jpg',
  'jpeg',
  'mp4',
  'webm',
];

const SecondStep: React.FC = (): JSX.Element => {
  const { t } = useTranslation();
  const { fileStore, filename, isAI }: ICampaing = useSelector(
    (state: IReducers) => state.campaing
  );
  const fileTypeAccepteds: string[] = CAMPAING_FILE_TYPE_ACCEPTEDS;
  const fileTypeDefault: string = CAMPAING_FILE_TYPE_DEFAULT;
  const [fileLoaded, setFileLoaded] = useState<any>(fileStore);
  const { enqueueSnackbar } = useSnackbar();
  const [loading, setLoading] = useState(false);
  const [contactVariables, setContactVariables] = useState<string[]>();
  const [file, setFile] = useState(null);
  const [fileNameInput, setFileNameInput] = useState(filename);
  const [fileError, setFileError] = useState<string>();
  const { values, setFieldValue } = useFormikContext();
  const [useWhitelist, setUseWhitelist] = useState(values.use_whitelist);
  const [useCPC, setUseCPC] = useState(values.use_cpc);
  const [useShortenerURl, setUseShortenerURl] = useState(values.use_shortener_url);
  const [useBlacklistNexus, setUseBlacklistNexus] = useState(
    values.use_blacklist_nexus
  );
  const [aiLayouts, setAiLayouts] = useState([]);
  const [isLoadingAiLayouts, setIsLoadingAiLayouts] = useState(false);
  const [allHeaders, setAllHeaders] = useState([]);
  const dispatch = useDispatch();
  let contactPositions: any[];
  const [aiLayoutId, setAiLayoutId] = useState('');

  const [isLoadingCompanies, setIsLoadingCompanies] = useState(false);
  const [companies, setCompanies] = useState([]);
  const [selectedCompanyId, setSelectedCompanyId] = useState('');

  const [isLoadingWabas, setIsLoadingWabas] = useState(false);
  const [wabas, setWabas] = useState([]);
  const [selectedWabaId, setSelectedWabaId] = useState('');

  const [isLoadingWppTemplates, setIsLoadingWppTemplates] = useState(false);
  const [wppTemplates, setWppTemplates] = useState([]);
  const [selectedWppTemplates, setSelectedWppTemplates] = useState([]);

  const [isLoadingEmailSenders, setIsLoadingEmailSenders] = useState(false);
  const [emailSenders, setEmailSenders] = useState([]);
  const [selectedEmailSender, setSelectedEmailSender] = useState('');

  const [isLoadingWppPhones, setIsLoadingWppPhones] = useState(false);
  const [wppPhones, setWppPhones] = useState([]);

  const [isLoadingQueues, setIsLoadingQueues] = useState(false);
  const [queues, setQueues] = useState([]);
  const [selectedQueueId, setSelectedQueueId] = useState('');

  const [isLoadingIntegrations, setIsLoadingIntegrations] = useState(false);
  const [integrations, setIntegrations] = useState([]);
  const [selectedIntegrationId, setSelectedIntegrationId] = useState('');

	const filteredWppTemplates = useMemo(() => {
		return wppTemplates.filter(template => selectedWabaId ? template.WabaId == selectedWabaId : true);
	}, [wppTemplates, selectedWabaId]);

	const { selectedWppPhones, selectedWabas } = useMemo(() => {
		const selectedWhatsappTemplates = wppTemplates.filter(
			template => selectedWppTemplates.includes(template.id)
		);

		const selectedWppPhones = new Set();
		const selectedWabas = new Set();

		for (const template of selectedWhatsappTemplates) {
			selectedWabas.add(template.WabaId);
			for (const phoneNumber of template.PhoneNumbers) {
				selectedWppPhones.add(phoneNumber.id);
			}
		}

		return {
			selectedWppPhones,
			selectedWabas
		}
	}, [wppTemplates, selectedWppTemplates]);

	const estimatedRemainingOutput = useMemo(() => {
		return wppPhones.reduce((acc, phone) => {
			if (selectedWppPhones.has(phone.id)) {
				acc += phone.remainingOutput;
			}
			return acc;
		}, 0);
	}, [wppPhones, selectedWppPhones]);

  const setMatrixVariables = (campaing: ICampaing): void => {
    dispatch(addCampaing(campaing));
  };
  const { templateVariables }: ICampaing = useSelector(
    (state: IReducers) => state.campaing
  );

  const clearMatrix = () => {
    //dispatch(RemoveCampaign())
    setMatrixVariables({
      matrix_variables_array: [],
      templateVariables: ['{{w___nome}}', '{{w___cpf}}', '{{w___contato}}'],
      template: '',
    });
    setFieldValue('template', '');
    setFieldValue('matrix_variables', []);
    setFieldValue('id_ai_user_layout', '');
    setAiLayoutId('');
  };

  const [readySteps, setReadySteps] = useState({
    1: false,
    2: false,
    3: false,
  });

  const setCampaingValues = (campaing: Partial<ICampaing>): void => {
    dispatch(addCampaing(campaing));
  };

  const { campaign_type }: ICampaing = useSelector(
    (state: IReducers) => state.campaing
  );

  const setFileValue = (file: any): void => {
    dispatch(addCampaing({ fileStore: file }));
  };

  const setReduxValue = (campaign: ICampaing): void => {
    dispatch(addCampaing(campaign));
  };

  const setInputFileName = (campaing: ICampaing): void => {
    dispatch(addCampaing(campaing));
  };

  const getValidEmailFields = (list: string[]): string[] => {
    return list.filter((text: string) => validateEmail(text));
  };

  const getValidaNumberFields = (list: number[]): number[] => {
    return list.filter((number: number) => validateNumber(number));
  };

  const getPositionsEmail = (list: string[], data: string[]): number[] => {
    return list.map((text: string) => data.indexOf(text));
  };

  const getPositionsNumber = (list: number[], data: number[]): number[] => {
    return list.map((number: number) => data.indexOf(number));
  };

  const updatePositions = (list: number[]): number[] => {
    let positions: number[] = [];
    list.forEach(position => {
      if (position) {
        positions = positions.concat(position);
      }
    });

    return positions;
  };

  const getUniquePositions = (positions: number[]): number[] => {
    const uniquePositions: number[] = [];

    positions.forEach((position: number) => {
      if (!uniquePositions.includes(position)) {
        uniquePositions.push(position);
      }
    });

    return uniquePositions;
  };

  const getContactPositions = (data: any[]): any[] => {
    return data.map((data: string[]) => {
      const validEmails = getValidEmailFields(data);
      if (validEmails.length > 0) {
        return getPositionsEmail(validEmails, data);
      }
    });
  };

  const getNumberPositions = (data: any[]): any[] => {
    return data.map((data: number[]) => {
      const validNumbers = getValidaNumberFields(data);
      if (validNumbers.length) {
        return getPositionsNumber(validNumbers, data);
      }
    });
  };

  const filterUniqueContactPositions = (
    contactPositions: number[]
  ): number[] => {
    return getUniquePositions(updatePositions(contactPositions));
  };

  const getHeadersContactVariablesName = (
    uniquePositions: number[],
    csvParsed: FileCsvParsed
  ) => {
    const contactVars = [];
    try {
      csvParsed.header.forEach((elem, index) => {
        const newContact = csvParsed.data[0][index].replace(/\D/g, '');
        if (newContact && newContact.length >= 10) {
          contactVars.push({ index: index, value: csvParsed.header[index] });
        }
      });
    } catch (err) {
      enqueueSnackbar(t('create-campaign.messages.corrupted-file'), {
        variant: 'info',
        autoHideDuration: 2000,
      });
      return;
    }

    if (contactVars.length == 0 && values.campaign_type !== 'EMAIL') {
      // setFileNameInput('')
      enqueueSnackbar(
        t('create-campaign.validations.no-valid-contacts-found'),
        {
          variant: 'info',
          autoHideDuration: 2000,
        }
      );
      setReduxValue({
        contact_variable_index: undefined,
        fileStore: undefined,
      });
      setFieldValue('contact_variable_index', '');
      // setFile(null)
      clearMatrix();
      // setFileLoaded(null)
    }

    return contactVars;
  };

  const handleChangeUseBlacklistNexus = () => {
    const newBlacklistNexus = !useBlacklistNexus;
    setUseBlacklistNexus(newBlacklistNexus);
    setCampaingValues({
      use_blacklist_nexus: newBlacklistNexus,
    });
    setFieldValue('use_blacklist_nexus', newBlacklistNexus);
  };

  const handleChangeWhitelist = () => {
    const newWhitelistFile = !useWhitelist;
    setUseWhitelist(newWhitelistFile);
    setCampaingValues({
      use_whitelist: newWhitelistFile,
    });
    setFieldValue('use_whitelist', newWhitelistFile);
  };

  const handleChangeCPC = () => {
    const newUseCPC = !useCPC;
    setUseCPC(newUseCPC);
    setCampaingValues({ use_cpc: newUseCPC });
    setFieldValue('use_cpc', newUseCPC);
  };

  const handleChangeShortenerUrl = () => {
    const newUseShortenerUrl = !useShortenerURl;
    setUseShortenerURl(newUseShortenerUrl);
    setCampaingValues({
      use_shortener_url: newUseShortenerUrl,
    });
    setFieldValue('use_shortener_url', newUseShortenerUrl);
  };

  const checkContactVariable = (csvParsed: FileCsvParsed) => {
    if (campaign_type === 'EMAIL') {
      contactPositions = getContactPositions(csvParsed.data);
    } else {
      contactPositions = getNumberPositions(csvParsed.data);
    }

    const parsedHeaders = csvParsed.header.map((title: string) => {
      return title.toLowerCase();
    });

    const findDuplicates = parsedHeaders.filter(
      (item, index) => parsedHeaders.indexOf(item) != index
    );
    if (findDuplicates.length) {
      enqueueSnackbar(t('create-campaign.validations.duplicated-headers'), {
        variant: 'error',
      });
      return;
    }

    setFieldValue('headers', [...parsedHeaders]);
    if (contactPositions) {
      const uniquePositions: number[] =
        filterUniqueContactPositions(contactPositions);
      setContactVariables(
        getHeadersContactVariablesName(uniquePositions, csvParsed)
      );
      // if (uniquePositions.length && campaign_type === 'SMS') {
      //   enqueueSnackbar(
      //     'Certifique-se que na variável de contato selecionada possua números válidos de telefone.',
      //     {
      //       variant: 'info',
      //       autoHideDuration: 2000
      //     }
      //   );
      // } else if (campaign_type === 'SMS' && !uniquePositions.length) {
      //   enqueueSnackbar(
      //     'Não foi encontrada nenhuma variável com números válidos!',
      //     {
      //       variant: 'error'
      //     }
      //   );
      // }

      // if (uniquePositions.length && campaign_type === 'EMAIL') {
      //   enqueueSnackbar(
      //     t('create-campaign.validations.make-sure-emails-are-valid'),
      //     {
      //       variant: 'info',
      //       autoHideDuration: 2000,
      //     }
      //   );
      // } else if (campaign_type === 'EMAIL' && !uniquePositions.length) {
      //   enqueueSnackbar(
      //     t('create-campaign.validations.no-valid-emails-found'),
      //     {
      //       variant: 'error',
      //     }
      //   );
      // }
      return;
    }
  };

  const fileChanged = (): void => {
    if (file) {
      setLoading(true);
      setTimeout(() => {
        readFile(file);
      }, 1000);
    }
  };

	const validateLayout = (fileContent) => {
		const rows = fileContent.split(/\r\n|\n/);

		const requiredFields = requiredFieldsMap[values.campaign_type];
		const customVariablesCount = customVariablesMap[values.campaign_type];

		const headers = rows[0].split(';');

		const hasRequiredFields = requiredFields.every((field, index) => headers[index] === field);
		let hasInvalidFields = false;

		if (customVariablesCount) {
			const optionalFields = Array(customVariablesCount).fill("").map((_, index) => `custom_${index + 1}`);
			hasInvalidFields = headers.some(field => !requiredFields.includes(field) && !optionalFields.includes(field));
		}

		if (!hasRequiredFields || hasInvalidFields) {
			return 'Arquivo inválido. Por favor, selecione um arquivo .csv com o formato correto.';
		}

		if (rows.length < 2) {
			return 'Arquivo vazio. Por favor, selecione um arquivo com dados.';
		}

		if (useCPC) {
			const cpfFilled = rows[1].split(';')[1];
			if (!cpfFilled) {
				return 'CPF não preenchido. Para utilizar o CPC, é necessário que o campo CPF esteja preenchido.';
			}
		}
	}

  const readFile = (file: File) => {
    try {
			setFileError(null);
			setFieldValue('has_file_error', false);
      const fileType = getFileType(file.name);
      const fileName = file.name;
      setFieldValue('filename', fileName.toLowerCase());

      if (file.size > MAX_FILE_SIZE) {
        setFileError('create-campaign.tabs.settings.validations.file-too-large');
				setFieldValue('has_file_error', true);
        setLoading(false);
        return;
      }

			const validations = {
				"WHATSAPP": validateLayout,
				"EMAIL": validateLayout,
			}

      if (checkFileTypeAccepted(fileType, fileTypeAccepteds)) {
        const reader = new FileReader();
        // var slice = file.slice(0, 2048);
        // reader.readAsArrayBuffer(slice);
        reader.readAsArrayBuffer(file);
        reader.onload = e => {
          if (e?.target?.result) {
            const arrayBuffer = e.target.result as ArrayBuffer;
            const buffer = Buffer.from(arrayBuffer);
            const encoding = jschardet.detect(buffer).encoding;
            const decoder = new TextDecoder(encoding);
            const strResult = decoder.decode(e.target.result as ArrayBuffer);
            const fileReaded =
              fileType === fileTypeDefault
                ? strResult
                : convertXslxToCsv(strResult);

						if (validations[values.campaign_type]) {
							const error = validations[values.campaign_type](fileReaded);
							if (error) {
								setFileError(error);
								setFieldValue('has_file_error', true);
								setLoading(false);
								return;
							}
						}

            setFileValue(fileReaded);
            setFileLoaded(fileReaded);
            // setFileNameInput(file.name);
            setInputFileName({ filename: fileName });
            setLoading(false);
            setFileError(null);
						setFieldValue('has_file_error', false);
            return;
          }
        };
        return;
      } else {
        setFileError(
          'create-campaign.tabs.settings.validations.file-not-supported'
        );
				setFieldValue('has_file_error', true);
        setLoading(false);
      }
    } catch (err) {
      enqueueSnackbar(t('create-campaign.messages.corrupted-file'), {
        variant: 'info',
        autoHideDuration: 2000,
      });
    }
  };

  const onChangeFile = (e: any) => {
    const file = e.currentTarget.files[0];
    setReduxValue({ contact_variable_index: undefined, fileStore: undefined });
    setFieldValue('contact_variable_index', '');
    if (file) {
      setFieldValue('matrix_variables', []);
      clearMatrix();
      setFileNameInput(file.name);
      setFile(file);
			if (values.campaign_type === "WHATSAPP" || values.campaign_type === "EMAIL") {
				if (file) {
					setLoading(true);
					readFile(file);
				}
			}
    }
  };

  const stepWasReady = (step: number) => {
    setReadySteps({
      ...readySteps,
      [step]: true,
    });
  };

  const setTemplateVariables = (campaing: ICampaing): void => {
    dispatch(addCampaing(campaing));
  };

  const handleAyLayoutChange = event => {
    const iaPrefix = 'ia___';
    const wPrefix = 'w___';
    const idLayout = parseInt(event.target.value);
    const selectedLayout = aiLayouts.find(layout => {
      return layout.id === idLayout;
    });
    const iaVariables = selectedLayout.condition_variables.map(variable => {
      variable = variable.replace(iaPrefix, '');
      return `{{${iaPrefix}${variable}}}`;
    });
    const wVairables = [];
    const variables = [];
    if (templateVariables && templateVariables.length) {
      templateVariables.forEach(variable => {
        if (variable.includes(iaPrefix)) {
          return;
        }

        if (variable.includes(wPrefix)) {
          wVairables.push(variable);
        } else {
          variables.push(variable);
        }
      });
    }
    setFieldValue('id_ai_user_layout', idLayout);
    setAiLayoutId(idLayout);
    setTemplateVariables({
      templateVariables: [...wVairables, ...iaVariables, ...variables],
    });
  };

  const handleSelectedCompanyChange = event => {
    const companyId = parseInt(event.target.value);

    const selectedCompany = companies.find(company => company.id === companyId);

    if (selectedCompany) {
      setFieldValue('company_cnpj', selectedCompany.cnpj);
      setFieldValue('business_id', selectedCompany.id);
      setSelectedCompanyId(selectedCompany.id);
			setSelectedWabaId('');
			setSelectedWppTemplates([]);
    }
  };

	const handleSelectedQueueChange = event => {
		const queueId = parseInt(event.target.value);

		const selectedQueue = queues.find(queue => queue.id === queueId);

		if (selectedQueue) {
			setFieldValue('queue_id', selectedQueue.id);
			setSelectedQueueId(selectedQueue.id);
		}
	}

	const handleSelectedIntegrationChange = event => {
		const integrationId = parseInt(event.target.value);

		const selectedIntegration = integrations.find(integration => integration.id === integrationId);

		if (selectedIntegration) {
			setFieldValue('integration_id', selectedIntegration.id);
			setSelectedIntegrationId(selectedIntegration.id);
		}
	}

  const handleSelectedSenderChange = event => {
    const senderId = parseInt(event.target.value);

    const selectedSender = emailSenders.find(sender => sender.id === senderId);

    if (selectedSender) {
      setFieldValue('sender', selectedSender.id);
      setSelectedEmailSender(selectedSender.id);
			setCampaingValues({
				senderID: selectedSender.id,
				senderEMAIL: selectedSender.email_address,
			});
    }
  };

  const handleSelectedWabaChange = event => {
    const wabaId = parseInt(event.target.value);

    const selectedWaba = wabas.find(waba => waba.id === wabaId);

    if (selectedWaba) {
      setFieldValue('waba_id', selectedWaba.id);
      setSelectedWabaId(selectedWaba.id);
    } else {
			setFieldValue('waba_id', '');
			setSelectedWabaId('');
		}
  };

  const handleSelectWppTemplate = event => {
		const templateId = parseInt(event.target.value);

		const templates = selectedWppTemplates.reduce((acc, templateId) => {
			acc[templateId] = true;
			return acc;
		}, {});

		if (templates[templateId]) {
			delete templates[templateId];
		} else {
			templates[templateId] = true;
		}

		const newTemplateIds = Object.keys(templates).map(id => parseInt(id));

		setFieldValue('template_ids', newTemplateIds);
		setSelectedWppTemplates(newTemplateIds);
  };

  useEffect(() => {
    async function loadAiLayouts() {
      setIsLoadingAiLayouts(true);
      try {
        const result = await getAiLayouts();
        if (result.status != 200) {
          enqueueSnackbar(
            t('create-campaign.messages.get-chatgpt-layouts-error'),
            {
              variant: 'error',
              autoHideDuration: 5000,
            }
          );
        } else {
          setAiLayouts(result.data);
        }
      } catch (error) {
        enqueueSnackbar(
          t('create-campaign.messages.get-chatgpt-layouts-error'),
          {
            variant: 'error',
            autoHideDuration: 5000,
          }
        );
      } finally {
        setIsLoadingAiLayouts(false);
      }
    }

    async function loadCompanies() {
      setIsLoadingCompanies(true);
      try {
        const result = await getCompanies();
        if (result.status != 200) {
          enqueueSnackbar(t('create-campaign.messages.get-companies-error'), {
            variant: 'error',
            autoHideDuration: 5000,
          });
        } else {
          setCompanies(result.data);
        }
      } catch (error) {
        enqueueSnackbar(t('create-campaign.messages.get-companies-error'), {
          variant: 'error',
          autoHideDuration: 5000,
        });
      } finally {
        setIsLoadingCompanies(false);
      }
    }

    async function loadQueues() {
      setIsLoadingQueues(true);
      try {
        const result = await getQueues();
        if (result.status != 200) {
          enqueueSnackbar(t('create-campaign.messages.get-queues-error'), {
            variant: 'error',
            autoHideDuration: 5000,
          });
        } else {
          setQueues(result.data);
        }
      } catch (error) {
        enqueueSnackbar(t('create-campaign.messages.get-queues-error'), {
          variant: 'error',
          autoHideDuration: 5000,
        });
      } finally {
        setIsLoadingQueues(false);
      }
    }

    async function loadIntegrations() {
      setIsLoadingIntegrations(true);
      try {
        const result = await getIntegrations();
        if (result.status != 200) {
          enqueueSnackbar(t('create-campaign.messages.get-integrations-error'), {
            variant: 'error',
            autoHideDuration: 5000,
          });
        } else {
          setIntegrations(result.data.queueIntegrations);
        }
      } catch (error) {
        enqueueSnackbar(t('create-campaign.messages.get-integrations-error'), {
          variant: 'error',
          autoHideDuration: 5000,
        });
      } finally {
        setIsLoadingIntegrations(false);
      }
    }

    async function loadSenders() {
      setIsLoadingEmailSenders(true);
      try {
        const result = await getEmailSenders();
        if (result.status != 200) {
          enqueueSnackbar(t('create-campaign.messages.get-senders-error'), {
            variant: 'error',
            autoHideDuration: 5000,
          });
        } else {
          setEmailSenders(result.data.results);
        }
      } catch (error) {
        enqueueSnackbar(t('create-campaign.messages.get-senders-error'), {
          variant: 'error',
          autoHideDuration: 5000,
        });
      } finally {
        setIsLoadingEmailSenders(false);
      }
    }

		if (values.campaign_type_ai) {
			loadAiLayouts();
		}

		if (values.campaign_type === "WHATSAPP") {
			loadCompanies();
			loadQueues();
			loadIntegrations();
		}

		if (values.campaign_type === "EMAIL") {
			loadSenders();
		}
  }, []);

	useEffect(() => {
		if (!selectedCompanyId) {
			setWabas([]);
			setWppTemplates([]);
			setWppPhones([]);
			setSelectedWabaId('');
			return;
		}

		let wabas;

		async function loadWabas() {
      setIsLoadingWabas(true);
      try {
        const result = await getWabas({
					BusinessId: selectedCompanyId,
				});
        if (result.status != 200) {
          enqueueSnackbar('Erro ao listar WABAs, contate a equipe de suporte.', {
            variant: 'error',
            autoHideDuration: 5000,
          });
        } else {
					setWabas(result.data);
					if (result.data.length === 1) {
						setFieldValue('waba_id', result.data[0].id);
						setSelectedWabaId(result.data[0].id);
					}
					wabas = result.data;
        }
      } catch (error) {
        enqueueSnackbar('Erro ao listar WABAs, contate a equipe de suporte.', {
          variant: 'error',
          autoHideDuration: 5000,
        });
      } finally {
        setIsLoadingWabas(false);
      }
    }

		const wabasPromise = loadWabas();

		async function loadWppTemplates() {
      setIsLoadingWppTemplates(true);
      try {
        const result = await getWhatsappTemplates({
					BusinessId: selectedCompanyId,
					active: true
				});
        if (result.status != 200) {
          enqueueSnackbar('Erro ao listar templates, contate a equipe de suporte.', {
            variant: 'error',
            autoHideDuration: 5000,
          });
        } else {
          setWppTemplates(result.data);
        }
      } catch (error) {
        enqueueSnackbar('Erro ao listar templates, contate a equipe de suporte.', {
          variant: 'error',
          autoHideDuration: 5000,
        });
      } finally {
        setIsLoadingWppTemplates(false);
      }
    }

		async function loadWppPhones() {
      setIsLoadingWppPhones(true);
      try {
        const result = await getWhatsappPhones({
					BusinessId: selectedCompanyId,
					output: true
				});
        if (result.status != 200) {
          enqueueSnackbar('Erro ao listar números, contate a equipe de suporte.', {
            variant: 'error',
            autoHideDuration: 5000,
          });
        } else {
					await wabasPromise;

					const wabaMap = wabas.reduce((acc, waba) => {
						acc[waba.id] = waba;
						return acc;
					}, {});

					const phones = result.data.map(phone => ({
						...phone,
						Waba: wabaMap[phone.WabaId],
					}))

          setWppPhones(phones);
        }
      } catch (error) {
        enqueueSnackbar('Erro ao listar números, contate a equipe de suporte.', {
          variant: 'error',
          autoHideDuration: 5000,
        });
      } finally {
        setIsLoadingWppPhones(false);
      }
    }

    loadWppTemplates();
    loadWppPhones();
	}, [selectedCompanyId]);

  useEffect(() => {
    if (fileLoaded) {
      const csvParsed = parseCsv(fileLoaded);
      setAllHeaders(
        csvParsed.header.map((elem, index) => {
          return { index: index, value: elem };
        })
      );
      checkContactVariable(csvParsed);

			if (values.campaign_type === 'WHATSAPP' || values.campaign_type === 'EMAIL') {
				let requiredVariables;

				if (values.campaign_type === 'WHATSAPP') {
					requiredVariables = [
						{ file_index: 0, w_template_variable: 'w___contato' },
						{ file_index: 1, w_template_variable: 'w___cpf' },
					]
				} else if (values.campaign_type === 'EMAIL') {
					requiredVariables = [
						{ file_index: 0, w_template_variable: 'w___contato' },
					]
				}

				const customHeaders = csvParsed.header.slice(requiredVariables.length);

				const customVariables = customHeaders.map((header, index) => ({
					file_index: index + requiredVariables.length,
					template_variable: header,
				}));

				const matrixVariables = [...requiredVariables, ...customVariables];
				const templateVariables = matrixVariables.map(variable => `{{${variable.w_template_variable || variable.template_variable}}}`);

				console.log("matrixVariables", matrixVariables);
				console.log("templateVariables", templateVariables);

				setFieldValue('matrix_variables', matrixVariables);
				setTemplateVariables({ templateVariables });
			}
    }
    if (fileNameInput) {
      setInputFileName({ filename: fileNameInput });
    }
  }, [fileLoaded, setFileLoaded]);

  function handleRCSValueChange(
    event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) {
    const { name, value } = event.target;
    setFieldValue(name, value);
    setCampaingValues({ [name]: value });
  }

  function updateSuggestions(suggestions) {
    setCampaingValues({ suggestions });
    setFieldValue('suggestions', suggestions);
  }

  function handleAddSuggestion() {
    if (values.suggestions.length >= 4) {
      enqueueSnackbar(t('create-campaign.messages.max-suggestions-reached'), {
        variant: 'info',
        autoHideDuration: 2000,
      });
      return;
    }

    const newSuggestion = {
      id: Math.random().toString(36).substr(2, 9),
      type: '',
      title: '',
      value: '',
    };

    const newSuggestions = [...values.suggestions, newSuggestion];
    updateSuggestions(newSuggestions);
  }

  function handleDeleteSuggestion(suggestion) {
    const newSuggestions = values.suggestions.filter(
      item => item.id !== suggestion.id
    );
    updateSuggestions(newSuggestions);
  }

  function handleSuggestionChange(suggestion) {
    return (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      const { name, value } = event.target;

      const newSuggestions = values.suggestions.map(item => {
        if (item.id === suggestion.id) {
          return {
            ...item,
            [name]: value,
          };
        }

        return item;
      });

      updateSuggestions(newSuggestions);
    };
  }

  async function handleSelectRichCardFile(event) {
    setFieldValue('rcs_rich_card_file', { loading: true });

    try {
      const file = event.currentTarget.files[0];

      if (!file) {
        setFieldValue('rcs_rich_card_file', { loading: false });
        return;
      }

      const fileType = getFileType(file.name);

      if (!ALLOWED_RICH_CARD_EXTENSIONS.includes(fileType)) {
        setFieldValue('rcs_rich_card_file', {
          error:
            'create-campaign.tabs.settings.validations.rich-card-file-not-supported',
        });
        return;
      }

      setFieldValue('rcs_rich_card_file', { file });
    } catch (err) {
      setFieldValue('rcs_rich_card_file', {
        error:
          'create-campaign.tabs.settings.messages.rich-card-file-read-error',
      });
    }
  }

	function handleDownloadExample () {
		const rows = [];

    const requiredFields = requiredFieldsMap[values.campaign_type] || [];
    const customVariablesCount = customVariablesMap[values.campaign_type] || 0;

		const customFields = Array(customVariablesCount).fill("").map((_, index) => `custom_${index + 1}`);
		const headers = requiredFields.concat(customFields);

		rows.push(headers);

		const exampleFields = requiredFieldsExampleMap[values.campaign_type];
    if (exampleFields) {
			const example = Object.assign(new Array(headers.length).fill(""), exampleFields);
			rows.push(example);
		}

    const csv = Papa.unparse(rows, { delimiter: ";" });
    const blob = new Blob([csv], { type: "text/csv;charset=utf-8;" });
    const link = document.createElement("a");
    link.href = URL.createObjectURL(blob);
    link.download = `exemplo-${values.campaign_type.toLowerCase()}.csv`;
    link.click();
  }

  return (
    <>
      <Grid container mt={2}>
        <StyledGrid item md={4} xs={12} padding={1} border="1px dashed grey">
          <Box component="div" height={
							values.campaign_type === "WHATSAPP" ? "280px"
							: values.campaign_type === "EMAIL" ? "550px"
							: "130px"} m={3}>
            <strong>
              2.1) {t('create-campaign.tabs.settings.file-upload')}
            </strong>
            <br />
            <InputFileBox>
              <label
                htmlFor="file"
                style={{
                  background: 'transparent',
                  height: 50,
                  width: '100%',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'space-between',
                  paddingLeft: 10,
                }}
              >
                <Typography noWrap variant="body1">
                  {fileNameInput ||
                    t('create-campaign.tabs.settings.file-placeholder')}
                </Typography>
								{loading ? <CircularProgress size={24} /> : <StyledIcon />}
              </label>
              <FormControl style={{ display: 'none' }}>
                <Field
                  component={StyledInputFile}
                  className="btn-file"
                  variant="outlined"
                  id="file"
                  type="file"
                  size="small"
									inputProps={{ accept: '.csv' }}
                  onChange={(e: any) => {
                    setFieldValue('filename', e.target.value.toLowerCase());
                    onChangeFile(e);
                  }}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        {loading ? <CircularProgress size={24} /> : <StyledIcon />}
                      </InputAdornment>
                    ),
                  }}
                />
              </FormControl>
              {fileError && (
                <StyledTypografhyErr>{t(fileError)}</StyledTypografhyErr>
              )}

							{['WHATSAPP', 'EMAIL'].includes(values.campaign_type) && (
								<Box>
									<Box marginTop={1}>
										<Typography variant="caption" display="block">
											- Extensões permitidas: <strong>.csv</strong>
										</Typography>
										<Typography variant="caption" display="block">
											- Tamanho máximo: <strong>50mb</strong>
										</Typography>
										<Typography variant="caption" display="block">
											- Cabeçalho <strong>(header)</strong> obrigatório
										</Typography>
										<Typography variant="caption" display="block">
											- Colunas obrigatórias: <strong>{requiredFieldsMap[values.campaign_type]?.join(";")}</strong>
										</Typography>
										<Typography variant="caption" display="block">
											- Separador de colunas: <strong>; (ponto e vírgula)</strong>
										</Typography>
									</Box>

									<Box marginTop={1}>
										<Button
											size="small"
											variant="contained"
											color="primary"
											onClick={handleDownloadExample}
										>
											Download exemplo .csv
										</Button>
									</Box>
								</Box>
							)}
            </InputFileBox>
						{(values.campaign_type !== "WHATSAPP" && values.campaign_type !== "EMAIL") && (
							<Button
								variant="contained"
								color="secondary"
								fullWidth
								onClick={() => fileChanged()}
								disabled={!file || loading}
								sx={{ mt: '12px' }}
							>
								{loading ? (
									<CircularProgress size={24} />
								) : (
									<TextButtonUpload>
										{t('create-campaign.tabs.settings.buttons.analyze-layout')}
									</TextButtonUpload>
								)}
							</Button>
						)}
          </Box>
        </StyledGrid>

        {values.campaign_type === 'SMS' && (
          <>
            <StyledGrid item md={4} xs={5} padding={1} border="1px dashed grey">
              <Box component="div" height="130px" m={2}>
                <strong>
                  2.4) {t('create-campaign.tabs.settings.select-template')}
                </strong>
                <br />
                &nbsp;
                <br />
                <ThirdStep
                  fileNameInput={fileNameInput}
                  stepWasReady={stepWasReady}
                />
              </Box>
            </StyledGrid>

            <StyledGrid item md={4} xs={5} padding={1} border="1px dashed grey">
              <Box component="div" height="130px" m={2}>
                <strong>
                  2.3) {t('create-campaign.tabs.settings.whitelist')}
                </strong>
                <br />
                &nbsp;
                <br />
                <FormGroup>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={useWhitelist}
                        onChange={handleChangeWhitelist}
                      />
                    }
                    label={t('create-campaign.tabs.settings.use-whitelist')}
                  />
                </FormGroup>
              </Box>
              <Box component="div" height="130px" m={2}>
                <strong>
                  2.5) {t('create-campaign.tabs.settings.shortenerUrl')}
                </strong>
                <br />
                &nbsp;
                <br />
                <FormGroup>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={useShortenerURl}
                        onChange={handleChangeShortenerUrl}
                      />
                    }
                    label={t('create-campaign.tabs.settings.use-shortenerUrl')}
                  />
                </FormGroup>
              </Box>
            </StyledGrid>
          </>
        )}

        {values.campaign_type === 'EMAIL' && (
          <>
						<StyledGrid item md={4} xs={6} padding={1} border="1px dashed grey">
							<Box component="div" height="130px" m={2}>
								<strong>
									2.2) {t('create-campaign.tabs.settings.select-sender')}
								</strong>
								<br />
								&nbsp;
								<br />
								{isLoadingEmailSenders ? (
									<CircularProgress />
								) : emailSenders.length > 0 ? (
									<FormControl disabled={fileNameInput ? false : true}>
										<RadioGroup
											aria-labelledby="demo-radio-buttons-group-label-2"
											name="radio-buttons-group-2"
											onChange={handleSelectedSenderChange}
											value={selectedEmailSender}
										>
											{emailSenders?.map(sender => {
												return (
													<FormControlLabel
														key={sender.id}
														value={sender.id}
														control={<Radio />}
														label={
															<Box display="flex" flexDirection="column">
																<Typography variant="body2" lineHeight="1.0">{sender.name}</Typography>
																<Typography variant="caption">{sender.email_address}</Typography>
															</Box>
														}
													/>
												);
											})}
										</RadioGroup>
									</FormControl>
								) : (
									<StyledTypografhyErr variant="body1">
										{t('create-campaign.tabs.settings.no-senders')}
									</StyledTypografhyErr>
								)}
							</Box>
						</StyledGrid>

            <StyledGrid item md={4} xs={5} padding={1} border="1px dashed grey">
              <Box component="div" height="130px" m={2}>
                <strong>
                  2.3) {t('create-campaign.tabs.settings.select-template')}
                </strong>
                <br />
                &nbsp;
                <br />
                <ThirdStep
                  fileNameInput={fileNameInput}
                  stepWasReady={stepWasReady}
                />
              </Box>
            </StyledGrid>
          </>
        )}

        {values.campaign_type === 'WHATSAPP' && (
          <>
						<StyledGrid item md={3} xs={12} padding={1} border="1px dashed grey">
              <Box component="div" height="130px" m={2}>
                <strong>
                  2.2) {t('create-campaign.tabs.settings.select-company')}
                </strong>
                <br />
                &nbsp;
                <br />
                {isLoadingCompanies ? (
                  <CircularProgress />
                ) : companies.length > 0 ? (
									<FormControl>
										<TextField
											select
											name="wpp_company"
											label="Selecione a empresa"
											onChange={handleSelectedCompanyChange}
                      value={selectedCompanyId}
											disabled={isLoadingWabas || isLoadingWppTemplates || isLoadingWppPhones}
										>
											{companies?.map(company => (
												<MenuItemStyled key={company.id} value={company.id}>
													{company.name}
												</MenuItemStyled>
											))}
										</TextField>
									</FormControl>
                ) : (
                  <StyledTypografhyErr variant="body1">
                    {t('create-campaign.tabs.settings.no-companies')}
                  </StyledTypografhyErr>
                )}
              </Box>
              <Box component="div" height="130px" m={2}>
                <strong>
                  2.3) Seleção de WABAs
                </strong>
                <br />
                &nbsp;
                <br />
								{isLoadingWabas ? (
                  <CircularProgress />
                ) : wabas?.length > 0 ? (
									<FormControl>
										<TextField
											select
											name="wpp_waba"
											label="Selecione o WABA"
											onChange={handleSelectedWabaChange}
                      value={selectedWabaId}
										>
											<MenuItemStyled value="">
												Todos
											</MenuItemStyled>
											{wabas?.map(waba => (
												<MenuItemStyled key={waba.id} value={waba.id}>
													{waba.name}
												</MenuItemStyled>
											))}
										</TextField>
									</FormControl>
                ) : selectedCompanyId && (
									<StyledTypografhyErr variant="body1">
										Nenhum waba disponível com os filtros selecionados, contate a equipe de suporte.
                  </StyledTypografhyErr>
								)}
              </Box>
            </StyledGrid>
						<StyledGrid item md={3} xs={12} padding={1} border="1px dashed grey">
						<Box component="div" height="130px" m={2}>
                <strong>
                  2.4) Seleção de Template
                </strong>
                <br />
                &nbsp;
                <br />
                {isLoadingWppTemplates ? (
                  <CircularProgress />
                ) : filteredWppTemplates.length > 0 ? (
									<FormGroup sx={{ gap: 1 }}>
										{filteredWppTemplates.map(template => {
											return (
												<FormControlLabel
													key={template.id}
													value={template.id}
													control={
														<Checkbox
															checked={selectedWppTemplates.includes(template.id)}
															onChange={handleSelectWppTemplate}
														/>
													}
													disabled={!template.validation?.valid}
													label={
														<>
															<Typography variant="body1" lineHeight="1.2">{template.name}</Typography>
															<Typography variant="caption">{template.validation?.message}</Typography>
														</>
													}
												/>
											)
										})}
									</FormGroup>
                ) : selectedCompanyId && (
									<StyledTypografhyErr variant="body1">
										Nenhum template disponível com os filtros selecionados, contate a equipe de suporte.
                  </StyledTypografhyErr>
								)}
              </Box>
            </StyledGrid>
						<StyledGrid item md={2} xs={12} padding={1} border="1px dashed grey">
              {/* <Box component="div" height="90px" m={2}>
                <strong>
                  2.5) Preferências
                </strong>
                <br />
                &nbsp;
                <br />
                <FormGroup>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={useCPC}
                        onChange={handleChangeCPC}
                      />
                    }
                    label="Usar CPC"
                  />
                </FormGroup>
              </Box> */}
              <Box component="div" height="130px" m={2}>
                <strong>
                  2.6) Atendimento
                </strong>
                <br />
                &nbsp;
                <br />
                {isLoadingQueues ? (
                  <CircularProgress />
                ) : queues.length > 0 ? (
									<FormControl>
										<TextField
											select
											name="wpp_chat_queue"
											label="Selecione a fila"
											onChange={handleSelectedQueueChange}
                      value={selectedQueueId}
										>
											{queues?.map(queue => (
												<MenuItemStyled key={queue.id} value={queue.id}>
													{queue.name}
												</MenuItemStyled>
											))}
										</TextField>
									</FormControl>
                ) : (
                  <StyledTypografhyErr variant="body1">
                    {t('create-campaign.tabs.settings.no-queues')}
                  </StyledTypografhyErr>
                )}
                <br />
                &nbsp;
                <br />
                {isLoadingIntegrations ? (
                  <CircularProgress />
                ) : integrations.length > 0 ? (
									<FormControl>
										<TextField
											select
											name="wpp_chat_integration"
											label="Selecione o bot"
											onChange={handleSelectedIntegrationChange}
                      value={selectedIntegrationId}
										>
											{integrations?.map(integration => (
												<MenuItemStyled key={integration.id} value={integration.id}>
													{integration.name}
												</MenuItemStyled>
											))}
										</TextField>
									</FormControl>
                ) : (
                  <StyledTypografhyErr variant="body1">
                    {t('create-campaign.tabs.settings.no-integrations')}
                  </StyledTypografhyErr>
                )}
              </Box>
            </StyledGrid>
            <StyledGrid item md={12} xs={12} padding={1} border="1px dashed grey">
							{selectedWppTemplates.length > 0 && (
								<Box component="div" m={2} mb={5}>
									<strong>Vazão Estimada:</strong> {estimatedRemainingOutput}
								</Box>
							)}
							<Box component="div" m={2}>
                <strong>
                  2.6) Números
                </strong>
                <br />
                &nbsp;
                <br />
								<Box sx={{
									display: 'flex',
									flexWrap: 'wrap',
									gap: 1
								}}>
									{isLoadingWppPhones ? (
										<CircularProgress />
									) : wppPhones.map(phoneNumber => {
										const isSelected = selectedWppPhones.has(phoneNumber.id);

										return (
											<Chip
												sx={{
													height: 'auto',
													padding: '4px',
													borderRadius: '4px',
													'& .MuiChip-label': {
														display: 'block',
														whiteSpace: 'normal',
													},
												}}
												key={phoneNumber.id}
												color="primary"
												variant={isSelected ? "filled" : "outlined"}
												icon={isSelected ? <CheckBox /> : null}
												label={
													<>
														<Typography variant="subtitle2" mb="-4px">
															{phoneNumber.displayNumber}
															<Typography variant="button" ml={1}>{phoneNumber.messagingLimit || "N/A"}</Typography>
														</Typography>
														<Typography variant="caption">{phoneNumber.verifiedName}</Typography>
													</>
												}
											/>
										)
									})}
								</Box>
              </Box>
							<Box component="div" m={2} mt={8}>
                <strong>
                  2.7) WABAs
                </strong>
                <br />
                &nbsp;
                <br />
								<Box sx={{
									display: 'flex',
									flexWrap: 'wrap',
									gap: 1
								}}>
									{isLoadingWabas ? (
										<CircularProgress />
									) : wabas.map(waba => {
										const isSelected = selectedWabas.has(waba.id);

										return (
											<Chip
												sx={{
													height: 'auto',
													padding: '4px',
													borderRadius: '4px',
													'& .MuiChip-label': {
														display: 'block',
														whiteSpace: 'normal',
													},
												}}
												key={waba.id}
												color="primary"
												variant={isSelected ? "filled" : "outlined"}
												icon={isSelected ? <CheckBox /> : null}
												label={
													<Typography variant="body2">
														{waba.name}
													</Typography>
												}
											/>
										)
									})}
								</Box>
              </Box>
            </StyledGrid>
          </>
        )}

        {values.campaign_type === 'RCS' && (
          <>
            <StyledGrid item container md={8} xs={5} border="1px dashed grey">
              <Grid item md={6} padding={2}>
                <Box component="div" height="130px" m={2}>
                  <strong>
                    2.2) {t('create-campaign.tabs.settings.rcs-type')}
                  </strong>
                  <br />
                  &nbsp;
                  <br />
                  <FormControl>
                    <TextField
                      select
                      name="rcs_content_type"
                      label={t(
                        'create-campaign.tabs.settings.rcs-content-type'
                      )}
                      value={values.rcs_content_type}
                      onChange={handleRCSValueChange}
                    >
                      <MenuItemStyled value="TEXT">
                        {t(
                          'create-campaign.tabs.settings.rcs-content-type-text'
                        )}
                      </MenuItemStyled>
                      <MenuItemStyled value="RICHCARD">
                        {t(
                          'create-campaign.tabs.settings.rcs-content-type-richcard'
                        )}
                      </MenuItemStyled>
                      <MenuItemStyled disabled value="CAROUSEL">
                        {t(
                          'create-campaign.tabs.settings.rcs-content-type-carousel'
                        )}
                      </MenuItemStyled>
                      <MenuItemStyled disabled value="FILE">
                        {t(
                          'create-campaign.tabs.settings.rcs-content-type-file'
                        )}
                      </MenuItemStyled>
                    </TextField>
                  </FormControl>
                </Box>
              </Grid>

              {values.rcs_content_type === 'TEXT' && (
                <Grid item md={6} padding={2} borderLeft="1px dashed grey">
                  <Box component="div" height="130px" m={2}>
                    <strong>
                      2.3) {t('create-campaign.tabs.settings.rcs-content')}
                    </strong>
                    <br />
                    &nbsp;
                    <br />
                    <FormControl>
                      <TextField
                        select
                        name="rcs_content_text"
                        label={t(
                          'create-campaign.tabs.settings.rcs-content-text'
                        )}
                        value={values.rcs_content_text}
                        onChange={handleRCSValueChange}
                      >
                        {allHeaders.map((header, index) => {
                          return (
                            <MenuItemStyled key={index} value={index}>
                              {header.value}
                            </MenuItemStyled>
                          );
                        })}
                      </TextField>
                    </FormControl>
                  </Box>
                </Grid>
              )}

              {values.rcs_content_type === 'RICHCARD' && (
                <Grid
                  item
                  md={6}
                  padding={2}
                  borderLeft="1px dashed grey"
                  height="100%"
                  overflow="auto"
                >
                  <Box component="div" m={2}>
                    <strong>
                      2.3) {t('create-campaign.tabs.settings.rcs-content')}
                    </strong>
                    <br />
                    &nbsp;
                    <br />
                    <Box
                      display="flex"
                      flexDirection="column"
                      gap={2}
                      marginBottom={2}
                    >
                      <RadioGroup
                        defaultValue="file_url"
                        name="rcs_content_file_type"
                        value={values.rcs_content_file_type}
                        onChange={handleRCSValueChange}
                        row
                      >
                        <FormControlLabel
                          value="file_url"
                          control={<Radio />}
                          label={t(
                            'create-campaign.tabs.settings.rcs-content-file-type-url'
                          )}
                        />
                        <FormControlLabel
                          value="local_file"
                          control={<Radio />}
                          label={t(
                            'create-campaign.tabs.settings.rcs-content-file-type-local'
                          )}
                        />
                      </RadioGroup>

                      {values.rcs_content_file_type === 'local_file' ? (
                        <>
                          <InputFileBox>
                            <label
                              htmlFor="rich-card-file"
                              style={{
                                background: 'transparent',
                                height: 50,
                                width: '100%',
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'space-between',
                                paddingLeft: 10,
                                paddingRight: 16,
                                cursor: 'pointer',
                              }}
                            >
                              <Typography noWrap variant="body2">
                                {values.rcs_rich_card_file?.file?.name ??
                                  t(
                                    'data-requests.new-request-dialog.fields.file'
                                  )}
                              </Typography>

                              {values.rcs_rich_card_file?.loading ? (
                                <CircularProgress size={24} />
                              ) : (
                                <StyledIcon />
                              )}
                            </label>
                            <input
                              id="rich-card-file"
                              name="rich-card-file"
                              type="file"
                              onChange={handleSelectRichCardFile}
                              style={{ display: 'none' }}
                            />
                          </InputFileBox>

                          {values.rcs_rich_card_file?.error && (
                            <StyledTypografhyErr
                              marginTop={-2}
                              variant="caption"
                            >
                              {t(values.rcs_rich_card_file.error)}
                            </StyledTypografhyErr>
                          )}
                        </>
                      ) : (
                        <FormControl>
                          <TextField
                            select
                            name="rcs_content_file_url"
                            label={t(
                              'create-campaign.tabs.settings.rcs-content-file-url'
                            )}
                            value={values.rcs_content_file_url}
                            onChange={handleRCSValueChange}
                          >
                            {allHeaders.map((header, index) => {
                              return (
                                <MenuItemStyled key={index} value={index}>
                                  {header.value}
                                </MenuItemStyled>
                              );
                            })}
                          </TextField>
                        </FormControl>
                      )}

                      <FormControl>
                        <TextField
                          select
                          name="rcs_content_title"
                          label={t(
                            'create-campaign.tabs.settings.rcs-content-title'
                          )}
                          value={values.rcs_content_title}
                          onChange={handleRCSValueChange}
                        >
                          {allHeaders.map((header, index) => {
                            return (
                              <MenuItemStyled key={index} value={index}>
                                {header.value}
                              </MenuItemStyled>
                            );
                          })}
                        </TextField>
                      </FormControl>

                      <FormControl>
                        <TextField
                          select
                          name="rcs_content_description"
                          label={t(
                            'create-campaign.tabs.settings.rcs-content-description'
                          )}
                          value={values.rcs_content_description}
                          onChange={handleRCSValueChange}
                        >
                          {allHeaders.map((header, index) => {
                            return (
                              <MenuItemStyled key={index} value={index}>
                                {header.value}
                              </MenuItemStyled>
                            );
                          })}
                        </TextField>
                      </FormControl>
                    </Box>
                  </Box>
                </Grid>
              )}
            </StyledGrid>
          </>
        )}

        {values.campaign_type === 'RCS' &&
          ['TEXT', 'RICHCARD'].includes(values.rcs_content_type) && (
            <Grid
              minHeight="150px"
              item
              md={12}
              xs={5}
              padding={3}
              paddingBottom={1}
              border="1px dashed grey"
            >
              <Box
                component="div"
                width="100%"
                height="100%"
                display="flex"
                flexDirection="column"
                overflow="auto"
                padding={1}
              >
                <Box component="div" display="flex" alignItems="center" gap={2}>
                  <strong>
                    2.4) {t('create-campaign.tabs.settings.suggestions')}
                  </strong>

                  <AddSuggestionButton
                    variant="contained"
                    color="secondary"
                    onClick={handleAddSuggestion}
                  >
                    <ButtonText variant="body1">
                      {t(
                        'create-campaign.tabs.settings.buttons.add-suggestion'
                      )}
                    </ButtonText>
                  </AddSuggestionButton>
                </Box>

                <SuggestionsContainer>
                  {values.suggestions?.map((suggestion, index) => (
                    <SuggestionItem key={suggestion.id}>
                      <Box
                        width="100%"
                        display="flex"
                        justifyContent="space-between"
                      >
                        <strong>
                          {t('create-campaign.tabs.settings.suggestion')}{' '}
                          {index + 1}
                        </strong>

                        <CloseIcon
                          onClick={() => handleDeleteSuggestion(suggestion)}
                          color="secondary"
                        />
                      </Box>

                      <FormControl>
                        <TextField
                          select
                          name="type"
                          label={t(
                            'create-campaign.tabs.settings.suggestion-type'
                          )}
                          value={suggestion.type}
                          onChange={handleSuggestionChange(suggestion)}
                        >
                          <MenuItemStyled value="OPEN_URL">
                            {t(
                              'create-campaign.tabs.settings.suggestion-type-url'
                            )}
                          </MenuItemStyled>
                          <MenuItemStyled value="DIAL_PHONE">
                            {t(
                              'create-campaign.tabs.settings.suggestion-type-dial'
                            )}
                          </MenuItemStyled>
                          <MenuItemStyled value="REPLY">
                            {t(
                              'create-campaign.tabs.settings.suggestion-type-reply'
                            )}
                          </MenuItemStyled>
                        </TextField>
                      </FormControl>

                      <FormControl>
                        <TextField
                          select
                          name="title"
                          label={t(
                            'create-campaign.tabs.settings.suggestion-title'
                          )}
                          value={suggestion.title}
                          onChange={handleSuggestionChange(suggestion)}
                        >
                          {allHeaders.map((header, index) => {
                            return (
                              <MenuItemStyled key={index} value={index}>
                                {header.value}
                              </MenuItemStyled>
                            );
                          })}
                        </TextField>
                      </FormControl>

                      <FormControl>
                        <TextField
                          select
                          name="value"
                          label={t(
                            'create-campaign.tabs.settings.suggestion-value'
                          )}
                          value={suggestion.value}
                          onChange={handleSuggestionChange(suggestion)}
                        >
                          {allHeaders.map((header, index) => {
                            return (
                              <MenuItemStyled key={index} value={index}>
                                {header.value}
                              </MenuItemStyled>
                            );
                          })}
                        </TextField>
                      </FormControl>
                    </SuggestionItem>
                  ))}
                </SuggestionsContainer>

                {values.suggestions?.length > 0 && (
                  <Typography
                    variant="body1"
                    sx={{ marginTop: '8px', fontSize: '14px' }}
                  >
                    {t(
                      'create-campaign.tabs.settings.messages.suggestion-title-max-length'
                    )}
                  </Typography>
                )}
              </Box>
            </Grid>
          )}

        {values.campaign_type_ai && (
          <StyledGrid item md={12} xs={5} padding={1} border="1px dashed grey">
            <Box component="div" height="130px" m={2}>
              <strong>
                2.5) {t('create-campaign.tabs.settings.ai-layouts')}
              </strong>
              <br />
              &nbsp;
              <br />
              {isLoadingAiLayouts ? (
                <CircularProgress />
              ) : (
                <FormControl disabled={fileNameInput ? false : true}>
                  <RadioGroup
                    aria-labelledby="demo-radio-buttons-group-label"
                    defaultValue="female"
                    name="radio-buttons-group"
                    onChange={handleAyLayoutChange}
                    value={aiLayoutId}
                  >
                    {aiLayouts?.map(layout => {
                      return (
                        <FormControlLabel
                          key={layout.id}
                          value={layout.id}
                          control={<Radio />}
                          label={`${layout.ai_name} - ${layout.title}`}
                        />
                      );
                    })}
                  </RadioGroup>
                </FormControl>
              )}
            </Box>
          </StyledGrid>
        )}
      </Grid>

			{(values.campaign_type !== 'WHATSAPP' && values.campaign_type !== 'EMAIL') && (
				<Grid container mt={0}>
					<BottomStyledGrid
						item
						md={12}
						xs={5}
						padding={1}
						border="1px dashed grey"
					>
						<Box component="div" height="380px" m={2}>
							<strong>
								2.6) {t('create-campaign.tabs.settings.variables-association')}
							</strong>
							<br />
							&nbsp;
							<br />
							<VariablesStep />
						</Box>
					</BottomStyledGrid>
				</Grid>
			)}
    </>
  );
};

export default SecondStep;
