import { Box, Divider, Grid, styled, Typography } from '@mui/material';

export const Container = styled(Box)(() => ({
  width: '100%',
  overflow: 'auto',

  '& .MuiBackdrop-root': {
    opacity: 0,
  },
}));

export const ContentContainer = styled(Box)(() => ({
  gridArea: 'content',
  width: '100%',
  marginTop: '15px',
}));

export const TopContainer = styled(Box)(({ theme }) => ({
  width: '98%',
  display: 'flex',
  marginLeft: 20,
  marginTop: 100,
  justifyContent: 'space-between',
  alignItems: 'center',

  [theme.breakpoints.down('xl')]: {
    marginTop: 70,
  },
}));

export const FiltersContainer = styled(Box)(() => ({
  width: 730,
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
}));

export const TemplateListContainer = styled(Grid)(() => ({
  width: '100%',
  // marginTop: 50,
  // paddingBottom: 10,
  // marginLeft: 20
}));

export const TemplateListTitles = styled(Grid)(() => ({
  width: '100%',
  display: 'grid',
  gridTemplateColumns: '1fr 1fr 0.65fr 1.35fr',
  alignItems: 'center',
  marginBottom: 20,
}));

export const Title = styled(Typography)(({ theme }) => ({
  margin: 'auto',
  fontWeight: 'bold',
  [theme.breakpoints.down('xl')]: {
    fontSize: 12,
  },
  [theme.breakpoints.up('xl')]: {
    fontSize: 14,
  },
}));

export const DividerLine = styled(Divider)(() => ({
  height: 1,
  backgroundColor: '#ccc',
  width: '100%',
}));

export const NoTemplatesContainer = styled(Box)(() => ({
  width: '100%',
  height: 400,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
}));

export const CircularProgressBox = styled(Box)(({ theme }) => ({
  alignItems: 'center',
  justifyContent: 'center',
  width: '100%',
  height: 280,
  display: 'flex',

  [theme.breakpoints.down('xl')]: {
    height: 230,
  },
}));
