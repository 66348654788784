import * as Yup from 'yup';

const defaultValues = {
	formId: 'campaignForm',
	formField: {
		title: {
			name: 'title',
			requiredErrorMsg: 'Opa! você deixou de dar um nome à sua campanha!',
		},
		senderEmail: {
			name: 'senderEmail',
			requiredErrorMsg: 'Não deixe de selecionar um remetente.',
		},
		filename: {
			name: 'filename',
			requiredErrorMsg:
				'É preciso realizar o upload de um arquivo com os dados necessários.',
		},
		contact_variable_index: {
			name: 'contact_variable_index',
			requiredErrorMsg: 'Não deixe de selecionar as variáveis de contato.',
		},
		template: {
			name: 'template',
			requiredErrorMsg: 'É preciso selecionar um template!',
		},
		start_date: {
			name: 'start_date',
		},
		end_date: {
			name: 'end_date',
		},
		schedule_date: {
			name: 'schedule_date',
			requiredErrorMsg:
				'Ops! Você precisa selecionar o tipo dos disparos, respectivos à campanha.',
		},
	},
};

const {
	formField: {
		title,
		senderEmail,
		filename,
		contact_variable_index,
		template,
		start_date,
		end_date,
		schedule_date,
	},
} = defaultValues;

export default [
	Yup.object().shape({
		[title.name]: Yup.string().required(`${title.requiredErrorMsg}`),
		[senderEmail.name]: Yup.string().required(
			`${senderEmail.requiredErrorMsg}`
		),
	}),
	Yup.object().shape({
		[filename.name]: Yup.string().required(`${filename.requiredErrorMsg}`),
		[contact_variable_index]: Yup.number().required(
			`${contact_variable_index.requiredErrorMsg}`
		),
	}),
	Yup.object().shape({
		[template.name]: Yup.string().required(`${template.requiredErrorMsg}`),
	}),
	Yup.object().shape({
		[start_date.name]: Yup.string().required(`${start_date}`),
		[end_date.name]: Yup.string().required(`${end_date}`),
		[schedule_date.name]: Yup.string().required(`${schedule_date}`)
	}),
];
