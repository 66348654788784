import { AxiosResponse } from 'axios';
import { BASE_URL } from '../../api';
import { request } from '../BASE_REQUEST';

export const getUserInfo = async (
  user_id: number
): Promise<AxiosResponse<any> | undefined> => {
  try {
    const response = await request({
      url: `${BASE_URL}/account/users/${user_id}/`,
      method: 'GET',
    });
    return response;
  } catch (error) {
    return Promise.reject(error);
  }
};
