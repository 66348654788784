import React, { useCallback, useState } from 'react';
import ReactFlow, {
  addEdge,
  Background,
  Controls,
  ReactFlowProvider,
  useEdgesState,
  useNodesState,
  Handle,
  Position,
	MarkerType,
} from 'react-flow-renderer';
import { Close } from "@mui/icons-material"
import './Journey.css';

const occurenceOptions = [
	{ value: '1', label: 'Sem Boleto' },
	{ value: '2', label: 'Alega Pagamento' },
];

const operators = {
	string: {
		label: 'Texto',
		operators: [
			{
				name: 'stringExists',
				label: 'Existe',
				expression:	'!!{{value1}}',
				additionalValues: 0,
			},
			{
				name: 'stringNotExists',
				label: 'Não Existe',
				expression: '!{{value1}}',
				additionalValues: 0,
			},
			{
				name: 'stringEquals',
				label: 'Igual',
				expression: '{{value1}} === {{value2}}',
				additionalValues: 1,
			},
			{
				name: 'stringNotEquals',
				label: 'Diferente',
				expression: '{{value1}} !== {{value2}}',
				additionalValues: 1,
			},
			{
				name: 'stringContains',
				label: 'Contém',
				expression: '{{value1}}.includes({{value2}})',
				additionalValues: 1,
			},
			{
				name: 'stringNotContains',
				label: 'Não Contém',
				expression: '!{{value1}}.includes({{value2}})',
				additionalValues: 1,
			},
		]
	},
	number: {
		label: 'Número',
		operators: [
			{
				name: 'numberEquals',
				label: 'Igual',
				expression: '{{value1}} === {{value2}}',
				additionalValues: 1,
			},
			{
				name: 'numberNotEquals',
				label: 'Diferente',
				expression: '{{value1}} !== {{value2}}',
				additionalValues: 1,
			},
			{
				name: 'numberGreaterThan',
				label: 'Maior que',
				expression: '{{value1}} > {{value2}}',
				additionalValues: 1,
			},
			{
				name: 'numberGreaterThanOrEquals',
				label: 'Maior ou igual a',
				expression: '{{value1}} >= {{value2}}',
				additionalValues: 1,
			},
			{
				name: 'numberLessThan',
				label: 'Menor que',
				expression: '{{value1}} < {{value2}}',
				additionalValues: 1,
			},
			{
				name: 'numberLessThanOrEquals',
				label: 'Menor ou igual a',
				expression: '{{value1}} <= {{value2}}',
				additionalValues: 1,
			},
		]
	},
	boolean: {
		label: 'Booleano',
		operators: [
			{
				name: "booleanIsTrue",
				label: "Verdadeiro",
				expression: "{{value1}} === true",
				additionalValues: 0,
			},
			{
				name: "booleanIsFalse",
				label: "Falso",
				expression: "{{value1}} === false",
				additionalValues: 0,
			},
		]
	},
	date: {
		label: 'Data',
		operators: [
			{
				name: 'dateEquals',
				label: 'Igual',
				expression: '{{value1}} === {{value2}}',
				additionalValues: 1,
			},
			{
				name: 'dateNotEquals',
				label: 'Diferente',
				expression: '{{value1}} !== {{value2}}',
				additionalValues: 1,
			},
			{
				name: 'dateGreaterThan',
				label: 'Depois de',
				expression: '{{value1}} > {{value2}}',
				additionalValues: 1,
			},
			{
				name: "dateGreaterThanOrEquals",
				label: "Depois ou igual a",
				expression: "{{value1}} >= {{value2}}",
				additionalValues: 1,
			},
			{
				name: 'dateLessThan',
				label: 'Antes de',
				expression: '{{value1}} < {{value2}}',
				additionalValues: 1,
			},
			{
				name: "dateLessThanOrEquals",
				label: "Antes ou igual a",
				expression: "{{value1}} <= {{value2}}",
				additionalValues: 1,
			},
		]
	},
}

function getOperator(operatorName) {
	if (!operatorName) return null;
	for (const value of Object.values(operators)) {
		const operator = value.operators.find((op) => op.name === operatorName);
		if (operator) return operator;
	}
}

function generateDecisionExpression(data) {
	const operator = getOperator(data.operator);

	if (!operator) return '';

	const expression = operator.expression;
	const keys = Object.keys(data).filter((key) => key.startsWith('value')).sort();
	const values = keys.map((key) => data[key]);

	const expressionValues = expression.match(/{{value\d+}}/g);
	if (expressionValues.length > values.length) return '';

	return expression.replace(/{{value\d+}}/g, () => values.shift());
}

// Custom node component for "File" (rectangular)
const FileNode = ({ data }) => {
  return (
    <div className="journey-file-node">
      {data.label}
      <Handle type="source" position={Position.Bottom} id="a" />
    </div>
  );
};

// Custom node component for "WhatsApp", "Email", "SMS", and "RCS" (small square)
const DefaultNode = ({ data }) => {
  return (
    <div className="journey-default-node">
			<label>{data.label}</label>
      <div>{data.name || ''}</div>
			<div>{data.costCenter || ''}</div>
      <Handle type="target" position={Position.Top} id="b" />
      <Handle type="source" position={Position.Bottom} id="c" />
      <button className="journey-delete-button" onClick={data.deleteNode}>
				<Close fontSize='12px' sx={{ color: 'white' }} />
			</button>
    </div>
  );
};

// Custom node component for "Date Action"
const DateActionNode = ({ data }) => {
  return (
    <div className="journey-action-node">
      <label><strong>{data.label}</strong></label>
      <div>Coluna: {data.columnIndex || 'Não definido'}</div>
      <div>Dias: {data.daysOffset || 'Não definido'}</div>
      <Handle type="target" position={Position.Top} id="b" />
      <Handle type="source" position={Position.Bottom} id="c" />
      <button className="journey-delete-button" onClick={data.deleteNode}>
				<Close fontSize='12px' sx={{ color: 'white' }} />
			</button>
    </div>
  );
};

// Custom node component for "Ocorrência"
const OccurrenceNode = ({ data }) => {
  return (
    <div className="journey-action-node">
      <label>{data.label}</label>
      <div>{data.selectedOption || ''}</div>
      <Handle type="target" position={Position.Top} id="b" />
      <Handle type="source" position={Position.Bottom} id="c" />
      <button className="journey-delete-button" onClick={data.deleteNode}>
				<Close fontSize='12px' sx={{ color: 'white' }} />
			</button>
    </div>
  );
};

// Custom node component for "Ocorrência"
const DialerDlrNode = ({ data }) => {
  return (
    <div className="journey-action-node">
      <label>{data.label}</label>
      <div>{data.dlr || ''}</div>
      <Handle type="target" position={Position.Top} id="b" />
      <Handle type="source" position={Position.Bottom} id="c" />
      <button className="journey-delete-button" onClick={data.deleteNode}>
				<Close fontSize='12px' sx={{ color: 'white' }} />
			</button>
    </div>
  );
};

const NegotiationPortalNode = ({ data }) => {
	return (
    <div className="journey-action-node">
      <label>{data.label}</label>
      <div>{data.portal || ''}</div>
      <Handle type="target" position={Position.Top} id="b" />
      <Handle type="source" position={Position.Bottom} id="c" />
      <button className="journey-delete-button" onClick={data.deleteNode}>
				<Close fontSize='12px' sx={{ color: 'white' }} />
			</button>
    </div>
	);
}

const HttpRequestNode = ({ data }) => {
	return (
		<div className="journey-action-node">
			<label>{data.label}</label>
			<div>{data.url || ''}</div>
			<Handle type="target" position={Position.Top} id="b" />
			<Handle type="source" position={Position.Bottom} id="c" />
			<button className="journey-delete-button" onClick={data.deleteNode}>
				<Close fontSize='12px' sx={{ color: 'white' }} />
			</button>
		</div>
	);
}

const DecisionNode = ({ data }) => {
  return (
    <div className="journey-flow-node">
      <label>{data.label}</label>
			<pre>{data.expression || ''}</pre>
			<Handle type="target" position={Position.Top} id="b" />
			<Handle type="source" position={Position.Bottom} id="c" />
			<button className="journey-delete-button" onClick={data.deleteNode}>
				<Close fontSize='12px' sx={{ color: 'white' }} />
			</button>
    </div>
  )
}

const nodeTypes = {
  fileNode: FileNode,
  defaultNode: DefaultNode,
  dateActionNode: DateActionNode,
  occurrenceNode: OccurrenceNode,
	dialerDlrNode: DialerDlrNode,
	negotiationPortal: NegotiationPortalNode,
	httpRequestNode: HttpRequestNode,
	decisionNode: DecisionNode,
};

let id = 1;
const getId = () => `node_${id++}`;

function Journey() {
	const [isSidebarOpen, setSidebarOpen] = useState(false);
  const [selectedNode, setSelectedNode] = useState(null);

  const initialNodes = [
    {
      id: '1',
      type: 'fileNode',
      data: { label: 'Arquivo' },
      position: { x: 50, y: 50 }, // Position the file node in the upper left corner
    },
  ];

  const [nodes, setNodes, onNodesChange] = useNodesState(initialNodes);
  const [edges, setEdges, onEdgesChange] = useEdgesState([]);

  const onConnect = useCallback(
    (params) => {
      // Verificar se a conexão é válida antes de adicioná-la
      const sourceNode = nodes.find((node) => node.id === params.source);
      const targetNode = nodes.find((node) => node.id === params.target);

      if (targetNode.type === 'occurrenceNode' && sourceNode.data.label !== 'WhatsApp') {
        alert('A ação "Ocorrência" só pode ser conectada ao nó "WhatsApp".');
        return;
      }

      setEdges((eds) => addEdge({
				...params,
				markerEnd: {
					type: MarkerType.ArrowClosed
				}
			}, eds));
    },
    [nodes, setEdges]
  );

  const deleteNode = (nodeId) => {
    setNodes((nds) => nds.filter((node) => node.id !== nodeId));
    setEdges((eds) => eds.filter((edge) => edge.source !== nodeId && edge.target !== nodeId));
  };

  const addNode = (type) => {
    const newNodeId = getId();
    const newNode = {
      id: newNodeId,
      type:
        type === 'Date Action'
          ? 'dateActionNode'
          : type === 'Ocorrência'
            ? 'occurrenceNode'
						: type === 'DLR do Discador'
						? 'dialerDlrNode'
						: type === 'Portal de Negociação'
						? 'negotiationPortal'
						: type === 'Requisição HTTP'
						? 'httpRequestNode'
						: type === 'Decisão'
						? 'decisionNode'
						: 'defaultNode',

      data: {
        label: type,
        deleteNode: () => deleteNode(newNodeId), // Passando o ID do nó corretamente
      },
      position: { x: 50, y: 50 },
    };
    setNodes((nds) => [...nds, newNode]);
		setSelectedNode(newNode);
		setSidebarOpen(true);
  };

	const onNodeClick = (event, node) => {
		if (!renderFieldsForNodeType(node.type)) return;
    setSelectedNode(node);
    setSidebarOpen(true);
  };

  const closeSidebar = () => {
    setSelectedNode(null);
    setSidebarOpen(false);
  };

	const updateNodeProperties = (key, value) => {
		setNodes((nds) =>
			nds.map((n) =>
				n.id === selectedNode.id
					? { ...n, data: { ...n.data, [key]: value } }
					: n
			)
		);
	};

	const updateDecisionNodeProperties = (key, value) => {
		const node = nodes.find((n) => n.id === selectedNode.id);

		const updatedNode = {
			...node,
			data: {
				...node.data,
				[key]: value,
			},
		};

		const expression = generateDecisionExpression(updatedNode.data);
		updatedNode.data.expression = expression;

		const updatedNodes = nodes.map((n) => (n.id === selectedNode.id ? updatedNode : n));

		setNodes(updatedNodes);
	}

	const renderFieldsForNodeType = (type) => {
		const nodeProperties = nodes.find((n) => n.id === selectedNode?.id)?.data || {};

		switch (type) {
			case 'defaultNode':
				return (
					<>
						<label>Nome</label>
						<input
							type="text"
							placeholder="Digite o nome da campanha"
							value={nodeProperties.name || ""}
							onChange={(e) => updateNodeProperties('name', e.target.value)}
						/>
						<label>Centro de Custo</label>
						<select
							value={nodeProperties.costCenter || ""}
							onChange={(e) => updateNodeProperties('costCenter', e.target.value)}
						>
							<option value="">Selecione um Centro de Custo</option>
							<option value="Pernambucanas">Pernambucanas</option>
							<option value="Bradesco">Bradesco</option>
							<option value="Itapeva">Itapeva</option>
							<option value="Afinz Sorocred">Afinz Sorocred</option>
							<option value="BTG Digital">BTG Digital</option>
						</select>
					</>
				);
			case 'dateActionNode':
				return (
					<>
						<label>Coluna</label>
						<input
							type="number"
							placeholder="Coluna"
							value={nodeProperties.columnIndex || ""}
							onChange={(e) => updateNodeProperties('columnIndex', e.target.value)}
						/>
						<label>Dias</label>
						<input
							type="number"
							placeholder="Dias (ex: -7)"
							value={nodeProperties.daysOffset || ""}
							onChange={(e) => updateNodeProperties('daysOffset', e.target.value)}
						/>
					</>
				);
			case 'occurrenceNode':
				return (
					<>
						<label>Selecione Ação</label>
						<select
							value={nodeProperties.selectedOption || ""}
							onChange={(e) => updateNodeProperties('selectedOption', e.target.value)}
						>
							<option value="">Selecione...</option>
							<option value="Sem Boleto">Sem Boleto</option>
							<option value="Alega Pagamento">Alega Pagamento</option>
						</select>
					</>
				);
			case 'dialerDlrNode':
				return (
					<>
						<label>DLR</label>
						<input
							type="text"
							placeholder="DLR"
							value={nodeProperties.dlr || ""}
							onChange={(e) => updateNodeProperties('dlr', e.target.value)}
						/>
					</>
				);
			case 'negotiationPortal':
				return (
					<>
						<label>Portal</label>
						<input
							type="text"
							placeholder="Portal"
							value={nodeProperties.portal || ""}
							onChange={(e) => updateNodeProperties('portal', e.target.value)}
						/>
					</>
				);
			case 'httpRequestNode':
				return (
					<>
						<label>URL</label>
						<input
							type="text"
							placeholder="URL"
							value={nodeProperties.url || ""}
							onChange={(e) => updateNodeProperties('url', e.target.value)}
						/>
						<label>Method</label>
						<select
							value={nodeProperties.method || ""}
							onChange={(e) => updateNodeProperties('method', e.target.value)}
						>
							<option value="">Selecione um método</option>
							<option value="GET">GET</option>
							<option value="POST">POST</option>
							<option value="PUT">PUT</option>
							<option value="DELETE">DELETE</option>
						</select>
						<label>Headers</label>
						<textarea
							placeholder="Headers"
							value={nodeProperties.headers || ""}
							onChange={(e) => updateNodeProperties('headers', e.target.value)}
						/>
						<label>Body</label>
						<textarea
							placeholder="Body"
							value={nodeProperties.body || ""}
							onChange={(e) => updateNodeProperties('body', e.target.value)}
						/>
					</>
				);
			case 'decisionNode':
				return (
					<>
						<label>Valor1</label>
						<input
							type="text"
							placeholder="Valor1"
							value={nodeProperties.value1 || ""}
							onChange={(e) => updateDecisionNodeProperties('value1', e.target.value)}
						/>
						<label>Operador</label>
						<select
							value={nodeProperties.operator || ""}
							onChange={(e) => updateDecisionNodeProperties('operator', e.target.value)}
						>
							<option value="">Selecione um tipo</option>
							{Object.entries(operators).map(([key, value]) => (
								<optgroup key={key} label={value.label}>
									{value.operators.map((operator) => (
										<option key={operator.name} value={operator.name}>{operator.label}</option>
									))}
								</optgroup>
							))}
						</select>
						{new Array(getOperator(nodeProperties.operator)?.additionalValues || 0).fill().map((_, index) => {
							const valueNumber = index + 2;

							return (
								<div key={`key${valueNumber}`}>
									<label>Valor{valueNumber}</label>
									<input
										type="text"
										placeholder={`Valor${valueNumber}`}
										value={nodeProperties[`value${valueNumber}`] || ""}
										onChange={(e) => updateDecisionNodeProperties(`value${valueNumber}`, e.target.value)}
									/>
								</div>
							)
						})}
					</>
				);
			default:
				return null;
		}
	};

  return (
    <ReactFlowProvider>
      <div className="journey-dndflow">
        <div className="journey-toolbar">
          <div className="journey-section">
						<h3>Campanhas</h3>
						<button className="journey-add-button" onClick={() => addNode('Email')}>Email</button>
						<button className="journey-add-button" onClick={() => addNode('WhatsApp')}>WhatsApp</button>
						<button className="journey-add-button" onClick={() => addNode('SMS')}>SMS</button>
						<button className="journey-add-button" onClick={() => addNode('RCS')}>RCS</button>
					</div>
					<div className="journey-section">
						<h3>Ações</h3>
						<button className="journey-add-button" onClick={() => addNode('Date Action')}>Date Action</button>
						<button className="journey-add-button" onClick={() => addNode('Ocorrência')}>Ocorrência</button>
						<button className="journey-add-button" onClick={() => addNode('DLR do Discador')}>DLR do Discador</button>
						<button className="journey-add-button" onClick={() => addNode('Portal de Negociação')}>Portal de Negociação</button>
						<button className="journey-add-button" onClick={() => addNode('Requisição HTTP')}>Requisição HTTP</button>
					</div>
					<div className="journey-section">
						<h3>Fluxo</h3>
						<button className="journey-add-button" onClick={() => addNode('Decisão')}>Decisão</button>
					</div>
        </div>
        <div className="journey-flow">
          <ReactFlow
            nodes={nodes}
            edges={edges}
            onNodesChange={onNodesChange}
            onEdgesChange={onEdgesChange}
            onConnect={onConnect}
            nodeTypes={nodeTypes}
						onNodeClick={onNodeClick}
						fitView
          >
            <Background />
            <Controls />
          </ReactFlow>
        </div>
				{isSidebarOpen && (
          <div className="journey-sidebar">
            <button className="journey-sidebar-close" onClick={closeSidebar}>
							<Close fontSize='12px' sx={{ color: 'white' }} />
						</button>
            {selectedNode && (
              <div>
                <h3>Editar Node</h3>
								{renderFieldsForNodeType(selectedNode.type)}
              </div>
            )}
          </div>
        )}
      </div>
    </ReactFlowProvider>
  );
}

export default Journey;
