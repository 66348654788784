import { Box } from '@mui/material';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import { Bar } from 'react-chartjs-2';
import { IEmail_Events } from '../interfaces';

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

interface IData {
  labels: string[];
  datasets: any[];
}

export const VerticarBarChartComponent: React.FC<IEmail_Events> = ({
  email_events,
}: IEmail_Events): JSX.Element => {
  const options = {
    responsive: true,
    plugins: {
      legend: {
        position: 'top' as const,
      },
      title: {
        display: true,
        text: 'Sumário das campanhas de e-mail',
      },
    },
  };
  const labels = ['Resumo dos eventos referentes às campanhas de e-mail'];

  const data: IData = {
    labels,
    datasets: [],
  };

  function generateRandomColor() {
    const randomColor = '#' + Math.floor(Math.random() * 16777215).toString(16);
    return randomColor;
  }

  for (const obj in email_events) {
    data.datasets.push({
      label: obj,
      data: [email_events[obj]],
      backgroundColor: generateRandomColor(),
    });
  }

  return (
    <Box sx={{ marginTop: 2.5 }}>
      <Bar options={options} data={data} />
    </Box>
  );
};
