import { UserInfo } from './interface';
import SVGImage from '../../../../assets/images/undraw_add_information_j2wg.svg';
import {
  AvatarContainer,
  Container,
  UserInfoInnerContainer,
  UserInfoText,
  UserInfoTitle,
} from './styles';

export const UserInfosContainer: React.FC<UserInfo> = ({
  user,
  plan,
  newBalance,
}: UserInfo): JSX.Element => (
  <>
    <Container>
      <UserInfoInnerContainer>
        <UserInfoTitle variant="body2">Nome: </UserInfoTitle>
        <UserInfoText>{user.name ? user.name : 'Não informado'}</UserInfoText>
      </UserInfoInnerContainer>
      <UserInfoInnerContainer>
        <UserInfoTitle variant="body2">E-mail: </UserInfoTitle>
        <UserInfoText>{user.email}</UserInfoText>
      </UserInfoInnerContainer>
      <UserInfoInnerContainer>
        <UserInfoTitle variant="body2">ID: </UserInfoTitle>
        <UserInfoText>{user.id}</UserInfoText>
      </UserInfoInnerContainer>
      <UserInfoInnerContainer>
        <UserInfoTitle variant="body2">Plano ativo:</UserInfoTitle>
        <UserInfoText>{plan.active ? 'Sim' : 'Não'}</UserInfoText>
      </UserInfoInnerContainer>
      <UserInfoInnerContainer>
        <UserInfoTitle variant="body2">Plano: </UserInfoTitle>
        <UserInfoText>
          {plan.account_type === 'POST_PAID' ? 'Pós-pago' : 'Pré-pago'}
        </UserInfoText>
      </UserInfoInnerContainer>
      <UserInfoInnerContainer>
        <UserInfoTitle variant="body2">Taxa por SMS: </UserInfoTitle>
        <UserInfoText>{plan.sms_tax}</UserInfoText>
      </UserInfoInnerContainer>
      <UserInfoInnerContainer>
        <UserInfoTitle variant="body2">Taxa por E-mail: </UserInfoTitle>
        <UserInfoText>{plan.email_tax}</UserInfoText>
      </UserInfoInnerContainer>
      <UserInfoInnerContainer>
        <UserInfoTitle variant="body2">Saldo: </UserInfoTitle>
        <UserInfoText>
          {newBalance
            ? newBalance.toLocaleString('pt-br', {
                style: 'currency',
                currency: 'BRL',
              })
            : Number(plan.balance).toLocaleString('pt-br', {
                style: 'currency',
                currency: 'BRL',
              })}
        </UserInfoText>
      </UserInfoInnerContainer>
      {/* <UserInfoInnerContainer>
        <UserInfoTitle variant="body2">Administrador: </UserInfoTitle>
        <UserInfoText>{user.is_staff ? 'Sim' : 'Não'}</UserInfoText>
      </UserInfoInnerContainer> */}

      <AvatarContainer>
        <img src={SVGImage} alt="" width={'60%'} />
      </AvatarContainer>
    </Container>
  </>
);
