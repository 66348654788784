import { AxiosResponse } from 'axios';
import { BASE_URL } from '../../api';
import { request } from '../BASE_REQUEST';

export const cancelCampaign = async campaign_id => {
  try {
    const response = await request({
      url: `${BASE_URL}/sms/campaigns/${campaign_id}/cancel/`,
      method: 'PATCH',
      data: {
        success: true,
      },
    });

    return response;
  } catch (error) {
    return Promise.reject(error);
  }
};
