import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';

import enUS from './locales/en-US.json';
import ptBR from './locales/pt-BR.json';

const resources = {
	'en': {
		translation: enUS,
	},
	'pt': {
		translation: ptBR,
	},
};

i18n
	.use(LanguageDetector)
	.use(initReactI18next)
	.init({
		debug: true,
		resources,
		fallbackLng: 'pt',
		interpolation: {
			escapeValue: false,
		},
	});

export default i18n;
