import { AppBar, Box, IconButton, styled, Typography } from '@mui/material';

export const IconArrowStyled = styled(IconButton)(({ theme }) => ({
  [theme.breakpoints.down('xl')]: {
    marginTop: -10,
  },
}));

export const BoxFirstButtons = styled(Box)(() => ({
  display: 'flex',
  width: '30%',
  justifyContent: 'space-between',
}));

export const BoxSecondButtons = styled(Box)(() => ({
  display: 'flex',
  width: '56%',
  justifyContent: 'flex-end',
  alignItems: 'center',
}));

export const AppBarStyled = styled(AppBar)(({ theme }) => ({
  backgroundColor: '#2E3192',

  [theme.breakpoints.down('xl')]: {
    height: 45,
    position: 'sticky',
  },
}));

export const BoxButtonCode = styled(Box)(({ theme }) => ({
  display: 'flex',
  color: '#fff',
  justifyContent: 'center',
  border: 'none',
  marginTop: '15px',
  borderTopLeftRadius: '8px',
  borderTopRightRadius: '8px',
  width: '100px',
  height: '52px',
  backgroundColor: '#777AD1',

  [theme.breakpoints.down('xl')]: {
    marginTop: '8px',
    width: '80px',
  },
}));

export const ButonCodeText = styled(Typography)(({ theme }) => ({
  color: 'white',

  [theme.breakpoints.down('xl')]: {
    fontSize: 13,
    marginTop: -10,
  },
}));

export const ButtonCodeStyled = styled(Typography)(() => ({
  alignSelf: 'center',
  textTransform: 'initial',
}));

export const BoxPositionButtons = styled(Box)(() => ({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  width: '100%',
}));

export const ButtonSave = styled(Box)(({ theme }) => ({
  display: 'flex',
  color: '#fff',
  justifyContent: 'center',
  border: 'none',
  marginTop: '15px',
  borderTopLeftRadius: '8px',
  borderTopRightRadius: '8px',
  width: '100px',
  height: '52px',
  textTransform: 'initial',
  transition: 'all 0.3s ease',
  cursor: 'pointer',

  [theme.breakpoints.down('xl')]: {
    fontSize: 13,
    marginTop: '8px',
    width: '80px',
  },

  '&:hover': {
    backgroundColor: theme.palette.secondary.main,
    color: '#ffff',
  },
}));

export const SaveText = styled(Typography)(({ theme }) => ({
  alignSelf: 'center',
  textTransform: 'initial',

  [theme.breakpoints.down('xl')]: {
    fontSize: 13,
    marginTop: -10,
  },
}));

export const IconButtonStyledEnd = styled(IconButton)(({ theme }) => ({
  backgroundColor: '#e0e0e0',
  marginRight: '15px',

  '&:hover': {
    backgroundColor: theme.palette.secondary.main,
    color: '#ffff',
  },
}));

export const ButtonsUndoRedoStyled = styled(Box)(({ theme }) => ({
  display: 'grid',
  justifyContent: 'center',

  '&:active': {
    color: theme.palette.secondary.main,
  },
}));

export const IconButtonStyled = styled(IconButton)<{
  isButtonSelected: boolean;
}>(({ isButtonSelected, theme }) => ({
  color: isButtonSelected ? theme.palette.secondary.main : '#C2C2C2',

  [theme.breakpoints.down('xl')]: {
    marginTop: -10,

    '& .MuiSvgIcon-root': {
      fontSize: 22,
    },
  },
}));

export const TemplateText = styled(Typography)(({ theme }) => ({
  [theme.breakpoints.down('xl')]: {
    marginTop: -10,
    fontSize: 13,
  },
}));
