import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useSnackbar } from 'notistack';
import { CircularProgress, TextareaAutosize, Typography } from '@mui/material';
import Container from '../../components/Container';
import { useParams } from 'react-router-dom';

import getAiLayoutsDetails from '../../utils/api/GET/getAiLayoutsDetails';

export default function AILayoutsDetails() {
  const { t } = useTranslation();
  const [planConditions, setPlanConditions] = useState([]);
  const [loading, setLoading] = useState(false);
  const { enqueueSnackbar } = useSnackbar();

  let { aiUserLayoutId } = useParams();

  useEffect(async () => {
    try {
      setLoading(true);
      const result = await getAiLayoutsDetails({
        ai_user_layout_id: aiUserLayoutId,
      });
      if (result.status != 200) {
        enqueueSnackbar(t('ai-layout-details.messages.get-layout-error'), {
          variant: 'error',
          autoHideDuration: 5000,
        });
      } else {
        setPlanConditions(result.data);
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);

      enqueueSnackbar(t('ai-layout-details.messages.get-layout-error'), {
        variant: 'error',
        autoHideDuration: 5000,
      });
    }
  }, []);

  return (
    <Container>
      <div>
        {loading ? (
          <CircularProgress size={20} />
        ) : (
          planConditions.map(planCondition => {
            return (
              <div
                key={planCondition.id}
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  width: '100%',
                }}
              >
                <Typography style={{ marginTop: '20px' }} variant="h3">
                  {planCondition.title}
                </Typography>
                <Typography style={{ marginTop: '20px' }} variant="subtitle2">
                  {t('ai-layout-details.fields.negotiation')}
                </Typography>
                <TextareaAutosize disabled style={{ padding: '10px' }}>
                  {planCondition.content}
                </TextareaAutosize>
                <Typography style={{ marginTop: '20px' }} variant="subtitle2">
                  {t('ai-layout-details.fields.variables')}
                </Typography>
                <TextareaAutosize disabled style={{ padding: '10px' }}>
                  {planCondition.condition_variables.join(', ')}
                </TextareaAutosize>
              </div>
            );
          })
        )}
      </div>
    </Container>
  );
}
