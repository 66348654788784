import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { useTranslation } from 'react-i18next';
import { IPopMenu } from './interfaces';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { TextStyled } from './styles';
import { ISideNav } from '../../../Auth/interfaces/side-nav.interface';
import { addNav } from '../../../Auth/actions/side-nav.action';

const PopMenuAI: React.FC<IPopMenu> = ({
  open,
  anchorEl,
  setAnchorEl,
}: IPopMenu): JSX.Element => {
	const { t } = useTranslation();
  const history = useHistory();
  const dispatch = useDispatch();

  const setIndexOfSideNavButton = (sideNav: ISideNav) => {
    dispatch(addNav(sideNav));
  };

  const handleNavigation = (route: string): void => {
    setAnchorEl(null);
    history.push(route);
    setIndexOfSideNavButton({ buttonIndex: 2, showSide: true });
  };

  return (
    <div>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={() => setAnchorEl(null)}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
      >
        {/* <MenuItem onClick={() => handleNavigation('/ai-layouts')}>
          <TextStyled>{t('layout.submenu.nexus-ai.layouts')}</TextStyled>
        </MenuItem>

        <MenuItem onClick={() => handleNavigation('/ai-tabs')}>
          <TextStyled>{t('layout.submenu.nexus-ai.tabs')}</TextStyled>
        </MenuItem> */}
      </Menu>
    </div>
  );
};

export default PopMenuAI;
