import { TemplateAction } from '../actions/template.action';
import { ADD_TEMPLATE } from '../constants/template.constant';
import { ITemplate } from '../interfaces/template.interface';

const initialState: ITemplate = {
  body: '',
  subject: '',
  content: '',
  type: 'EMAIL',
  id: '',
  name: '',
};

const TemplateReducer = (
  state: ITemplate = initialState,
  action: TemplateAction
): ITemplate => {
  switch (action.type) {
    case ADD_TEMPLATE: {
      const { body, subject, content, type, id, name } = action.payload;

      return {
        ...state,
        body: body ?? state.body,
        subject: subject ?? state.subject,
        content: content ?? state.content,
        type: type ?? state.type,
        id: id ?? state.id,
        name: name ?? state.name,
      };
    }
    default:
      return state;
  }
};

export default TemplateReducer;
