import { useState, useEffect } from 'react';
import { useHistory } from 'react-router';
import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import IconButton from '@mui/material/IconButton';
import LanguageButtons from '../../components/LanguageButtons';
import { addNav } from '../../Auth/actions/side-nav.action';
import { removeAuth } from '../../Auth/actions/auth.action';
import { PopMenu } from './PopMenu';
import PopMenuCampaign from './PopMenu/PopMenuCampaign';
import PopMenuAI from './PopMenu/PopMenuAI';
import PopMenuData from './PopMenu/PopMenuData';
import Logo from '../../assets/images/nexus-logo.jpeg';
import Rotas from '../../Routes';
import { menuItens, menuSair } from './constants';
import { ISideNav } from '../../Auth/interfaces/side-nav.interface';
import { IItem } from './types';
import { Box, Grid } from '@mui/material';
import { IAuth } from '../../Auth/interfaces/auth.interface';
import { useSelector } from 'react-redux';
import { IReducers } from '../../Auth/store/store';

import Fab from '@mui/material/Fab';
import AddIcon from '@mui/icons-material/Add';
import PsychologyIcon from '@mui/icons-material/Psychology';
import FolderSharedIcon from '@mui/icons-material/FolderShared';
import AccountTreeIcon from '@mui/icons-material/AccountTree';

const LayoutNew = (): JSX.Element => {
  // const [openBar, setOpenBar] = useState(false);
  const { t } = useTranslation();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [campaignAnchorEl, setcampaignAnchorEl] = useState<null | HTMLElement>(
    null
  );
  const [aiAnchorEl, seAiAnchorEl] = useState<null | HTMLElement>(null);
  const [dataAnchorEl, setDataAnchorEl] = useState<null | HTMLElement>(null);
  const history = useHistory();
  const dispatch = useDispatch();
  const open = Boolean(anchorEl);
  const campaignOpen = Boolean(campaignAnchorEl);
  const aiOpen = Boolean(aiAnchorEl);
  const dataOpen = Boolean(dataAnchorEl);
  const location = useLocation();
  const [useAddBtn, setUseAddBtn] = useState(true);
  // const { name }: IAuth = useSelector((state: IReducers) => state.auth);
  const { hasAI, hasData, hasJourney }: IAuth = useSelector(
    (state: IReducers) => state.auth
  );
  const [newMenuItems, setNewMenuItems] = useState(menuItens);

  useEffect(() => {
    // if (hasData) {
    //   setNewMenuItems(prev => [
    //     ...prev,
    //     {
    //       id: 'filter',
    //       label: 'layout.menu.filter',
    //       icon: <FolderSharedIcon />,
    //       url: '/data-requests',
    //     },
    //   ]);
    // }
    // if (hasAI) {
    //   setNewMenuItems(prev => [
    //     ...prev,
    //     {
    //       id: 'nexus-ai',
    //       label: 'layout.menu.nexus-ai',
    //       icon: <PsychologyIcon />,
    //       url: '/nexus-ia',
    //     },
    //   ]);
    // }
		if (hasJourney) {
			setNewMenuItems(prev => [
				...prev,
				{
					id: "journey",
					label: 'layout.menu.journey',
					icon: <AccountTreeIcon />,
					url: '/journey',
				},
			]);
		}
  }, []);

  useEffect(() => {
    if (
      location.pathname === '/campaing' ||
      location.pathname === '/user' ||
      location.pathname === '/main-users' ||
			location.pathname === '/journey'
    ) {
      setUseAddBtn(false);
    } else {
      setUseAddBtn(true);
    }
  }, [location]);

  const handleOpenMenu = (
    event: React.MouseEvent<HTMLDivElement, MouseEvent>
  ) => {
    setAnchorEl(event.currentTarget);
  };

  const handleCampaignOpenMenu = (
    event: React.MouseEvent<HTMLDivElement, MouseEvent>
  ) => {
    setcampaignAnchorEl(event.currentTarget);
  };

  const handleAiOpenMenu = (
    event: React.MouseEvent<HTMLDivElement, MouseEvent>
  ) => {
    seAiAnchorEl(event.currentTarget);
  };

  const handleOpenDataMenu = (
    event: React.MouseEvent<HTMLDivElement, MouseEvent>
  ) => {
    setDataAnchorEl(event.currentTarget);
  };

  const clearAuth = (auth: IAuth): void => {
    dispatch(removeAuth(auth));
  };

  const setIndexOfSideNavButton = (sideNav: ISideNav) => {
    dispatch(addNav(sideNav));
  };

  const handleClickButton = (
    index: number,
    item: IItem,
    event: React.MouseEvent<HTMLDivElement, MouseEvent>
  ) => {
    if (item.id === 'logout') {
      clearAuth({
        access: undefined,
        name: undefined,
        is_staff: false,
        user_id: undefined,
      });
      setIndexOfSideNavButton({ showSide: true, buttonIndex: 0 });
      history.push(item.url ?? '');
    } else if (item.id === 'settings') {
      handleOpenMenu(event);
    } else if (item.id === 'nexus-ai') {
      handleAiOpenMenu(event);
    } else if (item.id === 'filter') {
      handleOpenDataMenu(event);
    } else if (item.id === 'campaign') {
      handleCampaignOpenMenu(event);
    } else if (item.id === 'dashboard') {
      setIndexOfSideNavButton({
        showSide: true,
        buttonIndex: 0,
      });
      history.push(item.url ?? '');
    } else {
      setIndexOfSideNavButton({ showSide: true, buttonIndex: 1 });
      history.push(item.url ?? '');
    }
  };

  return (
    <Grid container direction={'column'} sx={{ minHeight: '100vh' }}>
      {useAddBtn && (
        <Fab
          onClick={() => history.push('/campaing')}
          sx={{
            position: 'fixed',
            bottom: '50px',
            right: '150px',
            zIndex: '99999',
          }}
          color="primary"
          aria-label="add"
        >
          <AddIcon style={{ color: 'white' }} />
        </Fab>
      )}
      {/* <Typography
				color="white" sx={{position: 'fixed', left: '140px', top: '20px'}}
				variant="h4"
			> {name}</Typography> */}
      <Grid container item sx={{ backgroundColor: 'black' }}>
        <Grid
          container
          item
          xs={1}
          sx={{ maxHeight: '65px', paddingLeft: '15px' }}
          alignItems={'center'}
        >
          <img src={Logo} alt={'Nexbox'} style={{ maxHeight: '70%' }} />
        </Grid>

        <Grid container item xs={9} justifyContent={'center'} color={'white'}>
          {/*Popup de configurações*/}
          <PopMenu anchorEl={anchorEl} setAnchorEl={setAnchorEl} open={open} />
          <PopMenuCampaign
            anchorEl={campaignAnchorEl}
            setAnchorEl={setcampaignAnchorEl}
            open={campaignOpen}
          />
          <PopMenuAI
            anchorEl={aiAnchorEl}
            setAnchorEl={seAiAnchorEl}
            open={aiOpen}
          />
          <PopMenuData
            anchorEl={dataAnchorEl}
            setAnchorEl={setDataAnchorEl}
            open={dataOpen}
          />

          {newMenuItems.map((item: IItem, index) => (
            <ListItem
              button
              key={index}
              onClick={e => handleClickButton(index, item, e)}
              sx={{
                padding: 0,
                fontSize: '12px',
                width: 'auto',
                p: '0 25px 0 25px',
                color: 'white',
              }}
            >
              <ListItemIcon
                sx={{
                  minWidth: '40px',
                  color: 'white',
                }}
              >
                <IconButton
                  color="secondary"
                  sx={{
                    filter: 'brightness(0) invert(1)',
                  }}
                >
                  {item.icon}
                </IconButton>
              </ListItemIcon>
              <ListItemText primary={t(item.label)} />
            </ListItem>
          ))}
        </Grid>

        <Grid
          container
          item
          xs={2}
          sx={{ paddingRight: '15px', color: 'white' }}
          justifyContent="end"
          color="text.secondary"
          display="flex"
          flexDirection="row"
        >
          <Box
            display="flex"
            justifyContent="start"
            alignItems="center"
            marginRight="16px"
            marginTop="2px"
          >
            <LanguageButtons />
          </Box>
          <Box
            display="flex"
            justifyContent="end"
            alignItems="center"
            onClick={e => handleClickButton(-1, menuSair, e)}
          >
            <IconButton
              color="secondary"
              sx={{
                filter: 'brightness(0) invert(1)',
              }}
            >
              {menuSair.icon}
            </IconButton>
            {t(menuSair.label)}
          </Box>
        </Grid>
      </Grid>
      <Grid container item sx={{ minHeight: 'calc(100vh - 65px)' }}>
        <Rotas />
      </Grid>
    </Grid>
  );
};

export default LayoutNew;
