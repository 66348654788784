import {
  Button,
  CircularProgress,
  FormControl,
  InputAdornment,
  Typography,
} from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { ICampaing } from '../../../Auth/interfaces/campaing.interface';
import { IReducers } from '../../../Auth/store/store';
// import { Field, useFormikContext } from 'formik';

import {
  CAMPAING_FILE_TYPE_ACCEPTEDS,
  CAMPAING_FILE_TYPE_DEFAULT,
} from '../../../shared/constants/campaing.constants';

import { useState } from 'react';
import { addCampaing } from '../../../Auth/actions/campaing.action';
// import { HeadersContextProps } from '../../Stepper/SecondStep/Interfaces';
import {
  checkFileTypeAccepted,
  convertXslxToCsv,
  getFileType,
  // parseCsv
} from '../../../utils/FileHelper';

import {
  StyledGridContainer,
  InputFileBox,
  StyledIcon,
  StyledTypografhyErr,
  ButtonInputFile,
  TextButtonUpload,
  StyledInputFile,
} from './styles';

const InputFile: React.FC = (): JSX.Element => {
  const { fileStore, filename }: ICampaing = useSelector(
    (state: IReducers) => state.campaing
  );

  const fileTypeAccepteds: string[] = CAMPAING_FILE_TYPE_ACCEPTEDS;
  const fileTypeDefault: string = CAMPAING_FILE_TYPE_DEFAULT;

  const [fileNameInput, setFileNameInput] = useState(
    filename ? filename : 'Selecione um arquivo'
  );
  const [file, setFile] = useState();
  const [isErr, setErr] = useState(false);
  const [loading, setLoading] = useState(false);
  const [fileLoaded, setFileLoaded] = useState<any>(fileStore);
  console.log(fileLoaded);

  const dispatch = useDispatch();

  const setFileValue = (file: any): void => {
    dispatch(addCampaing({ fileStore: file }));
  };

  const setInputFileName = (campaing: ICampaing): void => {
    dispatch(addCampaing(campaing));
  };

  const setReduxValue = (campaign: ICampaing): void => {
    dispatch(addCampaing(campaign));
  };

  const fileChanged = (): void => {
    if (file) {
      setLoading(true);
      setTimeout(() => {
        readFile(file);
      }, 1000);
    }
  };

  const readFile = (file: File) => {
    const fileType = getFileType(file.name);
    const fileName = file.name;
    // setFieldValue('filename', fileName.toLowerCase());

    if (checkFileTypeAccepted(fileType, fileTypeAccepteds)) {
      const reader = new FileReader();
      reader.readAsBinaryString(file);
      reader.onload = e => {
        if (e?.target?.result) {
          const fileReaded =
            fileType === fileTypeDefault
              ? e.target.result
              : convertXslxToCsv(e.target.result);
          setFileValue(fileReaded);
          setFileLoaded(fileReaded);
          setFileNameInput(file.name);
          setInputFileName({ filename: fileName });
          setLoading(false);
          setErr(false);
          return;
        }
      };
      return;
    } else {
      setErr(true);
      setLoading(false);
    }
  };

  const onChangeFile = (e: any) => {
    const file = e.currentTarget.files[0];
    setReduxValue({ contact_variable: undefined, fileStore: undefined });
    // setFieldValue('contact_variable', '');
    if (file) {
      setFileNameInput(file.name);
      setFile(file);
    }
  };

  return (
    <StyledGridContainer>
      <InputFileBox>
        <label
          htmlFor="file"
          style={{
            background: 'transparent',
            height: 50,
            width: '100%',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            paddingLeft: 10,
          }}
        >
          <Typography variant="body1">
            {[fileNameInput] && [fileNameInput]}
          </Typography>
          <StyledIcon />
        </label>
        <FormControl>
          <StyledInputFile
            className="btn-file"
            variant="outlined"
            id="file"
            type="file"
            size="small"
            onChange={(e: any) => {
              // setFieldValue('filename', e.target.value.toLowerCase());
              onChangeFile(e);
            }}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <StyledIcon />
                </InputAdornment>
              ),
            }}
          />
        </FormControl>
      </InputFileBox>

      <ButtonInputFile>
        <Button
          variant="contained"
          color="secondary"
          onClick={() => fileChanged()}
          disabled={!file || loading}
        >
          {loading ? (
            <CircularProgress size={24} />
          ) : (
            <TextButtonUpload>Analisar Layout</TextButtonUpload>
          )}
        </Button>
      </ButtonInputFile>
      {isErr ? (
        <StyledTypografhyErr>
          Arquivo nao suportado! insira asquivos no formato xlsx / csv.
        </StyledTypografhyErr>
      ) : null}
    </StyledGridContainer>
  );
};

export default InputFile;
