import {
  Box,
  Button,
  InputLabel,
  MenuItem,
  Select,
  styled,
  Typography,
  Grid,
} from '@mui/material';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';

export const TopContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  marginTop: 40,
  width: '100%',

  [theme.breakpoints.down('xl')]: {
    '& .MuiSvgIcon-root': {
      fontSize: 20,
    },
  },
}));

export const TopSelect = styled(Select)(() => ({
  width: '15vw',
  borderRadius: 0,
}));

export const AssociateButton = styled(Button)(({ theme }) => ({
  width: '30%',

  [theme.breakpoints.down('xl')]: {
    fontSize: 12,
  },
}));

export const SubTitleContainer = styled(Box)(() => ({
  width: '100%',
  marginTop: 40,
}));

export const VariablesContainer = styled(Box)(({ theme }) => ({
  width: '100%',
  height: 320,
  display: 'flex',
  flexDirection: 'column',
  overflow: 'auto',
  border: '1px solid #2E3192',
  alignItems: 'left',
  borderRadius: 8,
  backgroundColor: '#fafafa',
  transition: 'all 0.2s ease',

  [theme.breakpoints.down('xl')]: {
    width: '100%',
    height: 250,

    '& .MuiSvgIcon-root': {
      fontSize: 20,
    },
  },
}));

export const Variable = styled(Box)(() => ({
  display: 'flex',
  alignItems: 'center',
  columnGap: 2,
  justifyContent: 'center',
}));

export const VariableInnerTextContainer = styled(Box)(() => ({
  display: 'flex',
  borderBottom: '1px solid #2E3192',
  width: '100%',
  height: 50,
  alignItems: 'center',
  justifyContent: 'space-between',
  backgroundColor: '#fff',
  transition: 'all 0.2s ease',
}));

export const TemplateVariableText = styled(Typography)(({ theme }) => ({
  marginLeft: 16,

  [theme.breakpoints.down('xl')]: {
    fontSize: 12,
  },
}));

export const HeaderText = styled(Typography)(({ theme }) => ({
  [theme.breakpoints.down('xl')]: {
    fontSize: 12,
  },
}));

export const CloseIcon = styled(CloseOutlinedIcon)(() => ({
  cursor: 'pointer',
  marginRight: 16,
  transition: 'all 0.2s ease',
  '&:hover': {
    color: '#000',
  },
}));

export const InputLabelText = styled(InputLabel)(({ theme }) => ({
  [theme.breakpoints.down('xl')]: {
    fontSize: 13,
  },
}));

export const ButtonText = styled(Typography)(({ theme }) => ({
  [theme.breakpoints.down('xl')]: {
    fontSize: 13,
  },
}));

export const AssociationText = styled(Typography)(({ theme }) => ({
  [theme.breakpoints.down('xl')]: {
    fontSize: 14,
  },
}));

export const MenuItemStyled = styled(MenuItem)(({ theme }) => ({
  [theme.breakpoints.down('xl')]: {
    fontSize: 13,
  },
}));

export const StyledGrid = styled(Grid)(() => ({
  display: 'flex',
  flexDirection: 'row',
}));

export const StyledLeft = styled('div')(() => ({
  display: 'flex',
  flexDirection: 'column',
}));

export const StyledContentRigth = styled('div')(() => ({
  display: 'flex',
  flexDirection: 'column',
}));

export const StyledLeftContentTop = styled('div')(() => ({
  display: 'flex',
  marginBottom: '1em',
}));

export const StyledLeftContentRight = styled('div')(() => ({
  width: '-webkit-fill-available',
  marginLeft: '20px',
  marginRight: '60px',
}));

export const TextButtonUpload = styled(Typography)(({ theme }) => ({
  [theme.breakpoints.down('xl')]: {
    fontSize: 12,
  },
}));
