import { Box, styled, Typography } from '@mui/material';

export const ContainerTitleStyled = styled(Box)(() => ({
  width: 'calc(100% - 20px)',
  alignItems: 'center',
  margin: '10px 10px 20px 10px',
  padding: '10px 0 0 0',
  display: 'flex',
  justifyContent: 'space-between',
}));

export const ContainerTitleTextStyled = styled(Typography)(
  ({ theme: { breakpoints } }) => ({
    fontSize: 28,
    fontWeight: 'bold',

    [breakpoints.down('xl')]: {
      fontSize: 20,
    },
  })
);

export const ContainerTitleMiddleBoxStyled = styled(Box)(() => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  width: '600px',
}));

export const ContainerTitleRigthBoxStyled = styled(Box)(() => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
}));

export const ContainerTitleUsernameStyled = styled(Typography)(
  ({ theme: { breakpoints } }) => ({
    fontSize: 30,
    paddingRight: '20px',
    [breakpoints.down('xl')]: {
      fontSize: 18,
    },
  })
);

export const ContainerStyled = styled(Box)(() => ({
  width: '100%',
}));

export const LoadingContainer = styled(Box)(() => ({
  width: '100%',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  marginTop: 30,
}));
