import {
  Box,
  Divider,
  Grid,
  styled,
  Typography,
  Button,
  TextField,
	MenuItem,
} from '@mui/material';
import CloudUploadOutlinedIcon from '@mui/icons-material/CloudUploadOutlined';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';

export const StyledGridContainer = styled(Grid)(() => ({
  width: '100%',
}));
export const StyledIcon = styled(CloudUploadOutlinedIcon)(() => ({
  color: 'black',
  fontSize: 20,
  display: 'flex',
  marginRight: 20,
}));

export const InputFileBox = styled(Box)(({ theme }) => ({
  width: '100%',
  marginTop: 20,
  cursor: 'pointer',
  display: 'inline-block',
  height: 50,
  border: '1px solid #ccc',
  borderRadius: 5,

  '& .MuiFormControl-root': {
    width: '100%',
    visibility: 'hidden',
  },

  [theme.breakpoints.down('xl')]: {
    '& .MuiTypography-root': {
      fontSize: 13,
    },
  },
}));

export const StyledInputFile = styled(TextField)(() => ({
  width: '100%',
}));

export const ButtonInputFile = styled(Box)(() => ({
  width: '100%',
  display: 'flex',
  justifyContent: 'flex-end',
}));
export const StyledButtonFile = styled(Button)(({ theme }) => ({
  fontFamily: 'Montserrat',
  borderRadius: '12px',
  textTransform: 'initial',
  width: 250,
  height: 45,

  [theme.breakpoints.down('xl')]: {
    width: 200,
    height: 35,
  },
}));

export const TextButtonUpload = styled(Typography)(({ theme }) => ({
  [theme.breakpoints.down('xl')]: {
    fontSize: 12,
  },
}));

export const StyledBoxTitle = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  marginTop: '10px',
  columnGap: 10,
  width: '100%',
  marginBottom: '10px',
}));

export const StyledMiddleTitle = styled(Typography)(({ theme }) => ({
  [theme.breakpoints.down('xl')]: {
    fontSize: 14,
  },
  [theme.breakpoints.up('xl')]: {
    fontSize: 18,
  },
}));

export const DividerLine = styled(Divider)(() => ({
  backgroundColor: '#ccc',
  height: 1,
  width: '35%',
}));

export const StyledContactVariable = styled(Box)(() => ({
  width: '100%',
}));

export const StyledTypografhyErr = styled(Typography)(() => ({
  color: 'red',
}));

export const StyledGrid = styled(Grid)(() => ({
  overflow: 'auto',
  minHeight: '14.2em',
}));

export const BottomStyledGrid = styled(Grid)(() => ({
  overflow: 'auto',
  marginBottom: '50px',
}));

export const MenuItemStyled = styled(MenuItem)(({ theme }) => ({
  [theme.breakpoints.down('xl')]: { fontSize: 12 },
}));

export const AddSuggestionButton = styled(Button)(({ theme }) => ({
  width: '20%',

  [theme.breakpoints.down('xl')]: {
    fontSize: 12,
  },
}));

export const SuggestionsContainer = styled(Box)(({ theme }) => ({
	flex: 1,
	display: 'flex',
	gap: '12px',
}));

export const SuggestionItem = styled(Box)(({ theme }) => ({
	marginTop: '12px',
	marginBottom: '12px',
  width: '400px',
  border: '1px dashed #d0d0d0',
  borderRadius: 8,
  backgroundColor: '#fdfdfd',
	boxShadow: '1px 1px 5px 0px #f0f0f0',
	display: 'flex',
	flexDirection: 'column',
	gap: '12px',
	padding: '24px !important',
	flexShrink: 0,
	position: 'relative',
}));

export const ButtonText = styled(Typography)(({ theme }) => ({
  [theme.breakpoints.down('xl')]: {
    fontSize: 13,
  },
}));

export const CloseIcon = styled(CloseOutlinedIcon)(() => ({
	cursor: 'pointer',
  transition: 'all 0.2s ease',
  '&:hover': {
    color: '#000',
  },
}));

export const RichCardFileInput = styled(Box)(({ theme }) => ({
  width: '100%',
  marginTop: 20,
  cursor: 'pointer',
  display: 'inline-block',
  height: 50,
  border: '1px solid #ccc',
  borderRadius: 5,

  '& .MuiFormControl-root': {
    width: '100%',
    visibility: 'hidden',
  },

  [theme.breakpoints.down('xl')]: {
    '& .MuiTypography-root': {
      fontSize: 13,
    },
  },
}));
