import { TopContainerProps } from './interfaces';
import { Container, CustomDivider, PageTilte } from './styles';

export const TopContainer: React.FC<TopContainerProps> = ({
  title,
}: TopContainerProps): JSX.Element => (
  <>
    <Container>
      <PageTilte variant="h1">{title}</PageTilte>
    </Container>
    <CustomDivider />
  </>
);
