import { request } from '../BASE_REQUEST';
import { BASE_DATA_URL } from '../../api';

const getDataRequests = async (page, limit, query) => {
  try {
    const response = await request({
      url: `${BASE_DATA_URL}/api/whatsapp/requests`,
      method: 'GET',
      params: {
        page,
        limit,
        ...query,
      },
    });
    return response;
  } catch (error) {
    Promise.reject(error);
  }
};

export default getDataRequests;
