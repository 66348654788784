import { AxiosResponse } from 'axios';
import { request } from '../BASE_REQUEST';
import { BASE_DATA_URL } from '../../api';

export const uploadDataRequestFile = async (file: {
  name: string;
  content: string;
}): Promise<AxiosResponse<any> | undefined> => {
  const filename = file.name.replace(/\.[^/.]+$/, '');

  const response = await request({
    method: 'POST',
    url: `${BASE_DATA_URL}/api/whatsapp/file-upload`,
    data: { filename },
  });

  const UPLOAD_URL = response.data.uploadUrl;
  if (UPLOAD_URL) {
    return await createImage(file.content, UPLOAD_URL);
  } else {
    return response;
  }
};

export const createImage = async (
  file: string,
  url: string
): Promise<AxiosResponse<any>> => {
  console.log(file, url);

  const response = await request({
    method: 'PUT',
    url,
    data: file,
    headers: {
      'Content-Type': 'text/csv',
    },
  });

  return response;
};
