import { FormControl, TextField } from '@mui/material';
import { TransactionTypeSelectProps } from './interface';
import { MenuItemStyled, SelectTypeStyled } from './styles';

export const TransactionTypeSelect: React.FC<TransactionTypeSelectProps> = ({
  value,
  handleChange,
}: TransactionTypeSelectProps): JSX.Element => (
  <FormControl>
    <TextField
      required
      select
      label="Tipo de transação"
      value={value}
      onChange={handleChange}
      sx={SelectTypeStyled}
    >
      <MenuItemStyled value="CREDIT">Crédito</MenuItemStyled>
      <MenuItemStyled value="DEBIT">Débito</MenuItemStyled>
    </TextField>
  </FormControl>
);
