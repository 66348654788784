import { Typography } from '@mui/material';
import { TransactionSummaryProps } from './interface';
import { SummaryNumber } from './styles';

export const TransactionSummary: React.FC<TransactionSummaryProps> = ({
  userName,
  newBalance,
  value,
  originalBalance,
  type,
}: TransactionSummaryProps): JSX.Element => (
  <Typography variant="body2">
    Novo saldo de {userName} será de:
    <SummaryNumber>
      {newBalance
        ? type === 'CREDIT'
          ? (newBalance + Number(value)).toLocaleString('pt-br', {
              style: 'currency',
              currency: 'BRL',
            })
          : (newBalance - Number(value)).toLocaleString('pt-br', {
              style: 'currency',
              currency: 'BRL',
            })
        : type === 'CREDIT'
        ? (Number(originalBalance) + Number(value)).toLocaleString('pt-br', {
            style: 'currency',
            currency: 'BRL',
          })
        : (Number(originalBalance) - Number(value)).toLocaleString('pt-br', {
            style: 'currency',
            currency: 'BRL',
          })}
    </SummaryNumber>
  </Typography>
);
