import { request } from '../BASE_REQUEST';
import { BASE_AI_URL } from '../../api';

const getAiTabUrl = async (bucket, fileKey) => {
  try {
    const response = await request({
      url: `${BASE_AI_URL}/ai/download-attendance-file/`,
      method: 'GET',
      params: {
        bucket,
        file_key: fileKey,
      },
    });
    return response;
  } catch (error) {
    Promise.reject(error);
  }
};

export default getAiTabUrl;
