import {
  CircularProgress,
  Dialog,
  DialogContent,
  Slide,
  Switch,
  Toolbar,
} from '@mui/material';
import {
  FrameContainer,
  StyledBox,
  AppBarStyled,
  IconButtonStyled,
  TextName,
} from './styles';
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { IShowEmailTemplate } from './interfaces';
import CloseIcon from '@mui/icons-material/Close';
import { IReducers } from '../../../Auth/store/store';
import { CircularProgressBox } from '../../Stepper/FirstStep/styles';
import { IframeStyled } from '../../EditTemplate/TemplateBody/styles';
import { TransitionProps } from '@mui/material/transitions/transition';
import { ITemplate } from '../../../Auth/interfaces/template.interface';
import DesktopWindowsOutlinedIcon from '@mui/icons-material/DesktopWindowsOutlined';
import PhoneIphoneOutlinedIcon from '@mui/icons-material/PhoneIphoneOutlined';

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement;
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const ShowEmailTemplateModal: React.FC<IShowEmailTemplate> = ({
  open,
  setOpen,
  setScreenLoad,
}: IShowEmailTemplate): JSX.Element => {
  const { body, name }: ITemplate = useSelector(
    (state: IReducers) => state.template
  );

  const [isMobile, setIsMobile] = useState(false);

  const handleClose = () => {
    setOpen(false);
    setScreenLoad(false);
  };

  return (
    <Dialog fullScreen open={open} TransitionComponent={Transition}>
      <AppBarStyled>
        <Toolbar>
          <IconButtonStyled
            edge="start"
            color="inherit"
            onClick={handleClose}
            aria-label="close"
          >
            <CloseIcon
							color="secondary"
							sx={{ filter: 'brightness(0) invert(1)' }}
						/>
          </IconButtonStyled>
          <TextName variant="body1">{name}</TextName>
        </Toolbar>
      </AppBarStyled>
      <DialogContent>
        {body ? (
          <FrameContainer>
            <StyledBox>
              <DesktopWindowsOutlinedIcon />
              <Switch onChange={() => setIsMobile(!isMobile)} />
              <PhoneIphoneOutlinedIcon />
            </StyledBox>
            <IframeStyled
              isMobile={isMobile}
              srcDoc={body}
              title="output"
              sandbox="allow-scripts"
              frameBorder="0"
              height="100%"
            />
          </FrameContainer>
        ) : (
          <CircularProgressBox>
            <CircularProgress size={24} />
          </CircularProgressBox>
        )}
      </DialogContent>
    </Dialog>
  );
};

export default ShowEmailTemplateModal;
