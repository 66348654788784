import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { IReducers } from '../../../Auth/store/store';
import { ShowSmsContent } from '../ShowSMSContent';
import { ITemplate } from './interfaces';
import { ITemplate as TemplateInterface } from '../../../Auth/interfaces/template.interface';

import { ShowEmailTemplateModal } from '../ShowEmailTemplateModal';
import { EditSMSTemplateModal } from '../EditSMSTemplateModal';
import { DataGrid, GridColDef, GridRenderCellParams } from '@mui/x-data-grid';
import { Grid } from '@mui/material';
import { Edit, RemoveRedEyeOutlined } from '@mui/icons-material';

const Templates: React.FC<ITemplate> = ({
  templates,
  handleEditTemplate,
  content,
  type,
  handleShowContent,
  showContent,
  setShowContent,
  setScreenLoad,
  showEditSMSModal,
  showModal,
  setDeleteLoading,
  setShowEditSMSModal,
  deleteLoading,
  SMSContent,
  SMSTitle,
  pageCount,
  currentPage,
  handlePageChange,
  handleSaveTemplate
}: ITemplate): JSX.Element => {
	const { t } = useTranslation();
  const { id }: TemplateInterface = useSelector(
    (state: IReducers) => state.template
  );

	const columns: GridColDef[] = [
		{
			field: 'title',
			headerName: t('templates.table.header.title'),
			flex: 0.5,
		},
		{
			field: 'modified_at',
			headerName: t('templates.table.header.modified-at'),
			flex: 0.4,
		},
		{
      field: 'actions',
      headerName: t('templates.table.header.actions'),
      flex: 0.1,
      renderCell: (cell: GridRenderCellParams) => {
        return (
          <Grid container justifyContent={'space-evenly'} alignItems={'center'}>
            <Grid item>
              <RemoveRedEyeOutlined
                onClick={() => handleShowContent(cell.row.id, cell.row.title)}
                sx={{ cursor: 'pointer' }}
              />
            </Grid>
            {showContent ? (
              type === 'SMS' || type === 'WHATSAPP' ? (
                <ShowSmsContent
                  content={content}
                  open={id === cell.row.id}
                  setOpen={setShowContent}
                  setScreenLoad={setScreenLoad}
                />
              ) : (
                <ShowEmailTemplateModal
                  content={content}
                  open={true}
                  setOpen={setShowContent}
                  setScreenLoad={setScreenLoad}
                />
              )
            ) : null}

            <Grid item>
              <Edit
                onClick={() => handleEditTemplate(cell.row.id, cell.row.title)}
                sx={{ cursor: 'pointer' }}
              />
            </Grid>

            {showEditSMSModal && (
              <EditSMSTemplateModal
                content={SMSContent}
                title={SMSTitle}
                deleteLoading={deleteLoading}
                open={id === cell.row.id}
                setDeleteLoading={setDeleteLoading}
                setScreenLoad={setScreenLoad}
                setOpen={setShowEditSMSModal}
                handleEditTemplate={() =>
                  handleEditTemplate(cell.row.id, cell.row.title)
                }
                type={type}
                handleSaveTemplate={handleSaveTemplate}
              />
            )}
            {/*
            <Grid item>
              <DeleteOutlineIcon
                onClick={() => handleShowDeleteModalAndSetID(cell.row.id)}
                sx={{ cursor: 'pointer' }}
              />
            </Grid>

            {showModal && (
              <ConfirmDeleteTemplateModal
                deleteLoading={deleteLoading}
                open={id === cell.row.id}
                setOpen={setShowModal}
                handleDeleteTemplate={handleDeleteTemplate}
              />
            )} */}
          </Grid>
        );
      },
    }
	];

  return (
    <Grid container item xs={12}>
      <DataGrid
        columns={columns}
        rows={templates}
        sx={{ width: '100%', height: '80vh' }}
        pageSize={20}
        rowsPerPageOptions={[20]}
        rowCount={pageCount}
        pagination
        paginationMode="server"
        page={currentPage}
        onPageChange={handlePageChange}
      />
    </Grid>
  );
};

export default Templates;
