import * as React from 'react';
import { DataGrid, GridColDef, ptBR } from '@mui/x-data-grid';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import DownloadIcon from '@mui/icons-material/Download';
import SyncAltIcon from '@mui/icons-material/SyncAlt';

import { AddNewListButton } from '../../components/ContainmentList/AddNewListButton';

import { ContentContainer, CustomMenuItem, TextButton } from './styles';
import { IconButton, Menu, Theme } from '@mui/material';
import { useState } from 'react';
import { AddContactManuallyDialog } from '../../components/ContainmentList/AddContactManually';
import { AddContactUpload } from '../../components/ContainmentList/AddContactUpload';
import Container from '../../components/Container';

const dataGridStyles = ({ breakpoints }: Theme) => ({
  backgroundColor: '#F8F8F8',
  height: '712px',
  overflow: 'hidden',
  width: '99%',
  borderRadius: 0,

  [breakpoints.down('xl')]: {
    height: '480px',
  },
});

const ContainmentList: React.FC = (): JSX.Element => {
  const [showUpload, setShowUpload] = useState(false);
  const [showManually, setShowManually] = useState(false);

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  const openMenu = Boolean(anchorEl);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  const columns: GridColDef[] = [
    { field: 'type', headerName: 'Tipo', width: 170, editable: false },
    {
      field: 'listname',
      headerName: 'Nome da lista',
      width: 200,
      flex: 0.65,
      editable: false,
    },
    {
      field: 'contactsNumber',
      headerName: 'Número de contatos',
      flex: 0.65,
      editable: false,
      width: 150,
    },
    {
      field: 'actions',
      headerName: 'Ações',
      sortable: false,
      editable: false,
      renderCell: () => (
        <>
          <IconButton onClick={handleClick}>
            <MoreHorizIcon />
          </IconButton>
          <Menu
            id="actions-menu"
            anchorEl={anchorEl}
            open={openMenu}
            onClose={() => handleCloseMenu()}
          >
            <CustomMenuItem onClick={() => handleCloseMenu()}>
              <DownloadIcon />
              <TextButton>Download</TextButton>
            </CustomMenuItem>
            <CustomMenuItem onClick={() => handleCloseMenu()}>
              <SyncAltIcon />
              <TextButton>Asssociar lista à campanha</TextButton>
            </CustomMenuItem>
          </Menu>
        </>
      ),
      width: 200,
    },
  ];

  const rows = [
    {
      id: '0',
      type: 'Email',
      listname: 'lista_teste.csv',
      contactsNumber: 1800,
    },
    {
      id: '1',
      type: 'SMS',
      listname: 'lista_teste.csv',
      contactsNumber: 1800,
    },
  ];

  return (
    <Container
      title="Lista de Contenção"
      topRightContent={
        <AddNewListButton
          setShowUpload={setShowUpload}
          setShowManually={setShowManually}
        />
      }
    >
      <AddContactUpload showUpload={showUpload} setShowUpload={setShowUpload} />
      <AddContactManuallyDialog
        showManually={showManually}
        setShowManually={setShowManually}
      />

      <ContentContainer>
        <div style={{ width: '100%', overflow: 'hidden' }}>
          <DataGrid
            rows={rows}
            columns={columns}
            sx={dataGridStyles}
            disableSelectionOnClick
            localeText={ptBR.components.MuiDataGrid.defaultProps.localeText}
          />
        </div>
      </ContentContainer>
    </Container>
  );
};

export default ContainmentList;
