import {
  CircularProgress,
  DialogActions,
  DialogContent,
  FormControl,
  Grid,
  MenuItem,
  Select,
  TextField,
  Theme,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { ChangeEvent, FC, useState } from 'react';
import CustomDialogTitle from './DialogTtitle';
import { ICustomCreateTemplateDialog } from './interfaces';
import {
  CancelButton,
  CustomDialog,
  SaveButton,
  CircularProgressBox,
  NewTemplateText,
  TextButtonStyled,
} from './styles';

const EditTextFieldSms = ({ breakpoints }: Theme) => ({
  width: '100%',
  [`& fieldset`]: {
    borderRadius: 2,
  },

  [breakpoints.down('xl')]: {
    '& .MuiOutlinedInput-input': {
      height: 15,
      fontSize: 12,
    },
    '& .MuiInputLabel-root': {
      fontSize: 13,
    },
  },
});
const EditTextField = ({ breakpoints }: Theme) => ({
  width: '100%',
  [`& fieldset`]: {
    borderRadius: 2,
  },

  [breakpoints.down('xl')]: {
    '& .MuiOutlinedInput-input': {
      height: 15,
      fontSize: 12,
    },
    '& .MuiInputLabel-root': {
      fontSize: 13,
    },
  },
});

const CreateTemplateDialog: FC<ICustomCreateTemplateDialog> = ({
  setContent,
  setTitle,
  title,
	subject,
	setSubject,
  content,
  handleCreateTemplate,
  type,
  setShow,
  show,
  loading,
  errors,
}: ICustomCreateTemplateDialog): JSX.Element => {
	const { t } = useTranslation('translation', { keyPrefix: 'templates.dialogs.create-template' });

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    e.preventDefault();
    setTitle(e.target.value);
  };

  const handleChangeSubject = (e: ChangeEvent<HTMLInputElement>) => {
    e.preventDefault();
    setSubject(e.target.value);
  };

  const handleContentChange = (e: ChangeEvent<HTMLInputElement>) => {
    e.preventDefault();
    setContent(e.target.value);
  };

  const handleClose = () => {
    setShow(false);
  };

  return (
    <div id="custom-dialog-template">
      <CustomDialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={show}
        sx={{ borderRadius: 0 }}
        fullWidth
        maxWidth="sm"
        style={{ padding: '15px' }}
      >
        <CustomDialogTitle id="customized-dialog-title" onClose={handleClose}>
          <NewTemplateText variant="h2">{t('title')}</NewTemplateText>
        </CustomDialogTitle>
        {loading ? (
          <CircularProgressBox>
            <CircularProgress color="secondary" size={24} />
          </CircularProgressBox>
        ) : (
          <DialogContent dividers>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Select label={t('fields.type')} value={type} disabled>
                  <MenuItem value="WHATSAPP" selected={type === 'WHATSAPP'}>
                    Whatsapp
                  </MenuItem>
                  <MenuItem value="SMS" selected={type === 'SMS'}>
                    SMS
                  </MenuItem>
                  <MenuItem value="EMAIL" selected={type === 'EMAIL'}>
                    EMAIL
                  </MenuItem>
                </Select>
              </Grid>
            </Grid>
            <>
              <FormControl>
                <TextField
                  name="tax-input"
                  label={t('fields.title')}
                  type="text"
                  value={title}
                  onChange={handleChange}
                  sx={EditTextField}
                  style={{ marginTop: '10px' }}
                  inputProps={{ maxLength: 256 }}
                  error={Boolean(errors.title)}
                  helperText={errors.title}
                />
              </FormControl>

							{(type === "SMS" || type === "WHATSAPP") && (
								<FormControl>
									<TextField
										name="balance-input"
										label={
											type === 'SMS' ? t('fields.sms-content') : t('fields.whatsapp-content')
										}
										type="text"
										multiline
										minRows={5}
										maxRows={9}
										value={content}
										onChange={handleContentChange}
										sx={EditTextFieldSms}
										style={{ marginTop: '10px' }}
										inputProps={{ maxLength: 1024 }}
										error={Boolean(errors.content)}
										helperText={errors.content}
									/>
								</FormControl>
							)}

							{type === "EMAIL" && (
								<FormControl>
									<TextField
										name="email-subject"
										label={t('fields.email-subject')}
										type="text"
										value={subject}
										onChange={handleChangeSubject}
										sx={EditTextField}
										style={{ marginTop: '10px' }}
										inputProps={{ maxLength: 256 }}
										error={Boolean(errors.subject)}
										helperText={errors.subject}
									/>
								</FormControl>
							)}
            </>
          </DialogContent>
        )}
        <DialogActions>
          <CancelButton
            variant="contained"
            color="secondary"
            autoFocus
            onClick={handleClose}
          >
            <TextButtonStyled variant="body1">
							{t('buttons.cancel')}
						</TextButtonStyled>
          </CancelButton>

          <SaveButton
            color="primary"
            variant="contained"
            onClick={handleCreateTemplate}
          >
            <TextButtonStyled variant="body1">
							{t('buttons.save')}
						</TextButtonStyled>
          </SaveButton>
        </DialogActions>
      </CustomDialog>
    </div>
  );
};

export default CreateTemplateDialog;
