import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { BackButton } from '../Buttons/BackButton';
import { FowardButton } from '../Buttons/FowardButton';
import FirstStep from '../Stepper/FirstStep';
import { SecondStep } from '../Stepper/SecondStep';
import FourthStep from '../Stepper/FourthStep/FourthStep';

import { CampaingTabsProps } from '../../utils/interfaces';
import { StyledButtonsContainer, StyledTabPanel } from './styles';

import { ConfirmationModal } from './ConfirmationModal';
import { useFormikContext } from 'formik';
import { IMatrixVariables } from '../Stepper/VariablesStep/interfaces';
import StepHeader from '../CreateCampaignHeader';
import { Grid } from '@mui/material';

interface IValues {
  title: string;
  weekly_schedule: string[];
  contact_variable: string;
  template: string;
  matrix_variables: IMatrixVariables[];
}

const CampaingTabs: React.FC<CampaingTabsProps> = ({
  step,
  setStep,
  loading,
  setLoading,
  setOpenSideBar,
}: CampaingTabsProps): JSX.Element => {
  const {
    values: { weekly_schedule, contact_variable, title, campaign_type },
  } = useFormikContext<IValues>();

  const { t } = useTranslation();

  const [open, setOpen] = useState(false);

  const handleChange = (event: React.ChangeEvent<any>, newValue: number) => {
    setStep(newValue);
  };
  const handleClosePopUp = () => {
    setOpen(false);
  };
  const handleOpenPopUp = () => {
    setOpen(true);
  };

  return (
    <Grid container justifyContent={'center'}>
      <Grid container item xs={10}>
        <Grid container item xs={12} justifyContent={'space-between'}>
          <Grid item xs={campaign_type === 'SMS' ? 4 : 6}>
            <StepHeader
              handleChange={handleChange}
              field={title}
              fixedNumber={0}
              showNumber={1}
              step={step}
              title={t('create-campaign.tabs.campaign.title')}
            />
          </Grid>
          <Grid item xs={campaign_type === 'SMS' ? 4 : 6}>
            <StepHeader
              handleChange={handleChange}
              field={contact_variable}
              fixedNumber={1}
              showNumber={2}
              step={step}
              title={t('create-campaign.tabs.settings.title')}
            />
          </Grid>
          {campaign_type === 'SMS' && (
            <Grid item xs={4}>
              <StepHeader
                handleChange={handleChange}
                field={weekly_schedule}
                fixedNumber={2}
                showNumber={3}
                step={step}
                title={t('create-campaign.tabs.schedule.title')}
              />
            </Grid>
          )}
        </Grid>
        <Grid container item xs={12}>
          <StyledTabPanel value={step} index={0}>
            <FirstStep open={open} setOpen={setOpenSideBar} />
          </StyledTabPanel>
          <StyledTabPanel value={step} index={1}>
            <SecondStep />
          </StyledTabPanel>
          {campaign_type === 'SMS' && (
            <StyledTabPanel value={step} index={2}>
              <FourthStep />
            </StyledTabPanel>
          )}
          <ConfirmationModal
            loading={loading}
            setLoading={setLoading}
            open={open}
            handleClose={handleClosePopUp}
          />
        </Grid>
      </Grid>
      <StyledButtonsContainer container item xs={12}>
        {step === 0 ? (
          <div></div>
        ) : (
          <BackButton
            isLastStep={step}
            handleClick={() => setStep(s => s - 1)}
          />
        )}
        <FowardButton
          setStep={setStep}
          handleClickOpen={() =>
            (campaign_type === 'WHATSAPP' || weekly_schedule) &&
            handleOpenPopUp()
          }
          isLastStep={step}
        />
      </StyledButtonsContainer>
    </Grid>
  );
};

export default CampaingTabs;
