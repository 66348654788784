import { Box, styled, Typography } from '@mui/material';

export const StyledContainer = styled(Box)(() => ({
  width: '100%',
  height: 400,
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
}));

export const StyledTypography = styled(Typography)(({ theme }) => ({
  marginTop: theme.spacing(4),
}));

export const StyledTypographyLink = styled(Typography)(({ theme }) => ({
  color: theme.palette.secondary.main,
  textDecoration: 'underline',
  cursor: 'pointer',
}));
