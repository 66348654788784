import { Box, Button, TextField, Typography, styled } from '@mui/material';
import Dialog from '@mui/material/Dialog';
import FormControl from '@mui/material/FormControl';
import CloudUploadOutlinedIcon from '@mui/icons-material/CloudUploadOutlined';

export const NoWrapSpan = styled('span')(() => ({
  width: '100%',
  height: 'auto',
  display: 'inline-block',
  whiteSpace: 'nowrap',
  overflow: 'hidden !important',
  textOverflow: 'ellipsis',
}));

export const LoadingContainer = styled(Box)(() => ({
  width: '100%',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  marginTop: 30,
}));

export const CustomDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiPaper-root': {
    padding: '20px',
  },
  '& .MuiDialogActions-root': {
    padding: '20px',
  },
}));

export const CustomFormControl = styled(FormControl)(({ theme }) => ({
  margin: '15px',
}));

export const StyledIcon = styled(CloudUploadOutlinedIcon)(() => ({
  color: 'black',
  fontSize: 20,
  display: 'flex',
}));

export const InputFileBox = styled(Box)(({ theme }) => ({
  width: '100%',
  cursor: 'pointer',
  display: 'inline-block',
  height: 50,
  border: '1px solid #ccc',
  borderRadius: 5,
  paddingRight: 20,

  '& .MuiFormControl-root': {
    width: '100%',
    visibility: 'hidden',
  },

  [theme.breakpoints.down('xl')]: {
    '& .MuiTypography-root': {
      fontSize: 13,
    },
  },
}));

export const StyledInputFile = styled(TextField)(() => ({
  width: '100%',
}));

export const ButtonInputFile = styled(Box)(() => ({
  width: '100%',
  display: 'flex',
  justifyContent: 'flex-end',
}));
export const StyledButtonFile = styled(Button)(({ theme }) => ({
  fontFamily: 'Montserrat',
  borderRadius: '12px',
  textTransform: 'initial',
  width: 250,
  height: 45,

  [theme.breakpoints.down('xl')]: {
    width: 200,
    height: 35,
  },
}));

export const TextButtonUpload = styled(Typography)(({ theme }) => ({
  [theme.breakpoints.down('xl')]: {
    fontSize: 12,
  },
}));

export const StyledTypographyErr = styled(Typography)(() => ({
  color: 'red',
}));

export const SubmitButton = styled(Button)(({ theme }) => ({
  '&.Mui-disabled': {
    backgroundColor: '#E0E0E0',
    color: '#9E9E9E',
  },
}));
